import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./CoreChapterActivity.css";
import { settingsOutline } from "ionicons/icons";
import "../../Fonts.css";
import ProgressContainer from "../../components/progress/Progress";

const CoreChapterActivity: React.FC = () => {
  const router = useIonRouter();

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <ProgressContainer isActivity={true} percent={75}></ProgressContainer>
        <IonGrid>
          <IonRow class="ion-justify-content-between ion-align-items-center">
            <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon>
            <IonText className="titleText">Chapter 1</IonText>
            <IonText className="cciExitBtn" onClick={() => {router.push('/home/corestab', 'root', 'replace')}}>Exit</IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent cccoMainContent">
          <IonText className="cccoContentHeading">Your Experience</IonText>
          <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">
              You may have been diagnosed and treated for melanoma recently or
              up to several years ago.
            </IonText>
          </div>
          {/* <CoreChapterActivityQuestion question="" options=[{}] /> */}
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  router.push("/corechapterend", "forward", "push");
                }}
              >
                Next
              </IonButton>{" "}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default CoreChapterActivity;

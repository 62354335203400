import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonText,
} from "@ionic/react";

import "./MCQMultipleCheckOption.css";

import "../../Fonts.css";

interface MCQMultipleCheckOptionProps {
  index: number;
  text: string;
  isOpen: boolean;
  isSelected: Function;
}

const MCQMultipleCheckOption: React.FC<MCQMultipleCheckOptionProps> = ({
  index,
  text,
  isOpen,
  isSelected,
}) => {
  const selected = () => {
    isSelected(index);
  };

  return (
    <div className="acceMainContainer mcqmcoMainContainer">
      <IonGrid onClick={selected}>
        <IonRow>
          <IonCol size="auto" className="acceImgCol">
            {isOpen && (
              <IonImg className="mcqmcoCheckImg" src="assets/icons/CheckSelected.png"  />
            )}
            {!isOpen && (
              <IonImg className="mcqmcoCheckImg" src="assets/icons/Check.png"  />
            )}
          </IonCol>
          <IonCol className="acceTitleCol accqTitleCol">
            <IonText
              className="mcqmcoText"
            >
              {text}
            </IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default MCQMultipleCheckOption;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";


import { useState, useEffect } from "react";
import { UserService } from "../../../services/UserService";
import { postControlContentData, patchControlContentData, getContentProgressID } from "../../../services/dataApi";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";



const CC8: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    //if(SkinSelfCheckDataService.controlcontentNID===-1){
    getControlContentNID()
    // }

  }, []);

  async function getControlContentNID() {
    try {
      openLoader();
      const responseData = await getContentProgressID(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Control Content Response ---->>> ", respData);

            if (respData.length > 0) {
              //let parsedJSON = JSON.parse(respData[0]);
              let nid = respData[0].id;
              console.log(nid);
              let compProg = respData[0].completed;
              
              SkinSelfCheckDataService.controlcontentCompletedProgress=compProg
              console.log(nid);
              console.log("completed Progress",SkinSelfCheckDataService.controlcontentCompletedProgress);
              SkinSelfCheckDataService.controlcontentNID = nid;
            } else {
              SkinSelfCheckDataService.controlcontentNID = -1;
              console.log("Control Content NID empty");
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Control Content NID not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck NID - Error fetching data:", error);
    }
  }


  const navigateBack = () => {
    router.goBack();
  };

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };
  async function postControlContent() {
    openLoader();
    if (SkinSelfCheckDataService.controlcontentNID === -1) {
      try {
        openLoader();
        const responseData = await postControlContentData(

          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();

              UserService.controlBottomBarFromBack = 0;
              router.push("/home")
              //Save user data
              console.log("Patching Controlcontent success--" + respData);
            } else if (!isSuccess) {

              closeLoader();
              console.log("Patching Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    } else {
      try {
        openLoader();
        const responseData = await patchControlContentData(

          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();

              UserService.controlBottomBarFromBack = 0;
              router.push("/home")
              //Save user data
              console.log("posting Controlcontent success--" + respData);
            } else if (!isSuccess) {
              //Error

              closeLoader();
              console.log(" posting Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    }
  }


  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
                  className="settingsIcon"
                  icon={settingsOutline}
                  style={{ color: "transparent" }}
                ></IonIcon> */}
            <IonText className="titleText">Page 10</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
          <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Additional Resources
          </IonText>

          <div style={{ width: "100%", height: "16px" }} />


          {/* Main body here */}
          <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">
              <ul style={{ marginLeft: "16px" }}>
                <li>When looking for health or medical information online, it is important to find trustworthy and credible sources.</li>
                <li>Websites ending in .org, .edu, or .gov generally have reliable information because they come from registered organizations, educational institutions, or government agencies, respectively.</li>
                <li>When in doubt, talk with your health care provider to get the most reliable information about your health.</li>
                <li>If you are interested in learning even more about melanoma, sun safety and skin self-checks, consider the following sources:
                  <ul>
                    {/* <li><a href="https://www.skincancer.org/early-detection/self-exams/" target="_blank">https://www.skincancer.org/early-detection/self-exams/</a> </li>
                                        <li><a href="https://www.cancer.org/cancer/risk-prevention/sun-and-uv.html" target="_blank">https://www.cancer.org/cancer/risk-prevention/sun-and-uv.html</a></li>
                                        <li><a href="https://www.aad.org/public/diseases/skin-cancer/find/check-skin" target="_blank">https://www.aad.org/public/diseases/skin-cancer/find/check-skin</a></li> */}

                    <li><a href="https://www.cancer.org/cancer/types/melanoma-skin-cancer.html" target="_blank">https://www.cancer.org/cancer/types/melanoma-skin-cancer.html</a></li>
                    <li><a href="https://www.skincancer.org/skin-cancer-information/melanoma/" target="_blank">https://www.skincancer.org/skin-cancer-information/melanoma</a></li>
                    <li><a href="https://www.cancer.gov/types/skin" target="_blank">https://www.cancer.gov/types/skin</a></li>
                    <li><a href="https://skincancerprevention.org/" target="_blank">https://skincancerprevention.org/</a></li>
                    <li><a href="https://www.aad.org/public/diseases/skin-cancer" target="_blank">https://www.aad.org/public/diseases/skin-cancer</a></li>
                  </ul>
                </li>
              </ul>
            </IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText cc8Text"
              dangerouslySetInnerHTML={{
                __html: "Note: These links will open in a separate window.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <IonImg src="/assets/images/cc/Resources.svg"></IonImg>
          <div style={{ width: "100%", height: "116px" }} />
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      SkinSelfCheckDataService.controlcontentUserProgress = 10;
                      if (SkinSelfCheckDataService.controlcontentCompletedProgress < 10) {
                        postControlContent()
                      } else {
                        router.push("/home")
                      }
                      // router.push("/home")
                    }}
                  >
                    Back to content
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC8;

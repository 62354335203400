import { DataService } from "./DataService";
import { postProgress } from "../../src/services/dataApi";

export abstract class ProgressDataService {
  public static isGetCalled = false;

  //Core 1
  public static c1Ch0Progress = 0;
  public static c1Ch1Progress = 0;
  public static c1Ch2Progress = 0;
  public static c1Ch3Progress = 0;
  public static c1Ch4Progress = 0;
  public static c1Ch5Progress = 0;
  public static c1Ch6Progress = 0;
  public static c1Ch7Progress = 0;

  //Core 2
  public static c2Ch0Progress = 0;
  public static c2Ch1Progress = 0;
  public static c2Ch2Progress = 0;
  public static c2Ch3Progress = 0;
  public static c2Ch4Progress = 0;
  public static c2Ch5Progress = 0;

  public static progressObjTemp = {
    cores: [
      {
        coreNo: 1,
        chapters: [
          {
            chapterNo: 0,
            progress: 0,
          },
          {
            chapterNo: 1,
            progress: 0,
          },
          {
            chapterNo: 2,
            progress: 0,
          },
          {
            chapterNo: 3,
            progress: 0,
          },
          {
            chapterNo: 4,
            progress: 0,
          },
          {
            chapterNo: 5,
            progress: 0,
          },
          {
            chapterNo: 6,
            progress: 0,
          },
          {
            chapterNo: 7,
            progress: 0,
          },
        ],
      },
      {
        coreNo: 2,
        chapters: [
          {
            chapterNo: 0,
            progress: 0,
          },
          {
            chapterNo: 1,
            progress: 0,
          },
          {
            chapterNo: 2,
            progress: 0,
          },
          {
            chapterNo: 3,
            progress: 0,
          },
          {
            chapterNo: 4,
            progress: 0,
          },
          {
            chapterNo: 5,
            progress: 0,
          },
        ],
      },
    ],
  };

  //Progress
  public static progressObj = {
    cores: [
      {
        chapters: [
          {
            chapterNo: -1,
            progress: -1,
          },
        ],
      },
    ],
  };

  public static updateProgressOfCurrChapter = (progress: number, isActivity: boolean = false) => {
    let core = DataService.currentCoreIndex + 1;
    let chapter = DataService.currentChapterIndex + 1;

    if (core === 1) {
      if (!isActivity) {
        if (chapter === 7) {
          this.c1Ch7Progress = progress;
          console.log(this.c1Ch7Progress);
        } else if (chapter === 1) {
          this.c1Ch1Progress = progress;
          console.log(this.c1Ch1Progress);
        } else if (chapter === 2) {
          this.c1Ch2Progress = progress;
          console.log(this.c1Ch2Progress);
        }
        if (chapter === 3) {
          this.c1Ch3Progress = progress;
          console.log(this.c1Ch3Progress);
        }
        if (chapter === 4) {
          this.c1Ch4Progress = progress;
          console.log(this.c1Ch4Progress);
        }
        if (chapter === 5) {
          this.c1Ch5Progress = progress;
          console.log(this.c1Ch5Progress);
        }
        if (chapter === 6) {
          this.c1Ch6Progress = progress;
          console.log(this.c1Ch6Progress);
        }
        if (chapter === 8) {
          this.c1Ch7Progress = progress;
          console.log(this.c1Ch7Progress);
        }
      }
      if (isActivity) {
        this.c1Ch0Progress = progress;
        console.log(this.c1Ch0Progress);
      }
    }

    if (core === 2) {
      if (!isActivity) {
        if (chapter === 5) {
          this.c2Ch5Progress = progress;
          console.log(this.c2Ch5Progress);
        } else if (chapter === 1) {
          this.c2Ch1Progress = progress;
          console.log(this.c2Ch1Progress);
        } else if (chapter === 2) {
          this.c2Ch2Progress = progress;
          console.log(this.c2Ch2Progress);
        }
        if (chapter === 3) {
          this.c2Ch3Progress = progress;
          console.log(this.c2Ch3Progress);
        }
        if (chapter === 4) {
          this.c2Ch4Progress = progress;
          console.log(this.c2Ch4Progress);
        }
        if (chapter === 6) {
          this.c2Ch5Progress = progress;
          console.log(this.c2Ch5Progress);
        }
      }
      if (isActivity) {
        this.c2Ch0Progress = progress;
        console.log(this.c2Ch0Progress);
      }
    }

    this.getProgressJSON();
  };

  public static getProgressJSON = () => {
    let obj: any = {
      cores: [
        {
          coreNo: 1,
          chapters: [
            {
              chapterNo: 0,
              progress: this.c1Ch0Progress,
            },
            {
              chapterNo: 1,
              progress: this.c1Ch1Progress,
            },
            {
              chapterNo: 2,
              progress: this.c1Ch2Progress,
            },
            {
              chapterNo: 3,
              progress: this.c1Ch3Progress,
            },
            {
              chapterNo: 4,
              progress: this.c1Ch4Progress,
            },
            {
              chapterNo: 5,
              progress: this.c1Ch5Progress,
            },
            {
              chapterNo: 6,
              progress: this.c1Ch6Progress,
            },
            {
              chapterNo: 7,
              progress: this.c1Ch7Progress,
            },
          ],
        },
        {
          coreNo: 2,
          chapters: [
            {
              chapterNo: 0,
              progress: this.c2Ch0Progress,
            },
            {
              chapterNo: 1,
              progress: this.c2Ch1Progress,
            },
            {
              chapterNo: 2,
              progress: this.c2Ch2Progress,
            },
            {
              chapterNo: 3,
              progress: this.c2Ch3Progress,
            },
            {
              chapterNo: 4,
              progress: this.c2Ch4Progress,
            },
            {
              chapterNo: 5,
              progress: this.c2Ch5Progress,
            },
          ],
        },
      ],
    };

    console.log(obj);
    this.progressObj = obj;
  };

  public static getProgressJSONInit = () => {
    let obj: any = {
      cores: [
        {
          coreNo: 1,
          chapters: [
            {
              chapterNo: 0,
              progress: 0,
            },
            {
              chapterNo: 1,
              progress: 0,
            },
            {
              chapterNo: 2,
              progress: 0,
            },
            {
              chapterNo: 3,
              progress: 0,
            },
            {
              chapterNo: 4,
              progress: 0,
            },
            {
              chapterNo: 5,
              progress: 0,
            },
            {
              chapterNo: 6,
              progress: 0,
            },
            {
              chapterNo: 7,
              progress: 0,
            },
          ],
        },
        {
          coreNo: 2,
          chapters: [
            {
              chapterNo: 0,
              progress: 0,
            },
            {
              chapterNo: 1,
              progress: 0,
            },
            {
              chapterNo: 2,
              progress: 0,
            },
            {
              chapterNo: 3,
              progress: 0,
            },
            {
              chapterNo: 4,
              progress: 0,
            },
            {
              chapterNo: 5,
              progress: 0,
            },
          ],
        },
      ],
    };

    console.log(obj);
    this.progressObj = obj;
    return obj;
  };

  public static updateLocalProgressFromGet = () => {
    let progressData = this.progressObj;

    // Core 1
    this.c1Ch0Progress = progressData.cores[0].chapters[0].progress;
    this.c1Ch1Progress = progressData.cores[0].chapters[1].progress;
    this.c1Ch2Progress = progressData.cores[0].chapters[2].progress;
    this.c1Ch3Progress = progressData.cores[0].chapters[3].progress;
    this.c1Ch4Progress = progressData.cores[0].chapters[4].progress;
    this.c1Ch5Progress = progressData.cores[0].chapters[5].progress;
    this.c1Ch6Progress = progressData.cores[0].chapters[6].progress;
    this.c1Ch7Progress = progressData.cores[0].chapters[7].progress;

    // Core 2
    this.c2Ch0Progress = progressData.cores[1].chapters[0].progress;
    this.c2Ch1Progress = progressData.cores[1].chapters[1].progress;
    this.c2Ch2Progress = progressData.cores[1].chapters[2].progress;
    this.c2Ch3Progress = progressData.cores[1].chapters[3].progress;
    this.c2Ch4Progress = progressData.cores[1].chapters[4].progress;
    this.c2Ch5Progress = progressData.cores[1].chapters[5].progress;
  };

  public static getProgressofSection1 = () => {
    var count = 0;
    if (this.c1Ch0Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch1Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch2Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch3Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch4Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch5Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch6Progress == 100) {
      count = count + 1;
    }
    if (this.c1Ch7Progress == 100) {
      count = count + 1;
    }
    return Math.round((count / 8) * 100);
  }

  public static getProgressofSection2 = () => {
    var count = 0;
    if (this.c2Ch0Progress == 100) {
      count = count + 1;
    }
    if (this.c2Ch1Progress == 100) {
      count = count + 1;
    }
    if (this.c2Ch2Progress == 100) {
      count = count + 1;
    }
    if (this.c2Ch3Progress == 100) {
      count = count + 1;
    }
    if (this.c2Ch4Progress == 100) {
      count = count + 1;
    }
    if (this.c2Ch5Progress == 100) {
      count = count + 1;
    }
    return Math.round((count / 6) * 100);
  }
}

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
} from "@ionic/react";
import "./HomeStateCompleted.css";

interface HomeStateCompletedProps {
    imageUrl: string;
    title: string;
    statusImgUrl: string;
    desc: string;
    isCoreCompleted: boolean;
}

const HomeStateCompleted: React.FC<HomeStateCompletedProps> = ({
    imageUrl,
    title,
    statusImgUrl,
    desc,

}) => {
    return (
                <IonCol className="hscColCores">
                    <IonGrid >
                        <IonRow>
                            <IonCol size="auto" className="hscImageCol">
                                <IonImg className="hscImg" src={imageUrl}></IonImg>
                            </IonCol>
                            <IonCol>
                                <IonRow className="hscRowImgPos">
                                    <IonCol>
                                        <IonText className="hscCoreText">{title}</IonText>
                                    </IonCol>
                                    <IonCol className="hscColImg">
                                        <IonImg src={statusImgUrl} className="hscCheckMark"></IonImg>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonText className="hscCoreDescText">{desc}</IonText>
                                    </IonCol>
                                </IonRow>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonCol>
    );
};

export default HomeStateCompleted;

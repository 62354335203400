import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
} from "@ionic/react";
import "./Register.css";
import { useRef, useState } from "react";
import InputTextField from "../../components/input_text_field/InputTextField";
let username = "";
let password = "";
let confirmpassword = "";
const Register: React.FC = () => {
    var router = useIonRouter();


    const contentRef = useRef<HTMLIonContentElement | null>(null);
    const scrollIconRef = useRef<HTMLIonImgElement | null>(null);

    let scrollThroughBtn = false;
    const scrollToBottom = () => {
        contentRef.current && contentRef.current.scrollToBottom();
        if (scrollIconRef.current) {
            scrollIconRef.current.style.visibility = "hidden";
        }
    };

    const scrolling = () => {
        console.log("SCROLLLLLING");
        if (scrollIconRef.current) {
            scrollIconRef.current.style.visibility = "hidden";
        }
    };

    const scrollingEnd = () => {
        console.log("SCROLLLLLING ENDDDD");
        if (scrollIconRef.current) {
            scrollIconRef.current.style.visibility = "visible";
        }
    };


    // const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [enableNextBtn, setEnableNextBtn] = useState(true);
    // const [errorText, setErrorText] = useState("");

    const nextBtnClickHandler = () => {
        if (!enableNextBtn) {
            return;
        }
        register(username, password);
    };

    const goToLogin = () => {
        router.push("/login", "forward", "push");
      }

    async function register(username: string, password: string) {
        console.log("Method Called");
        // router.push("/home", "root", "replace");
    }

    const usernameChanged = (event: any) => {
        console.log("userame: " + event.detail.value);
        username = event.detail.value;

        checkEnableNextBtn();
    };

    const passwordChanged = (event: any) => {
        console.log("password: " + event.detail.value);
        password = event.detail.value;

        checkEnableNextBtn();
    };

    const confirmpasswordChanged = (event: any) => {
        console.log("confirmpassword: " + event.detail.value);
        confirmpassword = event.detail.value;

        checkEnableNextBtn();
    };

    const checkEnableNextBtn = () => {
        if (username.length > 0 && password.length > 0 && confirmpassword.length > 0 && (password === confirmpassword)) {
            console.log("Entered");
            setEnableNextBtn(true);
        } else {
            setEnableNextBtn(false);
        }
    };
    return (
        <IonPage>
            <IonContent
                fullscreen
                style={{ "--ion-background-color": "#187685" }}
                ref={contentRef}
                scrollEvents={true}
                onIonScrollStart={scrolling}
                onIonScrollEnd={scrollingEnd}
            >
                <div
                    className="cciBackButtonContainer"
                    onClick={() => {
                        router.goBack();
                    }}
                >
                    <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
                </div>
                <IonGrid>
                    <IonRow class="profileTitleRow">
                        <IonCol size="3"></IonCol>
                        <IonCol size="6" className="scsdTitle">
                            <IonText className="titleText">Register</IonText>
                        </IonCol>
                        <IonCol
                            className="scsdColRight"
                            size="3"
                        >
                            <IonText className="scsdRightButton"></IonText>
                        </IonCol>
                    </IonRow>
                </IonGrid>
                <div className="rbpmainContent">
                    {/* <div className="htScrollIconContainer">
              <IonImg
                src="assets/icons/FixedScroll.png"
                className="htScrollIcon"
                onClick={scrollToBottom}
                ref={scrollIconRef}
                alt="none"
              ></IonImg>
            </div> */}
                    <IonGrid className="rGrid">
                        <IonRow>
                            <IonCol size="12">
                                <IonText className="rWelcomeText">Hello!</IonText>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size="12">
                                <IonText className="rRegisterText"> Register to get started!</IonText>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid className="textInputGrid">
                        <IonRow>
                            {/* <IonCol size="12">
                                <InputTextField
                                    placeholder="Email"
                                    icon="assets/icons/MailOutline.png"
                                    isPassword={false}
                                    val=""
                                />
                            </IonCol>
                            <IonCol className="loginTextFieldCol" size="12">
                                <InputTextField
                                    placeholder="Password"
                                    icon="assets/icons/LockOutline.png"
                                    isPassword={true}
                                    val=""
                                />
                            </IonCol>
                            <IonCol className="loginTextFieldCol" size="12">
                                <InputTextField
                                    placeholder="Confirm Password"
                                    icon="assets/icons/LockOutline.png"
                                    isPassword={true}
                                    val=""
                                />
                            </IonCol> */}

                            <IonCol size="12" className="loginLoginBtnCol">
              {enableNextBtn && (
                <IonButton
                  className="loginBtnEnabled loginLoginBtn"
                  onClick={() => nextBtnClickHandler()}
                >
                  Register
                </IonButton>
              )}
              {!enableNextBtn && (
                <IonButton
                  className="loginBtn"
                  onClick={() => nextBtnClickHandler()}
                >
                  Register
                </IonButton>
              )}
            </IonCol>
              <IonCol size="12" className="rColText">
                <IonText className="ildescText">Already have an account?</IonText>
                <IonText className="ilregText" onClick={goToLogin}>Login</IonText>
              </IonCol>
                        </IonRow>
                    </IonGrid>
                </div>
            </IonContent>
        </IonPage>
    );
};

export default Register;

import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";

import "./CompletedChaptersSummary.css";

import "../../Fonts.css";

interface CompletedChaptersSummaryProps {
  title: string;
  options: Array<any>;
}

const CompletedChaptersSummary: React.FC<CompletedChaptersSummaryProps> = ({
  title,
  options,
}) => {
  return (
    <div className="accMainContainer accccMainContainer abbsMainContainer ccsMainContainer animate__animated animate__fadeInRight">
      <IonGrid className="accTitleGrid accccTitleGrid">
        <IonRow>
          <IonCol className="accccTitleCol ccsTitleCol" size="8">
            <IonText className="ccsTitleText">{title}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
     {(options !== null) && <IonGrid>
        {options.map((option: string, i: number) => {
          return <IonRow className="ccsOptionRow">
            <IonCol className="ccsPointsCol" size="12">
                <div>
                    <IonImg className="ccsImg" src="assets/icons/checkcircle.png" />
                </div>
                <div className="ccsOptionTextDiv">
                    <IonText className="ccsOptionText">{option}</IonText>
                </div>
            </IonCol>
          </IonRow>;
        })}
      </IonGrid>}
    </div>
  );
};

export default CompletedChaptersSummary;

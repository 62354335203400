import {
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useState } from "react";

import "./ProfileEditTextField.css";
import "../../Fonts.css";

import InputTextField from "../input_text_field/InputTextField";

interface ProfileEditTextFieldProps {
  placeholder: string;
  imageUrl: string;
  isPassword: boolean;
  textChanged: Function;
  clear: Function;
}

const ProfileEditTextField: React.FC<ProfileEditTextFieldProps> = ({
  placeholder,
  imageUrl,
  isPassword,
  textChanged,
  clear
}) => {
  const [textVal, setTextVal] = useState("");
  const [updated, setUpdated] = useState(false)

  const onTextChanged = (event: any) => {
    setTextVal(event.detail.value);
    textChanged(event);
  };

  const onClear = () => {
    setTextVal("");
    setUpdated(!updated);
    clear();
  };

  return (
    <IonGrid className="textInputGrid petfGrid">
      <IonRow>
        <IonCol>
          <InputTextField
            placeholder={placeholder}
            icon={imageUrl}
            isPassword={isPassword}
            textChanged={onTextChanged}
            val={textVal}
          />
        </IonCol>
        <IonCol className="petfCancelCol" size="auto">
          <div style={{ paddingRight: "4px", paddingBottom: "8px" }}>
            <IonImg
              src="assets/icons/CloseCircleOutline.png"
              onClick={() => {
                onClear();
              }}
            />
          </div>
          <div style={{ height: "2px", backgroundColor: "#9DA2B2" }}></div>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default ProfileEditTextField;

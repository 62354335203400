import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    useIonRouter,
  } from "@ionic/react";
  import { useState } from "react";
  
  import "./AccordianCoreContentExp.css";
  
  import "../../Fonts.css";
  
  interface AccordianCoreContentExpProps {
    text: string;
  }
  
  const AccordianCoreContentExp: React.FC<AccordianCoreContentExpProps> = ({text}) => {
    const [isOpen, setIsOpen] = useState(false);
    var router = useIonRouter();
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
   
  
    return (
      <div className="acceMainContainer">
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="acceImgCol">
              <IonImg src="assets/icons/ExclamationCircleFill.svg" />
            </IonCol>
            <IonCol className="acceTitleCol" >
              <IonText className="acceTitleText" dangerouslySetInnerHTML={{ __html: text }} ></IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }
  
  export default AccordianCoreContentExp;
  
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  useIonRouter,
} from "@ionic/react";
import "./CoreChapterContentOne.css";
import { settingsOutline } from "ionicons/icons";
import "../../Fonts.css";
import ProgressContainer from "../../components/progress/Progress";
import AccordianCoreContent from "../../components/accordian_core_content/AccordianCoreContent";
import AccordianCoreContentImgExp from "../../components/accordian_core_content_img_exp/AccordianCoreContentImgExp";
import AccordianCoreContentExp from "../../components/accordian_core_content_exp/AccordianCoreContentExp";
import AccordianMainImgText from "../../components/accordian_mainimg_text/AccordianMainimgText";
import AccordianText from "../../components/accordian_text/AccordianText";
import MainImgText from "../../components/mainimg_text/MainImgText";
import { DataService } from "../../services/DataService";

const CoreChapterContentOne: React.FC = () => {
  const router = useIonRouter();

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <ProgressContainer isActivity={false} percent={50}></ProgressContainer>
        <IonGrid>
          <IonRow class="ion-justify-content-between ion-align-items-center">
            <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon>
            <IonText className="titleText">Chapter 1</IonText>
            <IonText
              className="cciExitBtn"
              onClick={() => {
                // DataService.tabSelected="Cores";
                router.push("/home/corestab", "root", "replace");
              }}
            >
              Exit
            </IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent cccoMainContent">
          {/* <MainImgText imageUrl="assets/images/melanoma_highlights.png" mainImgText="ebeiubfe fiuefiuefb eiuebfiuebf eiubiub" expansionPoints={[{text: "hello tgththfhfhf grtgg esr fthrtht rtgrtg tgrt", imageUrl: "assets/icons/LockOutline.png"}, {text: "hello tgththfhfhf grtgg esr fthrtht rtgdfvdfvdfv sdfrtg tgrt", imageUrl: "assets/icons/LockOutline.png"}]}/> */}
          {/* <AccordianText imageUrl="assets/images/todo.png" title="hi all" description="hubyfbeuf efhuybewdew duybdewd ewuydbewbudew deuduybewd ewuduybewud  yub"  /> */}
          {/* <AccordianMainImgText title="Melanoma" imageUrl="assets/images/todo.png" expMainImgText="Melanoma begins with a melanocyte and is called the future of skin" expMainImgUrl="assets/images/melanocytes.png" expansionPoints={[{text: "hello tgththfhfhf grtgg esr fthrtht rtgrtg tgrt", imageUrl: "assets/icons/LockOutline.png"}, {text: "hello tgththfhfhf grtgg esr fthrtht rtgdfvdfvdfv sdfrtg tgrt", imageUrl: "assets/icons/LockOutline.png"}]} /> */}
          <IonText className="cccoContentHeading">What Will I Cover</IonText>
          <AccordianCoreContent
            title="What will I Learn?"
            description=""
            imageUrl="assets/images/Learning.svg"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <AccordianCoreContent
            title="What will I do?"
            description=""
            imageUrl="assets/images/todo.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <AccordianCoreContent
            title="How will this help?"
            description=""
            imageUrl="assets/images/help.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <IonText className="cccoContentHeading">
            Meet Others Diagnosed with Melanoma
          </IonText>
          <AccordianCoreContent
            title="Judith"
            description="I was very surprised when I was diagnosed with melanoma two years ago, I didn't use a lot of sun protection when I was younger, but I thought I was doing a pretty good job at protecting my skin more recently, I have been retired for 6 years, and I enjoy gardening and looking after my granddaughter, I are also a member of a community walking group."
            imageUrl="assets/images/Judith.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <AccordianCoreContent
            title="Sarah"
            description="I was diagnose 4 years ago with basal cell skin cancer on my nose. Before my diagnosis I had heard of skin cancer, but I thought it was something that only really happened later in life. My doctor told me that I was more at risk for skin cancer because of my fair skin and history of sunbathing and indoor tanning. After I was diagnosed with basal cell skin cancer, I stopped going to the tanning salon. Two years ago, however, I was diagnosed with stage 0 melanoma."
            imageUrl="assets/images/sarah.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <AccordianCoreContent
            title="Sergio"
            description="I was diagnose 4 years ago with basal cell skin cancer on my nose. Before my diagnosis I had heard of skin cancer, but I thought it was something that only really happened later in life. My doctor told me that I was more at risk for skin cancer because of my fair skin and history of sunbathing and indoor tanning. After I was diagnosed with basal cell skin cancer, I stopped going to the tanning salon. Two years ago, however, I was diagnosed with stage 0 melanoma."
            imageUrl="assets/images/Sergio.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <AccordianCoreContent
            title="Robert"
            description="I was diagnose 4 years ago with basal cell skin cancer on my nose. Before my diagnosis I had heard of skin cancer, but I thought it was something that only really happened later in life. My doctor told me that I was more at risk for skin cancer because of my fair skin and history of sunbathing and indoor tanning. After I was diagnosed with basal cell skin cancer, I stopped going to the tanning salon. Two years ago, however, I was diagnosed with stage 0 melanoma."
            imageUrl="assets/images/Robert.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
          <AccordianCoreContent
            title="Bill"
            description="I was diagnose 4 years ago with basal cell skin cancer on my nose. Before my diagnosis I had heard of skin cancer, but I thought it was something that only really happened later in life. My doctor told me that I was more at risk for skin cancer because of my fair skin and history of sunbathing and indoor tanning. After I was diagnosed with basal cell skin cancer, I stopped going to the tanning salon. Two years ago, however, I was diagnosed with stage 0 melanoma."
            imageUrl="assets/images/Bill.png"
            expansionPoints={[""]}
            bioExpansionPoints={[""]}
            audioUrl=""
            open={false}
            isOpened={() => {}}
          />
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  router.push("/corechapteractivity", "forward", "push");
                }}
              >
                Next
              </IonButton>{" "}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default CoreChapterContentOne;

import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useState } from "react";

import "./CoreContentCompletedImgText.css";

import "../../Fonts.css";

interface CoreContentCompletedImgTextProps {
  title: string;
  description: string;
  isImgLeft: boolean;
}

const CoreContentCompletedImgText: React.FC<
  CoreContentCompletedImgTextProps
> = ({ title, description, isImgLeft }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (isImgLeft) {
    return (
      <div className="cccitMainContainerLeft animate__animated animate__fadeIn 1000ms animate__delay-1s ">
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="acceImgCol">
              <IonImg src="assets/images/RectangleLeft.png" />
            </IonCol>
            <IonCol className="acceTitleCol pl-32">
              <IonGrid>
                <IonRow>
                  <IonCol className="cccitTitleCol">
                    <IonText className="cccitTitle">{title}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="cccitDescCol">
                    <IonText className="cccitDesc" dangerouslySetInnerHTML={{ __html: description}} ></IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonImg
          className="cccitAbsImgLeft"
          src="assets/images/CompletedLeft.svg"
        ></IonImg>
      </div>
    );
  } else {
    return (
        <div className=" cccitMainContainerRight animate__animated animate__fadeIn 1600ms ">
        <IonGrid>
          <IonRow>
            <IonCol className="acceTitleCol pl-0 pr-32">
              <IonGrid>
                <IonRow>
                  <IonCol className="cccitTitleCol">
                    <IonText className="cccitTitle">{title}</IonText>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="cccitDescCol">
                    <IonText className="cccitDesc" dangerouslySetInnerHTML={{ __html: description}}></IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonCol>
            <IonCol size="auto" className="acceImgCol ">
              <IonImg className="cccitRightTriImg " src="assets/images/RectangleRight.png" />
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonImg
          className="cccitAbsImgRight"
          src="assets/images/CompletedRight.svg"
        ></IonImg>
      </div>
    );
  }
};

export default CoreContentCompletedImgText;

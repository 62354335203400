import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";

import "./CoreChapterActivityOption.css";

import "../../Fonts.css";
import CoreChapterActivityOptionHint from "../core_chapter_activity_option_hint/CoreChapterActivityOptionHint";

interface CoreChapterActivityOptionProps {
  index: number
  text: string;
  isCorrect: string;
  hints: any;
  isOpen: boolean;
  isSelected: Function;
}

const CoreChapterActivityOption: React.FC<CoreChapterActivityOptionProps> = ({ index, text, isCorrect, hints, isOpen, isSelected }) => {
  const selected = () => {
    isSelected(index);
  }

  return (
    <div
      className={
        isOpen
          ? (isCorrect ? "acceMainContainer ccaoMainContainerSelectedCorrect" : "acceMainContainer ccaoMainContainerSelectedWrong")
          : "acceMainContainer"
      }
      onClick={selected}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="auto" className="acceImgCol">
            {isOpen && (
              <IonImg
                src="assets/icons/RadioSelected.png"
                onClick={selected}
              />
            )}
            {!isOpen && (
              <IonImg src="assets/icons/Radio.png" onClick={selected} />
            )}
          </IonCol>
          <IonCol className="acceTitleCol accqTitleCol">
            <IonText className={isOpen ? "ccaoTitleTextSelected" : "ccaoTitleText"}>{text}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen && (
        <div>
          {
            hints.map((hint: any, i: any) => {
              return(
                <CoreChapterActivityOptionHint text={hint} isWhiteBG={true} textClicked={() => {}} />
              )
            })
          }
        </div>
      )}
    </div>
  );
};

export default CoreChapterActivityOption;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import "./Contact.css";
import { star, informationCircle } from "ionicons/icons";
import { UserService } from "../../services/UserService";

const Contact: React.FC = () => {
  var router = useIonRouter();

  const handleEmailButtonClick = () => {
    const subject = encodeURIComponent('Hello from MyEmailApp!');
    const body = encodeURIComponent('I hope this email finds you well.');
    window.location.href = `mailto:recipient@example.com?subject=${subject}&body=${body}`;
  };

  const handleCallButtonClick = () => {
    const phoneNumber = '123456789'; // Replace with the desired phone number
    const telUrl = `tel:${phoneNumber}`;
    window.location.href = telUrl;
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            UserService.controlBottomBarFromBack = 4;
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonText className="titleText">Contact</IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent skincheck-mainContent profileMainContent">
          <IonItem className="default-list ion-no-padding">
            <IonImg
              className="default-icon"
              src="assets/icons/Email.png"
            ></IonImg>

            <IonLabel className="default-text ion-text-wrap contactLabel">mysmartskin@cinj.rutgers.edu</IonLabel>
            <IonButton className="mss-defaultbutton" slot="end" onClick={handleEmailButtonClick}>
              Send Email
            </IonButton>
          </IonItem>
          <IonItem className="default-list ion-no-padding">
            <IonImg
              className="default-icon"
              src="assets/icons/phone.png"
            ></IonImg>
            <IonLabel className="default-text ion-text-wrap contactLabel">732-266-9514</IonLabel>
            <IonButton className="mss-defaultbutton" slot="end" onClick={handleCallButtonClick}>
              Call
            </IonButton>
          </IonItem>
          <IonItem className="default-list ion-no-padding ">
            <IonImg
              className="default-icon"
              src="assets/icons/location.png"
            ></IonImg>
            <IonLabel className="default-text ion-text-wrap contactLabel">
              Rutgers Cancer Institute of New Jersey, 120 Albany Street, 8th floor New Brunswick, NJ 08901
            </IonLabel>
            {/* <IonImg
              className="default-icon"
              src="assets/icons/copy.png"
            ></IonImg> */}
          </IonItem>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled mss-profile"
                onClick={() => {
                  UserService.controlBottomBarFromBack = 4;
                  router.goBack();
                }}
              >
                Go Back to Profile
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default Contact;

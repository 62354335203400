import {
    IonItem,
    IonText,
    IonImg,
    IonAccordionGroup,
    IonAccordion,
    IonCardContent,
} from "@ionic/react";

import "./CoreAccordionDescriptionIntro.css";

import "../../Fonts.css";

interface CoreAccordionDescriptionIntroProps {
    desc: string;
    accImg: string;
    accHeader: string;
    accDesc: string;
    accPoints: Array<string>;
    bottomDesc:string;
}

const CoreAccordionDescriptionIntro: React.FC<
    CoreAccordionDescriptionIntroProps
> = ({ desc, accImg, accHeader, accDesc, accPoints,bottomDesc}) => {
    return (
        <div className='cadiMain'>
            <IonAccordionGroup className='cadiSmain'>
                <div className='IoncardContent'>
                    <IonText className="cadiSText" dangerouslySetInnerHTML={{ __html: desc }}></IonText>
                    <div className="cadiDivGap"></div>
                    <div className='cadiAccordin'>

                        <IonAccordion value="first" className='cadiAccordin'>

                            <IonItem slot="header" className='cadiItem' lines="none">
                                {/* <IonImg  src= />"./assets/images/qustnImg.png" */}
                                <IonImg src={accImg} />
                                <IonText className="cadiSTextDid" dangerouslySetInnerHTML={{ __html: accHeader }}></IonText>
                            </IonItem>

                            <IonCardContent slot="content" className='cadicardContent'>

                                <div slot="content" className='cadiUnorder'>
                                    <IonText className="cadiSText" dangerouslySetInnerHTML={{ __html: accDesc }}></IonText>
                                    {(accPoints !== null) && <ul className="caidmarUl">
                                        {accPoints.map((option: any, i: number) => {
                                            return (
                                                <li> <IonText className="cadiSText" dangerouslySetInnerHTML={{ __html: option }}></IonText></li>

                                            );
                                        })}
                                    </ul>}

                                </div>
                            </IonCardContent>
                        </IonAccordion>
                    </div>
                    <div className="cadiDivGap"></div>
                    <IonText className="cadiSText" dangerouslySetInnerHTML={{ __html: bottomDesc }}></IonText>
                </div>
            </IonAccordionGroup>

        </div>
    );
};

export default CoreAccordionDescriptionIntro;

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
  } from "@ionic/react";
  import { useState } from "react";
  
  import "./CoreContentCompletedImgTextSummary.css";
  
  import "../../Fonts.css";
import SelectedPointsSummary from "../selected_points_summary/SelectedPointsSummary";
import { DataSubmissionService } from "../../services/DataSubmissionService";
  
  interface CoreContentCompletedImgTextSummaryProps {
    title: string;
    isImgLeft: boolean;
    selectedGoal:string;
    selectedTask:string;
  }
  
  const CoreContentCompletedImgTextSummary: React.FC<
    CoreContentCompletedImgTextSummaryProps
  > = ({ title, isImgLeft,selectedGoal,selectedTask }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
  
    if (isImgLeft) {
      return (
        <div className="cccitMainContainerLeft cccitsMain animate__animated animate__fadeIn 1000ms animate__delay-1s ">
          <IonGrid>
            <IonRow>
              <IonCol size="auto" className="acceImgCol">
                <IonImg src="assets/images/RectangleLeft.png" />
              </IonCol>
              <IonCol className="acceTitleCol pl-32">
                <IonGrid>
                  <IonRow>
                    <IonCol className="cccitTitleCol cccitsTitleCol">
                      <IonText className="cccitTitle">{title}</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                        <IonText className="spsText">Goal:</IonText>
                        <IonText className="spsPointText">{selectedGoal}</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                        <IonText className="spsText">Task:</IonText>
                        <IonText className="spsPointText">{selectedTask}</IonText>
                    </IonCol>
                  </IonRow>
                  <SelectedPointsSummary isActivitySummaryComp={true} text="Reasons:" points={DataSubmissionService.c1A1SkinSelfCheckReasons} />
                  {/* <IonRow>
                    <IonCol className="cccitDescCol">
                      <IonText className="cccitDesc" dangerouslySetInnerHTML={{ __html: description}} ></IonText>
                    </IonCol>
                  </IonRow> */}
                </IonGrid>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonImg
            className="cccitAbsImgLeft"
            src="assets/images/CompletedLeft.svg"
          ></IonImg>
        </div>
      );
    } else {
      return (
          <div className=" cccitMainContainerRight cccitsMain animate__animated animate__fadeIn 1600ms ">
          <IonGrid>
            <IonRow>
              <IonCol className="acceTitleCol pl-0 pr-32">
                <IonGrid>
                  <IonRow>
                    <IonCol className="cccitTitleCol cccitsTitleCol">
                      <IonText className="cccitTitle">{title}</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow >
                    <IonCol size="12" className="ccctsGoalsCol">
                    <IonText className="spsPointText ccctsText">Goal:</IonText>
                        <IonText className="spsText">{selectedGoal}</IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol size="12">
                    <IonText className="spsPointText ccctsText">Task:</IonText>
                        <IonText className="spsText">{selectedTask}</IonText>
                    </IonCol>
                  </IonRow>
                  <SelectedPointsSummary isActivitySummaryComp={true} text="Reasons:" points={DataSubmissionService.c1A1SkinSelfCheckReasons} />
                  {/* <IonRow>
                    <IonCol className="cccitDescCol">
                      <IonText className="cccitDesc" dangerouslySetInnerHTML={{ __html: description}}></IonText>
                    </IonCol>
                  </IonRow> */}
                </IonGrid>
              </IonCol>
              <IonCol size="auto" className="">
                <IonImg className="cccitsImagStrech" src="assets/images/completedimgedge.png" />
              </IonCol>
            </IonRow>
          </IonGrid>
          {/* <IonImg
            className="cccitAbsImgRight"
            src="assets/images/CompletedRight.png"
          ></IonImg> */}
        </div>
      );
    }
  };
  
  export default CoreContentCompletedImgTextSummary;
  
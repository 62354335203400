import {
  IonBackdrop,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPopover,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./SkinCheckSpotDetails.css";
import { useRef, useState } from "react";
import ProgressFraction from "../../components/progress_fraction/ProgressFraction";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import { SkinSelfCheckBodyMapImg } from "../../services/SkinSelfCheckBodyMapImg";
import { PDFDocument, StandardFonts, rgb } from "pdf-lib";
import download from "downloadjs";
import { UserService } from "../../services/UserService";

const SkinCheckSpotDetails: React.FC = () => {
  var router = useIonRouter();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: Event | undefined;
  }>({
    open: false,
    event: undefined,
  });

  var primaryData = [
    {
      bgColor: "#99C1F8",
      text: "Asymmetrical",
      isSelected: false,
      imagePath: "/assets/images/scsdImages/A.png",
    },
    {
      bgColor: "#73ADBB",
      text: "Border Irregularity",
      isSelected: false,
      imagePath: "/assets/images/scsdImages/B.png",
    },
    {
      bgColor: "#73ADBB",
      text: "Color Variation",
      isSelected: false,
      imagePath: "/assets/images/scsdImages/C.png",
    },
    {
      bgColor: "#99C1F8",
      text: "Diameter (Size)",
      isSelected: false,
      imagePath: "/assets/images/scsdImages/D.png",
    },
    {
      bgColor: "#99C1F8",
      text: "Evolving",
      isSelected: false,
      imagePath: "/assets/images/scsdImages/E.png",
    },
    {
      bgColor: "#73ADBB",
      text: "Funny Looking",
      isSelected: false,
      imagePath: "/assets/images/scsdImages/F.png",
    },
  ];

  var [data, setData] = useState(primaryData);
  var [refresh, setRefresh] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [checkSelected, setCheckSelected] = useState(false);
  const optionSelected = (i: number) => {
    let tempArr = data;
    tempArr[i].isSelected = !tempArr[i].isSelected;
    if (setContinueBtnText()) {
      setBtntext("Continue");
    } else {
      setBtntext("None Of These Match");
    }
    setData(tempArr);
    setRefresh(!refresh);
  };
  const [btnText, setBtntext] = useState("None Of These Match");

  const calculatePartsPercentage = () => {
    var per = SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount / SkinSelfCheckDataService.skinselfcheckTotalRegionsCount;
    per = per * 100;
    return Math.round(per);
  }

  const setContinueBtnText = () => {
    let selected = false;
    data.map((opt: any, i: number) => {
      if (opt.isSelected && !selected) {
        selected = true;
      }
    });
    return selected;
  };
  const goToNextScreen = () => {

    var filteredData = data.filter(function (filter) {
      return filter.text == "Diameter (Size)";
    });
    if (
      filteredData.length > 0 &&
      filteredData[0].isSelected &&
      !checkSelected
    ) {
      setOpenModal(true);
    } else if (
      filteredData.length > 0 &&
      filteredData[0].isSelected &&
      checkSelected
    ) {
      SkinSelfCheckDataService.isInitialLoggingChars = false;
      router.push("/SkinCheckDiameter", "forward", "push");
    } else {
      SkinSelfCheckDataService.isInitialLoggingChars = false;
      SkinSelfCheckDataService.selectedDia = -1;
      router.push("/SkinCheckSpotReview", "forward", "push");
    }
  };
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
  };

  const save = () => {
    let selArr: [any] = [{}];
    selArr.pop();
    data.map((option: any, i: number) => {
      if (option.isSelected) {
        selArr.push({
          title: option.text,
        });
      }
    });
    (SkinSelfCheckDataService.selectedChars as Array<any>) = selArr;
    const selChars = SkinSelfCheckDataService.selectedChars;
    console.log(selChars);

    // let regSubPart = SkinSelfCheckDataService.selectedRegionSubPart;

    // if ("spots" in regSubPart) {
    //   let selectedArr: [string] = [""];
    //   selectedArr.pop();
    //   data.map((option: any, i: number) => {
    //     if (option.isSelected) {
    //       selectedArr.push(option.text);
    //     }
    //   });

    //   selectedArr.map((option: any, i: number) => {
    //     if (
    //       regSubPart.spots.length > 0 &&
    //       "characteristics" in regSubPart.spots[regSubPart.spots.length - 1]
    //     ) {
    //       regSubPart.spots[regSubPart.spots.length - 1].characteristics.push({
    //         title: option,
    //       });
    //     } else {
    //       regSubPart.spots.push({
    //         characteristics: [
    //           {
    //             title: option,
    //           },
    //         ],
    //       });
    //     }
    //   });

    //   const map = SkinSelfCheckDataService.bodyMap;
    //   console.log(map);
    // } else {
    // }
  };

  useIonViewWillEnter(() => {
    if (SkinSelfCheckDataService.isInitialLoggingChars) {
      let tempArray: [any] = [{}];
      tempArray.pop();
      primaryData.map((option: any, i: number) => {
        option.isSelected = false;
        tempArray.push(option);
      });
      setData(tempArray);
      setRefresh(!refresh);
    }
  });

  const downloadPDF = async () => {
    const pdfDoc = await PDFDocument.create();
    // const page = pdfDoc.addPage([1200, 2100]);
    const page = pdfDoc.addPage([2400, 3000]);

    const pngImage = await pdfDoc.embedPng(SkinSelfCheckBodyMapImg.bodymapImg);
    const { width, height } = pngImage.scale(1.0);
    page.drawImage(pngImage, {
      x: 10,
      y: -300, //page.getHeight() / 2 - height / 2,
      width,
      height,
    });

    // _____________________________________________________________

    // Convert PDF to blob and save to device.
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });

    download(blob, "bodymap.pdf");
  };

  return (
    <IonPage className="spot-details">
      {openModal && <IonBackdrop className="scscBD"></IonBackdrop>}
      {openModal && (
        <div id="scscModal">
          <IonGrid className="scsdGrid3 scsdGridUpdate">
            <IonRow>
              <IonCol size="12">
                <div className="scsdDiv5">
                  <IonImg
                    className="scsdImg2 "
                    src="/assets/images/MeasuringRuler.png"
                  ></IonImg>
                </div>
              </IonCol>
              <IonCol className="scsdCol5" size="12">
                <IonText className="scsdText5">
                  Don’t Forget Your Ruler{" "}
                </IonText>
              </IonCol>
              <IonCol className="scsdCol6">
                <IonText className="scsdText6">
                  Get your ruler ready to measure
                  your spot on the following screen.
                  {" "}
                </IonText>
              </IonCol>
              <IonCol size="12">
                <div className="scsdDiv7">
                  <div className="scsdDiv8">
                    {!checkSelected && (
                      <IonImg
                        src="/assets/icons/Check.png"
                        onClick={() => {
                          setCheckSelected(true);
                        }}
                      ></IonImg>
                    )}
                    {checkSelected && (
                      <IonImg
                        src="/assets/icons/CheckSelected.png"
                        onClick={() => {
                          setCheckSelected(false);
                        }}
                      ></IonImg>
                    )}
                    <IonText className="scsdText7">
                      Don't remind me again
                    </IonText>
                  </div>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="scsdDiv9">
                  <IonButton
                    className="loginBtnEnabled "
                    onClick={() => {
                      setOpenModal(false);
                      router.push("/SkinCheckDiameter", "forward", "push");
                    }}
                  >
                    Continue
                  </IonButton>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      )}
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
      >
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            SkinSelfCheckDataService.screenGoingBackFromChars = true;
            SkinSelfCheckDataService.refreshScreen = true;
            //SkinSelfCheckDataService.archiveSelectedFlag=false;

            router.push("skinselfcheckpartsel", "forward", "push");
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        <ProgressFraction
          percent={calculatePartsPercentage()}
          fraction={SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount + "/" +
            SkinSelfCheckDataService.skinselfcheckTotalRegionsCount}
          description="Body Parts"
        />
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">
              {SkinSelfCheckDataService.selectedRegionSubPart.title.length > 0 ? SkinSelfCheckDataService.selectedRegionSubPart.title : ""}
              </IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                UserService.controlBottomBarFromBack=2;
                router.push("home/skinchecktab", "forward", "push");
              }}
            >
              <IonText className="scsdRightButton">Body Map</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>

        <div className="mainContent skincheck-mainContent profileMainContent scsrMar scsdMainContent">
          {/* <IonImg
            className="scsdFloatingdiv"
            src="/assets/icons/FixedScroll.png"
            onClick={() => {
              scrollToBottom();
            }}
          ></IonImg> */}
          <div className="subAccMainContainer button-grp scsdTextPad">
            {/* TextGrid */}
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonText className="scsdsub-heading">
                    Ok, let’s talk about your spot.
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonText className="scsdsub-heading1">
                    Do{" "}
                    <span className="scsdsub-headingBold">
                      any of these characteristics
                    </span>{" "}
                    match your spot? Check all that apply.
                    <IonImg
                      className="scsdQuestionImg"
                      src="/assets/icons/QuestionCircleOutline.png"
                      onClick={(e) =>
                        setShowPopover({ open: true, event: e.nativeEvent })
                      }
                    />
                    <IonPopover
                      isOpen={showPopover.open}
                      event={showPopover.event}
                      onDidDismiss={(e) =>
                        setShowPopover({ open: false, event: undefined })
                      }
                    >
                      <IonContent class="ion-padding">
                        <IonText className="scsdDownloadPDFText1">Download and print the ABCDEF guide.</IonText>
                        <div>
                          <IonText className="scsdDownloadPDFText2" onClick={downloadPDF}>Download Guide</IonText>
                        </div>
                      </IonContent>
                    </IonPopover>
                    {/* <IonButton id="bottom-center"></IonButton> */}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            {/* ComponentGrid */}
            <IonGrid>
              <IonRow className="scsdRow">
                {data.map((option: any, i: number) => {
                  {
                    /* {detailSelected.map((option: boolean, i: number) => { */
                  }
                  return (
                    <IonCol
                      className={i % 2 === 0 ? "scsdCol1Even" : "scsdCol1Odd"}
                      size="12/2"
                    >
                      <div
                        className={
                          !option.isSelected
                            ? "scsdOptionDiv"
                            : "scsdOptionDiv scsdOptionDivSelected"
                        }
                      >
                        <div
                          className="scsdSubDiv"
                          onClick={() => {
                            optionSelected(i);
                          }}
                          style={{ backgroundColor: `${option.bgColor}` }}
                        >
                          <IonImg src={option.imagePath} className="scsdImg">
                            {" "}
                          </IonImg>
                          {!option.isSelected && (
                            <IonImg
                              src="/assets/icons/Check.png"
                              className="scsdCheck"
                            // onClick={() => {
                            //   optionSelected(i);
                            // }}
                            ></IonImg>
                          )}
                          {option.isSelected && (
                            <IonImg
                              src="/assets/icons/CheckMarkBordered.png"
                              className="scsdCheck"
                            // onClick={() => {
                            //   optionSelected(i);
                            // }}
                            ></IonImg>
                          )}
                        </div>
                        {/* <IonText className="scsdTextChar">
                                                    {option.textHeading}
                                                </IonText> */}
                        <IonText>
                          <span className="scsdTextChar-bold">
                            {option.text.charAt(0)}
                          </span>
                          <span className="scsdTextChar">
                            {option.text.slice(1)}
                          </span>
                        </IonText>
                      </div>
                    </IonCol>
                  );
                })}
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled scsdNextBtn"
                onClick={() => {
                  save();
                  goToNextScreen();
                }}
              >
                {btnText}
                {/* {btnText} */}
                {/* { {  ? "Continue" : "None of These match"} } */}
              </IonButton>
              {/* routerLink={`/skincheckspotreview/${encodedData}`} */}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckSpotDetails;

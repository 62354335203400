import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./AccordianBarrierSolutionSummary.css";

import "../../Fonts.css";
import AccordianBarrierSolutionSummaryExp from "../accordian_barrier_solution_summary_exp/AccordianBarrierSolutionSummaryExp";
import { DataSubmissionService } from "../../services/DataSubmissionService";

interface AccordianBarrierSolutionSummaryProps {
  title: string;
  options: Array<any>;
}

const AccordianBarrierSolutionSummary: React.FC<
  AccordianBarrierSolutionSummaryProps
> = ({ title, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sols, setSols] = useState([
    {}
  ]);

  useEffect(() => {
    let selectedOptions = options;
    console.log(selectedOptions);

    if (
      selectedOptions.length > 0 &&
      (selectedOptions[0].toString() === "true" ||
        selectedOptions[0].toString() === "false")
    ) {
      let solutions: any[] = [];
      selectedOptions.map((option: any, i: number) => {
        if (option) {
          solutions.push(DataSubmissionService.c1A1SkinSelfCheckBarriersSolutionsAllOptions[i])
        }
      });
      setSols(solutions);
    } else {
      setSols(options);
    }
  }, [])
  

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accMainContainer accccMainContainer abbsMainContainer animate__animated animate__fadeInRight">
      <IonGrid className="accTitleGrid accccTitleGrid" onClick={toggleAccordion}>
        <IonRow>
          <IonCol className="accccTitleCol" size="8">
            <IonText className="abssTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
                
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen &&
        sols.map((option: any, i: number) => {
          return (
            <AccordianBarrierSolutionSummaryExp
              key={i}
              index={i}
              title={option.solution.text}
              points={option.solution.points}
              pointsDesc={option.solution.pointsDesc}
              textSummary={option.solution.textSummary}
              pointsDescSummary={option.solution.pointsDescSummary}
              pointsSummary={option.solution.pointsSummary}
            />
          );
        })}
    </div>
  );
};

export default AccordianBarrierSolutionSummary;

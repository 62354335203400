import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton
} from "@ionic/react";
import "./HomeIntro.css";

interface HomeIntroProps {
  imageUrl: string;
  title: string;
  desc: string;
  question: string;
  buttonText: string;
  parentButton: Function;
}

const HomeIntro: React.FC<HomeIntroProps> = ({
  imageUrl,
  title,
  desc,
  question,
  buttonText,
  parentButton,
}) => {
  const buttonAction=()=>{
    parentButton();
   }
  return (
    <div className="hiMainContainer">
      <IonGrid>
        <IonRow>
          <IonCol size="auto">
            <IonImg className="hiImg" src={imageUrl} />
          </IonCol>
          <IonCol className="hiCol2">
            <IonText className="hiTitle">{title}</IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonText className="hiDesc">{desc}</IonText>
        </IonRow>
        <IonRow>
          <IonText className="hiQstn">{question}</IonText>
        </IonRow>
        <IonRow>
          {/* <IonButton className="hiBtn" onClick={() => {buttonAction()}}>
            {buttonText}
          </IonButton> */}
          <div className="hiBtn" onClick={() => {buttonAction()}}>
            <IonText className="hiBtnText">Yes, Show Me Around
            </IonText>
          </div>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default HomeIntro;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { chevronBack, settingsOutline } from "ionicons/icons";

import "./ProfileEdit.css";
import ProfileEditTextField from "../../components/profile_edit_textfield/ProfileEditTextField";
import { useState } from "react";

let email="";
let password="";

const ProfileEdit: React.FC = () => {
  var router = useIonRouter();

  const [enableSaveBtn, setEnableSaveBtn] = useState(false);

  const [refresh, setRefresh] = useState(false);


  const navigateBack = () => {
    router.goBack();
  };

  const nextBtnClickHandler = () => {
    if (!enableSaveBtn) {
      return;
    }
    router.goBack();
  };
  const checkEnableSaveBtn = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.length > 0 && re.test(email) && password.length > 0) {
      setEnableSaveBtn(true);
    } else {
      setEnableSaveBtn(false);
    }
  };
  const emailChanged = (event: any) => {
    email = event.detail.value;
    checkEnableSaveBtn();
  };

  const emailClear = () => {
    email = "";
    checkEnableSaveBtn();
    // setRefresh(!refresh)
  };

  const passwordClear = () => {
    password = "";
    checkEnableSaveBtn();
    // setRefresh(!refresh)
  };

  const passwordChanged = (event: any) => {
    password = event.detail.value;
    checkEnableSaveBtn();
  };
 

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonText className="titleText">Profile Edit</IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent profileMainContent">
          <ProfileEditTextField
            imageUrl="/assets/icons/MailOutline.svg"
            placeholder="Email"
            isPassword={false}
            textChanged={emailChanged}
            clear={emailClear}
          />
          <div className="petfPasswordFieldDiv">
            <ProfileEditTextField
              imageUrl="/assets/icons/LockOutline.svg"
              placeholder="Password"
              isPassword={true}
              textChanged={passwordChanged}
              clear={passwordClear}
            />
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnDefault"
                onClick={() => {
                  navigateBack();
                }}
              >
                Cancel
              </IonButton>
              {enableSaveBtn && <IonButton
                className="loginBtnEnabled"
                onClick={() => {
                  nextBtnClickHandler();
                }}
              >
                Save
              </IonButton>}

              {!enableSaveBtn && <IonButton
                className="profilesaveBtn"
                onClick={() => {
                  nextBtnClickHandler();
                }}
              >
                Save
              </IonButton>}
              
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default ProfileEdit;

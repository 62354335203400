import "./HomeCoreProgress.css";
import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import HomeCoreProgressBar from "../../components/home_core_progress_bar/HomeCoreProgressBar";

interface HomeCoreProgressProps {
  imageUrl: string;
  coreNo: string;
  coreTitle: string;
  coresCompleted: number;
  totalCores: number;
  click: Function;
}

const HomeCoreProgress: React.FC<HomeCoreProgressProps> = ({
  imageUrl,
  coreNo,
  coreTitle,
  coresCompleted,
  totalCores,
  click,
}) => {
  const progressPercentage = () => {
    return (coresCompleted * 100) / totalCores;
  };

  return (
    <IonCol
      className="hcpColCores"
      onClick={() => {
        click();
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="auto" className="hcpImageCol">
            <IonImg src={imageUrl} className="hcpImage"></IonImg>
          </IonCol>
          <IonCol>
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  {/* title and chapter */}
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonGrid>
                          <IonRow>
                            <IonCol>
                              <IonGrid>
                                <IonRow>
                                  <IonCol>
                                    <IonGrid>
                                      <IonRow>
                                        <IonCol size="auto">
                                          <IonImg
                                            src="assets/images/chapter Progress Icon.png"
                                            className="hcpChapterProgressImage"
                                          />
                                        </IonCol>
                                        <IonCol>
                                          <IonText className="hcpCoreText">
                                            {coreNo}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                      <IonRow>
                                        <IonCol>
                                          <IonText className="hcpChapterText">
                                            {coreTitle}
                                          </IonText>
                                        </IonCol>
                                      </IonRow>
                                    </IonGrid>
                                  </IonCol>
                                </IonRow>
                              </IonGrid>
                            </IonCol>
                            <IonCol size="auto" className="hcpRightIconCol">
                              {/* <IonImg src="assets/icons/RightOutline-right.svg" /> */}
                            </IonCol>
                          </IonRow>
                        </IonGrid>
                      </IonCol>
                      <IonCol size="12" className="hcpProgressbar">
                        <HomeCoreProgressBar
                          percentage={progressPercentage()}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
                <IonCol size="12">
                  <IonText className="hcpProgressText">
                    {coresCompleted.toString()} of {totalCores.toString()}{" "}
                    chapters has completed
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonCol>
  );
};

export default HomeCoreProgress;

import { IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import "./ShowPrize.css";

interface ShowPrizeProps {
  prizes: Array<any>;
  displayPrizeDetails: Function;
  prizeSelected: Function;
}

const ShowPrize: React.FC<ShowPrizeProps> = ({ prizes,
   displayPrizeDetails,prizeSelected }) => {
  return (
    <div className="spMainDiv">
      <IonGrid>
        <IonRow>
          {prizes.map((prize: any, i: number) => {
            return (
              <>
                <IonCol size="5.5">
                  <div
                    className={
                      i % 2 === 0
                        ? "spGridTileColDiv spGridTileColDivOdd"
                        : "spGridTileColDiv spGridTileColDivEven"
                    }
                  >
                     {prize.showGetPrize && (
                      <div className="gpGridTileBtn" onClick={() => {
                        prizeSelected(prize)
                      }}> Get the Prize</div>
                    )}
                    {(prize.showRedeemedPrizeDetails) && <IonImg
                      style={{
                        opacity: "1",
                        width: "80%",
                        height: "80%"
                      }}
                      src={prize.imgUrl}
                      onClick={() => {
                        displayPrizeDetails(prize)
                      }}
                    ></IonImg>}
                    {(!prize.showRedeemedPrizeDetails) &&  <IonImg
                      className="spGridTileImgOp"
                      src={prize.imgUrl}
                    ></IonImg>}
                    <div className="spGridTileProgressDiv">
                      {prize.progress !== null && (
                        <IonText className="spGridTileProgressDivText">{`${prize.progress} / ${prize.progressTotal}`}</IonText>
                      )}
                    </div>
                  </div>
                  <div className="spGridTileTextDiv">
                    <IonText className="spGridTileText">{prize.text}</IonText>
                  </div>
                </IonCol>
                {i % 2 === 0 && <IonCol size="1"></IonCol>}
              </>
            );
          })}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ShowPrize;

import { IonGrid, IonRow, IonCol, IonImg, IonText } from "@ionic/react";
import "./coreVideoPlayer.css";
import ReactPlayer from "react-player";
import { useEffect, useState } from "react";

interface CoreVideoPlayer {
  coreVideoUrl: string;
  coreVideoText: string;
}

const CoreVideoPlayer: React.FC<CoreVideoPlayer> = ({
  coreVideoUrl,
  coreVideoText,
}) => {

  function calculateHeight16x9() {
    const aspectRatio = 9 / 16; // Inverted aspect ratio for 16:9 (height / width)
    var innerWidth = window.innerWidth;

    var page = document.getElementsByClassName("ion-page");
    let pgWidth = (page[0] as HTMLElement).offsetWidth;

    // console.log("innder width video", innerWidth)
    // if (innerWidth > 767) {
    //   innerWidth = 767;
    // }
    const height = (pgWidth * aspectRatio) - 32;
    return height;
  }

  return (
    <>
      <div className="video-player-main-main-div">
        <div className="video-player-main-container">
          <IonGrid>
            <IonRow>
              <IonCol>
                <ReactPlayer
                  className="video-player"
                  url={coreVideoUrl}
                  controls
                  playing
                  height={calculateHeight16x9()}
                  playIcon={<IonImg src="assets\icons\play.png"></IonImg>}
                  light="assets\icons\play.png"
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol className="core-video-text-col" style={{paddingTop: ((coreVideoText !== null) && coreVideoText.length > 0) ? "16px" : "0px"}}>
                <IonText className="core-video-text">
                  {coreVideoText}
                </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </div>
    </>
  );
};
export default CoreVideoPlayer;

import { IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import "./SkinCheckProgress.css";

interface SkinCheckProgressProps {
  hideDesc: boolean;
  progresses: Array<any>;
}

const SkinCheckProgress: React.FC<SkinCheckProgressProps> = ({
  hideDesc,
  progresses,
}) => {
  return (
    <div>
      <div className="scpProgressMainContainer">
        <IonGrid>
          <IonRow>
            <IonCol className="scpProgressTitleCol">
              <IonText className="scpProgressTitle">
                Skin Self-Check Progress
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow style={{ position: "relative", marginTop: "14px" }}>
            <div className="scpGraphBGXLabelImDiv">
              <IonText className="scpGraphBGXLabelImDivText">
                Body Parts
              </IonText>
            </div>
            <IonCol size="2.5"></IonCol>
            <IonCol>
              <div className="scpGraphBG">
                <div className="scpGraphBGUpperLineDiv"></div>
                <IonGrid>
                  <IonRow>
                    <IonCol>
                      <div className="scpGraphAxisLineDiv">
                        <div className="scpGraphAxisLineXNoDiv">
                          <IonText className="scpGraphAxisLineXNoText">
                            11
                          </IonText>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div className="scpGraphAxisLineDiv">
                        <div className="scpGraphAxisLineXNoDiv">
                          <IonText className="scpGraphAxisLineXNoText">
                            9
                          </IonText>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div className="scpGraphAxisLineDiv">
                        <div className="scpGraphAxisLineXNoDiv">
                          <IonText className="scpGraphAxisLineXNoText">
                            6
                          </IonText>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <div className="scpGraphAxisLineDiv">
                        <div className="scpGraphAxisLineXNoDiv">
                          <IonText className="scpGraphAxisLineXNoText">
                            3
                          </IonText>
                        </div>
                        <div className="scpGraphAxisLineXNoDivBottom">
                          <IonText className="scpGraphAxisLineXNoText">
                            0
                          </IonText>
                        </div>
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>

                <div className="scpBarsBG">
                {progresses.reverse().map((progress: any) => {
                      return (
                        <div
                          className={
                            progress.percent === 100 ? "scpBar" : "scpBarInComp"
                          }
                          style={{ height: `${progress.percent}%` }}
                        ></div>
                      );
                    })}
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="2.5">
              <div
                style={{
                  width: "100%",
                  height: "20px%",
                  backgroundColor: "green",
                }}
              ></div>
            </IonCol>

            <IonCol>
              <div className="scgMonthsDiv">
                {progresses.reverse().map((progress: any) => {
                  return (
                    <IonText className="scpGraphAxisLineXNoText">
                      {progress.month}
                    </IonText>
                  );
                })}
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="scpRow">
            <IonCol size="2" className="scpImageCol"></IonCol>
            <IonCol className="scpTextCol">
              <IonText className="scpGraphBGXLabelImDivText">Month</IonText>
            </IonCol>
          </IonRow>
          {!hideDesc && (
            <IonRow className="scpRow">
              <IonCol size="auto" className="scpImageCol">
                <IonImg
                  className="scpInfoImg"
                  src="/assets/icons/InformationCircleOutline.png"
                ></IonImg>
              </IonCol>
              <IonCol className="scpTextCol">
                <IonText
                  className="scpGraphBGXLabelImDivText"
                  style={{ fontWeight: "bold" }}
                >
                  You can track you monthly progress here once you start your
                  Skin Self-Check
                </IonText>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </div>
    </div>
  );
};

export default SkinCheckProgress;

import { IonImg, } from '@ionic/react';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import './Progress.css';
interface ProgressbarProps { 
  percent: number;
  isActivity: boolean;
}
// const percentage = 50;
const ProgressContainer: React.FC<ProgressbarProps> = ({ percent, isActivity }) => {
  return (
  <>

<div className='progressbar-outer'></div>
  <div className="progressbarImgDiv">
    <IonImg src={isActivity ? 'assets/icons/FlagOutline.png' : 'assets/icons/BookOutline.png'} />
  </div>
  <div style={{ width: 62 }}>
      <CircularProgressbar className='progressbar' value={percent}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0,

          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',

          // Text size
          textSize: '16px',

          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,

          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',
          // Colors
          pathColor: '#ED826E',
          textColor: '#f88',
          trailColor: '#FFE3DF',
          backgroundColor: '#FFE3DF',
        })} />
    </div></>
  );
};

export default ProgressContainer;

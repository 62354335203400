import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";

import "./LearnMoreImgText.css";

import "../../Fonts.css";

interface LearnMoreImgTextProps {
  text: string;
  imageUrl: string;
  click: Function;
}

const LearnMoreImgText: React.FC<LearnMoreImgTextProps> = ({
  imageUrl,
  text,
  click,
}) => {
  return (
    <div className="acceMainContainer lmitMainContainer item 3- animate__animated animate__fadeInRight animate__fast 1600ms">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonImg src={imageUrl} />
          </IonCol>
          <IonCol className="accieTitleCol">
            <IonText className="acceTitleText lmitText">{text}</IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="cccSetGoalBtnContainerCol">
            <div className="lmitBtn" onClick={() => {click()}}>
              Back to Section 1 Content
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default LearnMoreImgText;


import "./MainImgText.css";

import "../../Fonts.css";
import AccordianCoreContentImgExp from "../accordian_core_content_img_exp/AccordianCoreContentImgExp";
import AccordianCoreContentIconExp from "../accordian_core_content_icon_exp/AccordianCoreContentIconExp";

interface MainImgTextProps {
  imageUrl: string;
  mainImgText: string;
  expansionPoints: Array<any>;
}

const MainImgText: React.FC<MainImgTextProps> = ({
  imageUrl,
  mainImgText,
  expansionPoints,
}) => {
  return (
    <div className="accMainContainer accccMainContainer">
      <div>
        <div>
          <AccordianCoreContentImgExp text={mainImgText} imageUrl={imageUrl} />
        </div>
        {expansionPoints.map((point, i) => {
          return (
            <AccordianCoreContentIconExp
              text={point.text}
              imageUrl={point.imageUrl}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MainImgText;

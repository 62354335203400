import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import "./SkinCheckCameraPreview.css";
import ProgressFraction from "../../components/progress_fraction/ProgressFraction";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";

const SkinCheckCameraPreview: React.FC = () => {
  const router = useIonRouter();

  const calculatePartsPercentage = () => {
    var per = SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount / SkinSelfCheckDataService.skinselfcheckTotalRegionsCount;
    per = per * 100;
    return Math.round(per);
  }

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        <ProgressFraction percent={calculatePartsPercentage()}
          fraction={SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount + "/" + SkinSelfCheckDataService.skinselfcheckTotalRegionsCount}
          description="Body Parts" />
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">{SkinSelfCheckDataService.selectedRegionSubPart.title}</IonText>
            </IonCol>
            <IonCol className="scsdColRight" size="3" onClick={() => {
              router.push("home/skinchecktab", "forward", "push");
            }}>
              <IonText className="scsdRightButton">Body Map</IonText>
            </IonCol>


          </IonRow>
        </IonGrid>
        <div className="mainContent sscpsMainContent sccpMar">
          <IonText className="sccpsDescText">
            Great, would you like to use this photo to log your spot?
          </IonText>
          <IonImg
            className="sccpCapturedImg"
            src={SkinSelfCheckDataService.tempSelectedImgUrl}
          />
          <div className="sccpRetakeBtnDiv">
            <IonButton
              className="loginBtnEnabled scsdNextBtn sccpRetakeBtn"
              onClick={() => {
                SkinSelfCheckDataService.isInitialLoggingImg = false;
                SkinSelfCheckDataService.selectedImgUrl = "";
                SkinSelfCheckDataService.tempSelectedImgUrl = "";
                router.goBack();
              }}
            >
              <IonImg
                className="sceContinueImg"
                src="/assets/icons/CameraOutline.png"
              />
              <IonText>Retake photo</IonText>
            </IonButton>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnDefault sccpCancelBtn"
                onClick={() => {
                  SkinSelfCheckDataService.isInitialLoggingImg = false;
                  SkinSelfCheckDataService.selectedImgUrl = "";
                  SkinSelfCheckDataService.tempSelectedImgUrl = "";
                  router.push("/skincheckspotreview", "forward", "push");
                }}
              >
                I’d Rather Not
              </IonButton>
              <IonButton
                className="loginBtnEnabled scsdNextBtn sccpNextBtn"
                onClick={() => {
                  SkinSelfCheckDataService.isInitialLoggingImg = false;
                  SkinSelfCheckDataService.selectedImgUrl = SkinSelfCheckDataService.tempSelectedImgUrl;
                  SkinSelfCheckDataService.tempSelectedImgUrl = "";
                  SkinSelfCheckDataService.addimageselSpots = [];
                  //SkinSelfCheckDataService.addimageDivRef="";
                  SkinSelfCheckDataService.addimageHeight = 0;
                  SkinSelfCheckDataService.addimageWidth = 0;
                  SkinSelfCheckDataService.addimagedivWidth = 0;
                  router.push("/skincheckimagemultispots", "forward", "push");
                }}
              >
                <IonImg
                  className="sceContinueImg"
                  src="/assets/icons/CheckCircleOutline.png"
                />
                <IonText className="sccpText">Use Image</IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckCameraPreview;

import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";

import "./BarrierMCQMultipleCheckOption.css";

import "../../Fonts.css";

interface BarrierMCQMultipleCheckOptionProps {
  index: number;
  text: string;
  isOpen: boolean;
  isSelected: Function;
  isDisabled: boolean;
}

const BarrierMCQMultipleCheckOption: React.FC<
  BarrierMCQMultipleCheckOptionProps
> = ({ index, text, isOpen, isSelected, isDisabled }) => {
  const selected = () => {
    isSelected(index);
  };

  return (
    <div
      className={
        isOpen
          ? "acceMainContainer bmcqmcoMainContainerSelected"
          : "acceMainContainer mcqmcoMainContainer"
      }
    >
      <IonGrid onClick={selected}>
        <IonRow>
          <IonCol size="auto" className="acceImgCol">
            {isOpen && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/CheckSelected.png"
              />
            )}
            {!isOpen && !isDisabled && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/Check.png"
                // onClick={selected}
              />
            )}
            {!isOpen && isDisabled && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/CheckMarkDisabled.png" //Check img disabled
                // onClick={selected}
              />
            )}
          </IonCol>
          <IonCol className="acceTitleCol accqTitleCol">
            {!isDisabled && (
              <IonText
                className="bmcqmcoText"
                dangerouslySetInnerHTML={{ __html: text }}
              ></IonText>
            )}
            {isDisabled && (
              <IonText
                className="bmcqmcoText bmcqmcoTextDisabled"
                dangerouslySetInnerHTML={{ __html: text }}
              ></IonText>
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default BarrierMCQMultipleCheckOption;

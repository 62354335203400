import { useState } from "react";
import "./hamburger.css";
import {
  IonImg,
  IonItem,
  IonList,
  useIonRouter,
  useIonViewWillLeave,
} from "@ionic/react";
import { useLocation } from "react-router-dom";
import { DataService } from "../../services/DataService";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import { UserService } from "../../services/UserService";

interface HamburgerProps {
  callbackFunc: Function;
  scHistoryMenu: boolean;
}

const Hamburger: React.FC<HamburgerProps> = ({
  callbackFunc,
  scHistoryMenu,
}) => {
  var router = useIonRouter();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion11 = () => {
    setIsOpen(!isOpen);
  };

  let currentRouteUrl = location.pathname;

  const dispatchTabSelectedEvent = () => {
    // Create a custom event
    const customEvent = new CustomEvent("tab-event-tab3", {
      detail: {
        message: "",
      },
    });

    // Dispatch the custom event on a DOM element
    const targetElement = document.getElementById("target-element"); // Replace with your target element's ID or reference
    if (targetElement) {
      targetElement.dispatchEvent(customEvent);
    }
  };

  const hamburgerRouteHandler = (routeComponent: string, flag: boolean) => {
    if (routeComponent.indexOf("skinchecktab") != -1 && flag) {
      SkinSelfCheckDataService.showSkinSelfCheckVideo = false;
    } else {
      SkinSelfCheckDataService.showSkinSelfCheckVideo = true;
    }
    console.log("video flag", SkinSelfCheckDataService.showSkinSelfCheckVideo);
    const currentRouteUrl = location.pathname;
    if (currentRouteUrl != routeComponent) {
      setIsOpen(false);
      router.push(routeComponent, "forward", "push");

      setTimeout(() => {
        dispatchTabSelectedEvent();
      }, 300);
    } else {
      if (routeComponent.indexOf("skinchecktab") != -1 && flag) {
        UserService.controlBottomBarFromBack=2;
        setIsOpen(false);
        router.push(routeComponent, "forward", "push");

        setTimeout(() => {
          dispatchTabSelectedEvent();
        }, 300);
      }
    }
  };

  useIonViewWillLeave(() => {
    setIsOpen(false);
  });

  const checkLaterClicked = () => {
    setIsOpen(false);
    callbackFunc();
    // // Create a custom event
    // const customEvent = new CustomEvent("Set Reminder", {
    //   detail: {
    //     message: "",
    //   },
    // });

    // // Dispatch the custom event on a DOM element
    // const targetElement = document.getElementById("target-element"); // Replace with your target element's ID or reference
    // if (targetElement) {
    //   targetElement.dispatchEvent(customEvent);
    //   setIsOpen(false);
    // }
  };

  const hamburgerSaveSpotHandler = (routeComponent: string, flag: boolean) => {
    setIsOpen(false);
    callbackFunc("openSaveSpot");
  };

  const hamburgerSpotRemovedHandler = (
    routeComponent: string,
    flag: boolean
  ) => {
    setIsOpen(false);
    callbackFunc("openArchiveSpots");
  };

  return (
    <div className="hamburgerMC">
      <span id="target-element"></span>
      <div
        onClick={() => {
          toggleAccordion11();
        }}
      >
        <IonImg
          className="absImageCheck"
          slot="end"
          src="assets/icons/Hamburger-Menu.png"
        />
      </div>
      {isOpen && !scHistoryMenu && (
        <div className="custom-nav">
          <IonList className="list-items bg-color">
            <IonItem
              className="bg-color"
              onClick={() => hamburgerRouteHandler("/skinchecksettings", false)}
            >
              <IonImg
                className="absImageCheck"
                id="trigger-button"
                slot="start"
                src="assets/icons/set-outline.png"
              />{" "}
              Settings
            </IonItem>
            <IonItem
              className="bg-color"
              onClick={() => hamburgerRouteHandler("/home/skinchecktab", true)}
            >
              <IonImg
                className="absImageCheck"
                id="trigger-button"
                slot="start"
                src="assets/icons/circle-info.png"
              />{" "}
              Skin Self-Check Tutorial
            </IonItem>
            {/* <IonItem className="bg-color">
              <IonImg
                className="absImageCheck"
                id="trigger-button"
                slot="start"
                src="assets/icons/circle-info.png"
              />{" "}
              Skin Self-Check Tour
            </IonItem> */}
            {currentRouteUrl != "/home/skinchecktab" && (
              <IonItem
                className="bg-color"
                onClick={() => {
                  checkLaterClicked();
                }}
              >
                <IonImg
                  className="absImageCheck"
                  id="trigger-button"
                  slot="start"
                  src="assets/icons/later-check.png"
                />{" "}
                Finish My Check Later
              </IonItem>
            )}
            <IonItem
              className="bg-color"
              onClick={() => {
                hamburgerRouteHandler("/home/skinchecktab", false);
              }}
            >
              <IonImg
                className="absImageCheck left-angle"
                id="trigger-button"
                slot="start"
                src="assets/icons/left-arrow.png"
              />{" "}
              Back to Full Body Map
            </IonItem>
          </IonList>
        </div>
      )}
      {isOpen && scHistoryMenu && (
        <div className="save-spot-custom-nav">
          <div className="triangle-arrow"></div>
          <IonList className="list-items bg-color">
            {/* <IonItem
              className="bg-color"
              onClick={() =>
                hamburgerSaveSpotHandler("/skincheckspotrevisit", false)
              }
            >
              <IonImg
                className="absImageSave"
                id="trigger-button"
                slot="start"
                src="assets/icons/SaveOutline.png"
              />{" "}
              Save spot information
            </IonItem> */}
            <IonItem
              className="bg-color"
              onClick={() =>
                hamburgerSpotRemovedHandler("/skincheckspotrevisit", true)
              }
            >
              <IonImg
                className="absImageSave"
                id="trigger-button"
                slot="start"
                src="assets/icons/CheckCircleOutline.png"
              />{" "}
              Archived Spots
            </IonItem>
          </IonList>
        </div>
      )}
    </div>
  );
};

export default Hamburger;

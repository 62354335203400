import {
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./Accordion.css";
import SubAccordion from "../sub_accordian/SubAccordian";

import "../../Fonts.css";
import { ProgressDataService } from "../../services/ProgressDataService";

interface AccordionProps {
  coreNo: number;
  title: string;
  description: string;
  imageUrl: string;
  chapters: Array<any>;
  click: Function;
  isOpened: boolean;
  isOpenedClicked: Function;
}

const Accordion: React.FC<AccordionProps> = ({
  coreNo,
  title,
  description,
  imageUrl,
  chapters,
  click,
  isOpened,
  isOpenedClicked,
}) => {
  const [isOpen, setIsOpen] = useState(isOpened);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      isOpenedClicked();
    }
  };

  const clickProp = (screens: any, coreNo: number, chapterNo: string) => {
    click(screens, coreNo, chapterNo);
  };

  const getProgressLockState = (i: number, core: number, chapter: number) => {
    let retVal = "done";
    let ch = i + 1;
    let cor = core + 1;
    let progressData = ProgressDataService.progressObj; //ProgressDataService.progressObj;

    // if (ch !== 1) {
    if (cor === 1) {
      if (ch === 1) {
        let cor1Ch1Prog = progressData.cores[0].chapters[1].progress;

        if (cor1Ch1Prog === 100) {
          retVal = "chapterDone";
        } else {
          retVal = "done";
        }
      } else if (ch === 7) {
        // If Activity
        let cor1ActProg = progressData.cores[0].chapters[0].progress;

        if (cor1ActProg === 100) {
          retVal = "chapterDone";
        } else {
          if (
            "progress" in progressData.cores[cor - 1].chapters[6] &&
            progressData.cores[cor - 1].chapters[6].progress !== 100
          ) {
            retVal = "locked";
          }
        }
      } else if (ch === 8) {
        let cor1Ch8Prog = progressData.cores[0].chapters[7].progress;

        if (cor1Ch8Prog === 100) {
          retVal = "chapterDone";
        } else {
          if (
            "progress" in progressData.cores[cor - 1].chapters[0] &&
            progressData.cores[cor - 1].chapters[0].progress !== 100
          ) {
            retVal = "locked";
          }
        }
      } else {
        //For remaining Chapters
        if (progressData.cores[0].chapters[ch].progress === 100) {
          retVal = "chapterDone";
        } else {
          if (
            "progress" in progressData.cores[cor - 1].chapters[ch - 1] &&
            progressData.cores[cor - 1].chapters[ch - 1].progress !== 100
          ) {
            retVal = "locked";
          }
        }
      }
    } else if (cor === 2) {
      if (ch === 1) {
        let cor2Ch1Prog = progressData.cores[1].chapters[1].progress;
        if (cor2Ch1Prog === 100) {
          retVal = "chapterDone";
        } else {
          let coresTemp = progressData.cores[0];
          let chapTemp = coresTemp.chapters[7];
          if ("progress" in chapTemp && chapTemp.progress !== 100) {
            retVal = "locked";
          }
        }
      } else if (ch === 5) {
        // If Activity
        let cor2ActProg = progressData.cores[1].chapters[0].progress;

        if (cor2ActProg === 100) {
          retVal = "chapterDone";
        } else {
          let coresTemp = progressData.cores[cor - 1];
          let chapTemp = coresTemp.chapters[ch - 1];
          if ("progress" in chapTemp && chapTemp.progress !== 100) {
            retVal = "locked";
          }
        }
      } else if (ch === 6) {
        let cor2Ch6Prog = progressData.cores[1].chapters[5].progress;

        if (cor2Ch6Prog === 100) {
          retVal = "chapterDone";
        } else {
          let coresTemp = progressData.cores[cor - 1];
          let chapTemp = coresTemp.chapters[0];
          if ("progress" in chapTemp && chapTemp.progress !== 100) {
            retVal = "locked";
          }
        }
      } else {
        //For remaining Chapters
        if (progressData.cores[1].chapters[ch].progress === 100) {
          retVal = "chapterDone";
        } else {
          let coresTemp = progressData.cores[cor - 1];
          let chapTemp = coresTemp.chapters[ch - 1];
          if ("progress" in chapTemp && chapTemp.progress !== 100) {
            retVal = "locked";
          }
        }
      }
    }
    // }

    return retVal;
  };

  return (
    <div className="accMainContainer accordion-mss">
      <IonGrid className="accTitleGrid" onClick={toggleAccordion}>
        <IonRow>
          <IonCol size="auto">
            {/* <div className="accLessonImg"></div> */}
            <IonImg src={imageUrl} />
          </IonCol>
          <IonCol className="accTitleCol" size="8">
            <IonText className="accTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonText className="accContentText">{description}</IonText>
      {isOpen &&
        chapters.map((chapter, index) => (
          <SubAccordion
            coreNo={coreNo}
            key={index}
            chapterNo={chapter.chapterNo}
            title={chapter.title}
            isActivity={chapter.isActivity}
            progress={getProgressLockState(index, coreNo, chapter.chapterNo)} //{chapter.progress}
            click={() => {
              const progLockState = getProgressLockState(
                index,
                coreNo,
                chapter.chapterNo
              );
              if (progLockState === "done" || progLockState === "chapterDone") {
                clickProp(chapter.screens, coreNo, chapter.chapterNo);
              }
            }}
            screens={chapter.screens}
          />
        ))}
    </div>
  );
};

export default Accordion;

import { C2DataSubmissionService } from "./C2DataSubmissionService";
import { DataService } from "./DataService";

export abstract class QuizService {
  public static capturedQuiz: any = {
    Section: "",
    Chapter: "",
    MCQ: [],
    FnF: [],
    FnFMCQ: [],
    CardsMCQ: [],
    MultipleRadioBtns: [],
    FiveStarRating: [],
    RadioMCQ: [],
    IncDec: [],
    MultipleCheck: [],
  };

  public static captureMCQ = (
    question: string,
    answer: string,
    screenIndex: number
  ) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "MCQ",
      question: question,
      answer: answer,
    };

    let tempArr = (this.capturedQuiz.MCQ as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.MCQ = tempArr;

    // this.capturedQuiz.MCQ.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureFnF = (answerData: Array<any>, screenIndex: number) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "FnF",
      answerData: answerData,
    };

    let tempArr = (this.capturedQuiz.FnF as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.FnF = tempArr;

    // this.capturedQuiz.FnF.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureFnFMCQ = (
    answerData: Array<any>,
    screenIndex: number
  ) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "FnFMCQ",
      answerData: answerData,
    };

    let tempArr = (this.capturedQuiz.FnFMCQ as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.FnFMCQ = tempArr;

    // this.capturedQuiz.FnFMCQ.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureMultipleRadioBtns = (screenIndex: number) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let answerData = {
      "Use sunscreen with SPF 30 or higher?":
        this.captureMultipleRadioBtnsGetAnswerText(
          C2DataSubmissionService.c2Ch1S3LikertQstn1
        ),
      "Wear a long-sleeved shirt?": this.captureMultipleRadioBtnsGetAnswerText(
        C2DataSubmissionService.c2Ch1S3LikertQstn2
      ),
      "Wear a wide-brimmed hat?": this.captureMultipleRadioBtnsGetAnswerText(
        C2DataSubmissionService.c2Ch1S3LikertQstn3
      ),
      "Wear sunglasses?": this.captureMultipleRadioBtnsGetAnswerText(
        C2DataSubmissionService.c2Ch1S3LikertQstn4
      ),
      "Avoid the sun from 10 a.m. to 4 p.m.?":
        this.captureMultipleRadioBtnsGetAnswerText(
          C2DataSubmissionService.c2Ch1S3LikertQstn5
        ),
      "Stay in the shade or under an umbrella?":
        this.captureMultipleRadioBtnsGetAnswerText(
          C2DataSubmissionService.c2Ch1S3LikertQstn6
        ),
      "Have a sunburn?": this.captureMultipleRadioBtnsGetAnswerText(
        C2DataSubmissionService.c2Ch1S3LikertQstn7
      ),
      "Intentionally or incidentally tan?":
        this.captureMultipleRadioBtnsGetAnswerText(
          C2DataSubmissionService.c2Ch1S3LikertQstn8
        ),
    };

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "MultipleRadioBtns",
      answerData: answerData,
    };

    let tempArr = (this.capturedQuiz.MultipleRadioBtns as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.MultipleRadioBtns = tempArr;

    // this.capturedQuiz.MultipleRadioBtns.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureMultipleRadioBtnsGetAnswerText = (index: number) => {
    let retVal = "";
    if (index === 0) {
      retVal = "Never";
    } else if (index === 1) {
      retVal = "Rarely";
    } else if (index === 2) {
      retVal = "Sometimes";
    } else if (index === 3) {
      retVal = "Often";
    } else if (index === 4) {
      retVal = "Always";
    }

    return retVal;
  };

  public static captureFiveStarRating = (
    screenIndex: number,
    selectedStar: number
  ) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "FiveStarRating",
      answerData: {
        question:
          "Using the scale below, rate how important it is for you to engage in sun-safe behaviors:",
        answer: selectedStar,
      },
    };

    let tempArr = (this.capturedQuiz.FiveStarRating as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.FiveStarRating = tempArr;

    // this.capturedQuiz.FiveStarRating.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureRadioMCQ = (
    screenIndex: number,
    question: string,
    answer: string
  ) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "RadioMCQ",
      answerData: {
        question: question,
        answer: answer,
      },
    };

    let tempArr = (this.capturedQuiz.RadioMCQ as Array<any>).filter(
      (item) => item.answerData.question !== question
    );
    tempArr.push(obj);
    this.capturedQuiz.RadioMCQ = tempArr;

    // this.capturedQuiz.RadioMCQ.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureIncDec = (
    screenIndex: number,
    question: string,
    answer: number
  ) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "IncDec",
      answerData: {
        question: question,
        answer: answer,
      },
    };

    let tempArr = (this.capturedQuiz.IncDec as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.IncDec = tempArr;

    // this.capturedQuiz.IncDec.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };

  public static captureMultipleCheck = (
    screenIndex: number,
    question: string,
    answers: Array<string>
  ) => {
    const core = DataService.currentCoreIndex;
    const ch = DataService.currentChapterIndex;

    this.capturedQuiz.Section = core + 1;
    this.capturedQuiz.Chapter = ch + 1;

    let obj = {
      // section: core + 1,
      // chapter: ch + 1,
      screenIndex: screenIndex,
      type: "MultipleCheck",
      answerData: {
        question: question,
        answers: answers,
      },
    };

    let tempArr = (this.capturedQuiz.MultipleCheck as Array<any>).filter(
      (item) => item.screenIndex !== screenIndex
    );
    tempArr.push(obj);
    this.capturedQuiz.MultipleCheck = tempArr;

    // this.capturedQuiz.MultipleCheck.push(obj);
    let captMCQ = this.capturedQuiz;
    console.log(captMCQ);
  };
}

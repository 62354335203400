import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
  } from "@ionic/react";
  import "./PrizeSelectedWelldone.css";
import { DataService } from "../../services/DataService";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { UserService } from "../../services/UserService";
  
  const PrizeSelectedWelldone: React.FC = () => {
    var router = useIonRouter();
    const navigateBack = () => {
      DataSubmissionService.isGoalsGetAPICalled=false
      UserService.controlBottomBarFromBack=3;
      router.push("home/mystufftab", "forward", "push");
      //router.goBack();
    };
  
    const navigateToBodymap = () => {
      DataSubmissionService.isGoalsGetAPICalled=false
      UserService.controlBottomBarFromBack=3;
      router.push("home/mystufftab", "forward", "push");
    };
  
    return (
      <IonPage>
        <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
          <IonGrid>
            <IonRow class="profileTitleRow">
              <IonText className="titleText">
                {DataService.selectedPrizeTitle+" Prizes"}
              </IonText>
            </IonRow>
          </IonGrid>
          <div
            className="mainContent"
            style={{ marginTop: "24px", paddingTop: "40px" }}
          >
            {/* Back Button */}
            <div className="cciBackButtonContainer" onClick={navigateBack}>
              <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
            </div>
            <div className="sceImgDiv">
              <IonImg src="/assets/images/Welldone.svg" className="sceImg" />
            </div>
  
            <div className="pswMainTextDiv">
              <IonText className="pswMainText">Well done!</IonText>
            </div>
  
            <div className="pswMainText2Div">
              <IonText className="pswMainText2">
              {"You've submitted the details to get a prize for "+DataService.selectedPrizeTitle+"."}
              </IonText>
            </div>
  
          
                <IonGrid className="pswMainText3Div">
                    <IonRow>
                <IonCol size="12">
              <IonText className="pswMainText3">
              The arrival of your prize can take up to 1 month because of shipping
              </IonText>
              </IonCol>
              <IonCol size="12" className="pswgap">
              <IonText className="pswMainText3">
              You will receive a confirmation email about your prize.
              </IonText>
              </IonCol>
              </IonRow>
              </IonGrid>
           
          </div>
        </IonContent>
        <div className="buttonFooter">
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol
                className="custom-flex"
                style={{
                  paddingLeft: "16px",
                  paddingRight: "16px",
                  paddingTop: "8px",
                  paddingBottom: "8px",
                }}
              >
                <IonButton
                  className="loginBtnEnabled scsdNextBtn"
                  onClick={navigateToBodymap}
                >
                  {/* <IonImg
                    className="sceContinueImg"
                    src="/assets/icons/CheckCircleOutline.png"
                  /> */}
                  <IonText>Go Back to MyStuff</IonText>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonPage>
    );
  };
  
  export default PrizeSelectedWelldone;
  
import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useState } from "react";
import "./AccordianResourcesSummary.css";
import "../../Fonts.css";
import { chevronDown, chevronUp } from "ionicons/icons";

interface AccordianResourcesSummaryProps {
  text: string;
  desc: string;
  options: Array<any>;
}

const AccordianResourcesSummary: React.FC<AccordianResourcesSummaryProps> = ({
  text,
  desc,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="absMainContainer animate__animated animate__fadeInRight">
      <IonGrid
        onClick={() => {
          toggleAccordion();
        }}
      >
        <IonRow>
          {!isOpen && (
            <>
              <IonCol size="12">
                <div className="agsMainDiv">
                  <IonText className="agaMainDivText">{text}</IonText>
                  <IonIcon
                    className="accChevronIcon"
                    icon={isOpen ? chevronUp : chevronDown}
                    onClick={toggleAccordion}
                  ></IonIcon>
                </div>
              </IonCol>
            </>
          )}
          {isOpen && (
            <>
              <IonCol size="12">
                <div className="agsMainDiv">
                  <IonText className="agaMainDivText">{text}</IonText>
                  <IonIcon
                    className="accChevronIcon"
                    icon={isOpen ? chevronUp : chevronDown}
                    onClick={toggleAccordion}
                  ></IonIcon>
                </div>
              </IonCol>
              <IonCol size="12" className="arsDescCol">
                <IonText className="arsDescText">{desc}</IonText>
              </IonCol>
            </>
          )}
          {isOpen &&
            options.map((option: any) => {
              return (
                <IonCol size="12">
                  <div className="agssOpenedDiv">
                    <IonGrid>
                      <IonRow>
                        <IonCol className="agssOpenedDivImgCol" size="auto">
                          <IonImg
                            className="agssOpenedDivImg"
                            src={option.imgUrl}
                          />
                        </IonCol>
                        <IonCol className="agssTextCol">
                          <IonText
                            className="arsOptionText"
                            dangerouslySetInnerHTML={{ __html: option.text }}
                          ></IonText>
                          {/* <IonText
                            className="agssOpenedDivDesc"
                            dangerouslySetInnerHTML={{ __html: desc }}
                          ></IonText> */}
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              );
            })}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AccordianResourcesSummary;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import { useState,useEffect } from "react";
import "./CC2.css";
import AccordianTextCC from "../../../components/accordian_text_cc/AccordianTextCC";
import {postControlContentData,patchControlContentData,getContentProgressID} from "../../../services/dataApi";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";

const CC2: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);



  async function getControlContentNID() {
    try {
      openLoader();
      const responseData = await getContentProgressID(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Control Content Response ---->>> " , respData);

            if (respData.length > 0) {
              //let parsedJSON = JSON.parse(respData[0]);
              let nid = respData[0].id;
              console.log(nid);
              let compProg=respData[0].completed;
              SkinSelfCheckDataService.controlcontentCompletedProgress=compProg
              console.log(nid);
              console.log("completed Progress",SkinSelfCheckDataService.controlcontentCompletedProgress);
              SkinSelfCheckDataService.controlcontentNID = nid;
            } else {
              SkinSelfCheckDataService.controlcontentNID = -1;
              console.log("Control Content NID empty");
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Control Content NID not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck NID - Error fetching data:", error);
    }
  }

  useEffect(() => {
   // if(SkinSelfCheckDataService.controlcontentNID===-1){
      getControlContentNID()
   // }
   
  },[]);

  const navigateBack = () => {
    router.goBack();
  };

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };
  async function postControlContent() {
    openLoader();
    if (SkinSelfCheckDataService.controlcontentNID===-1) {
      try {
        openLoader();
        const responseData = await postControlContentData(
          
          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc3")
              //Save user data
              console.log("Patching Controlcontent success--" + respData);
            } else if (!isSuccess) {
            
              closeLoader();
              console.log("Patching Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    } else {
      try {
        openLoader();
        const responseData = await patchControlContentData(
          
          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc3")
              //Save user data
              console.log("posting Controlcontent success--" + respData);
            } else if (!isSuccess) {
              //Error
             
              closeLoader();
              console.log(" posting Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    }
  }

  const [accordionData, setAccordianData] = useState([
    {
      title: "Examine your face",
      imageUrl: "/assets/images/cc/Face.png",
      description:
        "Check the inside and outside of your nose, lips, mouth, eyes and ears.",
      open: false,
    },
    {
      title: "Inspect your scalp",
      imageUrl: "/assets/images/cc/Scalp.png",
      description:
        "Check each section of your scalp by parting your hair into sections.  Consider using a blow dryer or comb.",
      open: false,
    },
    {
      title: "Scan your arms",
      imageUrl: "/assets/images/cc/Arms.png",
      description:
        "Lift your arms and examine them all the way down to your hands where you will check the palm and back of your hand, fingers, and fingernails.  Don’t forget about your elbows or armpits.​​",
      open: false,
    },
    {
      title: "Inspect your torso",
      imageUrl: "/assets/images/cc/Torso.png",
      description: "Thoroughly check your neck, chest and torso.​​",
      open: false,
    },
    {
      title: "Scan your upper back",
      imageUrl: "/assets/images/cc/UpperBack.png",
      description:
        "Use your mirrors to inspect the back of your neck, shoulders and upper back.​​",
      open: false,
    },
    {
      title: "Scan your lower back​",
      imageUrl: "/assets/images/cc/LowerBack.png",
      description:
        "Still using your mirrors, scan your lower back, buttocks and back of legs.​​",
      open: false,
    },
    {
      title: "Inspect your legs and genitals​",
      imageUrl: "/assets/images/cc/Genitals.png",
      description:
        "Finally, work down your legs checking your genitals, legs, feet, heels, toes, soles and toenails.​​",
      open: false,
    },
    {
      title: "Scan your lower back​",
      imageUrl: "/assets/images/cc/LowerBack.png",
      description:
        "Still using your mirrors, scan your lower back, buttocks and back of legs.​​",
      open: false,
    },
  ]);

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
            <IonText className="titleText">Page 4</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
          {/* Main body here */}
          <IonText className="ion-margin-bottom default-text cccoContentHeading">
            How To Do a Skin Self-Check
          </IonText>
          <div style={{ width: "100%", height: "16px" }} />
          <IonText className="ccaDescText">
          We will now walk you through how to do a skin self-check. Click on each arrow to learn more about each body part.
          </IonText>

          {accordionData.map((option: any, i: number) => {
            return (
              <AccordianTextCC
                key={`acccc2${i}`}
                title={option.title}
                imageUrl={option.imageUrl}
                description={option.description}
                open={option.open}
                isOpened={(open: boolean) => {
                  let tempAccOpenStates: any[] = [];

                  for (let index = 0; index < accordionData.length; index++) {
                    let element = accordionData[index];
                    element.open = false;
                    tempAccOpenStates.push(element);
                  }

                  if (open) {
                    tempAccOpenStates[i].open = true;
                  }
                  setAccordianData(tempAccOpenStates);
                  // setRefresh(!refresh);
                }}
              />
            );
          })}
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      SkinSelfCheckDataService.controlcontentUserProgress=4
                      if(SkinSelfCheckDataService.controlcontentCompletedProgress<10){
                      postControlContent()
                      }else{
                        router.push("/cc3")
                      }
                      //router.push("/cc3");
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC2;


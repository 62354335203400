import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./AccordianBarrierSolution.css";
import "../../Fonts.css";
import CoreChapterActivityOptionHint from "../core_chapter_activity_option_hint/CoreChapterActivityOptionHint";
import { DataSubmissionService } from "../../services/DataSubmissionService";

interface AccordianBarrierSolutionProps {
  setOpen: boolean;
  isViewed: boolean;
  isChecked: boolean;
  text: string;
  desc: string;
  pointsDesc: string;
  points: Array<string>;
  solIsViewedCallback: Function;
  textClicked: Function;
}

const AccordianBarrierSolution: React.FC<AccordianBarrierSolutionProps> = ({
  setOpen,
  isViewed,
  isChecked,
  text,
  desc,
  pointsDesc,
  points,
  solIsViewedCallback,
  textClicked
}) => {
  const [isOpen, setIsOpen] = useState(setOpen ? true : false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    solIsViewed(!isOpen);
  };

  const solIsViewed = (viewed: boolean) => {
    solIsViewedCallback(viewed);
  }

  useEffect(() => {
    console.log(text);
    console.log(DataSubmissionService.noneOfTheseSelected);
    console.log("kk")
  }, [])
  

  return (
    <div className="absMainContainer animate__animated animate__fadeInRight">
      <IonGrid onClick={() => {
                
              }}>
        <IonRow onClick={() => {
                toggleAccordion();
              }}>
          {!DataSubmissionService.noneOfTheseSelected && isChecked && (
            <IonCol className="absImageCheckCol" size="auto">
              <IonImg
                className="absImageCheck"
                src="assets/icons/CheckMark.png"
              />
            </IonCol>
          )}
          <IonCol className={isChecked ? "acceTitleCol tsohCol2 absCol2" : "acceTitleCol tsohCol2 absCol2Checked"}>
            <IonText
              className="absText"
              dangerouslySetInnerHTML={{ __html: text }}
            ></IonText>
          </IonCol>
          <IonCol className="absViewedImgCol" size="auto">
          {isViewed && <IonImg
              className="absViewedImg"
              src="assets/icons/EyeFill.png"
            />}
          </IonCol>
          <IonCol
            className="acceTitleCol ccaoh-acceTitleCol tsohCol3"
            size="auto" 
          >
            <IonImg
              className="absChevronImg"
              src={isOpen ? "assets/icons/UpOutline.png" : "assets/icons/DownOutline.png"}
              
            />
          </IonCol>
        </IonRow>
        {isOpen && (
          <IonRow onClick={() => {
            toggleAccordion();
          }}>
            <IonCol className="absDescTextCol" size="12">
              <IonText
                className="absDescText"
                dangerouslySetInnerHTML={{ __html: desc }}
              ></IonText>
            </IonCol>
          </IonRow>
        )}
        {isOpen && pointsDesc !== null && pointsDesc.length > 0 && (
          <IonRow>
            <IonCol className="absPointsDescTextCol" size="12">
              <IonText
                className="absDescText"
                dangerouslySetInnerHTML={{ __html: pointsDesc }}
              ></IonText>
            </IonCol>
          </IonRow>
        )}
        {isOpen && points !== null && 
          points.map((point: any, i: number) => {
            return (
              <>
                <CoreChapterActivityOptionHint isWhiteBG={false} text={point} textClicked={textClicked} />
              </>
            );
          })}
      </IonGrid>
    </div>
  );
};

export default AccordianBarrierSolution;

import { PDFDocument, StandardFonts, rgb, PageSizes, PDFPage } from "pdf-lib";
import { TableDataService, TableDefinition } from "./TableDataService";
import { SkinSelfCheckBodyMapImg } from "./SkinSelfCheckBodyMapImg";

interface TableDisplayProps {
  columnWidths: number[];
}

export abstract class PdfReportService {
  private static drawTable = (
    pg: PDFPage,
    x: number,
    y: number,
    width: number,
    data: TableDefinition,
    opts: TableDisplayProps
  ) => {
    let hgt = 0;

    return hgt;
  };

  public static createSummaryReport = async (observations: any) => {
    let debug = 0;
    console.log("PDFReportService.createSummaryReport() " + debug++);
    const summary = TableDataService.compileStats(observations);

    console.log("PDFReportService.createSummaryReport() " + debug++);
    const pdfDoc = await PDFDocument.create();

    // Embed the Helvetica font
    const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
    const helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

    const margin = 20;
    const padding = 20;
    const fontSize = 8;
    const titleFontSize = 24;
    const titlePadding = titleFontSize / 2;
    const subtitleFontSize = 16;
    const subtitlePadding = titlePadding;

    console.log("PDFReportService.createSummaryReport() " + debug++);
    let page = pdfDoc.addPage(PageSizes.Letter);
    // Get the width and height of the page
    let { width, height } = page.getSize();
    let headerPrinted = false;
    const printableWidth = width - 2 * margin;

    let runningY = height - margin;

    console.log("PDFReportService.createSummaryReport() " + debug++);
    let lineHeight = helveticaFont.heightAtSize(titleFontSize);
    runningY -= lineHeight;
    page.drawText("Body Map", {
      x: margin,
      y: runningY,
      size: titleFontSize,
      font: helveticaBold,
      color: rgb(0, 0, 0),
    });
    runningY -= titlePadding;

    page.drawRectangle({
      x: 0,
      y: 720,
      width: 792,
      height: 1,
      borderColor: rgb(0.13333333333, 0.5, 0.56470588235), // Black border color
      borderWidth: 1, // Border width
      color: rgb(0.13333333333, 0.5, 0.56470588235), // Fill color (red in this case)
    });

    const pngImage = await pdfDoc.embedPng(SkinSelfCheckBodyMapImg.bodymapImg);
    // const { width, height } = pngImage.scale(1.0);
    page.drawImage(pngImage, {
      x: -5,
      y: 100, //-100, //page.getHeight() / 2 - height / 2,
      width,
      height: height - 200,
    });

    runningY -= height;

    console.log("PDFReportService.createSummaryReport() " + debug++);
    runningY -= titlePadding;

    for (var i = 0; i < observations.length; i++) {
      if (runningY < margin) {
        // add a new page
        page = pdfDoc.addPage(PageSizes.Letter);
        width = page.getWidth();
        height = page.getHeight();
        headerPrinted = false;
        runningY = height - margin;
      }

      console.log("PDFReportService.createSummaryReport() " + debug++);
      const columnWidth = printableWidth / 4;
      //   const tableX = margin + columnWidth * 3;
      const tableX = margin + columnWidth;

      if (!headerPrinted) {
        lineHeight = helveticaBold.heightAtSize(fontSize);
        runningY -= lineHeight + 25;

        page.drawRectangle({
          x: margin,
          y: runningY - 10,
          width: tableX - columnWidth / 2 - margin,
          height: 30,
          borderColor: rgb(0, 0, 0), // Black border color
          borderWidth: 1, // Border width
          color: rgb(0.13333333333, 0.5, 0.56470588235), // Fill color (red in this case)
        });
        page.drawText("Spot Number", {
          x: margin + 5,
          y: runningY,
          size: fontSize,
          font: helveticaBold,
          color: rgb(1, 1, 1),
        });

        page.drawRectangle({
          x: tableX - columnWidth / 2,
          y: runningY - 10,
          width:
            tableX +
            columnWidth -
            columnWidth / 2 +
            5 -
            (tableX - columnWidth / 2) +
            50,
          height: 30,
          borderColor: rgb(0, 0, 0), // Black border color
          borderWidth: 1, // Border width
          color: rgb(0.13333333333, 0.5, 0.56470588235), // Fill color (red in this case)
        });
        page.drawText("Body Part Name", {
          x: tableX - columnWidth / 2 + 5,
          y: runningY,
          size: fontSize,
          font: helveticaBold,
          color: rgb(1, 1, 1),
        });

        page.drawRectangle({
          x: tableX + columnWidth - columnWidth / 2 + 5 + 50,
          y: runningY - 10,
          width:
            tableX +
            1 * columnWidth -
            (tableX + columnWidth - columnWidth / 2 + 5) +
            120,
          height: 30,
          borderColor: rgb(0, 0, 0), // Black border color
          borderWidth: 1, // Border width
          color: rgb(0.13333333333, 0.5, 0.56470588235), // Fill color (red in this case)
        });
        page.drawText("Region/Side/Zone", {
          //   x: tableX
          x: tableX + columnWidth - columnWidth / 2 + 5 + 5 + 50,
          y: runningY,
          size: fontSize,
          font: helveticaBold,
          color: rgb(1, 1, 1),
        });

        page.drawRectangle({
          x: tableX + 1 * columnWidth + 120 + 50,
          y: runningY - 10,
          width:
            printableWidth -
            (tableX - columnWidth / 2 - margin) -
            (tableX +
              columnWidth -
              columnWidth / 2 +
              5 -
              (tableX - columnWidth / 2)) -
            (tableX +
              1 * columnWidth -
              (tableX + columnWidth - columnWidth / 2 + 5)) -
            120 -
            50,
          height: 30,
          borderColor: rgb(0, 0, 0), // Black border color
          borderWidth: 1, // Border width
          color: rgb(0.13333333333, 0.5, 0.56470588235), // Fill color (red in this case)
        });
        page.drawText("Characteristics", {
          //   x: tableX + 1 * columnWidth,
          x: tableX + 1 * columnWidth + 5 + 120 + 50,
          y: runningY,
          size: fontSize,
          font: helveticaBold,
          color: rgb(1, 1, 1),
        });
        runningY -= padding;
        headerPrinted = true;
      }

      lineHeight = helveticaFont.heightAtSize(fontSize);
      runningY -= lineHeight;

      page.drawRectangle({
        x: margin,
        y: runningY - 10,
        width: tableX - columnWidth / 2 - margin + 50,
        height: 30,
        borderColor: rgb(0, 0, 0), // Black border color
        borderWidth: 1, // Border width
        color: rgb(1, 1, 1), // Fill color (red in this case)
      });
      page.drawText((i + 1).toString(), {
        x: margin + 5,
        y: runningY, //height - 4 * fontSize,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      page.drawRectangle({
        x: tableX - columnWidth / 2,
        y: runningY - 10,
        width:
          tableX +
          columnWidth -
          columnWidth / 2 +
          5 -
          (tableX - columnWidth / 2) +
          50,
        height: 30,
        borderColor: rgb(0, 0, 0), // Black border color
        borderWidth: 1, // Border width
        color: rgb(1, 1, 1), // Fill color (red in this case)
      });
      page.drawText(observations[i].bodyPartName.toString(), {
        // x: tableX - 1 * columnWidth,
        x: tableX - columnWidth / 2 + 5,
        y: runningY,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      page.drawRectangle({
        x: tableX + columnWidth - columnWidth / 2 + 5 + 50,
        y: runningY - 10,
        width:
          tableX +
          1 * columnWidth -
          (tableX + columnWidth - columnWidth / 2 + 5) +
          120,
        height: 30,
        borderColor: rgb(0, 0, 0), // Black border color
        borderWidth: 1, // Border width
        color: rgb(1, 1, 1), // Fill color (red in this case)
      });
      page.drawText(observations[i].zone.toString(), {
        x: tableX + columnWidth - columnWidth / 2 + 5 + 50 + 5,
        y: runningY,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      page.drawRectangle({
        x: tableX + 1 * columnWidth + 120 + 50,
        y: runningY - 10,
        width:
          printableWidth -
          (tableX - columnWidth / 2 - margin) -
          (tableX +
            columnWidth -
            columnWidth / 2 +
            5 -
            (tableX - columnWidth / 2)) -
          (tableX +
            1 * columnWidth -
            (tableX + columnWidth - columnWidth / 2 + 5)) -
          120 -
          50,
        height: 30,
        borderColor: rgb(0, 0, 0), // Black border color
        borderWidth: 1, // Border width
        color: rgb(1, 1, 1), // Fill color (red in this case)
      });
      page.drawText(observations[i].characteristics.toString(), {
        x: tableX + 1 * columnWidth + 5 + 120 + 50,
        y: runningY,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
      runningY -= padding;
    }

    // ABCDEF Guide image
    page = pdfDoc.addPage(PageSizes.Letter);
    const abcdefGuideImg = await pdfDoc.embedPng(SkinSelfCheckBodyMapImg.abcdefGuide);
    // const { width, height } = pngImage.scale(1.0);
    page.drawImage(abcdefGuideImg, {
      x: 0,
      y: -20, //page.getHeight() / 2 - height / 2,
      width,
      height: 800
    });
    /////////////////

    console.log("PDFReportService.createSummaryReport() " + debug++);

    const date = new Date();
    let currDate =
      (date.getMonth() > 8
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1)) +
      "/" +
      (date.getDate() > 9 ? date.getDate() : "0" + date.getDate()) +
      "/" +
      date.getFullYear();

    // Draw a number at the bottom of each page.
    // Note that the bottom of the page is `y = 0`, not the top
    const pages = await pdfDoc.getPages();
    const headerText = "Skin Self-Check Date: " + currDate;
    const headerSize = 10;
    const headerWidth = helveticaFont.widthOfTextAtSize(headerText, headerSize);
    const headerHeight = helveticaFont.heightAtSize(headerSize);

    for (const [i, page] of Object.entries(pages)) {
      let { width, height } = page.getSize();

      page.drawText(headerText, {
        x: width - headerWidth - margin,
        y: height - headerHeight - margin / 2,
        size: headerSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });

      page.drawText(`${+i + 1}`, {
        x: width / 2,
        y: 10,
        size: fontSize,
        font: helveticaFont,
        color: rgb(0, 0, 0),
      });
    }
    // _____________________________________________________________

    // Convert PDF to blob and save to device.
    console.log("PDFReportService.createSummaryReport() " + debug++);
    const pdfBytes = await pdfDoc.save();
    console.log("PDFReportService.createSummaryReport() " + debug++);

    return pdfBytes;
  };
}

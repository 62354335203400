import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { useState ,useEffect} from "react";
import "./CC1.css";
import {postControlContentData,patchControlContentData,getContentProgressID} from "../../../services/dataApi";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";

const CC1: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const navigateBack = () => {
    router.goBack();
  };


  useEffect(() => {
    //if(SkinSelfCheckDataService.controlcontentNID===-1){
      getControlContentNID()
    //}
   
  },[]);

  async function getControlContentNID() {
    try {
      openLoader();
      const responseData = await getContentProgressID(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Control Content Response ---->>> " , respData);

            if (respData.length > 0) {
              //let parsedJSON = JSON.parse(respData[0]);
              let nid = respData[0].id;
              console.log(nid);
              let compProg=respData[0].completed;
              SkinSelfCheckDataService.controlcontentCompletedProgress=compProg
              console.log(nid);
              console.log("completed Progress",SkinSelfCheckDataService.controlcontentCompletedProgress);
              SkinSelfCheckDataService.controlcontentNID = nid;
            } else {
              SkinSelfCheckDataService.controlcontentNID = -1;
              console.log("Control Content NID empty");
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Control Content NID not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck NID - Error fetching data:", error);
    }
  }
  
  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };


  async function postControlContent() {
    openLoader();
    if (SkinSelfCheckDataService.controlcontentNID===-1) {
      try {
        openLoader();
        const responseData = await postControlContentData(
          
          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc2")
              //Save user data
              console.log("Patching Controlcontent success--" + respData);
            } else if (!isSuccess) {
            
              closeLoader();
              console.log("Patching Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    } else {
      try {
        openLoader();
        const responseData = await patchControlContentData(
          
          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc2")
              //Save user data
              console.log("posting Controlcontent success--" + respData);
            } else if (!isSuccess) {
              //Error
             
              closeLoader();
              console.log(" posting Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
              className="settingsIcon"
              icon={settingsOutline}
              style={{ color: "transparent" }}
            ></IonIcon> */}
            <IonText className="titleText">Page 3</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
        <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Skin Self-Checks
          </IonText>

          {/* Main body here */}
          <div style={{ width: "100%", height: "16px" }} />

        <IonImg src="/assets/images/cc/Sun protection.svg"></IonImg>
            <div style={{ width: "100%", height: "16px" }} />

          
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "<strong>50%</strong> of people find their skin cancer on their own.  Checking your body <strong>each month</strong> will allow you to monitor your skin for any new, changing or unusual spots between doctor visits.  The <strong>earlier</strong> skin cancer spots are found, the <strong>simpler</strong> they are to treat.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "The most thorough <strong>skin self-checks</strong> should only take <strong>10-15 minutes</strong> to complete each <strong>month</strong>.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">Before you do a <strong>skin self-check</strong>, gather the right tools:
              <ul style={{ marginLeft: "16px" }}>
                <li>Full-length mirror​</li>
                <li>Handheld mirror​​</li>
                <li>Notebook or phone to document your findings​</li>
                <li>Blow dryer and comb to check your scalp​</li>
                <li>Ruler </li>
              </ul>
            </IonText>
            <div style={{ width: "100%", height: "16px" }} />
            <div className="ccaDescTextContainer">
              <IonText
                className="ccaDescText"
                dangerouslySetInnerHTML={{
                  __html: "Consider asking a family member or friend to assist you with your exam for those <strong>hard to see areas</strong> such as your back and scalp.",
                }}
              ></IonText>
            </div>
            <div style={{ width: "100%", height: "132px" }} />
          </div>
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      SkinSelfCheckDataService.controlcontentUserProgress=3
                      if(SkinSelfCheckDataService.controlcontentCompletedProgress<10){
                      postControlContent()
                      }else{
                        router.push("/cc2")
                      }
                      //router.push("/cc2")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC1;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import { useState, useEffect } from "react";
import AccordianTextCC from "../../../components/accordian_text_cc/AccordianTextCC";
import AccordianMainImgTextCC from "../../../components/accordian_mainimg_text_cc/AccordianMainImgTextCC";
import { DataService } from "../../../services/DataService";
import { postControlContentData, patchControlContentData, getContentProgressID } from "../../../services/dataApi";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";


const CC3: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    // if(SkinSelfCheckDataService.controlcontentNID===-1){
    getControlContentNID()
    // }

  }, []);

  async function getControlContentNID() {
    try {
      openLoader();
      const responseData = await getContentProgressID(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Control Content Response ---->>> ", respData);

            if (respData.length > 0) {
              //let parsedJSON = JSON.parse(respData[0]);
              let nid = respData[0].id;
              console.log(nid);
              let compProg = respData[0].completed;
              SkinSelfCheckDataService.controlcontentCompletedProgress=compProg
              console.log(nid);
              console.log("completed Progress",SkinSelfCheckDataService.controlcontentCompletedProgress);
              SkinSelfCheckDataService.controlcontentNID = nid;
            } else {
              SkinSelfCheckDataService.controlcontentNID = -1;
              console.log("Control Content NID empty");
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Control Content NID not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck NID - Error fetching data:", error);
    }
  }

  const navigateBack = () => {
    router.goBack();
  };

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };
  async function postControlContent() {
    openLoader();
    if (SkinSelfCheckDataService.controlcontentNID === -1) {
      try {
        openLoader();
        const responseData = await postControlContentData(

          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc4")
              //Save user data
              console.log("Patching Controlcontent success--" + respData);
            } else if (!isSuccess) {

              closeLoader();
              console.log("Patching Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    } else {
      try {
        openLoader();
        const responseData = await patchControlContentData(

          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc4")
              //Save user data
              console.log("posting Controlcontent success--" + respData);
            } else if (!isSuccess) {
              //Error

              closeLoader();
              console.log(" posting Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    }
  }

  // let text1="Assymetrical";
  // let text2="Border Irregularity";
  // let text3="Color Variation";
  // let text4="Diameter (Size) > 6mm";
  // let text5="Evolving";
  // let text6="Funny Looking";

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
                className="settingsIcon"
                icon={settingsOutline}
                style={{ color: "transparent" }}
              ></IonIcon> */}
            <IonText className="titleText">Page 5</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>

          <IonText className="ion-margin-bottom default-text cccoContentHeading">
            ABCDEFs of Melanoma
          </IonText>

          {/* Main body here */}
          {/* <div className="ccaDescTextContainer">
            <IonText className="ccaDescText">

              When checking your body, look for spots that meet the <strong>ABCDEF</strong> criteria:
              <ul style={{ marginLeft: "16px" }}>
                <li><strong>A</strong> – Asymmetrical​</li>
                <li><strong>B</strong> – Border irregularity​​</li>
                <li><strong>C</strong> – Color variation​​</li>
                <li><strong>D</strong> – Diameter &gt; 6 mm​</li>
                <li><strong>E</strong> – Evolving​ </li>
                <li><strong>F</strong> – Funny looking​​ </li>
              </ul>
            </IonText>
          </div> */}
          <div style={{ width: "100%", height: "16px" }} />
          <IonText className="ccaDescText">
            When checking your body, look for spots that meet the <strong>ABCDEF</strong> criteria:
          </IonText>
          {DataService.accordionImgData.map((option: any, i: number) => {
            return (
              <AccordianMainImgTextCC
                key={Math.random()}
                title={option.title}
                imageUrl={option.imageUrl}
                expMainImgText=""
                expMainImgUrl={option.expMainImgUrl}
                expansionPoints={option.expansionPoints}
                open={option.open}
                isOpened={(open: boolean) => {
                  let tempAccOpenStates: any[] = [];

                  for (let index = 0; index < DataService.accordionImgData.length; index++) {
                    let element = DataService.accordionImgData[index];
                    element.open = false;
                    tempAccOpenStates.push(element);
                  }

                  if (open) {
                    tempAccOpenStates[i].open = true;
                  }
                  DataService.accordionImgData = (tempAccOpenStates);
                  setRefresh(!refresh);
                }}
              />
            );
          })}
          <div style={{ width: "100%", height: "16px" }} />
          <IonText className="ccaDescText">
            If you find any <strong>unusual</strong> or <strong>abnormal</strong>  spots, make sure to consult with your doctor.
          </IonText>

          <div style={{ width: "100%", height: "116px" }} />
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      SkinSelfCheckDataService.controlcontentUserProgress = 5
                      if (SkinSelfCheckDataService.controlcontentCompletedProgress < 10) {
                        postControlContent()
                      } else {
                        router.push("/cc4")
                      }
                      //router.push("/cc4")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC3;

import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import { useEffect, useState } from "react";

import "./AccordianBarrierSolutionSummaryExp.css";

import "../../Fonts.css";

interface AccordianBarrierSolutionSummaryExpProps {
  index: number;
  title: string;
  pointsDesc: string;
  points: any;
  textSummary: string;
  pointsDescSummary: string;
  pointsSummary: any;
}

const AccordianBarrierSolutionSummaryExp: React.FC<
  AccordianBarrierSolutionSummaryExpProps
> = ({
  index,
  title,
  points,
  pointsDesc,
  textSummary,
  pointsDescSummary,
  pointsSummary,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    console.log(title);
    console.log(pointsDesc);
    console.log(points);
  }, []);

  return (
    <div className="acceMainContainer absseMainContainer">
      <div className="abssTitleDivDiv">
        <div className={index % 2 === 0 ? "abssTitleDiv" : "abssTitleDiv2"}>
          <IonText>{textSummary}</IonText>
        </div>
      </div>
      {title === "None of these" && (
        <IonGrid>
          <IonRow>
            <IonCol size="12" className="absseNoneOfTheseImgCol">
              <IonImg
                src="assets/images/noneofthese.png"
                className="absseNoneOfTheseImg"
              ></IonImg>
            </IonCol>
          </IonRow>
          <IonCol size="12" className="absseNoneOfTheseImgCol">
            <IonText className="absseNoneOfTheseText">
              You have selected none of these
            </IonText>
          </IonCol>
          <IonRow></IonRow>
        </IonGrid>
      )}
      {title !== "None of these" && (
        <IonGrid>
          <IonRow>
            <IonCol className="abssePointsDescCol" size="12">
              <IonText
                className="abssePointsText"
                dangerouslySetInnerHTML={{ __html: pointsDescSummary }}
              ></IonText>
            </IonCol>
          </IonRow>
          <IonRow className="atseOptionsRow">
            {points !== null && points !== undefined && 
              points.map((point: string, i: number) => {
                return (
                  <IonCol className="abssePointCol" size="12">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="auto">
                          <div className="abssePointsCircle"></div>
                        </IonCol>
                        <IonCol>
                          <div className="absseOptTextDiv">
                            <IonText
                              className="abssePointsText"
                              dangerouslySetInnerHTML={{ __html: point }}
                            ></IonText>
                          </div>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonCol>
                );
              })}
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default AccordianBarrierSolutionSummaryExp;

import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import "./HomeSkinSelfCheck.css";

interface HomeSkinSelfCheckProps {
  title: string;
  imageUrl: string;
  desc: string;
  buttonText: string;
  haveMultipleButtons: boolean;
  click: Function;
}

const HomeSkinSelfCheck: React.FC<HomeSkinSelfCheckProps> = ({
  title,
  imageUrl,
  desc,
  buttonText,
  haveMultipleButtons,
  click,
}) => {
  return (
    <IonGrid className="hcsMainGrid">
      <IonRow>
        <IonCol size="12">
          <IonText className="hcsTitleText">{title}</IonText>
        </IonCol>
      </IonRow>
      <IonGrid className="hcsSubGrid">
        <IonRow className="hcsSubRow">
          <IonCol size="auto">
            <IonImg src={imageUrl}></IonImg>
          </IonCol>
          <IonCol className="hcsSubCol">
            <IonText
              className="hcsSubText"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></IonText>
          </IonCol>
        </IonRow>
        {haveMultipleButtons && (
          <IonRow className="hcsButtonsRow">
            <IonCol
              className="hcsButtonStart"
              onClick={() => {
                click();
              }}
            >
              <IonText className="hcsBtnTextStart">{buttonText}</IonText>
            </IonCol>
            {/* <IonCol className="hcsButtonSet">
              <IonText className="hcsBtnTextSet">Set Reminder</IonText>
            </IonCol> */}
          </IonRow>
        )}
        {!haveMultipleButtons && (
          <IonRow className="hcsButtonsRow">
            <IonCol className="hcsButtonStart"
            onClick={() => {
              click();
            }}>
              <IonText className="hcsBtnTextStart">{buttonText}
              
              </IonText>
              
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </IonGrid>
  );
};

export default HomeSkinSelfCheck;

import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonImg,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  chevronBack,
  closeCircleOutline,
  closeOutline,
  settingsOutline,
} from "ionicons/icons";

import "./SkinCheckCamera.css";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useRef, useState, useEffect } from "react";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
const SkinCheckCamera: React.FC = () => {
  var router = useIonRouter();
  const [openModal, setOpenModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const openPopover = () => {};

  const checkSkincheckAlert = () => {
    //let skincheckflag = localStorage.getItem("cameraPrivacyAlertShown");
    //if (skincheckflag != null) {
    setShowAlert(true);
    //takePicture();
    // } else {
    //   setShowAlert(true);
    // }
  };

  const takePicture = async () => {
    //localStorage.setItem("cameraPrivacyAlertShown","true");
    setShowAlert(false);
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: true,
      source: CameraSource.Photos,
      resultType: CameraResultType.DataUrl,
    });
    // Do something with the image
    console.log("IMGGG URRRL -----> " + image.dataUrl);
    if (image.dataUrl !== undefined) {
      SkinSelfCheckDataService.tempSelectedImgUrl = image.dataUrl;
      router.push("/skincheckcamerapreview", "forward", "push");
    }
  };
  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(null);

  const [presentingElement, setPresentingElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    setPresentingElement(page.current);
  }, []);

  function dismiss() {
    setOpenModal(false);
    modal.current?.dismiss();
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        {openModal && <div className="sccpOverlay" onClick={dismiss}></div>}
        <div>
          <div
            className="cciBackButtonContainer sccBackBtnContainer"
            onClick={() => {
              router.goBack();
            }}
          >
            <IonImg src="/assets/icons/LeftOutline.png"></IonImg>
          </div>

          <IonGrid>
            <IonRow class="profileTitleRow">
              <IonText className="sccTitleText">
              {SkinSelfCheckDataService.selectedRegionSubPart.title.length > 0 ? SkinSelfCheckDataService.selectedRegionSubPart.title : ""}
              </IonText>

              <IonButtons className="custom-skincheckbuttons">
                <IonButton id="open-modal" expand="block">
                  <IonImg
                    onClick={() => {
                      openPopover();
                      setTimeout(() => {
                        setOpenModal(true);
                      }, 250);
                    }}
                    className="absImageCheck"
                    src="assets/icons/QuestionCircleOutlineWhite.png"
                  />
                </IonButton>
              </IonButtons>
            </IonRow>
          </IonGrid>
          <div className="sccPhotoImgDiv">
            <IonImg
              className="sccPhotoImg"
              src="/assets/images/TakingSpotPhoto.png"
            />
          </div>
          <div
            className="sccCameraBtnImgDiv"
            style={{ top: `${window.innerHeight / 2 - 50}px` }}
          >
            <IonImg
              className="sccCameraBtnImg"
              src="/assets/icons/CameraBtn.png"
              onClick={checkSkincheckAlert}
            />
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header={"Disclaimer!"}
              message={
                "These pictures are stored in mySmartSkin for your use and will not be distributed to other parties"
              }
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                },
                {
                  text: "Ok",
                  handler: takePicture,
                },
              ]}
            />
          </div>
          <div className="sccpNoteGrid">
            <IonText className="sccpNoteText">
              Note : We recommend using a smartphone or tablet so that you can
              take photos within the body map
            </IonText>
          </div>
        </div>

        <IonModal
          className="no-padding"
          id="skin-modal"
          ref={modal}
          trigger="open-modal"
          presentingElement={presentingElement!}
        >
          <IonContent className="ion-padding">
            <IonIcon
              onClick={() => dismiss()}
              className="pull-right absImageCheck"
              icon={closeCircleOutline}
              style={{ color: "#000000", width: "30px", fontSize: "30px" }}
            ></IonIcon>
            <br />
            <IonRow className="header clearfix">
              <IonCol className="ion-no-padding">
                {" "}
                <h5 className="text-black d-flex">
                  {" "}
                  <IonImg
                    className="absImageCheck skin-icon"
                    src="assets/icons/CameraOutline-CameraOutline.svg "
                  />{" "}
                  Taking a proper photo of your spot
                </h5>
              </IonCol>
            </IonRow>
            <div className="info-card">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="full-image"
                    src="assets/images/skincheck/skincheck.svg"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="text-black skin-ioncontent">
                  Try to keep <strong>2 - 6 Inches</strong> between your phone
                  and the spot. Be consistent with the distance you use,
                  <strong> the closer the better.</strong>
                </IonCol>
              </IonRow>
            </div>

            <div className="info-card">
              <IonRow>
                <IonCol>
                  <IonImg
                    className="full-image"
                    src="assets/images/skincheck-2.png"
                  />
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="text-black skin-ioncontent">
                  Try to <strong> center the spot</strong> over the cross-hairs.
                </IonCol>
              </IonRow>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default SkinCheckCamera;

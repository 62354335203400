import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import {
  checkboxOutline,
  chevronForward,
  locateOutline,
  lockClosed,
  newspaperOutline,
} from "ionicons/icons";

import "./SubAccordian.css";

import "../../Fonts.css";
import { DataService } from "../../services/DataService";
import { AppInfoTimeService } from "../../services/AppInfoTimeService";

interface SubAccordionProps {
  coreNo: number;
  isActivity: boolean;
  progress: string;
  title: string;
  chapterNo: string;
  click: Function;
  screens: any;
}

const SubAccordion: React.FC<SubAccordionProps> = ({
  coreNo,
  chapterNo,
  title,
  isActivity,
  progress,
  click,
  screens,
}) => {
  return (
    <div
      className="subAccMainContainer"
      onClick={() => {
        // DataService.currentCoreScreen = screens[0];
        // DataService.currentCoreScreens = screens;
        click(screens, coreNo, chapterNo);

        if (AppInfoTimeService.appSectionsTimeNid === -1) {
          //Current change
          AppInfoTimeService.resetAppSectionsTimer();

          AppInfoTimeService.sendAppSectionsTimeToBE(coreNo + 1);
        } else {
          //Previous
          // AppInfoTimeService.resetAppSectionsTimer(coreNo + 1);

          //Current change
          AppInfoTimeService.resetAppSectionsTimer();
          
          AppInfoTimeService.sendAppSectionsTimeToBE(coreNo + 1);
        }
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="10">
            <IonGrid>
              <IonRow className="subAccTitleRow">
                <IonCol size="auto">
                  <IonImg
                    className="subAccLeftIcon"
                    src={
                      isActivity === false && progress === "done"
                        ? "assets/images/BookCompleted.png"
                        : isActivity === true
                        ? "assets/images/OutlineGoal.png"
                        : "assets/images/CloseBook.svg"
                    }
                  ></IonImg>
                </IonCol>
                <IonCol>
                  <IonText class="subAccChapterNoText">{chapterNo}</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="auto">
                  <IonIcon
                    className="subAccLeftIcon"
                    style={{ color: "transparent" }}
                    icon={chevronForward}
                  ></IonIcon>
                </IonCol>
                <IonCol>
                  <IonText className="subAccChapterTitleText">{title}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol size="2" className="subAccChevronCol">
            <IonImg
              className="subAccRightIcon"
              src={
                progress === "done" || progress === "unlocked"
                  ? "assets/images/RightOutline-right.svg"
                  : progress === "chapterDone"
                  ? "assets/icons/CheckMark.png"
                  : "assets/images/LockFill.svg"
              }
            ></IonImg>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default SubAccordion;

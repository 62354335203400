import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";

import "./FiveStarRating.css";

import "../../Fonts.css";
import { useEffect, useState } from "react";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { DataService } from "../../services/DataService";
import { C2DataSubmissionService } from "../../services/C2DataSubmissionService";

interface FiveStarRatingProps {
  click: Function;
  descriptions: Array<string>;
}

const FiveStarRating: React.FC<FiveStarRatingProps> = ({
  click,
  descriptions,
}) => {
  const [desc, setDesc] = useState("");
  const [starStatuses, setStarStatuses] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    if (DataService.currentCoreIndex === 1 && DataService.currentChapterIndex === 3) {
      let savedRating = C2DataSubmissionService.c2Ch4SunSafeImpRating;
      console.log(savedRating);
  
      for (let i = 0; i < savedRating; i++) {
        starSelected(i);
      }

      return;
    }

    let savedRating = DataSubmissionService.c1A1SkinSelfCheckImportanceRating;
    console.log(savedRating);

    for (let i = 0; i < savedRating; i++) {
      starSelected(i);
    }
  }, []);

  const starSelected = (index: number) => {
    var starSelectedTemp = [false, false, false, false, false];
    starStatuses.map((selected, i) => {
      if (i <= index) {
        starSelectedTemp[i] = true;
      }
    });
    setStarStatuses(starSelectedTemp);

    setDesc(descriptions[index]);

    click(index, descriptions[index]);
  };

  return (
    <div style={{ position: "relative" }}>
      <IonGrid>
        <IonRow>
          <IonCol className="fsrStarImgCol">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="fsrStarImgCol">
                  <IonImg
                    className="fsrStarImg"
                    src={
                      starStatuses[0]
                        ? "assets/icons/Star1Selected.png"
                        : "assets/icons/Star1.png"
                    }
                    onClick={() => {
                      starSelected(0);
                    }}
                  />
                </IonCol>
                {!starStatuses[0] && (
                  <>
                    <IonCol size="12" className="fsrStarImgCol">
                      <IonText className="fsrCornerText">|</IonText>
                    </IonCol>
                    <IonCol size="12" className="fsrStarImgCol">
                      <IonText className="fsrCornerText">Not</IonText>
                    </IonCol>
                    <IonCol size="12" className="fsrStarImgCol">
                      <IonText className="fsrCornerText">Important</IonText>
                    </IonCol>
                  </>
                )}
              </IonRow>
            </IonGrid>
          </IonCol>
          <IonCol className="fsrStarImgColFlexStart">
            <IonImg
              className="fsrStarImg"
              src={
                starStatuses[1]
                  ? "assets/icons/Star2Selected.png"
                  : "assets/icons/Star2.png"
              }
              onClick={() => {
                starSelected(1);
              }}
            />
          </IonCol>
          <IonCol className="fsrStarImgColFlexStart">
            <IonImg
              className="fsrStarImg"
              src={
                starStatuses[2]
                  ? "assets/icons/Star3Selected.png"
                  : "assets/icons/Star3.png"
              }
              onClick={() => {
                starSelected(2);
              }}
            />
          </IonCol>
          <IonCol className="fsrStarImgColFlexStart">
            <IonImg
              className="fsrStarImg"
              src={
                starStatuses[3]
                  ? "assets/icons/Star4Selected.png"
                  : "assets/icons/Star4.png"
              }
              onClick={() => {
                starSelected(3);
              }}
            />
          </IonCol>
          <IonCol className="fsrStarImgCol">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="fsrStarImgCol">
                  <IonImg
                    className="fsrStarImg"
                    src={
                      starStatuses[4]
                        ? "assets/icons/Star5Selected.png"
                        : "assets/icons/Star5.png"
                    }
                    onClick={() => {
                      starSelected(4);
                    }}
                  />
                </IonCol>
                {!starStatuses[0] && (
                  <>
                    <IonCol size="12" className="fsrStarImgCol">
                      <IonText className="fsrCornerText">|</IonText>
                    </IonCol>
                    <IonCol size="12" className="fsrStarImgCol">
                      <IonText className="fsrCornerText">Very</IonText>
                    </IonCol>
                    <IonCol size="12" className="fsrStarImgCol">
                      <IonText className="fsrCornerText">Important</IonText>
                    </IonCol>
                  </>
                )}
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      {starStatuses[0] && (
        <IonGrid className="fsrVeryImpTextGrid">
          <IonRow>
            <IonCol size="12" className="fsrVeryImpTextCol">
              <IonText className="fsrVeryImpText">{desc}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </div>
  );
};

export default FiveStarRating;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import { useState, useEffect } from "react";
import { postControlContentData, patchControlContentData, getContentProgressID } from "../../../services/dataApi";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";


const CC4: React.FC = () => {
  var router = useIonRouter();

  const [refresh, setRefresh] = useState(false);

  const [showLoader, setShowLoader] = useState(false);


  const navigateBack = () => {
    router.goBack();
  };

  useEffect(() => {
    //if(SkinSelfCheckDataService.controlcontentNID===-1){
    getControlContentNID()
    // }

  }, []);

  async function getControlContentNID() {
    try {
      openLoader();
      const responseData = await getContentProgressID(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Control Content Response ---->>> ", respData);

            if (respData.length > 0) {
              //let parsedJSON = JSON.parse(respData[0]);
              let nid = respData[0].id;
              console.log(nid);
              let compProg = respData[0].completed;
             
              SkinSelfCheckDataService.controlcontentCompletedProgress=compProg
              console.log(nid);
              console.log("completed Progress",SkinSelfCheckDataService.controlcontentCompletedProgress);
              SkinSelfCheckDataService.controlcontentNID = nid;
            } else {
              SkinSelfCheckDataService.controlcontentNID = -1;
              console.log("Control Content NID empty");
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Control Content NID not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck NID - Error fetching data:", error);
    }
  }

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };
  async function postControlContent() {
    openLoader();
    if (SkinSelfCheckDataService.controlcontentNID === -1) {
      try {
        openLoader();
        const responseData = await postControlContentData(

          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc5")
              //Save user data
              console.log("Patching Controlcontent success--" + respData);
            } else if (!isSuccess) {

              closeLoader();
              console.log("Patching Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    } else {
      try {
        openLoader();
        const responseData = await patchControlContentData(

          (isSuccess: boolean, respData: any) => {
            if (isSuccess) {
              //Success
              closeLoader();
              router.push("/cc5")
              //Save user data
              console.log("posting Controlcontent success--" + respData);
            } else if (!isSuccess) {
              //Error

              closeLoader();
              console.log(" posting Controlcontent failure--" + respData);
            }
          }
        );
        console.log(responseData);
      } catch (error) {
        // closeLoader();
        console.error("Error posting Skinselfcheck:", error);
      }
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonGrid>
          <IonRow class="titleCenter">
            {/* <IonIcon
                  className="settingsIcon"
                  icon={settingsOutline}
                  style={{ color: "transparent" }}
                ></IonIcon> */}
            <IonText className="titleText">Page 6</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
          </IonRow>
        </IonGrid>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <div className="mainContent" style={{ paddingTop: "32px" }}>
          <IonText className="ion-margin-bottom default-text cccoContentHeading">
            Sun Damage
          </IonText>
          {/* Main body here */}
          <div style={{ width: "100%", height: "16px" }} />


          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "<strong>Sun damage</strong> is a major risk factor for skin cancer.  If your skin is exposed to UV light without protection, it will damage the skin and can impact the genetic composition of your cells.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <div className="ccaDescTextContainer">
            <IonText
              className="ccaDescText"
              dangerouslySetInnerHTML={{
                __html: "You can have <strong>sun damage</strong> and not be able to see it on the surface.  The pictures below are taken with a UV camera lens to show damage that is hidden beneath the skin’s surface.",
              }}
            ></IonText>
          </div>
          <div style={{ width: "100%", height: "16px" }} />
          <IonImg src="/assets/images/cc/UV.png"></IonImg>
          <div style={{ width: "100%", height: "150px" }} />
          {/* Continue Button */}
          <div className="buttonFooter" style={{ left: "0px" }}>
            <IonGrid>
              <IonRow>
                <IonCol
                  size="12"
                  style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    paddingTop: "8px",
                    paddingBottom: "8px",
                  }}
                >
                  enableNextBtn && (
                  <IonButton
                    className="loginBtnEnabled"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      SkinSelfCheckDataService.controlcontentUserProgress = 6;
                      if (SkinSelfCheckDataService.controlcontentCompletedProgress < 10) {
                        postControlContent()
                      } else {
                        router.push("/cc5")
                      }
                      //router.push("/cc5")
                    }}
                  >
                    Continue
                  </IonButton>
                  )
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter"></div>
    </IonPage>
  );
};

export default CC4;

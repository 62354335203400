import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonIcon,
} from "@ionic/react";
import { useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./AccordianText.css";

import "../../Fonts.css";
import CoreChapterActivityOptionHint from "../core_chapter_activity_option_hint/CoreChapterActivityOptionHint";

interface AccordianTextProps {
  title: string;
  imageUrl: string;
  description: string;
  open: boolean;
  isOpened: Function;
}

const AccordianText: React.FC<AccordianTextProps> = ({
  title,
  imageUrl,
  description,
  open,
  isOpened
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      isOpened();
    }
  };

  return (
    <div className="accMainContainer accccMainContainer">
      <IonGrid className="accTitleGrid accccTitleGrid">
        <IonRow>
          <IonCol size="auto">
            {/* <div className="accLessonImg"></div> */}
            <IonImg src={imageUrl} onClick={toggleAccordion} />
          </IonCol>
          <IonCol className="accTitleCol accccTitleCol" size="8">
          <IonText className="accTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
                onClick={toggleAccordion}
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen && (
        <CoreChapterActivityOptionHint text={description} isWhiteBG={false} textClicked={() => {}} />
      )}
    </div>
  );
};

export default AccordianText;

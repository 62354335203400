import {
  IonButton,
  IonContent,
  IonImg,
  IonPage,
  IonPopover,
  IonText,
  useIonRouter,
  IonDatetime
} from "@ionic/react";
import "./ComponentTesting.css";
import { useRef, useState, useEffect } from "react";
import CoreSingleCheckOption from "../../components/core_single_select_option/CoreSingleCheckOption";
import CoreIncrementDecrement from "../../components/core_increment_decrement/coreIncrementDecrement";
import CoreContentCompletedImgTextGoal from "../../components/core_content_completed_img_text_setgoal/CoreContentCompletedImgTextSetGoal";
import CoreAccordionDescriptionIntro from "../../components/core_accordion_description_intro/CoreAccordionDescriptionIntro";
import CoreFnf from "../../components/core_fnf/corefnf";
import CoreAccordianImageDesc from "../../components/core_accordion_image_desc/CoreAccordianImageDesc";
import CoreVideoPlayer from "../../components/core_video-player/coreVideoPlayer";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";
import CoreMCQ from "../../components/core_mcq/CoreMCQ";
import SummaryDescription from "../../components/summary_description/SummaryDescription";
import AccordianBioDesc from "../../components/accordian_bio_desc/AccordianBioDesc";
import React from "react";
import LikertScale from "../../components/likert_scale_component/LikertScale";
import AccordinaActivityPerson from "../../components/accordian_activity_person/AccordianActivityPerson";
import ImageDescriptionPopOver from "../../components/image_description_popover/ImageDescriptionPopOver";
import DateTimePicker from "../../components/date_time_picker/dateTimePicker";

const ComponentTesting: React.FC = () => {
  var router = useIonRouter();
  const modal = useRef<HTMLIonModalElement>(null);
  const [showLoader, setShowLoader] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  
  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const togglePlay = () => {
    if (isPlaying) {
      if (audioRef !== null && audioRef.current) {
        audioRef.current.pause();
      }
    } else {
      if (audioRef !== null && audioRef.current) {
        audioRef.current.play();
      }
    }
    setIsPlaying(!isPlaying);
  };

  const openForm = () => {
    router.push("/formpage");
  };

  const openModal = () => {
    setShowLoader(true);

    setTimeout(() => {
      closeModal();
    }, 2000);
  };

  const closeModal = () => {
    setShowLoader(false);
  };

  const counterValue = (count: number) => {
    console.log(count);
  };

  const likertIndex = (index: any) => {
    console.log(index);
  };

  const images = [
    {
      imageUrl: "/assets/images/Watersport.png",
      description: "Description for image 1",
      point:
        "<strong>Have someone</strong> to help me during my skin self-check",
    },
    {
      imageUrl: "assets/images/scsdImages/B.png",
      description: "Description for image 2",
      point:
        "<strong>Have someone</strong> to help me during my skin self-check",
    },
    {
      imageUrl: "assets/images/scsdImages/C.png",
      description: "Description for image 3",
      point:
        "<strong>Have someone</strong> to help me during my skin self-check",
    },
    {
      imageUrl: "assets/images/scsdImages/D.png",
      description: "Description for image 4",
      point:
        "<strong>Have someone</strong> to help me during my skin self-check",
    },
    {
      imageUrl: "assets/images/scsdImages/E.png",
      description: "Description for image 5",
      point:
        "<strong>Have someone</strong> to help me during my skin self-check",
    },
    {
      imageUrl: "assets/images/scsdImages/F.png",
      description: "Description for image 6",
      point:
        "<strong>Have someone</strong> to help me during my skin self-check",
    },
    // Add more images as needed
  ];

  const [openImageIndex, setOpenImageIndex] = useState<number | null>(null);

  const handleImageClick = (index: number) => {
    setOpenImageIndex(openImageIndex === index ? null : index);
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#00acdf" }}>
       {/* <DateTimePicker></DateTimePicker> */}
        {/* <div className="ctMainContainer">
          <AccordinaActivityPerson
            title={"Sports Activity"}
            mainimageUrl={"/assets/images/Watersport.png"}
            description={
              "I was very surprised when I was diagnosed with melanoma two years ago, I didn't use a lot of sun protection when I was younger, but I thought I was doing a pretty good job at protecting my skin more recently, I have been retired for 6 years, and I enjoy gardening and looking after my granddaughter, I are also a member of a community walking group"
            }
            imageUrl={"/assets/images/Doc.png"}
            audioUrl={"/assets/audio/judith_help.mp3"}
            text="Judith"
            open={false}
            isOpened={() => {}}
          ></AccordinaActivityPerson>
          <LikertScale
            question="use sunscreen with SPF 30 or higher?"
            likertClickIndex={likertIndex}
          />
          <div className="image-accordion">
            <ImageDescriptionPopOver imageArray={images} />
          </div>
        </div> */}
        {/* <div className="ctMainContainer">
          <div
            className="lBack"
            onClick={() => {
              router.goBack();
            }}
          >
            <IonImg src="/assets/icons/LeftOutlineBlack.png"></IonImg>
          </div>

          <IonPopover
            id="factPopover"
            ref={popover}
            isOpen={popoverOpen}
            onDidDismiss={() => setPopoverOpen(false)}
          >
            <IonContent class="ion-padding">
              <IonText className="factPopoverText">Hello fact</IonText>
            </IonContent>
          </IonPopover>

          <div>
            <button onClick={openPopover}>Open Popover</button>
          </div>

          <div>
            <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button>
            <audio ref={audioRef}>
              <source src="assets/audio/aud.mp3" type="audio/mp3" />
              Your browser does not support the audio element.
            </audio>
          </div>

          <IonButton onClick={openModal}>Open Loader</IonButton>

          <IonButton onClick={openForm}>Open Form</IonButton>

          <LoadingScreen isOpen={showLoader} />

          <IonText
              className="absText"
              dangerouslySetInnerHTML={{ __html: '<IonText>Hi with a <span style="color: blue;">blue color</span></IonText>'}}
            ></IonText>

          <CoreSingleCheckOption
            title="Do you plan to sunbathe in the next 12 months?"
            listOptions={["Yes", "No", "Not Sure"]}
            selected={() => {}}
          />
          <SummaryDescription text="ABCD" />
          <CoreIncrementDecrement
            question={
              "How many times in the past 12 months have you sunbathed?"
            }
            onSelected={counterValue}
          />
          <div style={{ marginBottom: "16px" }}></div>
          <CoreAccordionDescriptionIntro
            desc="Damage to the skin is caused by the sun’s ultraviolet (UV) rays, which you can’t see or feel. UV rays are different from the light from the sun that you see (visible light) or its warmth that you feel (infrared radiation). The sun’s UV rays can still be very strong even when it is cloudy or cool outside. That is why it is important to be sun-safe and protect your skin year round and in any weather conditions."
            accImg="./assets/images/qustnImg.png"
            accHeader="Did You Know?"
            accDesc="A number of factors can make the sun’s UV rays even stronger, including:"
            accPoints={[
              "The time of day: the sun’s UV rays are strongest from 10 a.m. to 4 p.m.",
              "Reflective surfaces such as water, sand, or snow",
              "Being at a higher altitude",
              "Being closer to the equator",
            ]}
            bottomDesc="Understanding UV is important to keep your skin safe. Even though a burn may heal, damage beneath the surface of the skin can never be repaired. However, it's never too late to protect your skin from further damage. Engaging in sun-safe behaviors will help you to reduce your risk of skin cancer and prevent skin aging."
          />
          <CoreFnf
            data={[
              {
                id: 1,
                title: "What do you know about spots 1?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question: "Normal spots are usually multicoloured.",
                answer: "Normal spots are not usually multicoloured",
                answerType: "Fiction",
              },
              {
                id: 2,
                title: "What do you know about spots 2?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question: "Normal spots usually have a round or oval shape.",
                answer: "Normal spots usually have a round or oval shape.",
                answerType: "Fact",
              },

              {
                id: 3,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question: "Apply sunscreen as soon as you get outside.",
                answer: "Don't wait until you are outside to apply sunscreen.",
                answerType: "Fiction",
              },
              {
                id: 4,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question:
                  "Apply sunscreen about 20 minutes before you go outside.",
                answer: "Sunscreen should be applied before you go outside.",
                answerType: "Fact",
              },
              {
                id: 5,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question:
                  "Most people apply less than half the recommended amount of sunscreen.",
                answer:
                  "keep this fact in mind when you are applying sunscreen.",
                answerType: "Fact",
              },
              {
                id: 6,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question: "Sunscreen is all you need to use to stay sun-safe.",
                answer:
                  "Sunscreen is just one of several behaviors that can help you stay sun-safe.",
                answerType: "Fiction",
              },
              {
                id: 7,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question:
                  "To use enough sunscreen, you can apply it in two layers:use it once on all exposed areas and then apply it again right away .",
                answer:
                  "This also helps ensure that you don't miss any areas of your body.",
                answerType: "Fact",
              },
              {
                id: 8,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question:
                  "Once you have put sunscreen on in the morning, you are protected for the day.",
                answer: "Sunscreen must be reapplied to be effective.",
                answerType: "Fiction",
              },
              {
                id: 9,
                title: "What do you know about sunscreen?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question:
                  "Reapply sunscreen every two hours or more often if you are sweating a lot or swimming.",
                answer:
                  "Be sure to reapply sunscreen early & often, especially if you have been sweating or swimming.",
                answerType: "Fact",
              },
            ]}
            isSelected={() => {}}
          />
          <CoreMCQ
            data={[
              {
                id: 1,
                title: "What do you know about spots?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question: "Normal spots are usually multicoloured.",
                questionImages: "assets/images/scsdImages/A.png",
                answer: "Asymmetrical",
                answerType: "A is for Asymmetrical",
                mcqButton: ["Actinic", "Anemic", "Asymmetrical", "Aligned"],
              },
              {
                id: 2,
                title: "What do you know about spots?",
                description:
                  "Read each statement and choose if it's fact, or fiction",
                question: "Normal spots usually have a round or oval shape.",
                questionImages: "assets/images/scsdImages/B.png",
                answer: "Border irregularity",
                answerType: "B is for Border irregularity",
                mcqButton: [
                  "Blurry",
                  "Brown color",
                  "Bulging",
                  "Border irregularity",
                ],
              },
            ]}
            isSelected={() => {}}
          />
          <CoreAccordianImageDesc
            setOpen={false}
            desc="The sun emits two types of ultraviolet (UV) rays that can damage the skin:"
            accDescHead="UVA Rays"
            accDescHead2="UVB Rays"
            accDescPoints={[
              "Cause early Aging of the skin, wrinkles, and sun spots",
              "Damage the deeper layers of your skin",
              "Cause skin cancer",
            ]}
            accDescPoints2={[
              "Cause immediate damage in the form of a Burn",
              "Damage the top layers of your skin",
              "Cause skin cancer",
            ]}
          />
          <LikertScale question="use sunscreen with SPF 30 or higher?" likertClickIndex = {likertIndex}/>
          <CoreVideoPlayer
            coreVideoUrl="https://vimeo.com/378460253"
            coreVideoText="Monthly skin self-checks are an important part of your follow-up
                care."
          />
          <AccordianBioDesc
            title="Sara"
            imageUrl="/assets/images/Judith.png"
            description="jhewbfd ewdiwebd ewdebdwe dweibdwe dwehkdb"
            audioUrl=""
          />
        </div> */}
      </IonContent>
    </IonPage>
  );
};

export default ComponentTesting;


import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./AccordianMainimgText.css";

import "../../Fonts.css";
import AccordianCoreContentImgExp from "../accordian_core_content_img_exp/AccordianCoreContentImgExp";
import AccordianCoreContentIconExp from "../accordian_core_content_icon_exp/AccordianCoreContentIconExp";

interface AccordianMainImgTextProps {
  title: string;
  expMainImgUrl: string;
  imageUrl: string;
  expMainImgText: string;
  expansionPoints: Array<any>;
  open: boolean;
  isOpened: Function;
}

const AccordianMainImgText: React.FC<AccordianMainImgTextProps> = ({
  title,
  imageUrl,
  expMainImgUrl,
  expMainImgText,
  expansionPoints,
  open,
  isOpened
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      isOpened();
    }
  };

  return (
    <div className="accMainContainer accccMainContainer">
      <IonGrid className="accTitleGrid accccTitleGrid">
        <IonRow>
          <IonCol size="auto">
            {/* <div className="accLessonImg"></div> */}
            <IonImg src={imageUrl} />
          </IonCol>
          <IonCol className="accTitleCol accccTitleCol" size="8">
            <IonText className="accTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
                onClick={toggleAccordion}
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen && (
        <div>
          {expMainImgUrl && (
            <div>
              <AccordianCoreContentImgExp
                text={expMainImgText}
                imageUrl={expMainImgUrl}
              />
            </div>
          )}
          {expansionPoints.map((point, i) => {
            return (
              <AccordianCoreContentIconExp
                text={point.text}
                imageUrl={point.imageUrl}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AccordianMainImgText;

import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import { useEffect, useState } from "react";

import "./CoreForm8.css";

import "../../Fonts.css";
import CoreFormStrategy from "../core_form_strategy/coreFormStrategy";
import { enter } from "ionicons/icons";
import { exit } from "process";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { DataService } from "../../services/DataService";
import { C2DataSubmissionService } from "../../services/C2DataSubmissionService";

let selStrategies: string[] = [];

interface CoreForm8Props {
  question: string;
  desc: string;
  listOptions: Array<any>;
  selected: Function;
  limitOptions: number;
}

const CoreForm8: React.FC<CoreForm8Props> = ({
  question,
  desc,
  listOptions,
  selected,
  limitOptions,
}) => {
  const [selectedStates, setSelectedStates] = useState([false]);
  const [refresh, setRefresh] = useState(false);
  // const [strat, setStrat] = useState([""]);

  useEffect(() => {
    let tempArr = [];
    tempArr = new Array(listOptions.length).fill(false);
    setSelectedStates(tempArr);

    checkForData();
  }, [listOptions]);

  const checkForData = () => {
    console.log(desc);

    if (
      DataService.currentCoreIndex === 1 &&
      DataService.currentChapterIndex === 3
    ) {
      let selectedOptions = C2DataSubmissionService.c2Ch4S4SunSafeImpOpts;
      let selectedStrategies = C2DataSubmissionService.c2Ch4S4SunSafeImpStrgs;

      if (
        selectedOptions !== undefined &&
        selectedOptions !== null &&
        selectedOptions.length > 0
      ) {
        let selectedIndexes: number[] = [];
        selectedOptions.forEach((selOpt: string, i: number) => {
          let ind = listOptions.indexOf(selOpt);
          selectedIndexes.push(ind);
        });

        if (selectedIndexes.length > 0) {
          let tempArr = new Array(listOptions.length).fill(false);

          selectedIndexes.forEach((indd: number) => {
            tempArr[indd] = true;
          });

          selStrategies = selectedStrategies;
          setSelectedStates(tempArr);
          // setStrat(selectedStrategies);
          pushToParentCallback(tempArr, selectedStrategies);
        }
      }
      return;
    }

    let savedGoals = DataSubmissionService.c2A1SkinSelfCheckGoals.goals;
    let selectedGoal = DataSubmissionService.c2A1LatestGoalTitle;

    if (selectedGoal.length > 0) {
      let filteredGoalsByTitle =
        DataSubmissionService.c2A1SkinSelfCheckGoals.goals.filter(
          (goal) => selectedGoal === goal.title
        );

      if (filteredGoalsByTitle.length > 0) {
        let filteredGoal = filteredGoalsByTitle[0];
        let filteredGoalMcqs = filteredGoal.mcqs;

        let filteredMCQsByQuestion = filteredGoalMcqs.filter(
          (mcq) => desc === mcq.question
        );

        if (filteredMCQsByQuestion.length > 0) {
          let filteredMCQ = filteredMCQsByQuestion[0];
          let selectedOptions = filteredMCQ.answers;
          let selectedStrategies = filteredMCQ.strategies;

          if (selectedOptions.length > 0) {
            let selectedIndexes: number[] = [];
            selectedOptions.forEach((selOpt: string, i: number) => {
              let ind = listOptions.indexOf(selOpt);
              selectedIndexes.push(ind);
            });

            if (selectedIndexes.length > 0) {
              let tempArr = new Array(listOptions.length).fill(false);

              selectedIndexes.forEach((indd: number) => {
                tempArr[indd] = true;
              });

              selStrategies = selectedStrategies;
              setSelectedStates(tempArr);
              // setStrat(selectedStrategies);
              pushToParentCallback(tempArr, selectedStrategies);
            }
          }
        }
      }
    }
  };

  const pushToParentCallback = (
    selectedOptionsArr: boolean[],
    selectedStrategies: string[]
  ) => {
    var selectedOptionstempArr: string[] = [];
    var selectedStrategiesTempArr: string[] = [];
    var checkFlag: boolean = false;
    selectedOptionsArr.map((opt, i) => {
      if (opt) {
        selectedOptionstempArr.push(listOptions[i]);
      } else {
        if (i == listOptions.length - 1) {
          selectedStrategiesTempArr = [];
          checkFlag = true;
        }
      }
    });
    if (checkFlag) {
      selected(selectedOptionstempArr, selectedStrategiesTempArr);
    } else {
      selected(selectedOptionstempArr, selectedStrategies);
    }
  };

  const setOptionselected = (index: number) => {
    let tempArr = [];
    tempArr = selectedStates;

    const trueValues = tempArr.filter((value) => value === true);
    const count = trueValues.length;

    // tempArr[index] = !tempArr[index];

    if (limitOptions > 0) {
      //If selected index is false or count less than 2
      if (tempArr[index] || count < limitOptions) {
        tempArr[index] = !tempArr[index];

        setSelectedStates(tempArr);
        setRefresh(!refresh);
        if (index == listOptions.length - 1 && !tempArr[index]) {
          // setStrat([]);
          selStrategies = [];
        }
        pushToParentCallback(selectedStates, selStrategies);
      }
    } else {
      tempArr[index] = !tempArr[index];

      setSelectedStates(tempArr);
      setRefresh(!refresh);
      if (index == listOptions.length - 1 && !tempArr[index]) {
        // setStrat([]);
        selStrategies = [];
      }
      pushToParentCallback(selectedStates, selStrategies);
    }
  };

  return (
    <div className="coreformmcqContainer">
      <IonGrid>
        {question !== null && question !== undefined && question.length > 0 && (
          <IonRow className="coreformmcqQuesdiv">
            <IonText className="coreformQuesText">{question}</IonText>
          </IonRow>
        )}

        <IonRow
          className="coreformmcqDescdiv"
          style={{
            paddingLeft:
              question !== null && question !== undefined && question.length > 0
                ? "16px"
                : "0px",
          }}
        >
          <IonText
            className="coreformDescText"
            dangerouslySetInnerHTML={{ __html: desc }}
          ></IonText>
        </IonRow>

        <IonCol>
          {listOptions.map((option: string, i: number) => {
            return (
              <IonRow
                className="coreformmcqOptionsdiv"
                // onClick={() => {
                //   setOptionselected(i);
                // }}
              >
                <IonCol size="auto">
                  {selectedStates[i] && (
                    <IonImg
                      className="coreformImage"
                      src="assets/icons/core_form_mcq_check.png"
                      onClick={() => {
                        setOptionselected(i);
                      }}
                    />
                  )}
                  {!selectedStates[i] && (
                    <IonImg
                      className="coreformImage"
                      src="assets/icons/core_form_mcq_uncheck.png"
                      onClick={() => {
                        setOptionselected(i);
                      }}
                    />
                  )}
                </IonCol>
                <IonCol className="coreformmcqOptionsTextdiv">
                  <IonText className="coreformOptionText">{option}</IonText>
                </IonCol>
                <IonCol size="12">
                  {i === listOptions.length - 1 && selectedStates[i] && (
                    <CoreFormStrategy
                      selectedStrategies={selStrategies}
                      placeholder={(listOptions[listOptions.length - 1].includes("reason")) ? "Enter your Reason" : "Enter your Strategy"}
                      inputTextFieldType="text"
                      isReason={listOptions[listOptions.length - 1].includes("reason")}
                      enterdValues={(stratgies: Array<string>) => {
                        selStrategies = stratgies;
                        // setStrat(stratgies);
                        pushToParentCallback(selectedStates, stratgies);
                      }}
                    />
                  )}
                </IonCol>
              </IonRow>
            );
          })}
        </IonCol>
      </IonGrid>
    </div>
  );
};

export default CoreForm8;

import { useEffect, useState } from "react";
import CoreFnfProgressBar from "../core_fnf_progress-bar/corefnfProgressBar";
import "./CoreMCQ.css";
import { CoreMCQJsonDataType } from "../../services/DataService";
import CoreMCQBars from "../core_mcq_bars/CoreMCQBars";

interface CoreMCQ {}

interface CoreMCQProps {
  data: Array<any>;
  isSelected: Function;
}

const CoreMCQ: React.FC<CoreMCQProps> = ({
  data,
  isSelected
}) => {
  const [index, setIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<CoreMCQJsonDataType>(
    data[index]
  );
  const [isQuestion, setIsQuestion] = useState<boolean>(true);
  let totalNoOfQuestions = data.length;

  // let index = 0;
  // let currentQuestion = data[index];
  // let isQuestion = true;
  // let totalNoOfQuestions = data.length;
  
  // const checkCurrentQuestion = () => {
  //   if (currentQuestion === undefined) {
      // setCurrentQuestion(data[0]);
      // setIndex(0);
      // setIsQuestion(true);
      // totalNoOfQuestions = data.length;

  //     return true;
  //   }
  //   return true;
  // }

  useEffect(() => {
    setCurrentQuestion(data[0]);
    setIndex(0);
    setIsQuestion(true);
    totalNoOfQuestions = data.length;
  }, [data])
  

  const onClickContinue = () => {
    if (index === totalNoOfQuestions - 1) {
      setCurrentQuestion(data[totalNoOfQuestions - 1]);
    } else {
      var tempIndex = index + 1;
      setIndex(tempIndex);
      setCurrentQuestion(data[tempIndex]);
    }
    setIsQuestion(true);
  };

  const finalAnswerSelected = () => {
    isSelected(data);
  }

  const answerSelected = (question: any, answer: string) => {
    console.log(question);
    console.log(answerSelected);

    question["enteredAnswer"] = answer;
    console.log(question);
  }

  return (
    <>
    {true && <>
      <CoreFnfProgressBar
        coreFnfTitle={currentQuestion.title}
        coreFnfDescription={currentQuestion.description}
        questionNo={`${currentQuestion.id}/${totalNoOfQuestions}`}
      />
      <CoreMCQBars
        question={currentQuestion.question}
        questionImage={currentQuestion.questionImages}
        currentQuestion={currentQuestion}
        continueHandler={onClickContinue}
        isQuestion={isQuestion}
        setIsQuestion={setIsQuestion}
        mcqButton={currentQuestion.mcqButton}
        hideContinue={(currentQuestion.id === totalNoOfQuestions)}
        finalAnswerSelected = {finalAnswerSelected}
        answerSelected = {answerSelected}
      ></CoreMCQBars>
      </>}
    </>
  );
};

export default CoreMCQ;

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
    useIonRouter
  } from "@ionic/react";
  import "./MyStuffRewardsProgressState.css";
import { DataService } from "../../services/DataService";
  
  
  interface MyStuffRewardsProgressStateProps {
    imageUrl: string;
    title: string;
    earned: string;
    total: string;
    isEarned : boolean;
    buttonText:string;
    buttonTextWon:string;
  }
  
  const MyStuffRewardsProgressState: React.FC<MyStuffRewardsProgressStateProps> = ({
    imageUrl,
    title,
    earned,
    total,
    isEarned,
    buttonText,
    buttonTextWon
  }) => {
    var router = useIonRouter();

    const navigateToBadges = (sel:string) => {
        DataService.selectedPrizeTitle="";
        DataService.isSelectedBagePrizeTab=sel;
        router.push("/RewardsBadgesPrizes", "forward", "push");
      };
    return (
        <><div style={{ marginBottom: "24px" }}></div>
        <IonGrid className="msGridFill">
            <IonRow>
                <IonCol size="auto" className="msImagebeCol">
                    <IonImg src={imageUrl}></IonImg>
                </IonCol>
                <IonCol>
                    <IonRow>
                        <IonCol>
                            <IonText className="msTextBadgesEarned">{title}</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonText className="msTextEarnedBadges">{earned}</IonText>
                            <IonText className="msTextTotalBadges"> {total}</IonText>
                        </IonCol>
                    </IonRow>
                </IonCol>
                {!isEarned && <IonCol size="2" className="msButtonCol">
                    <div className="msButtonDes" onClick={() => {navigateToBadges(title)}}><IonText className="msTextView">{buttonText}</IonText></div>
                </IonCol>}
                {isEarned && <IonCol size="5" className="msButtonCol1">
                    <div className="msButtonDes1" onClick={() => {navigateToBadges(title)}}><IonText className="msTextViewWon">{buttonTextWon}</IonText></div>
                </IonCol>}
                
            </IonRow>
        </IonGrid></>
    );
  };
  
  export default MyStuffRewardsProgressState;
  
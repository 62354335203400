import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
} from "@ionic/react";
import "./coreIncrementDecrement.css";
import { useEffect, useState } from "react";
import { C2DataSubmissionService } from "../../services/C2DataSubmissionService";

interface CoreIncrementDecrement {
    question: string;
    onSelected: Function;
}


const CoreIncrementDecrement: React.FC<CoreIncrementDecrement> = ({ question, onSelected }) => {
    const [counter, setCounter] = useState(-1);

    useEffect(() => {
        if (question === "How many times in the past 12 months have you sunbathed?") {
            let answer = C2DataSubmissionService.c2Ch3S1IncDec;
            if (answer >= 0) {
                setCounter(answer);
            }
        }
    }, [])

    const decreaseCountHandler = () => {
        if (counter > 0) {
            let dec = counter - 1;
            setCounter(counter - 1);
            onSelected(dec);
        }
        if (counter > 99) {
            setCounter(0);
            onSelected(counter);
        }
    }
    const increaseCountHandler = () => {
        let inc = counter + 1;
        setCounter(counter + 1);
        if (counter === 99) {
            setCounter(99);
        }
        onSelected(inc);
    }
    return (
        <>
            <div className="coreIncreDecreMainContainer">
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            <IonText className="coreQuestionText">{question}</IonText>
                        </IonCol>
                    </IonRow>
                    <IonRow className="coreIncreDecre">
                        <IonCol size="auto">
                            <IonImg src="assets\icons\MinusCircleEnabled.png" onClick={decreaseCountHandler}></IonImg>
                        </IonCol>
                        <IonText className="count">
                            {counter < 10 ? "0" + counter : counter} <span className="coreCountTime">Times</span>
                        </IonText>
                        <IonCol size="auto">
                            <IonImg src="assets\icons\AddCircleOutline.png" className="minusCircleImg" onClick={increaseCountHandler}></IonImg>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
        </>
    );
};
export default CoreIncrementDecrement;


export abstract class C2DataSubmissionService {
  //Core-2 Chapter 4 Sun Safe Star Rating
  public static c2Ch4SunSafeImpRating: number;
  public static c2Ch4SunSafeImpRatingDesc: string;

  //Core-2 Chapter 4 Screen 3 List Options
  public static c2Ch4S3SunSafePriorityOpts: Array<string>;
  public static c2Ch4S3SunSafePriorityStrgs: Array<string>;

  //Core-2 Chapter 4 Screen 4 List Options
  public static c2Ch4S4SunSafeImpOpts: Array<string>;
  public static c2Ch4S4SunSafeImpStrgs: Array<string>;

  //Core-2 Chapter 1 Screen 3 likerts
  public static c2Ch1S3LikertQstn1 = -1;
  public static c2Ch1S3LikertQstn2 = -1;
  public static c2Ch1S3LikertQstn3 = -1;
  public static c2Ch1S3LikertQstn4 = -1;
  public static c2Ch1S3LikertQstn5 = -1;
  public static c2Ch1S3LikertQstn6 = -1;
  public static c2Ch1S3LikertQstn7 = -1;
  public static c2Ch1S3LikertQstn8 = -1;

  public static c2Ch1TotalLikertScaleInd = 0;
  public static c2Ch1TotalLikertScaleIndShowsText =
    "opportunities to improve your sun-safe behaviors";
  public static c2Ch1TotalLikertScaleIndShowsTextGreat =
    "you’re doing a great job protecting your skin from the sun";
  public static c2Ch1TotalLikertScaleIndShowsDesc1Text =
    "<strong>protecting yourself from the sun is critical!</strong> Sun damage builds up over time and protecting yourself at any age can help reduce your risk of getting another skin cancer.It’s never too late to begin or improve your engagement in sun-safe behaviors.";
  public static c2Ch1TotalLikertScaleIndShowsDesc2Text =
    "<strong>continuing to protect yourself from the sun is critical!</strong> Continuing to protect yourself can help reduce your risk of getting another skin cancer.";
  public static c2Ch1TotalLikertScaleIndShowsSol1Text =
    "help <strong>boost your engagement in sun-safe behaviors</strong> and help <strong>reduce your sun exposure.</strong>";
  public static c2Ch1TotalLikertScaleIndShowsSol2Text =
    "show you some steps to help <strong>you keep up the good work,</strong> and <strong>even improve your engagement in sun-safe behaviors!</strong>";

  public static c2Ch3DynamicText1 =
    "Many people struggle with regular sunscreen use. Don’t give up! Using sunscreen is an important sun-safe behavior. mySmartSkin will help you to overcome your own challenges to using sunscreen. For the best protection, use a";
  public static c2Ch3DynamicText2 =
    "You’re on the right track with your sunscreen use but have opportunities to use it more often. Many people struggle with regular sunscreen use. mySmartSkin will help you to overcome your own challenges to using sunscreen. For the best protection, use a";
  public static c2Ch3DynamicText3 =
    "It’s great that you are regularly protecting yourself with sunscreen! For the best protection, continue to use";

  //C2CH3 Sun Safe Clothing & Sunglasses Dynamic Long Sleeved Shirt
  public static c2Ch3DynamicTextCLSG1 = "Wearing a";
  public static c2Ch3DynamicTextCLSG2 =
    " is an important sun-safe behavior, and one you have the opportunity to engage in more often. It’s an easy way to keep your torso and arms protected from the sun.";

  public static c2Ch3DynamicTextCLSG3 = "You’re on the right track. Wearing a";
  public static c2Ch3DynamicTextCLSG4 =
    " when you are in the sun is an easy way to keep your torso and arms protected.";

  public static c2Ch3DynamicTextCLSG5 =
    "It’s great that you regularly protect yourself with a";
  public static c2Ch3DynamicTextCLSG6 = "! Keep up the good work.";
  //C2CH3 Sun Safe Clothing & Sunglasses Dynamic Wide Brimmed Hat
  public static c2Ch3DynamicTextBH1 = "Wearing a";
  public static c2Ch3DynamicTextBH2 =
    " when you are in the sun is a great way to protect your head, face, ears, and neck. This is a sun-safe behavior you can be engaging in more often.";

  public static c2Ch3DynamicTextBH3 = "You’re on the right track. Wearing a";
  public static c2Ch3DynamicTextBH4 =
    " when you are in the sun is a great way to protect your head, face, ears, and neck.";

  public static c2Ch3DynamicTextBH5 =
    "It’s great that you regularly protect yourself with a";
  public static c2Ch3DynamicTextBH6 = "! Keep up the good work.";

  //C2CH3 Sun Safe Clothing & Sunglasses Dynamic Wearing Sunglasses
  public static c2Ch3DynamicTextWS1 =
    "It’s important to wear sunglasses that offer ultraviolet (UV) protection. The sun’s rays can damage your eyes and cause cataracts and other eye conditions.";
  public static c2Ch3DynamicTextWS2 =
    " can also help to prevent wrinkles around the eyes. This is a sun-safe behavior you can be engaging in more often.";

  public static c2Ch3DynamicTextWS3 = "You’re on the right track. ";
  public static c2Ch3DynamicTextWS4 =
    " when you are outside helps protect your eyes against cataracts and other eye conditions. It also helps to prevent winkles around the eyes.";

  public static c2Ch3DynamicTextWS5 =
    "It’s great that you regularly protect yourself by ";
  public static c2Ch3DynamicTextWS6 =
    "! Be sure to use sunglasses that protect against both ultraviolet A (UVA) and ultraviolet B (UVB) rays.";

  public static c2Ch3DynamicTextoa1 =
    "You’re on the right track with these sun-safe behaviors. mySmartSkin will help you engage in these behaviors more often.";
  public static c2Ch3DynamicTextoa2 =
    " It’s great that you regularly protect yourself with a long-sleeved shirt, wide-brimmed hat, and sunglasses! Remember to also use SPF 30+ sunscreen and seek shade when possible. Keep up the good work!";

  public static c2Ch3DynamicTextas1 =
    "The sun is very strong between 10 a.m. and 4 p.m. and limiting ultraviolet (UV) exposure during this time is an important sun-safe behavior.";
  public static c2Ch3DynamicTextas2 =
    " remember to use multiple sun-safe behaviors for best protection. Try to plan your outdoor activities at times when the sun is less strong, such as first thing in the morning or in the late afternoon or evening";

  public static c2Ch3DynamicTextas3 = "You’re on the right track.";
  public static c2Ch3DynamicTextas4 =
    " remember to use multiple sun-safe behaviors for best protection. Try to plan your outdoor activities at times when the sun is less strong, such as first thing in the morning or in the late afternoon or evening";

  public static c2Ch3DynamicTextas5 =
    "It’s great that you regularly avoid the sun during peak ultraviolet (UV) times!";
  public static c2Ch3DynamicTextas6 =
    " remember to use multiple sun-safe behaviors for best protection";

  public static c2Ch3DynamicTextss1 =
    " It’s important to try and stay in the shade or use a large umbrella for shade when you are in the sun. When you can’t find shade or use an umbrella, remember to use multiple";
  public static c2Ch3DynamicTextss2 =
    "Try to plan your outdoor activities at times when the sun is less strong, such as first thing in the morning or in the late afternoon or evening.";
  public static c2Ch3DynamicTextss3 =
    "You’re on the right track. When you can’t find shade or stay under a large umbrella, remember to use multiple";
  public static c2Ch3DynamicTextss4 =
    "Try to plan your outdoor activities at times when the sun is less strong, such as first thing in the morning or in the late afternoon or evening.";
  public static c2Ch3DynamicTextss5 =
    "It’s great that you regularly seek shade or use an umbrella when outdoors! When you can’t find shade or stay under a large umbrella, remember to use multiple";
  public static c2Ch3DynamicTextss6 = "";

  public static getLikertDescriptionFromIndex = (ind: number) => {
    let retval = "";
    if (ind === 0) {
      retval = "Never";
    } else if (ind === 1) {
      retval = "Rarely";
    } else if (ind === 2) {
      retval = "Sometimes";
    } else if (ind === 3) {
      retval = "Often";
    } else if (ind === 4) {
      retval = "Always";
    }
    return retval;
  };

  // Core-2 Chapter 3 Screen 1
  public static c2Ch3S1SinMCQ1 = "";
  public static c2Ch3S1IncDec = 0;
  public static c2Ch3S1SinMCQ2 = "";

  public static c2Ch3S1SinTanMCQ1 = "";
  public static c2Ch3S1IncTanDec = 0;
  public static c2Ch3S1SinTanMCQ2 = "";

  public static tanningBeliefsSolutions = [
    {
      question: "",
      answer: "",
    },
  ];

  //Core-2 Chapter 4 Boosting
  public static getC2Ch4BoostFeedbacks = (option: string) => {
    let retval = "";
    if (option === "learn more about sun-safe behaviors") {
      retval =
        "This Core focuses on helping you learn more about increasing your engagement in sun-safe behaviors and avoiding risky behaviors. People commonly understand the importance of such behaviors, but are less confident how to do them more often in their daily life. This Section will help you do that";
    } else if (
      option === "talk about sun-safe behaviors with family and friends "
    ) {
      retval =
        "Talking about your intentions out loud with those closest to you can help you engage more in sun-safe behaviors. Social support can be a huge component of engaging in sun-safe behaviors. It is OK to let those around you know the behaviors in which you have barriers to overcome. Talking through barriers with other people can help you devise and put into practice strategies to overcome them. mySmartSkin will also provide strategies throughout this Core.";
    } else if (
      option === " learn more about the health benefits of sun-safe behaviors"
    ) {
      retval =
        "As a person diagnosed with melanoma, you have good reason to learn more about the benefits of engaging in sun-safe behaviors. This Core focuses on helping you understand these benefits.";
    } else if (
      option ===
      "remind myself of the downsides of not engaging in sun-safe behaviors "
    ) {
      retval =
        "As you already learned, your health history of melanoma places you at greater risk for getting another skin cancer. mySmartSkin will help you learn more about the downsides of not engaging in sun-safe behaviors in ways that help encourage you.";
    } else if (
      option ===
      "think about where sun-safe behaviors fit into my health priorities"
    ) {
      retval =
        "Over time, people often change their health priorities to match their current life situation. For some people, engaging in sun-safe behaviors may not have been a priority, while for others it is. mySmartSkin will help you find the right balance to fit sun-safe behaviors into your everyday routine.";
    }
    return retval;
  };
}

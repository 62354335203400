import {
  IonAlert,
  IonImg,
  IonPage,
  IonText,
  useIonViewWillEnter,
} from "@ionic/react";
import {
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import "./Home.css";
import { Redirect, Route } from "react-router-dom";
import HomeTab from "./tabs/home/HomeTab";
import CoresTab from "./tabs/cores/CoresTab";
import SkinCheckTab from "./tabs/skin_check/SkinCheckTab";
import MyStuffTab from "./tabs/my_stuff/MyStuff";
import ProfileTab from "./tabs/profile/Profile";
import { useEffect, useRef, useState } from "react";
import { DataService } from "../services/DataService";
import { SkinSelfCheckDataService } from "../services/SkinSelfCheckDataService";
import { ProgressDataService } from "../services/ProgressDataService";
import { DataSubmissionService } from "../services/DataSubmissionService";
import { UserService } from "../services/UserService";
import { AppInfoTimeService } from "../services/AppInfoTimeService";

const Home: React.FC = () => {
  // const [showAlert, setShowAlert] = useState(false);
  const [tab1Img, setTab1Img] = useState("");
  const [tab2Img, setTab2Img] = useState("");
  const [tab3Img, setTab3Img] = useState("");
  const [tab4Img, setTab4Img] = useState("");
  const [tab5Img, setTab5Img] = useState("");

  const [coresTabFlag, setCoresTabFlag] = useState(false);

  const tabTextsUnselected = document.getElementsByClassName(
    "tabButtonUnselected"
  );
  var tabText1 = tabTextsUnselected[0];
  var tabText2 = tabTextsUnselected[1];
  var tabText3 = tabTextsUnselected[2];
  var tabText4 = tabTextsUnselected[3];
  var tabText5 = tabTextsUnselected[4]; 

useEffect(()=>{
  tabText1 = tabTextsUnselected[0];
  tabText2 = tabTextsUnselected[1];
  tabText3 = tabTextsUnselected[2];
  tabText4 = tabTextsUnselected[3];
  tabText5 = tabTextsUnselected[4]; 
  console.log("bottomhandler in effect", UserService.controlBottomBarFromBack);
  if (UserService.isControlContentRole) {
    //if(!DataService.homeTabSet){
    DataService.homeTabSet = true;
    if(UserService.controlBottomBarFromBack==0){
      if (tabText1 !== undefined) {
        tabText1.classList.remove("tabButtonUnselected");
        tabText1.classList.add("tabButtonSelected");
      }
      setCoresTabFlag(true);
      setTab1Img("../../assets/images/tabs/FileFilled.png");
      setTab2Img("../../assets/images/tabs/UserOutline.png");
    }else{
      if (tabText2 !== undefined) {
        tabText2.classList.remove("tabButtonUnselected");
        tabText2.classList.add("tabButtonSelected");
      }
      setTab1Img("../../assets/images/tabs/FileOutline.png");
      setTab2Img("../../assets/images/tabs/UserFilled.png");
    }
    
    // }
    // setTimeout(() => {
    //   setRefresh(!refresh)
    // }, 500);
  } else {
    // if (!DataService.homeTabSet) {
    //   DataService.homeTabSet = true;
    if (UserService.controlBottomBarFromBack == 1) {//sections
      setTab1Img("../../assets/images/tabs/HomeOutline.png");
      setTab2Img("../../assets/images/tabs/FileFilled.png");
      setTab3Img("../../assets/images/tabs/SkincareOutline.png");
      setTab4Img("../../assets/images/tabs/BoxOutline.png");
      setTab5Img("../../assets/images/tabs/UserOutline.png");
      if (tabText2 !== undefined) {
        tabText2.classList.remove("tabButtonUnselected");
        tabText2.classList.add("tabButtonSelected");
      }
    }else if (UserService.controlBottomBarFromBack == 2) {//skin self check
      setTab1Img("../../assets/images/tabs/HomeOutline.png");
      setTab2Img("../../assets/images/tabs/FileOutline.png");
      setTab3Img("../../assets/images/tabs/SkincareFilled.png");
      setTab4Img("../../assets/images/tabs/BoxOutline.png");
      setTab5Img("../../assets/images/tabs/UserOutline.png");
      if (tabText3 !== undefined) {
        tabText3.classList.remove("tabButtonUnselected");
        tabText3.classList.add("tabButtonSelected");
      }
    } else if (UserService.controlBottomBarFromBack == 3) {//mystuff
      setTab1Img("../../assets/images/tabs/HomeOutline.png");
      setTab2Img("../../assets/images/tabs/FileOutline.png");
      setTab3Img("../../assets/images/tabs/SkincareOutline.png");
      setTab4Img("../../assets/images/tabs/BoxFilled.png");
      setTab5Img("../../assets/images/tabs/UserOutline.png");
      if (tabText4 !== undefined) {
        tabText4.classList.remove("tabButtonUnselected");
        tabText4.classList.add("tabButtonSelected");
      }
    }else if (UserService.controlBottomBarFromBack == 4) {//profile
      setTab1Img("../../assets/images/tabs/HomeOutline.png");
      setTab2Img("../../assets/images/tabs/FileOutline.png");
      setTab3Img("../../assets/images/tabs/SkincareOutline.png");
      setTab4Img("../../assets/images/tabs/BoxOutline.png");
      setTab5Img("../../assets/images/tabs/UserFilled.png");
      if (tabText5 !== undefined) {
        tabText5.classList.remove("tabButtonUnselected");
        tabText5.classList.add("tabButtonSelected");
      }
    }  else { //home
      if (tabText1 !== undefined) {
        tabText1.classList.remove("tabButtonUnselected");
        tabText1.classList.add("tabButtonSelected");
      }
      setTab1Img("../../assets/images/tabs/HomeFilled.png");
      setTab2Img("../../assets/images/tabs/FileOutline.png");
      setTab3Img("../../assets/images/tabs/SkincareOutline.png");
      setTab4Img("../../assets/images/tabs/BoxOutline.png");
      setTab5Img("../../assets/images/tabs/UserOutline.png");
    }


    // }
    // setTimeout(() => {
    //   setRefresh(!refresh)
    // }, 500);
  }
});
  
  // useIonViewWillEnter(() => {
  //   // AppInfoTimeService.sendAppTimeToBE();

  //   console.log("roles", UserService.userRole);
  //   console.log("bottomhandler", UserService.controlBottomBarFromBack);
   
  // });

  const handleDispatchEventTab1 = () => {
    dispatchTabSelectEvent("tab1");
  };

  const handleDispatchEventTab2 = () => {
    dispatchTabSelectEvent("tab2");
  };

  const handleDispatchEventTab3 = () => {
    console.log("here in dispacth")
    dispatchTabSelectEvent("tab3");
  };

  const handleDispatchEventTab4 = () => {
    dispatchTabSelectEvent("tab4");
  };

  const handleDispatchEventTab5 = () => {
    dispatchTabSelectEvent("tab5");
  };

  // Add an event listener to the target element
  const targetElement = document.getElementById("target-element"); // Replace with the actual ID
  if (targetElement) {
    if (UserService.isControlContentRole) {
      targetElement.addEventListener("tab-event-tab1", handleDispatchEventTab1);

      targetElement.addEventListener("tab-event-tab2", handleDispatchEventTab2);
    } else {
      targetElement.addEventListener("tab-event-tab1", handleDispatchEventTab1);

      targetElement.addEventListener("tab-event-tab2", handleDispatchEventTab2);

      targetElement.addEventListener("tab-event-tab3", handleDispatchEventTab3);

      targetElement.addEventListener("tab-event-tab4", handleDispatchEventTab4);

      targetElement.addEventListener("tab-event-tab5", handleDispatchEventTab5);
    }
  }

  const dispatchTabSelectEvent = (tab: string) => {
    console.log("tab...." + tab);
    const tabTextsUnselected = document.getElementsByClassName(
      "tabButtonUnselected"
    );
    if (UserService.isControlContentRole) {
      tabText1 = tabTextsUnselected[0];
      tabText2 = tabTextsUnselected[1];
      (tabText1 as HTMLElement).style.color = "#1f2133";
      (tabText1 as HTMLElement).style.fontWeight = "500";
      tabText1.classList.remove("tabButtonSelected");
      tabText1.classList.add("tabButtonUnselected");
      tabText2.classList.remove("tabButtonSelected");
      tabText2.classList.add("tabButtonUnselected");
      setTab1Img("../../assets/images/tabs/FileOutline.png");
      setTab2Img("../../assets/images/tabs/UserOutline.png");
    } else {
      tabText1 = tabTextsUnselected[0];
      tabText2 = tabTextsUnselected[1];
      tabText3 = tabTextsUnselected[2];
      tabText4 = tabTextsUnselected[3];
      tabText5 = tabTextsUnselected[4];
      (tabText1 as HTMLElement).style.color = "#1f2133";
      (tabText1 as HTMLElement).style.fontWeight = "500";
      tabText1.classList.remove("tabButtonSelected");
      tabText1.classList.add("tabButtonUnselected");
      tabText2.classList.remove("tabButtonSelected");
      tabText2.classList.add("tabButtonUnselected");
      tabText3.classList.remove("tabButtonSelected");
      tabText3.classList.add("tabButtonUnselected");
      tabText4.classList.remove("tabButtonSelected");
      tabText4.classList.add("tabButtonUnselected");
      tabText5.classList.remove("tabButtonSelected");
      tabText5.classList.add("tabButtonUnselected");

      setTab1Img("../../assets/images/tabs/HomeOutline.png");
      setTab2Img("../../assets/images/tabs/FileOutline.png");
      setTab3Img("../../assets/images/tabs/SkincareOutline.png");
      setTab4Img("../../assets/images/tabs/BoxOutline.png");
      setTab5Img("../../assets/images/tabs/UserOutline.png");
    }
    if (tab === "tab1") {
      if (UserService.isControlContentRole) {
        setTab1Img("../../assets/images/tabs/FileFilled.png");
        setCoresTabFlag(true);
      } else {
        setTab1Img("../../assets/images/tabs/HomeFilled.png");
      }

      tabText1.classList.add("tabButtonSelected");
      (tabText1 as HTMLElement).style.color = "#005c6c";
      (tabText1 as HTMLElement).style.fontWeight = "700";
    } else if (tab === "tab2") {
      if (UserService.isControlContentRole) {
        setTab2Img("../../assets/images/tabs/UserFilled.png");
        setCoresTabFlag(false);
      } else {
        setTab2Img("../../assets/images/tabs/FileFilled.png");
        setCoresTabFlag(true);
      }
      tabText2.classList.add("tabButtonSelected");
    } else if (tab === "tab3") {
      setTab3Img("../../assets/images/tabs/SkincareFilled.png");
      tabText3.classList.add("tabButtonSelected");
    } else if (tab === "tab4") {
      setTab4Img("../../assets/images/tabs/BoxFilled.png");
      tabText4.classList.add("tabButtonSelected");
    } else if (tab === "tab5") {
      setTab5Img("../../assets/images/tabs/UserFilled.png");
      tabText5.classList.add("tabButtonSelected");
    }
  };

  const tabClicked = (event: any) => {
    console.log("Tab bottomhandler", UserService.controlBottomBarFromBack);
    console.log("Tab Selected >>> " + event.detail.tab);
    if (UserService.isControlContentRole) {
      (tabText1 as HTMLElement).style.color = "#1f2133";
      (tabText1 as HTMLElement).style.fontWeight = "500";
      tabText1.classList.remove("tabButtonSelected");
      tabText1.classList.add("tabButtonUnselected");
      tabText2.classList.remove("tabButtonSelected");
      tabText2.classList.add("tabButtonUnselected");

      setTab1Img("../../assets/images/tabs/FileOutline.png");
      setTab2Img("../../assets/images/tabs/UserOutline.png");
    } else {
      (tabText1 as HTMLElement).style.color = "#1f2133";
      (tabText1 as HTMLElement).style.fontWeight = "500";
      tabText1.classList.remove("tabButtonSelected");
      tabText1.classList.add("tabButtonUnselected");
      tabText2.classList.remove("tabButtonSelected");
      tabText2.classList.add("tabButtonUnselected");
      tabText3.classList.remove("tabButtonSelected");
      tabText3.classList.add("tabButtonUnselected");
      tabText4.classList.remove("tabButtonSelected");
      tabText4.classList.add("tabButtonUnselected");
      tabText5.classList.remove("tabButtonSelected");
      tabText5.classList.add("tabButtonUnselected");

      setTab1Img("../../assets/images/tabs/HomeOutline.png");
      setTab2Img("../../assets/images/tabs/FileOutline.png");
      setTab3Img("../../assets/images/tabs/SkincareOutline.png");
      setTab4Img("../../assets/images/tabs/BoxOutline.png");
      setTab5Img("../../assets/images/tabs/UserOutline.png");
    }

    if (event.detail.tab === "tab1") {
      //Reset SSE Timer
      AppInfoTimeService.resetSSETimer();

      // Reset Sections Timer
      //Previous
      // AppInfoTimeService.resetAppSectionsTimer(DataService.currentCoreIndex + 1);
      AppInfoTimeService.resetAppSectionsTimer();
      AppInfoTimeService.appSectionsTimeNid = -1;

      if (UserService.isControlContentRole) {
        setTab1Img("../../assets/images/tabs/FileFilled.png");
        setCoresTabFlag(true);
        DataService.selectedC1A1Goal = null;
      } else {
        setTab1Img("../../assets/images/tabs/HomeFilled.png");
      }
      UserService.controlBottomBarFromBack = 0;
      tabText1.classList.add("tabButtonSelected");
      (tabText1 as HTMLElement).style.color = "#005c6c";
      (tabText1 as HTMLElement).style.fontWeight = "700";
      ProgressDataService.isGetCalled = false;
    } else if (event.detail.tab === "tab2") {
      //Reset SSE Timer
      AppInfoTimeService.resetSSETimer();

      //Start Sections Timer
      // AppInfoTimeService.sendAppSectionsTimeToBE();

      
      if (UserService.isControlContentRole) {
        UserService.controlBottomBarFromBack = 4;
        setTab2Img("../../assets/images/tabs/UserFilled.png");
        setCoresTabFlag(false);
      } else {
        UserService.controlBottomBarFromBack = 1;
        setTab2Img("../../assets/images/tabs/FileFilled.png");
        setCoresTabFlag(true);
        DataService.selectedC1A1Goal = null;
      }

      tabText2.classList.add("tabButtonSelected");
    } else if (event.detail.tab === "tab3") {

      UserService.controlBottomBarFromBack = 2;
      // Reset Sections Timer
      //Previous
      // AppInfoTimeService.resetAppSectionsTimer(DataService.currentCoreIndex + 1);
      AppInfoTimeService.resetAppSectionsTimer();

      AppInfoTimeService.appSectionsTimeNid = -1;

      //Start SSE Timer
      AppInfoTimeService.sendSSETimeToBE();

      // setShowAlert(true);
      SkinSelfCheckDataService.skinSelfCheckGetAPICalled = false;
      setTab3Img("../../assets/images/tabs/SkincareFilled.png");
      tabText3.classList.add("tabButtonSelected");
    } else if (event.detail.tab === "tab4") {

      UserService.controlBottomBarFromBack = 3;
      //Reset Sections Timer
      //Previous
      // AppInfoTimeService.resetAppSectionsTimer(DataService.currentCoreIndex + 1);
      AppInfoTimeService.resetAppSectionsTimer();
      AppInfoTimeService.appSectionsTimeNid = -1;

      //Reset SSE Timer
      AppInfoTimeService.resetSSETimer();

      setTab4Img("../../assets/images/tabs/BoxFilled.png");
      tabText4.classList.add("tabButtonSelected");
      DataSubmissionService.isGoalsGetAPICalled = false;
    } else if (event.detail.tab === "tab5") {

      UserService.controlBottomBarFromBack = 4;
      // Reset Sections Timer
      //Previous
      // AppInfoTimeService.resetAppSectionsTimer(DataService.currentCoreIndex + 1);
      AppInfoTimeService.resetAppSectionsTimer();
      AppInfoTimeService.appSectionsTimeNid = -1;

      //Reset SSE Timer
      AppInfoTimeService.resetSSETimer();

      setTab5Img("../../assets/images/tabs/UserFilled.png");
      tabText5.classList.add("tabButtonSelected");
    }
  };


  return (
    <IonTabs onIonTabsWillChange={tabClicked}>
      {!UserService.isControlContentRole && (
        <IonRouterOutlet>
          <Route exact path="/home/hometab">
            <HomeTab />
          </Route>
          <Route exact path="/home/corestab">
            <CoresTab
              coresTabFlag={coresTabFlag}
              setCoresTabFlag={setCoresTabFlag}
            />
          </Route>
          <Route path="/home/skinchecktab">
             <span id="target-element"></span> 
            <SkinCheckTab />
          </Route>
          <Route path="/home/mystufftab">
            <MyStuffTab />
          </Route>
          <Route path="/home/profiletab">
            <ProfileTab />
          </Route>
          <Route exact path="/">
            <Redirect to="/home/hometab" />
          </Route>
          <Route exact path="/home">
            <Redirect to="/home/hometab" />
          </Route>
        </IonRouterOutlet>
      )}

      {UserService.isControlContentRole && (
        <IonRouterOutlet>
          <Route exact path="/home/corestab">
            <CoresTab
              coresTabFlag={coresTabFlag}
              setCoresTabFlag={setCoresTabFlag}
            />
          </Route>

          <Route path="/home/profiletab">
            <ProfileTab />
          </Route>
          <Route exact path="/">
            <Redirect to="/home/corestab" />
          </Route>
          <Route exact path="/home">
            <Redirect to="/home/corestab" />
          </Route>
        </IonRouterOutlet>
      )}

      {!UserService.isControlContentRole && (
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/home/hometab">
            <IonImg className="tabsImg" src={tab1Img} />
            {UserService.controlBottomBarFromBack == 0 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              Home
            </IonText>)}
            {UserService.controlBottomBarFromBack != 0 && (<IonText
              className="tabButtonUnselected"
            >
              Home
            </IonText>)}

          </IonTabButton>
          <IonTabButton tab="tab2" href="/home/corestab">
            <IonImg className="tabsImg" src={tab2Img} />
            {UserService.controlBottomBarFromBack == 1 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              Sections
            </IonText>)}
            {UserService.controlBottomBarFromBack != 1 && (<IonText
              className="tabButtonUnselected"
            >
              Sections
            </IonText>)}
          </IonTabButton>
          <IonTabButton tab="tab3" href="/home/skinchecktab">
            <IonImg className="tabsImg" src={tab3Img} />
            {UserService.controlBottomBarFromBack == 2 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              Skin Check
            </IonText>)}
            {UserService.controlBottomBarFromBack != 2 && (<IonText
              className="tabButtonUnselected"
            >
              Skin Check
            </IonText>)}
          </IonTabButton>
          <IonTabButton tab="tab4" href="/home/mystufftab">
            <IonImg className="tabsImg" src={tab4Img} />
            {UserService.controlBottomBarFromBack == 3 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              My Stuff
            </IonText>)}
            {UserService.controlBottomBarFromBack != 3 && (<IonText
              className="tabButtonUnselected"
            >
              My Stuff
            </IonText>)}
          </IonTabButton>
          <IonTabButton tab="tab5" href="/home/profiletab">
            <IonImg className="tabsImg" src={tab5Img} />
            {UserService.controlBottomBarFromBack == 4 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              Profile
            </IonText>)}
            {UserService.controlBottomBarFromBack != 4 && (<IonText
              className="tabButtonUnselected"
            >
              Profile
            </IonText>)}
          </IonTabButton>
        </IonTabBar>
      )}

      {UserService.isControlContentRole && (
        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/home/corestab">
            <IonImg className="tabsImg" src={tab1Img} />
            {UserService.controlBottomBarFromBack == 0 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              Content
            </IonText>)}
            {UserService.controlBottomBarFromBack != 0 && (<IonText
              className="tabButtonUnselected"
            >
              Content
            </IonText>)}
          </IonTabButton>

          <IonTabButton tab="tab2" href="/home/profiletab">
            <IonImg className="tabsImg" src={tab2Img} />
            {UserService.controlBottomBarFromBack == 4 && (<IonText
              className="tabButtonUnselected"
              style={{ color: "#005c6c", fontWeight: "700" }}
            >
              Profile
            </IonText>)}
            {UserService.controlBottomBarFromBack != 4 && (<IonText
              className="tabButtonUnselected"
            >
              Profile
            </IonText>)}
          </IonTabButton>
        </IonTabBar>
      )}
    </IonTabs>
  );
};

export default Home;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./ForgotPassword.css";
import InputTextField from "../../components/input_text_field/InputTextField";
import { useEffect, useState } from "react";
import { postForgotPassword } from "../../services/dataApi";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";

const ForgotPassword: React.FC = () => {
  var router = useIonRouter();

  const [inputEmail, setInputEmail] = useState("");
  const [continueBtnDisable, setContinueBtnDisable] = useState(true);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isSuccessMessage, setIsSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    if (inputEmail.length > 0 && inputEmail != "") {
      emailValidator(inputEmail);
    } else {
      setContinueBtnDisable(true);
      setIsValidEmail(true);
    }
  });

  const inputEmailHandler = (event: any) => {
    const enteredEmail = event.detail.value;
    setInputEmail(enteredEmail);
    setContinueBtnDisable(false);
    console.log("entered email ----->", enteredEmail);
  };

  const emailValidator = (text: any) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(text);
    if (!isValidEmail) {
      setIsValidEmail(false);
    } else {
      setIsValidEmail(true);
    }
  };

  const continueBtnHandler = async () => {
    try {
      setShowLoader(true);
      const responseData = await postForgotPassword(
        {
          "mail": inputEmail,
        },
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            setShowLoader(false);
            setIsSuccessMessage(true);
            setShowErrorMessage(false);
          } else if (!isSuccess) {
            //Error
            setShowLoader(false);
            setIsSuccessMessage(false);
            setShowErrorMessage(true);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      setShowLoader(false);
      setIsSuccessMessage(false);
      setShowErrorMessage(true);
    }
  };

  const inputCloseHandler = () => {
    console.log("jagadesh");
    setInputEmail("");
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <LoadingScreen text="Submitting..." isOpen={showLoader} />
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonText className="titleText">{"Forgot Password"}</IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent sscpsMainContent">
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <IonText className="sscpsDescText forgot-password-desc-bold">
                  {"Don't Worry!"}
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="forgot-pass-sub-desc-row">
              <IonCol size="12">
                <IonText className="sscpsDescText2">
                  It occurs. Please enter the email address linked with your
                  account.
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow className="input-email-row">
              <IonCol size="12">
                {!continueBtnDisable && (
                  <IonImg
                    onClick={() => {
                      inputCloseHandler();
                    }}
                    src="assets/icons/CloseCircleOutline.png"
                    className="input-close-icon"
                    style={{ cursor: "pointer" }}
                  />
                )}
                <InputTextField
                  placeholder="Enter email"
                  icon="assets/icons/MailOutline.svg"
                  isPassword={false}
                  textChanged={inputEmailHandler}
                  val={inputEmail}
                />
              </IonCol>
            </IonRow>
            {!isValidEmail && (
              <IonRow>
                <IonCol size="12">
                  <IonText className="email-validation-text">
                    Please enter a valid email address
                  </IonText>
                </IonCol>
              </IonRow>
            )}
            <IonRow>
              <IonCol size="12" className="loginLoginBtnCol ilBottomSpace">
                <IonButton
                  className="loginBtnEnabled loginLoginBtn"
                  disabled={continueBtnDisable}
                  onClick={() => continueBtnHandler()}
                >
                  Continue
                </IonButton>
              </IonCol>
            </IonRow>
            {isSuccessMessage && (
              <IonRow>
                <IonCol size="12" className="success-text">
                  <IonText>
                    A link for setting password is sent to your email. Please set your password using that.
                  </IonText>
                </IonCol>
              </IonRow>
            )}
            {showErrorMessage && (
              <IonRow>
                <IonCol size="12" className="error-text">
                  <IonText>
                    Something went wrong. Please try again later.
                  </IonText>
                </IonCol>
              </IonRow>
            )}
          </IonGrid>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ForgotPassword;

import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";

import "./ImgTextColTappable.css";

import "../../Fonts.css";

interface ImgTextColTappableProps {
  text: string;
  imageUrl: string;
  click: Function;
}

const ImgTextColTappable: React.FC<ImgTextColTappableProps> = ({
  imageUrl,
  text,
  click,
}) => {
  return (
    <div
      className="acceMainContainer lmitMainContainer animate__animated animate__fadeInRight"
      onClick={() => {
        click();
      }}
    >
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonImg src={imageUrl} />
          </IonCol>
          <IonCol className="accieTitleCol">
            <IonText className="acceTitleText lmitText"  dangerouslySetInnerHTML={{ __html: text }}></IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default ImgTextColTappable;

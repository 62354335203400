import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useEffect, useState } from "react";

import "./CoreMCQBars.css";

import "../../Fonts.css";
import { CoreMCQJsonDataType } from "../../services/DataService";

interface CoreMCQBarsProps {
  question: string;
  questionImage: string;
  isQuestion: boolean;
  isRight?: boolean;
  continueHandler?: () => void;
  currentQuestion?: CoreMCQJsonDataType;
  setIsQuestion?: React.Dispatch<React.SetStateAction<boolean>>;
  mcqButton: Array<any>;
  hideContinue: boolean;
  finalAnswerSelected: Function;
  answerSelected: Function;
}
const CoreMCQBars: React.FC<CoreMCQBarsProps> = ({
  question,
  questionImage,
  continueHandler,
  currentQuestion,
  isQuestion,
  setIsQuestion,
  mcqButton,
  hideContinue,
  finalAnswerSelected,
  answerSelected
}) => {
  //const [isQuestion, setIsQuestion] = useState(true);
  const [isRight, setIsRight] = useState(false);
  const getBackgroundDiv = (isright: boolean, isques: boolean) => {
    if (isQuestion) {
      return "coreMCQbarQuesContainer";
    } else {
      if (isright) {
        return "coreMCQbarQuesRightContainer";
      } else {
        return "coreMCQbarQuesWrongContainer";
      }
    }
  };

  const factHandler = (answer: string) => {
    answerSelected(currentQuestion, answer);

    if (setIsQuestion) {
      setIsQuestion(!isQuestion);
    }
    if (currentQuestion?.answer.toLocaleLowerCase() === answer.toLocaleLowerCase()) {
      setIsRight(true);
    } else {
      setIsRight(false);
    }
    console.log(isRight, "fact");

    if (hideContinue) {
      finalAnswerSelected();
    }
  };

  const getDescriptionStyle = (isright: boolean) => {
    if (isright) {
      return "coreMCQTitleText";
    } else {
      return "coreMCQWrongTitleDescText";
    }
  };

  

  return (
    <div className={getBackgroundDiv(isRight, isQuestion)}>
      <IonGrid>
        {/* {!isRight && !isQuestion && (
          <IonRow className="coreMCQRowfirst">
            <IonText className="coreMCQWrongTitleText">
              Thanks for trying!
            </IonText>
          </IonRow>
        )} */}
        {isRight && !isQuestion && (
          <IonRow className="coreMCQRowfirst">
            <IonText className="coreMCQRightTitleText">
              Thats Right!{" "}
              <span className="coreMCQRightText">
                {currentQuestion?.answerType}
              </span>
            </IonText>
          </IonRow>
        )}

        {!isQuestion && !isRight && (currentQuestion?.answerType !== null) && (currentQuestion?.answerType !== undefined) && (currentQuestion?.answerType.length > 0)  && (
          <IonRow className="coreMCQRowRight">
            <IonText className="coreMCQWrongTitleText">
              The correct answer is:{" "}
              <span className="coreMCQWrongText">
                {currentQuestion?.answerType}
              </span>
            </IonText>
          </IonRow>
        )}

     
        {isQuestion && (
          <IonRow className="coreMCQRow">
            <IonCol size="auto">
              <IonImg className="coreMCQTitleText" src={questionImage}></IonImg>
            </IonCol>
          </IonRow>
        )}
        {isQuestion && (
          <IonRow>
            {mcqButton.map((buttonName) => (
              <IonCol
                size="12"
                className="coreMCQdiv"
                onClick={() => factHandler(buttonName)}
              >
                <IonText className="coreMCQbuttonTitleText">
                  {buttonName}
                </IonText>
              </IonCol>
            ))}
          </IonRow>
        )}
        {!hideContinue && !isQuestion && (
          <IonRow>
            <div className="coreMCQseconddiv" onClick={continueHandler}>
              <IonText className="coreMCQbuttonTitleText">Continue</IonText>
            </div>
          </IonRow>
        )}
      </IonGrid>
    </div>
  );
};

export default CoreMCQBars;

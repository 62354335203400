import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./MyStuff.css";
import { useEffect, useRef, useState } from "react";
import SkinCheckProgress from "../../../components/skin_check_progress/SkinCheckProgress";
import MyStuffRewardsProgressState from "../../../components/mystuff_rewards_progressstate/MyStuffRewardsProgressState";
import { DataService } from "../../../services/DataService";
import MyStuffGoalProgress from "../../../components/mystuff_goal_progress/MyStuffGoalProgress";
import LoadingScreen from "../../../components/loading_screen/LoadingScreen";
import { DataSubmissionService } from "../../../services/DataSubmissionService";
import { parseDate } from "pdf-lib";
import {
  getC1A1,
  getC2A1,
  getLockUnLockBadze,
  getLockUnLockPrize,
  getSkinSelfCheckData,
  getSkinSelfSpotHistory,
} from "../../../services/dataApi";
import { SkinSelfCheckDataService } from "../../../services/SkinSelfCheckDataService";
import { format } from "date-fns";
import { AppInfoTimeService } from "../../../services/AppInfoTimeService";

const MyStuffTab: React.FC = () => {
  const [showLoader, setShowLoader] = useState(false);
  const [c1Goals, setC1Goals] = useState([{}]);
  const [c2Goals, setC2Goals] = useState([{}]);
  const [loaderText, setLoaderText] = useState("Loading");
  const [progresses, setProgresses] = useState([]);

  const [refresh, setRefresh] = useState(false);
  const [prizeEarnCount, setPrizeEarnCount] = useState(0);
  const [badzeEarnCount, setBadzeEarnCount] = useState(0);
  var isPrizeRedeemed = false;
  var isBadgeEarned = false;

  var router = useIonRouter();

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  //var progresses:any = [{
  //   month: "Aug",
  //   percent: 100,
  // },
  // {
  //   month: "Sept",
  //   percent: 100,
  // },
  // {
  //   month: "Oct",
  //   percent: 0,
  // },
  // {
  //   month: "Nov",
  //   percent: 100,
  // },
  // {
  //   month: "Dec",
  //   percent: 20,
  // }];
  // const progressesPs = [
  //   {
  //     month: "Aug",
  //     percent: 100,
  //   },
  //   {
  //     month: "Sept",
  //     percent: 100,
  //   },
  //   {
  //     month: "Oct",
  //     percent: 0,
  //   },
  //   {
  //     month: "Nov",
  //     percent: 100,
  //   },
  //   {
  //     month: "Dec",
  //     percent: 20,
  //   },
  // ];
  // const progressesC = [
  //   {
  //     month: "Aug",
  //     percent: 100,
  //   },
  //   {
  //     month: "Sept",
  //     percent: 100,
  //   },
  //   {
  //     month: "Oct",
  //     percent: 100,
  //   },
  //   {
  //     month: "Nov",
  //     percent: 100,
  //   },
  //   {
  //     month: "Dec",
  //     percent: 100,
  //   },
  // ];

  useIonViewWillEnter(() => {
    if (!DataSubmissionService.isGoalsGetAPICalled) {
      DataSubmissionService.isGoalsGetAPICalled = true;

      getC1A1BE();
    }
    // var tempArr = SkinSelfCheckDataService.calculateSkinSelfCheckProgress();
    // setProgresses(tempArr);

    // updateScenario();
  });

  async function getSpotHistory() {
    try {
      setLoaderText("Loading Spot History");
      openLoader();
      const responseData = await getSkinSelfSpotHistory(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get SpotHistory Response ---->>> " + respData);
            if (respData.length > 0) {
              SkinSelfCheckDataService.historybodyMap = respData;
              var tempArr =
                SkinSelfCheckDataService.calculateSkinSelfCheckProgress();
                setProgresses(tempArr);
            } else {
              console.log("Get Spot History JSON empty");
            }
            
            updateScenario();
           
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(
              "Get Spot History Response not success ---->>> " + respData
            );
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Spot History Response - Error fetching data:", error);
    }
  }

  async function getC1A1BE() {
    setLoaderText("Loading Section 1 Activity Data");
    try {
      openLoader();
      const responseData = await getC1A1(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Skinselfcheck Response ---->>> " + respData);

            if (respData.length > 0) {
              let parsedJSON = JSON.parse(respData);
              DataSubmissionService.c1A1BEJSON = parsedJSON;
              console.log(parseDate);

              // updateScenario();
              getC2A1BE();
            } else {
              console.log("Skin Self Check JSON empty");
              getC2A1BE();
              // updateScenario();
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            getC2A1BE();
            console.log(
              "Get Skinselfcheck Response not success ---->>> " + respData
            );

            //updateScenario();
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck Response - Error fetching data:", error);
    }
  }

  async function getC2A1BE() {
    setLoaderText("Loading Section 2 Activity Data");
    try {
      openLoader();
      const responseData = await getC2A1(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            console.log("Get Skinselfcheck Response ---->>> " + respData);

            if (respData.length > 0) {
              let parsedJSON = JSON.parse(respData);
              DataSubmissionService.c2A1SkinSelfCheckGoals = parsedJSON;
              console.log(parseDate);

              checkLockUnLockPrize();
            } else {
              console.log("Skin Self Check JSON empty");

              //updateScenario();
              checkLockUnLockPrize();
            }
          } else if (!isSuccess) {
            //Error
            closeLoader();
            checkLockUnLockPrize();
            console.log(
              "Get Skinselfcheck Response not success ---->>> " + respData
            );

            //updateScenario();
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Get Skinselfcheck Response - Error fetching data:", error);
    }
  }

  async function checkLockUnLockPrize() {
    try {
      setLoaderText("Checking Prize Details");
      openLoader();
      const responseData = await getLockUnLockPrize(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            //Save user data
            console.log("resp before", respData);
            var resp: any[] = respData.message;
            console.log("resp here", resp);
            for (var i = 0; i < DataService.prizes.length; i++) {
              var localprize = DataService.prizes[i];
              for (var j = 0; j < resp.length; j++) {
                var localresp = resp[j];
                if (localresp.title.indexOf(localprize.text) != -1) {
                  if (localresp.title.indexOf("Completed") != -1) {
                    if (localresp.isRedeem == true) {
                      localprize.showGetPrize = false;
                    } else {
                      localprize.showGetPrize = true;
                    }
                    if (
                      "numerator" in localresp &&
                      "denominator" in localresp
                    ) {
                      var tit = localresp.title;
                      tit = tit.substring(0, tit.lastIndexOf(" "));
                      if (localresp.numerator == 0) {
                        localprize.progress = null;
                      } else {
                        if (tit === "Sun Goal Update") {
                          localprize.progress = null;
                        } else {
                          localprize.progress = localresp.numerator;
                        }

                      }
                      if (localresp.denominator == 0) {
                        localprize.progressTotal = null;
                      } else {
                        if (tit === "Sun Goal Update") {
                          localprize.progressTotal = null;
                        } else {
                          localprize.progressTotal = localresp.denominator;
                        }
                      }
                    }
                    localprize.showRedeemedPrizeDetails = localresp.isRedeem;
                    DataService.prizes[i] = localprize;
                    break;
                  } else {
                    localprize.showRedeemedPrizeDetails = localresp.isRedeem;
                    DataService.prizes[i] = localprize;
                  }
                }
              }
            }
            var count = 0;
            isPrizeRedeemed = false;
            for (var i = 0; i < DataService.prizes.length; i++) {
              var localprize = DataService.prizes[i];
              if (localprize.showRedeemedPrizeDetails == true) {
                isPrizeRedeemed = true;
                count = count + 1;
              }
              if (localprize.showGetPrize == true) {
                count = count + 1;
              }
            }
            DataService.prizeEarnCount = count;
            setPrizeEarnCount(count);
            checkLockUnLockBadze();
          } else if (!isSuccess) {
            //Error
            closeLoader();
            checkLockUnLockBadze();
            console.log(" GET prize failure--" + respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error posting prize:", error);
    }
  }

  async function checkLockUnLockBadze() {
    try {
      setLoaderText("Checking Badge Details");
      openLoader();
      const responseData = await getLockUnLockBadze(
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            //Save user data
            console.log("resp before", respData);
            var resp: any[] = respData.message;
            console.log("resp here", resp);
            for (var i = 0; i < DataService.badgePrimaryData.length; i++) {
              var localbadze = DataService.badgePrimaryData[i];
              for (var j = 0; j < resp.length; j++) {
                var localresp = resp[j];
                if (
                  localresp.title.indexOf(localbadze.badgeName) != -1 &&
                  localresp.title.indexOf("Completed") != -1
                ) {
                  localbadze.isDisabled = false;

                  if ("numerator" in localresp && "denominator" in
                    localresp) {
                    if (localresp.numerator == null || localresp.numerator == 0) {
                      localbadze.earnedBadges = null;
                    } else {
                      localbadze.earnedBadges = localresp.numerator;
                    }
                    if (localresp.denominator == null || localresp.denominator == 0) {
                      localbadze.totalBadges = null
                    } else {
                      localbadze.totalBadges = localresp.denominator;
                    }
                  }
                  if ("ReceivingDateTime" in localresp) {
                    localbadze.recievedDateTime = localresp.ReceivingDateTime;
                  }
                  DataService.badgePrimaryData[i] = localbadze;
                } else if (
                  localresp.title.indexOf(localbadze.badgeName) != -1 &&
                  localresp.title.indexOf("Pending") != -1
                ) {

                  if ("numerator" in localresp && "denominator" in localresp) {
                    if (localresp.numerator == null || localresp.numerator == 0) {
                      localbadze.earnedBadges = null;
                    } else {
                      localbadze.earnedBadges = localresp.numerator;
                    }
                    if (localresp.denominator == null || localresp.denominator == 0) {
                      localbadze.totalBadges = null
                    } else {
                      localbadze.totalBadges = localresp.denominator;
                    }
                  }
                  if ("ReceivingDateTime" in localresp) {
                    localbadze.recievedDateTime = localresp.ReceivingDateTime;
                  }
                  DataService.badgePrimaryData[i] = localbadze;
                }
              }
            }
            var count1 = 0;
            for (var i = 0; i < DataService.badgePrimaryData.length; i++) {
              var localprize = DataService.badgePrimaryData[i];
              if (localprize.isDisabled == false) {
                isBadgeEarned = true;
                count1 = count1 + 1;
              }
            }
            DataService.badzeEarnCount = count1;
            setBadzeEarnCount(count1);
            getSpotHistory();
          } else if (!isSuccess) {
            //Error
            closeLoader();
            getSpotHistory();
            console.log(" GET prize failure--" + respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error posting prize:", error);
    }
  }

  let textgoals =
    "You’ll see your <strong> Badges and Prizes</strong> as you complete the activities of MSS.";
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollIconRef = useRef<HTMLIonImgElement | null>(null);
  let scrollThroughBtn = false;
  const [clickCount, setClickCount] = useState(0);
  const [btnText, setBtntext] = useState("ProgressState");
  var router = useIonRouter();
  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrolling = () => {
    console.log("SCROLLLLLING");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrollingEnd = () => {
    console.log("SCROLLLLLING ENDDDD");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "visible";
    }
  };

  const myStuffStates = () => {
    let count = clickCount + 1;
    setClickCount(count);
    if (count === 1) {
      setBtntext("CompleteState");
    } else if (count === 2) {
      setBtntext("UnhappyState");
    } else if (count === 3) {
      setBtntext("ProgressState");
      setClickCount(0);
    }
  };

  const updateScenario = () => {
    let c1A1Data: any = DataSubmissionService.c1A1BEJSON;
    let retVal = [];

    let g1s1 = c1A1Data.goals[0].subgoals.gatherTheRightTools;
    if (g1s1 !== null) {
      let obj = {
        text: g1s1.goal.mainDesc,
        imageUrl: g1s1.goal.mainImgUrl,
        goal: g1s1,
      };
      retVal.push(obj);
    }

    let g1s2 = c1A1Data.goals[0].subgoals.findSomeoneToHelp;
    if (g1s2 !== null) {
      let obj = {
        text: g1s2.goal.mainDesc,
        imageUrl: g1s2.goal.mainImgUrl,
        goal: g1s2,
      };
      retVal.push(obj);
    }

    let g1s3 = c1A1Data.goals[0].subgoals.setReminders;
    if (g1s3 !== null) {
      let obj = {
        text: g1s3.goal.mainDesc,
        imageUrl: g1s3.goal.mainImgUrl,
        goal: g1s3,
      };
      retVal.push(obj);
    }

    ////////////////////////

    let g2s1 = c1A1Data.goals[1].subgoals.performThoroughSSCs;
    if (g2s1 !== null) {
      let obj = {
        text: g2s1.goal.mainDesc,
        imageUrl: g2s1.goal.mainImgUrl,
        goal: g2s1,
      };
      retVal.push(obj);
    }

    let g2s2 = c1A1Data.goals[1].subgoals.checkFrequentlyMissedAreas;
    if (g2s2 !== null) {
      let obj = {
        text: g2s2.goal.mainDesc,
        imageUrl: g2s2.goal.mainImgUrl,
        goal: g2s2,
      };
      retVal.push(obj);
    }

    ////////////////////////

    let g3s1 = c1A1Data.goals[2].subgoals.setAsideTimeEachMonth;
    if (g3s1 !== null) {
      let obj = {
        text: g3s1.goal.mainDesc,
        imageUrl: g3s1.goal.mainImgUrl,
        goal: g3s1,
      };
      retVal.push(obj);
    }

    let g3s2 = c1A1Data.goals[2].subgoals.setAMonthlyReminder;
    if (g3s2 !== null) {
      let obj = {
        text: g3s2.goal.mainDesc,
        imageUrl: g3s2.goal.mainImgUrl,
        goal: g3s2,
      };
      retVal.push(obj);
    }

    let g3s3 = c1A1Data.goals[2].subgoals.findSOmeoneToHoldMeAcc;
    if (g3s3 !== null) {
      let obj = {
        text: g3s3.goal.mainDesc,
        imageUrl: g3s3.goal.mainImgUrl,
        goal: g3s3,
      };
      retVal.push(obj);
    }

    //Update State to progress if there are goals
    ////////////////////////
    if (retVal.length > 0) {
      setC1Goals(retVal);
      setClickCount(1);
    }

    updateScenarioC2A1();
  };

  const updateScenarioC2A1 = () => {
    let c2A1DataGoals: any = DataSubmissionService.c2A1SkinSelfCheckGoals.goals;
    let retVal = [];

    let g1 = c2A1DataGoals[0];
    let g1QuestionLength = g1.mcqs[0].question.length;
    if (g1QuestionLength > 0) {
      let obj = {
        text: g1.title,
        imageUrl: "",
        goal: g1,
      };
      retVal.push(obj);
    }

    let g2 = c2A1DataGoals[1];
    let g2QuestionLength = g2.mcqs[0].question.length;
    if (g2QuestionLength > 0) {
      let obj = {
        text: g2.title,
        imageUrl: "",
        goal: g2,
      };
      retVal.push(obj);
    }

    let g3 = c2A1DataGoals[2];
    let g3QuestionLength = g3.mcqs[0].question.length;
    if (g3QuestionLength > 0) {
      let obj = {
        text: g3.title,
        imageUrl: "",
        goal: g3,
      };
      retVal.push(obj);
    }

    let g4 = c2A1DataGoals[3];
    let g4QuestionLength = g4.mcqs[0].question.length;
    if (g4QuestionLength > 0) {
      let obj = {
        text: g4.title,
        imageUrl: "",
        goal: g4,
      };
      retVal.push(obj);
    }

    let g5 = c2A1DataGoals[4];
    let g5QuestionLength = g5.mcqs[0].question.length;
    if (g5QuestionLength > 0) {
      let obj = {
        text: g5.title,
        imageUrl: "",
        goal: g5,
      };
      retVal.push(obj);
    }

    //Update State to progress if there are goals
    ////////////////////////
    if (retVal.length > 0) {
      setC2Goals(retVal);
      setClickCount(1);
    }
    setRefresh(!refresh);
  };

  const navigateToGoal = (goal: any) => {
    console.log("Navigate to: >>>>>>> " + goal);

    DataService.selectedC1A1Goal = goal;
    DataService.isEntryToC1A1ThroughMyStuff = true;
    DataService.coreOpenedFromCoresTab = false;

    //Navigate
    DataService.navigateToCore1 = true;
    DataService.navigateToCore2 = false;
    DataService.refreshCoresFromBE = true;
    DataService.currentCoreIndex = 0;
    DataService.currentChapterIndex = 6;

    //Start Sections Timer
    AppInfoTimeService.sendAppSectionsTimeToBE(1);

    router.push("/home/corestab", "root", "push");
  };

  const navigateToC2A1Goal = (goal: any) => {
    console.log("Navigate to: >>>>>>> " + goal);

    DataService.selectedC2A1Goal = goal;
    DataSubmissionService.c2A1LatestGoalTitle = goal.title;
    DataService.isEntryToC2A1ThroughMyStuff = true;
    DataService.coreOpenedFromCoresTab = false;

    //Navigate
    DataService.navigateToCore1 = false;
    DataService.navigateToCore2 = true;
    DataService.refreshCoresFromBE = true;
    DataService.currentCoreIndex = 0;
    DataService.currentChapterIndex = 4;

    //Start Sections Timer
    AppInfoTimeService.sendAppSectionsTimeToBE(2);

    router.push("/home/corestab", "root", "push");
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
        onIonScrollStart={scrolling}
        onIonScrollEnd={scrollingEnd}
      >
        <LoadingScreen text={loaderText} isOpen={showLoader} />
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">My Stuff</IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={({ }) => {
                myStuffStates();
              }}
            >
              {/* <IonText>{btnText} </IonText> */}
            </IonCol>
          </IonRow>
        </IonGrid>
        {(clickCount === 0 || clickCount === 3) && (
          <div
            className="mainContent"
            style={{
              marginTop: "16px",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            {/* <div className="htScrollIconContainer">
          <IonImg
            src="assets/icons/FixedScroll.svg"
            className="htScrollIcon"
            onClick={scrollToBottom}
            ref={scrollIconRef}
            alt="none"
          ></IonImg>
        </div> */}

            <div style={{ marginBottom: "24px" }}></div>
            <IonGrid>
              <IonRow>
                <IonCol size="auto" className="msImageCol">
                  <IonImg src="/assets/images/g8.svg"></IonImg>
                </IonCol>
                <IonCol className="msTextCol">
                  <IonText className="msTextHeading">
                    You can track your Skin Self-Check progress and update your
                    goals here.
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div style={{ marginBottom: "32px" }}></div>
            <SkinCheckProgress hideDesc={false} progresses={progresses} />
            <div style={{ marginBottom: "24px" }}></div>
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">Your Goals</IonText>
                  </IonCol>
                  <IonCol size="1">
                    {/* <IonImg
                      className="htImgStuff"
                      src="/assets/icons/RightOutline-right.svg"
                    ></IonImg> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div style={{ marginBottom: "16px" }}></div>
            <IonGrid className="msGridFill">
              <IonRow className="msImageRowGoals">
                <IonCol size="auto" className="msImageColGoals">
                  <IonImg
                    className="msImageGoals"
                    src="/assets/images/Empty.svg"
                  ></IonImg>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <IonText className="msTextGoals">
                    Your goals will appear here when you create them.
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div style={{ marginBottom: "24px" }}></div>
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">Badges and Prizes</IonText>
                  </IonCol>
                  <IonCol size="1">
                    {/* <IonImg
                      className="htImgStuff"
                      src="/assets/icons/RightOutline-right.svg"
                    ></IonImg> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div style={{ marginBottom: "16px" }}></div>
            <IonGrid className="msGridFill">
              <IonRow className="msImageRowGoals">
                <IonCol size="auto" className="msImageColGoals">
                  <IonImg
                    className="msImageGoals"
                    src="/assets/images/Badge.svg"
                  ></IonImg>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="msTextColGoals">
                  <IonText
                    className="msTextBadges"
                    dangerouslySetInnerHTML={{ __html: textgoals }}
                  ></IonText>
                </IonCol>
              </IonRow>

              <div className="hisDivBtn2" onClick={() => {
                router.push("/rewardsbadgesprizes", "forward", "push");
              }}>
                <IonText
                  className="msTextViewAll"
                >
                  View All
                </IonText>
              </div>
            </IonGrid>
          </div>
        )}
        {clickCount === 1 && (
          <div
            className="mainContent"
            style={{
              marginTop: "16px",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            {/* <div className="htScrollIconContainer">
          <IonImg
            src="assets/icons/FixedScroll.svg"
            className="htScrollIcon"
            onClick={scrollToBottom}
            ref={scrollIconRef}
            alt="none"
          ></IonImg>
        </div> */}

            <div style={{ marginBottom: "24px" }}></div>
            <IonGrid>
              <IonRow>
                <IonCol size="auto" className="msImageCol">
                  <IonImg src="/assets/images/g8.svg"></IonImg>
                </IonCol>
                <IonCol className="msTextCol">
                  <IonText className="msTextHeading">
                    You can track your Skin Self-Check progress and update your
                    goals here.
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div style={{ marginBottom: "32px" }}></div>
            <SkinCheckProgress hideDesc={true} progresses={progresses} />
            <div style={{ marginBottom: "24px" }}></div>
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">Your Goals</IonText>
                  </IonCol>
                  <IonCol size="1">
                    {/* <IonImg
                      className="htImgStuff"
                      src="/assets/icons/RightOutline-right.svg"
                    ></IonImg> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div style={{ marginBottom: "16px" }}></div>
            <MyStuffGoalProgress
              title="Skin Self-Check Goals"
              goals={c1Goals}
              goalSelected={(goal: any) => {
                navigateToGoal(goal);
              }}
            // goals={[
            //   {
            //     text: "Gather the right tools",
            //     imageUrl: "assets/images/sscm.svg",
            //   },
            //   {
            //     text: "Check All My Body Parts",
            //     imageUrl: "assets/images/Goal2Img.svg",
            //   },
            // ]}
            />
            <div style={{ marginBottom: "16px" }}></div>
            {(c2Goals.length > 0 && ("text" in c2Goals[0])) && <MyStuffGoalProgress
              title="Sun Safe Behavior Goals"
              goals={c2Goals}
              // goals={[
              //   {
              //     text: "Gather the right tools",
              //     imageUrl: "assets/images/sscm.svg",
              //   },
              //   {
              //     text: "Check All My Body Parts",
              //     imageUrl: "assets/images/Goal2Img.svg",
              //   },
              // ]}
              goalSelected={(goal: any) => {
                navigateToC2A1Goal(goal);
              }}
            />}

            {/* <div style={{ marginBottom: "16px" }}></div> */}

            <div style={{ marginBottom: "24px" }}></div>
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">Badges and Prizes</IonText>
                  </IonCol>
                  <IonCol size="1">
                    {/* <IonImg
                      className="htImgStuff"
                      src="/assets/icons/RightOutline-right.svg"
                    ></IonImg> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <MyStuffRewardsProgressState
              imageUrl="/assets/images/Badge.svg"
              title="Badges Earned"
              earned={"" + badzeEarnCount}
              total={"/" + DataService.badgePrimaryData.length}
              isEarned={isBadgeEarned}
              buttonText="View"
              buttonTextWon="View & Get Badge "
            />
            <MyStuffRewardsProgressState
              imageUrl="/assets/images/Prize.svg"
              title="Prizes Earned"
              earned={"" + prizeEarnCount}
              total={"/" + DataService.prizes.length}
              isEarned={!isPrizeRedeemed}
              buttonText="View"
              buttonTextWon="View & Get Prize"
            />
            {/* {DataService.isPrizeRedeemed && <MyStuffRewardsProgressState imageUrl="/assets/images/Prize.svg" title="Prizes Redeemed" earned="5" total=" /6" isEarned={!DataService.isPrizeRedeemed} buttonText="View" buttonTextWon="View & Get Prize" />} */}
          </div>
        )}

        {clickCount === 2 && (
          <div
            className="mainContent"
            style={{
              marginTop: "16px",
              paddingBottom: "16px",
              paddingTop: "16px",
            }}
          >
            {/* <div className="htScrollIconContainer">
          <IonImg
            src="assets/icons/FixedScroll.svg"
            className="htScrollIcon"
            onClick={scrollToBottom}
            ref={scrollIconRef}
            alt="none"
          ></IonImg>
        </div> */}

            <div style={{ marginBottom: "24px" }}></div>
            <IonGrid>
              <IonRow>
                <IonCol size="auto" className="msImageCol">
                  <IonImg src="/assets/images/g8.svg"></IonImg>
                </IonCol>
                <IonCol className="msTextCol">
                  <IonText className="msTextHeading">
                    You can track your Skin Self-Check progress and update your
                    goals here.
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div style={{ marginBottom: "32px" }}></div>
            <SkinCheckProgress hideDesc={true} progresses={progresses} />
            <div style={{ marginBottom: "24px" }}></div>
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">Your Goals</IonText>
                  </IonCol>
                  <IonCol size="1">
                    {/* <IonImg
                      className="htImgStuff"
                      src="/assets/icons/RightOutline-right.svg"
                    ></IonImg> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <div style={{ marginBottom: "16px" }}></div>
            <IonGrid className="msGridFill">
              <IonRow className="msImageRowGoals">
                <IonCol size="auto" className="msImageColGoals">
                  <IonImg
                    className="msImageGoals"
                    src="/assets/images/Empty.svg"
                  ></IonImg>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12">
                  <IonText className="msTextGoals">
                    Well done You have successfully achieved all your Goals.
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>

            <div style={{ marginBottom: "24px" }}></div>
            <div className="">
              <IonGrid className="htDivText">
                <IonRow>
                  <IonCol size="11">
                    <IonText className="htHeading">Badges and Prizes</IonText>
                  </IonCol>
                  <IonCol size="1">
                    {/* <IonImg
                      className="htImgStuff"
                      src="/assets/icons/RightOutline-right.svg"
                    ></IonImg> */}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
            <MyStuffRewardsProgressState
              imageUrl="/assets/images/Badge.svg"
              title="Badges Earned"
              earned="30"
              total=" /30"
              isEarned={false}
              buttonText="View"
              buttonTextWon="View & Get Badge "
            />
            <MyStuffRewardsProgressState
              imageUrl="/assets/images/Prize.svg"
              title="Prizes Earned"
              earned="6"
              total=" /6"
              isEarned={false}
              buttonText="View"
              buttonTextWon="View & Get Prize"
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MyStuffTab;

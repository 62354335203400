import {
  IonCol,
  IonGrid,
  IonImg,
  IonInput,
  IonRow,
  IonText,
} from "@ionic/react";
import "./coreFormStrategy.css";
import { TextFieldTypes } from "@ionic/core";
import { useEffect, useState } from "react";
import { Console } from "console";

interface CoreFormStrategyProps {
  placeholder: string;
  inputTextFieldType: TextFieldTypes;
  enterdValues: Function;
  selectedStrategies: string[];
  isReason: false
}

const CoreFormStrategy: React.FC<CoreFormStrategyProps> = ({
  placeholder,
  inputTextFieldType,
  enterdValues,
  selectedStrategies,
  isReason
}) => {
  const [inputCount, setInputCount] = useState(1);
  const [inputValues, setInputValues] = useState([""]);

  useEffect(() => {
    if (selectedStrategies.length > 0) {
      setInputCount(selectedStrategies.length);
      setInputValues(selectedStrategies);
      // enterdValues(inputValues);
    }
  }, [selectedStrategies]);

  const handleInputChange = (index: number, event: any) => {
    const newInputValues = [...inputValues];
    newInputValues[index] = event.target.value;
    setInputValues(newInputValues);
    enterdValues(newInputValues);
    //console.log(newInputValues[index]);
  };

  const handleAddClick = () => {
    setInputCount(inputCount + 1);
    console.log("inpiut", inputCount);
    setInputValues([...inputValues, ""]);
    enterdValues(inputValues);
  };

  const handleRemoveClick = (index: number) => {
    const emptyInputValues = [...inputValues];
    emptyInputValues.splice(index, 1);
    setInputValues(emptyInputValues);
    setInputCount(inputCount - 1);
    enterdValues(emptyInputValues);
  };
  return (
    <>
      <div className="itf-main-container">
        <IonGrid>
          {Array.from({ length: inputCount }, (_, index) => (
            <IonRow key={index} className="itf-textfield-row">
              <IonCol className="itf-textfield-col">
                <IonInput
                  placeholder={placeholder}
                  className="itf-textfield"
                  type={inputTextFieldType}
                  value={inputValues[index]}
                  onIonInput={(event: any) => handleInputChange(index, event)}
                ></IonInput>
              </IonCol>
              {index > 0 && (
                <div className="cfsCloseContainer">
                  <IonImg
                    src="assets\icons\CloseCircleOutline.png"
                    className="close-itf"
                    onClick={() => handleRemoveClick(index)}
                  ></IonImg>
                </div>
              )}
            </IonRow>
          ))}
          <IonRow className="add-itf">
            <IonCol size="auto">
              <IonImg
                src="assets\icons\increment.png"
                className="minusCircleImg"
                onClick={handleAddClick}
              ></IonImg>
            </IonCol>
            <IonText className="add-itf-text">{isReason ? "Add Another Reason" : "Add Another Strategy"}</IonText>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default CoreFormStrategy;

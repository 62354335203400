// import { Observation } from '../pages/observations/SubmitObservation';

export interface TableDefinition {
  header: string,
  columnHeadings: string[],
  rows: any[],
}

export abstract class TableDataService {
  public static compileStats = (obsArray: any) => {
    let summary = {
      buttsCount: 0,
      buttsPickup: 0,
      otherCount: 0,
      otherPickup: 0,
      ecigCount: 0,
      ecigPickup: 0,
      cannabisCount: 0,
      cannabisPickup: 0,
      multiCount: 0,
      multiPickup: 0,
      unsureCount: 0,
      unsurePickup: 0,
    };

    summary.buttsCount = obsArray.reduce((acc: number, obs: any) => {
      return acc + obs.butts;
    }, 0);
    summary.otherCount = obsArray.reduce((acc: number, obs: any) => {
      return acc + obs.other;
    }, 0);
    summary.ecigCount = obsArray.reduce((acc: number, obs: any) => {
      return acc + obs.ecig;
    }, 0);
    summary.cannabisCount = obsArray.reduce((acc: number, obs: any) => {
      return acc + obs.cannabis;
    }, 0);
    summary.multiCount = obsArray.reduce((acc: number, obs: any) => {
      return acc + obs.multipurpose;
    }, 0);
    summary.unsureCount = obsArray.reduce((acc: number, obs: any) => {
      return acc + obs.unsure;
    }, 0);

    summary.buttsPickup = obsArray.reduce((acc: number, obs: any) => {
      return acc + (obs.wastePickedUp ? obs.butts : 0);
    }, 0);
    summary.otherPickup = obsArray.reduce((acc: number, obs: any) => {
      return acc + (obs.wastePickedUp ? obs.other : 0);
    }, 0);
    summary.ecigPickup = obsArray.reduce((acc: number, obs: any) => {
      return acc + (obs.wastePickedUp ? obs.ecig : 0);
    }, 0);
    summary.cannabisPickup = obsArray.reduce((acc: number, obs: any) => {
      return acc + (obs.wastePickedUp ? obs.cannabis : 0);
    }, 0);
    summary.multiPickup = obsArray.reduce((acc: number, obs: any) => {
      return acc + (obs.wastePickedUp ? obs.multipurpose : 0);
    }, 0);
    summary.unsurePickup = obsArray.reduce((acc: number, obs: any) => {
      return acc + (obs.wastePickedUp ? obs.unsure : 0);
    }, 0);

    return summary;
  };

  public static createSummaryTable = async (observations: any) => {
    const summaryData = TableDataService.compileStats(observations);

    const table:TableDefinition = {
      header: "TEC Waste Observed and Picked Up",
      columnHeadings: ["Type of TEC Waste", "Observed", "Picked Up"],
      rows: [
        ["Cigarette Butts", summaryData.buttsCount, summaryData.buttsPickup],
        ["Other Tobacco Producs", summaryData.otherCount, summaryData.otherPickup],
        ["E-cigarettes/Vapes", summaryData.ecigCount, summaryData.ecigPickup],
        ["Cannabis", summaryData.cannabisCount, summaryData.cannabisPickup],
        ["Multi-purpose Items", summaryData.multiCount, summaryData.multiPickup],
        ["Unsure", summaryData.unsureCount, summaryData.unsurePickup],
      ]
    };

    return table;
  }

  public static createDataTable = async (observations: any) => {
    // columnHeadings: ["date", "datetime", "lat", "lng", "photo", "tag", "type", "count", "pickup"],
    const table:TableDefinition = {
      header: "Observations",
      columnHeadings: ["username", "date", "datetime", "lat", "lng", "butts", "cannabis", "ecig", "other", "multipurpose", "unsure", "pickup", "reportid", "projectname","imageid","imageurl"],
      rows: [],
    }

    console.log(observations);
    const obsLength = observations.length;
    for (let i = 0; i < obsLength; i++) {
      const obs = observations[i];
      const row:any[] = [];
      row.push(obs.username);
      row.push(obs.date);
      row.push(obs.reportedDateTime);
      row.push(obs.lat);
      row.push(obs.lng);
      row.push(obs.butts);
      row.push(obs.cannabis);
      row.push(obs.ecig);
      row.push(obs.other);
      row.push(obs.multipurpose);
      row.push(obs.unsure);
      row.push(obs.wastePickedUp);
      row.push(obs.id);
      row.push(obs.projectName);
      row.push(obs.wasteImageId);
      row.push(obs.wasteImage);
      table.rows.push(row);
    }
    console.log(table);
    return table;
  }
    
//   public static generateCSV = async (observations:Observation[]) => {
//     let output:string = "";

//     const data = await TableDataService.createDataTable(observations);
    
//     output += data.columnHeadings.map((item) => {
//       return '"' + item + '"';
//     }).join(',');

//     output += '\r\n';

//     data.rows.forEach((row:any[]) => {
//       output += row.map((item) => { return '"' + item + '"' }).join(',');
//       output += '\r\n';
//     });
    
//     return output;
//   }
}

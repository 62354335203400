import React from "react";
import { IonContent, IonModal, IonSpinner } from "@ionic/react";
import "./LoadingScreen.css";

interface CoreAccordionDescriptionIntroProps {
  isOpen: boolean;
  text: string;
}

const LoadingScreen: React.FC<CoreAccordionDescriptionIntroProps> = ({
  isOpen,
  text,
}) => {
  return (
    <IonModal isOpen={isOpen}>
      <IonContent fullscreen style={{ "--ion-background-color": "#ffff" }}>
        <div className="loading-container">
          <IonSpinner name="lines" className="lsSpinnerStyle" color="primary" />
          <p className="lsSpinnerText">
            {text.length > 0 ? text : "Loading..."}
          </p>
        </div>
      </IonContent>
    </IonModal>
  );
};

export default LoadingScreen;

import {
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonModal,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./RewardsBadgesPrizes.css";
import { useRef, useState } from "react";
import GetPrize from "../../components/get_prize/GetPrize";
import GetBadge from "../../components/get_badge/GetBadge";
import HomeCoreProgressBar from "../../components/home_core_progress_bar/HomeCoreProgressBar";
import { DataService } from "../../services/DataService";
import ShowPrize from "../../components/show_prize/ShowPrize";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";
import {
  getLockUnLockPrize,
  getSubmittedPrizeDetails,
} from "../../services/dataApi";
import { DataSubmissionService } from "../../services/DataSubmissionService";

import { format } from "date-fns";
import { ProgressDataService } from "../../services/ProgressDataService";
import { AppInfoTimeService } from "../../services/AppInfoTimeService";
import { UserService } from "../../services/UserService";

const RewardsBadgesPrizes: React.FC = () => {
  var router = useIonRouter();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollIconRef = useRef<HTMLIonImgElement | null>(null);
  const modal = useRef<HTMLIonModalElement>(null);
  const [openProgressBadgeModal, setOpenProgressBadgeModal] = useState(false);
  const [openProgressCompleteBadgeModal, setOpenProgressCompleteBadgeModal] =
    useState(false);
  const [openProgressCompletePrizeModal, setOpenProgressCompletePrizeModal] =
    useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [loaderText, setLoaderText] = useState("Loading");
  const [selPrizeTitle, setSelPrizeTitle] = useState("");
  const [prizeCount, setPrizeCount] = useState(0);
  const [prizeEarnCount, setPrizeEarnCount] = useState(
    DataService.prizeEarnCount
  );

  const [badzeEarnCount, setBadzeEarnCount] = useState(
    DataService.badzeEarnCount
  );
  const [submittedPrizes, setSubmittedPrizes] = useState("");
  const [deliveryDate, setDeliveryDate] = useState("Please wait till coordinator updates delivery date.");

  const [selectedBadzeTitle, setSelectedBadzeTitle] = useState("");

  const [badzeRecievingDate, setBadzeRecievingDate] = useState("");
  const [badzeRecievingTime, setBadzeRecievingTime] = useState("");

  const [isSelectedLeft, setSelectedLeft] = useState(false);
  const [isSelectedRight, setSelectedRight] = useState(false);
  const [sectionPercent, setSectionPercent] = useState(0);
  const [sectionNo, setSectionNo] = useState(1);
  const [disableCore2ButtonBadge, setDisableCore2ButtonBadge] = useState(false);

  useIonViewWillEnter(() => {
    //checkLockUnLockPrize();
    DataService.badzeSelImagePath = "";
    DataService.prizeSelImagePath = "";
    if (DataService.isSelectedBagePrizeTab === "Badges Earned") {
      setSelectedLeft(true);
      setSelectedRight(false);
    } else {
      setSelectedLeft(false);
      setSelectedRight(true);
    }
  });

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  let scrollThroughBtn = false;
  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrolling = () => {
    console.log("SCROLLLLLING");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrollingEnd = () => {
    console.log("SCROLLLLLING ENDDDD");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "visible";
    }
  };

  const setState = () => {
    setSelectedLeft(isSelectedRight);
    setSelectedRight(isSelectedLeft);
  };

  const [badgeData, setBadgeData] = useState(DataService.badgePrimaryData);

  const [prizeData, setPrizeData] = useState(DataService.prizes);

  // const prizesC = [
  //   {
  //     text: "Section 1",
  //     imgUrl: "assets/images/Illustrations (2).png",
  //     showGetPrize: false,
  //     progress: null,
  //     progressTotal: 0,
  //     showRedeemedPrizeDetails: true
  //   },
  //   {
  //     text: "Section 2",
  //     imgUrl: "assets/images/Sunsafe.png",
  //     showGetPrize: false,
  //     progress: null,
  //     progressTotal: 0,
  //     showRedeemedPrizeDetails: false
  //   },
  //   {
  //     text: "Skin Self-Check",
  //     imgUrl: "assets/images/sscm.png",
  //     showGetPrize: false,
  //     progress: 0,
  //     progressTotal: 3,
  //     showRedeemedPrizeDetails: false
  //   },
  //   {
  //     text: "Progress Check for Sun Goal",
  //     imgUrl: "assets/images/SunSafe2.png",
  //     showGetPrize: false,
  //     progress: null,
  //     progressTotal: 0,
  //     showRedeemedPrizeDetails: false
  //   },
  // ];

  async function getSelectedPrizeDetails(title: string) {
    try {
      setLoaderText("Loading Prize Details");
      openLoader();
      const responseData = await getSubmittedPrizeDetails(
        title,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            //Save user data
            console.log("resp before", respData);
            if (respData.ExpectedDeliveryDate !== null && respData.ExpectedDeliveryDate.length>0) {
              setDeliveryDate(respData.ExpectedDeliveryDate);
            } else {
              setDeliveryDate("Please wait till coordinator updates delivery date.");
            }
            var selprizes: any[] = respData.selectedprizes;
            setPrizeCount(selprizes.length);
            var text = "A ";
            if (selprizes.length == 1 && selprizes[0] === "Umbrella") {
              text = "An ";
            }

            for (var i = 0; i < selprizes.length; i++) {
              text = text + selprizes[i] + ", ";
            }
            if (text.lastIndexOf(",") != -1) {
              text = text.substring(0, text.lastIndexOf(","));
            }
            console.log("submittedprizes", text);
            setSubmittedPrizes(text);
            setOpenProgressCompletePrizeModal(true);
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(" GET prize failure--" + respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error posting prize:", error);
    }
  }

  const openBadgeOverlay = (badge: any) => {
    setSelectedBadzeTitle(badge.badgeName);
    if (badge.recievedDateTime !== null) {
      const date = new Date(badge.recievedDateTime);
      setBadzeRecievingDate(format(date, "dd MMM yyyy"));
      setBadzeRecievingTime(format(date, "hh:mm a"));
    } else {
      setBadzeRecievingDate("");
      setBadzeRecievingTime("");
    }
    DataService.badzeSelImagePath = badge.imagePath;
    console.log("badge Image!!!!!!!! " + DataService.badzeSelImagePath);
    console.log("badge opened!!!!!!!! " + badge.badgeName);
    if (badge.isDisabled && badge.badgeName === "Section 2") {
      setSectionPercent(ProgressDataService.getProgressofSection2())
      setSectionNo(2)
      setOpenProgressBadgeModal(true);
      if (ProgressDataService.getProgressofSection2() ==0) {
       setDisableCore2ButtonBadge(true)
      }else{
        setDisableCore2ButtonBadge(false)
      }
    }
    if (badge.isDisabled && badge.badgeName === "Section 1") {
      setSectionPercent(ProgressDataService.getProgressofSection1())
      setSectionNo(1)
      setOpenProgressBadgeModal(true);
      setDisableCore2ButtonBadge(false)
    }
    if (!badge.isDisabled) {
      setOpenProgressCompleteBadgeModal(true);
    }
  };

  const onGetPrizeClicked = (prize: any) => {
    DataService.selectedPrizeTitle = prize.text;
    router.push("/RedeemPrizes", "forward", "push");
  };

  const openPrizeOverlay = (prize: any) => {
    console.log("prize title", prize.text);
    console.log("prize id", prize.id);
    setSelPrizeTitle(prize.text);
    DataService.prizeSelImagePath = prize.imgUrl;
    getSelectedPrizeDetails(prize.id);
  };

  const navigateToCurrentChapter = (core: number) => {
    if (core === 1) {
      var lastCompletedIndexOfCore1 = -1;
      for (
        let index = 0;
        index < ProgressDataService.progressObj.cores[0].chapters.length;
        index++
      ) {
        const chapter =
          ProgressDataService.progressObj.cores[0].chapters[index];
        const progress = chapter.progress;

        if (progress === 100) {
          lastCompletedIndexOfCore1 = index;
        }
      }

      if (lastCompletedIndexOfCore1 === 6) {
        if (
          ProgressDataService.progressObj.cores[0].chapters[0].progress === 100
        ) {
          lastCompletedIndexOfCore1 = 7;
        } else {
          lastCompletedIndexOfCore1 = 6;
        }
      }

      if (
        lastCompletedIndexOfCore1 === 7 &&
        ProgressDataService.progressObj.cores[0].chapters[7].progress === 100
      ) {
        lastCompletedIndexOfCore1 = 8;
      }
      if (lastCompletedIndexOfCore1 === -1) {
        lastCompletedIndexOfCore1 = 0;
      }
      DataService.navigateToCore1 = true;
      DataService.navigateToCore2 = false;
      DataService.refreshCoresFromBE = true;
      DataService.currentCoreIndex = 0;
      DataService.currentChapterIndex = lastCompletedIndexOfCore1;

      //Start Sections Timer
      AppInfoTimeService.sendAppSectionsTimeToBE(1);

      router.push("/home/corestab", "forward", "push");
    } else {
      var lastCompletedIndexOfCore2 = -1;
      for (
        let index = 0;
        index < ProgressDataService.progressObj.cores[1].chapters.length;
        index++
      ) {
        const chapter =
          ProgressDataService.progressObj.cores[1].chapters[index];
        const progress = chapter.progress;

        if (progress === 100) {
          lastCompletedIndexOfCore2 = index;
        }
      }

      if (lastCompletedIndexOfCore2 === 4) {
        if (
          ProgressDataService.progressObj.cores[1].chapters[0].progress === 100
        ) {
          lastCompletedIndexOfCore2 = 5;
        } else {
          lastCompletedIndexOfCore2 = 4;
        }
      }

      if (
        lastCompletedIndexOfCore2 === 5 &&
        ProgressDataService.progressObj.cores[1].chapters[5].progress === 100
      ) {
        lastCompletedIndexOfCore2 = 6;
      }

      if (lastCompletedIndexOfCore2 === -1) {
        lastCompletedIndexOfCore2 = 0;
      }

      DataService.navigateToCore1 = false;
      DataService.navigateToCore2 = true;
      DataService.refreshCoresFromBE = true;
      DataService.currentCoreIndex = 1;
      DataService.currentChapterIndex = lastCompletedIndexOfCore2;

      //Start Sections Timer
      AppInfoTimeService.sendAppSectionsTimeToBE(2);

      router.push("/home/corestab", "forward", "push");
    }
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
        onIonScrollStart={scrolling}
        onIonScrollEnd={scrollingEnd}
      >
        <LoadingScreen text={loaderText} isOpen={showLoader} />

        <IonModal
          className="modalStyle"
          ref={modal}
          isOpen={openProgressBadgeModal}
          onDidDismiss={() => {
            setOpenProgressBadgeModal(false);
          }}
          // trigger="open-modal"
          initialBreakpoint={0.8}
          breakpoints={[0, 0.8, 0.8, 0.9]}
        >
          <IonContent>
            <IonGrid>
              <IonRow className="rbpBadgePModalTitleRow">
                <IonCol size="auto">
                  <IonImg src="assets/icons/ClockCircleOutline.png" />
                </IonCol>
                <IonCol size="auto" className="rbpBadgePModalTitleTextCol">
                  <IonText className="rbpPModalText">
                    {selectedBadzeTitle}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="rbpBadgePModalTitleDivider"></div>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                  <IonImg
                    className="rbpBadgePModalBadgeImg"
                    src={DataService.badzeSelImagePath}
                  />
                </IonCol>
                <IonCol
                  size="12"
                  className="rbpBadgePModalBadgeImgCol rbpBadgePModalBadgeProgressCol"
                >
                  <HomeCoreProgressBar percentage={sectionPercent} />
                </IonCol>
                <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                  <IonText className="rbpCompleteBadgeText">
                    {" You have completed " +
                      sectionPercent +
                      "% of " +
                      selectedBadzeTitle}
                  </IonText>
                </IonCol>
                <IonCol
                  size="12"
                  className="rbpBadgePModalBadgeImgCol rbpBadgePModalBadgeDesc2Col"
                >
                  <IonText className="rbpBadgePModalBadgeDesc2Text">
                    {" We encourage you to complete " +
                      selectedBadzeTitle +
                      " in order to earn this badge"}
                  </IonText>
                </IonCol>
                {
                  (!disableCore2ButtonBadge &&( <IonCol size="12" className="rbpBadgePModalBadgeImgCol"
                  onClick={() => {
                    {
                      setOpenProgressBadgeModal(false);
                      navigateToCurrentChapter(sectionNo);
                    }
                  }}
                >
                  <div className="rbpBadgePModalBadgeGoToBtnDiv">
                    <IonText className="rbpBtnText">
                      {"Go to " + selectedBadzeTitle}
                    </IonText>
                  </div>
                </IonCol>))
                }
               
                {/* <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                   <div className="rbpBadgePModalHintDiv">
                    <IonGrid>
                      <IonRow>
                        <IonCol size="auto">
                          <IonImg
                            className="rbpQuestionImg"
                            src="assets/images/Question.png"
                          />
                        </IonCol>
                        <IonCol className="rbpQuestionImgQstnCol">
                          <IonText className="rbpCompleteBadgeTextQues">How to earn this badge?</IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol className="rbpAnswerCol">
                          <IonText className="rbpCompleteBadgeTextLorum">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nec libero ac enim accumsan euismod. Ut feugiat nisi lacus. Pellentesque rutrum risus et augue dapibus, sed aliquet purus porta. Nam vitae turpis non quam sagittis commodo. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</IonText>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div> 
                </IonCol> */}
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal
          ref={modal}
          isOpen={openProgressCompleteBadgeModal}
          onDidDismiss={() => {
            setOpenProgressCompleteBadgeModal(false);
          }}
          // trigger="open-modal"
          initialBreakpoint={0.7}
          breakpoints={[0, 0.7, 0.85, 0.9]}
        >
          <IonContent>
            <IonGrid>
              <IonRow className="rbpBadgePModalTitleRow">
                <IonCol size="auto">
                  <IonImg src="assets/icons/ClockCircleOutline.png" />
                </IonCol>
                <IonCol size="auto" className="rbpBadgePModalTitleTextCol">
                  <IonText className="rbpPModalText">
                    {selectedBadzeTitle}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="rbpBadgePModalTitleDivider"></div>
            <IonGrid className="rbpBadgePModalBadgeImgGrid">
              <IonRow>
                <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                  <IonImg
                    className="rbpBadgePModalBadgeImg"
                    src={DataService.badzeSelImagePath}
                  />
                </IonCol>

                <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                  <IonText className="rbpCompleteBadgeText">
                    {"You have completed " +
                      selectedBadzeTitle +
                      " and earned this badge."}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className="rbpCompletetimeGrid">
              <IonRow>
                <IonCol size="6">
                  <IonText className="rbpRD">Receiving Date</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="rbpRD">Receiving Time</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="6">
                  <IonText className="rbpRDT">{badzeRecievingDate}</IonText>
                </IonCol>
                <IonCol size="6">
                  <IonText className="rbpRDT">{badzeRecievingTime}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <IonModal
          ref={modal}
          isOpen={openProgressCompletePrizeModal}
          onDidDismiss={() => {
            setOpenProgressCompletePrizeModal(false);
          }}
          // trigger="open-modal"
          initialBreakpoint={0.7}
          breakpoints={[0, 0.7, 0.85, 0.9]}
        >
          <IonContent>
            <IonGrid>
              <IonRow className="rbpBadgePModalTitleRow">
                <IonCol size="auto">
                  <IonImg src="assets/icons/ClockCircleOutline.png" />
                </IonCol>
                <IonCol size="auto" className="rbpBadgePModalTitleTextCol">
                  <IonText className="rbpPModalText">{selPrizeTitle}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className="rbpBadgePModalTitleDivider"></div>
            <IonGrid className="rbpBadgePModalBadgeImgGrid">
              <IonRow>
                <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                  <IonImg
                    className="rbpBadgePModalBadgeImg"
                    src={DataService.prizeSelImagePath}
                  />
                </IonCol>

                <IonCol size="12" className="rbpBadgePModalBadgeImgCol">
                  {prizeCount == 1 && (
                    <IonText className="rbpCompleteBadgeText">
                      {"You have completed " +
                        selPrizeTitle +
                        " and earned " +
                        prizeCount +
                        " Prize."}
                    </IonText>
                  )}
                  {prizeCount > 1 && (
                    <IonText className="rbpCompleteBadgeText">
                      {"You have completed " +
                        selPrizeTitle +
                        " and earned " +
                        prizeCount +
                        " Prizes."}
                    </IonText>
                  )}
                </IonCol>
              </IonRow>
            </IonGrid>
            <IonGrid className="rbpCompletetimeGrid">
              <IonRow>
                <IonCol size="12">
                  <IonText className="rbpRD">Prizes Earned</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonText className="rbpRDT">{submittedPrizes}</IonText>
                </IonCol>
              </IonRow>
              <div style={{ marginBottom: "16px" }}></div>
              <IonRow>
                <IonCol size="12">
                  <IonText className="rbpRD">Estimated Delivery Time</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonText className="rbpRDT">{deliveryDate}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            DataSubmissionService.isGoalsGetAPICalled = false;
            UserService.controlBottomBarFromBack=3;
            router.goBack();
            //router.push("home/mystufftab", "forward", "push");
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        {/* <ProgressFraction
            percent={50}
            fraction="1/15"
            description="Body Parts"
          /> */}
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">Badges & Prizes</IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();

                router.push("home/skinchecktab", "forward", "push");
              }}
            >
              <IonText className="scsdRightButton"></IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="rbpmainContent">
          {/* <div className="htScrollIconContainer">
            <IonImg
              src="assets/icons/FixedScroll.png"
              className="htScrollIcon"
              onClick={scrollToBottom}
              ref={scrollIconRef}
              alt="none"
            ></IonImg>
          </div> */}
          <IonGrid>
            <IonRow>
              <IonCol
                size="6"
                className={
                  isSelectedLeft
                    ? "rbpColheaderLeft rbpColheaderPrize"
                    : "rbpColheaderLeft"
                }
                style={{ paddingTop: "0px !important" }}
                onClick={setState}
              >
                <IonText
                  className={
                    isSelectedLeft
                      ? "rbpTextRewardsSelected"
                      : "rbpTextRewardsDeselected"
                  }
                >
                  Badges
                </IonText>
              </IonCol>
              <IonCol
                size="6"
                className={
                  isSelectedRight
                    ? "rbpColheaderRight rbpColheaderPrize"
                    : "rbpColheaderRight"
                }
                style={{ paddingTop: "0px !important" }}
                onClick={setState}
              >
                <IonText
                  className={
                    isSelectedRight
                      ? "rbpTextRewardsSelected"
                      : "rbpTextRewardsDeselected"
                  }
                >
                  Prizes
                </IonText>
                {isSelectedRight && (
                  <IonText className="rbpTextNumber">{prizeEarnCount}</IonText>
                )}
              </IonCol>
            </IonRow>
          </IonGrid>
          <div style={{ marginBottom: "24px" }}></div>
          {isSelectedRight && (
            <IonGrid className="">
              <IonRow>
                <IonCol size="auto" className="msImagebeCol">
                  <IonImg src="/assets/images/Prize.svg"></IonImg>
                </IonCol>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonText className="msTextBadgesEarned">
                        Prizes Earned
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText className="msTextEarnedBadges">{prizeEarnCount}</IonText>
                      <IonText className="msTextTotalBadges"> {" / " + DataService.prizes.length}</IonText>
                    </IonCol>
                  </IonRow>
                </IonCol>
                {/* <IonCol size="6" className="rbpColLeft">
                  <IonGrid>
                    <IonRow className="rbphowToEarn">
                      <IonCol size="auto" className="rbpColQues">
                        <IonImg src="/assets/images/Question.png"></IonImg>
                      </IonCol>
                      <IonCol>
                         <IonText className="rbpTexthowtoEarn">
                          How to earn prizes?
                        </IonText> 
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol> */}
              </IonRow>
            </IonGrid>
          )}
          <div style={{ marginBottom: "24px" }}></div>
          {/* {(!DataService.isPrizeRedeemed && isSelectedRight) &&
            <GetPrize prizes={prizeData} prizeSelected={onGetPrizeClicked} />} */}
          {isSelectedRight && (
            <ShowPrize
              prizes={prizeData}
              displayPrizeDetails={openPrizeOverlay}
              prizeSelected={onGetPrizeClicked}
            />
          )}
          {isSelectedLeft && (
            <IonGrid className="">
              <IonRow>
                <IonCol size="auto" className="msImagebeCol">
                  <IonImg src="/assets/images/Badge.svg"></IonImg>
                </IonCol>
                <IonCol>
                  <IonRow>
                    <IonCol>
                      <IonText className="msTextBadgesEarned">
                        Badges Earned
                      </IonText>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonText className="msTextEarnedBadges">{badzeEarnCount}</IonText>
                      <IonText className="msTextTotalBadges">{" / " + DataService.badgePrimaryData.length}</IonText>
                    </IonCol>
                  </IonRow>
                </IonCol>
                {/* <IonCol size="6" className="rbpColLeft">
                  <IonGrid>
                    <IonRow className="rbphowToEarn">
                      <IonCol size="auto" className="rbpColQues">
                        <IonImg src="/assets/images/Question.png"></IonImg>
                      </IonCol>
                      <IonCol>
                        <IonText className="rbpTexthowtoEarn">
                          How to earn badges?
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol> */}
              </IonRow>
            </IonGrid>
          )}
          <div style={{ marginBottom: "24px" }}></div>
          {isSelectedLeft && (
            <GetBadge badges={badgeData} badgeSelected={openBadgeOverlay} />
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RewardsBadgesPrizes;

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonIcon,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  useIonRouter,
} from "@ionic/react";
import "./CoreChapterIntro.css";
import { chevronBack, settingsOutline } from "ionicons/icons";
import "../../Fonts.css";
import ProgressContainer from "../../components/progress/Progress";
import { DataService } from "../../services/DataService";

const CoreChapterIntro: React.FC = () => {
  var router = useIonRouter();

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <IonGrid>
          <IonRow class="ion-justify-content-between ion-align-items-center">
            <IonIcon
              icon={chevronBack}
              style={{ color: "transparent" }}
            ></IonIcon>
            <IonText className="titleText">Chapter 1</IonText>
            {/* <IonIcon className="settingsIcon" icon={settingsOutline}></IonIcon> */}
            <IonText
              className="cciExitBtn"
              onClick={() => {
                // DataService.tabSelected="Cores";
                router.push("/home/corestab", "root", "replace");
              }}
            >
              Exit
            </IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent custom-content">
        <IonTitle className="ion-margin-bottom default-text">
          Overview
        </IonTitle>
          <IonCard className="default-border-radius default-card">
            <IonCardContent>
              <IonImg src="/assets/images/welcome-goal.png"></IonImg>
              <p className="default-text text-bold default-padding-top default-padding-bottom"> We're going to introduce you </p>
              <IonGrid>
                <IonRow className="default-text list-row">
                  <IonCol size="auto" className="listimage"> 1</IonCol>
                  <IonCol size="auto" className="default-font">What you will Cover in Core 1. </IonCol>
                </IonRow>
                <IonRow  className="default-text list-row">
                  <IonCol size="auto" className="listimage"> 2</IonCol>
                  <IonCol size="auto" className="default-font">Meet Others Diagnosed with Melanoma</IonCol>
                </IonRow>

                <IonRow  className="default-text list-row">
                  <IonCol size="auto" className="listimage"> 3</IonCol>
                  <IonCol size="auto" className="default-font">Tell us about Your Experience</IonCol>
                </IonRow>
              </IonGrid>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  router.push("/corechaptercontentone", "forward", "push");
                }}
              >
                Continue
              </IonButton>{" "}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default CoreChapterIntro;

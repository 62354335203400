import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
  } from "@ionic/react";
  import { useState } from "react";
  
  import "./AccordianCoreContentIconExp.css";
  import "../../Fonts.css";
  
  interface AccordianCoreContentIconExpProps {
    text: string;
    imageUrl: string
  }
  
  const AccordianCoreContentIconExp: React.FC<AccordianCoreContentIconExpProps> = ({text, imageUrl}) => {
    const [isOpen, setIsOpen] = useState(false);
  
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
  
    return (
      <div className="acceMainContainer">
        <IonGrid>
          <IonRow>
            <IonCol size="auto" className="accieImgCol">
              <IonImg src={imageUrl} />
            </IonCol>
            <IonCol className="acceTitleCol">
              <IonText className="acceTitleText" dangerouslySetInnerHTML={{ __html: text }}></IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  };
  
  export default AccordianCoreContentIconExp;
  
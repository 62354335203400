import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
} from "@ionic/react";
import "./MyStuffGoalProgress.css";

interface MyStuffGoalProgressProps {
    title: string;
    goals: Array<any>;
    goalSelected: Function;
}

const MyStuffGoalProgress: React.FC<MyStuffGoalProgressProps> = ({
    goals,
    title,
    goalSelected
}) => {
    return (
        <IonGrid className="msgBgGrid">
            <IonGrid>
                <IonRow>
                    <IonCol size="12" className="msgColSSC">
                        <IonText className="msgHeadingText">{title}</IonText>
                    </IonCol>
                </IonRow>
            </IonGrid>
            <div style={{ marginBottom: "16px" }}></div>
            <IonGrid className="msgGridContent" >
                <IonRow>
                    {goals.map((goal: any, index: number) => {
                        return (
                            <>
                            <IonCol size="11.5/2" className= {index>1 ? "msgColbg msColbgMar" : "msgColbg"}>
                                <div className="msgImgDiv">
                                    <IonImg src="assets/images/todo.png"/*{goal.imageUrl}*/ className="msgImgProp">
                                    </IonImg>
                                </div>
                                <IonText className="msgHeadingText1">{goal.text}</IonText>
                                <div className="msgTextDiv">
                                    <IonText className="msgTextBtn" onClick={() => {
                                        goalSelected(goal.goal)
                                    }}>Update Goal</IonText>
                                </div>
                            </IonCol>
                            {(index%2===0) && <IonCol size="0.5"></IonCol>}
                            </>
                        )
                    }
                    )}

                </IonRow>
            </IonGrid>
        </IonGrid>

    );
};

export default MyStuffGoalProgress;

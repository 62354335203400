import axios from "axios";
import { Component } from "react";
import { getApi } from "../services/dataApi";

export abstract class DataService extends Component {
  protected abstract getBaseUrl(): string;
  public static selectedPrizeTitle="";
  public static isSelectedBagePrizeTab="";
  public static getCoresFromBE = true;
  public static refreshCoresFromBE = true;

  public static navigateToPrizeFromPopup =false;

  public static homeTabSet = false;

  public static navigateToCore1 = false;
  public static navigateToCore2 = false;

  public static coresProgressStarted = false;

  public static isEntryToC1A1ThroughMyStuff = false;
  public static selectedC1A1Goal: any = null;

  public static isEntryToC2A1ThroughMyStuff = false;
  public static selectedC2A1Goal: any = null;

  public static coreOpenedFromCoresTab = true;

  public static prizeEarnCount=0;

  public static badzeEarnCount=0;

  public static badzeSelImagePath=""
  public static prizeSelImagePath=""

  public static  prizes = [
    {
      id: "core1-completion",
      text: "Section 1",
      imgUrl: "assets/images/Learn.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: null,
      showRedeemedPrizeDetails: false
  
    },
    {
      id: "core2-completion",
      text: "Section 2",
      imgUrl: "assets/images/SunSafe2.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: null,
      showRedeemedPrizeDetails: false
    },
    {
      id: "first-sse",
      text: "First Skin Self Check",
      imgUrl: "assets/images/sscm.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: null,
      showRedeemedPrizeDetails: false
    },
    {
      id: "second-sse",
      text: "Second Skin Self Check",
      imgUrl: "assets/images/sscm.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: null,
      showRedeemedPrizeDetails: false
    },
    
    {
      id: "sun-goal-update",
      text: "Sun Goal Update",
      imgUrl: "assets/images/Sunsafe.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: null,
      showRedeemedPrizeDetails: false
    },
  ];

  public static badgePrimaryData = [
    {
      imagePath: "/assets/images/badges/OnBoarding.svg",
      badgeName: "On Boarding",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
    {
      imagePath: "/assets/images/badges/Core1.svg",
      badgeName: "Section 1",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
    {
      imagePath: "/assets/images/badges/Core2.svg",
      badgeName: "Section 2",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
    {
      imagePath: "/assets/images/badges/SkinSelfCheck.svg",
      badgeName: "Skin Self Check",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
    {
      imagePath: "/assets/images/badges/GoalCreation.svg",
      badgeName: "Goal Creation",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
    {
      imagePath: "/assets/images/badges/GoalUpdate.svg",
      badgeName: "Goal Update",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
    {
      imagePath: "/assets/images/badges/FinalMss.svg",
      badgeName: "Completing MSS",
      earnedBadges: null,
      totalBadges: null,
      isDisabled: true,
      recievedDateTime:null,
    },
  ];

  public static accordionImgData = [{
    title: "Asymmetrical",
    imageUrl: "/assets/images/scsdImages/WithBG/A.png",
    expMainImgUrl:"assets/images/scsdImages/A-FBig/A.png",
    expansionPoints: [
      {
        text: "A stands for Asymmetrical.",
        imageUrl: "assets/icons/ExclamationCircleFill.svg",
      },
      
    ],
    open: false,
  },
  {
    title: "Border Irregularity",
    imageUrl: "/assets/images/scsdImages/WithBG/B.png",
    expMainImgUrl:"assets/images/scsdImages/A-FBig/B.png",
    expansionPoints: [
      {
        text: "B stands for Border irregularity.",
        imageUrl: "assets/icons/ExclamationCircleFill.svg",
      },
      
    ],
    open: false,
  },
  {
    title: "Color Variation",
    imageUrl: "/assets/images/scsdImages/WithBG/C.png",
    expMainImgUrl:"assets/images/scsdImages/A-FBig/C.png",
    expansionPoints: [
      {
        text: "C stands for Color variation.",
        imageUrl: "assets/icons/ExclamationCircleFill.svg",
      },
      
    ],
    open: false,
  },
  {
    title: "Diameter larger than 6mm",
    imageUrl: "/assets/images/scsdImages/WithBG/D.png",
    expMainImgUrl:"assets/images/scsdImages/A-FBig/D.png",
    expansionPoints: [
      {
        text: "D stands for Diameter larger than 6 mm.",
        imageUrl: "assets/icons/ExclamationCircleFill.svg",
      },
      
    ],
    open: false,
  },
  {
    title: "Evolving",
    imageUrl: "/assets/images/scsdImages/WithBG/E.png",
    expMainImgUrl:"assets/images/scsdImages/A-FBig/E.png",
    expansionPoints: [
      {
        text: "E stands for Evolving.",
        imageUrl: "assets/icons/ExclamationCircleFill.svg",
      },
      
    ],
    open: false,
  },
  {
    title: "Funny Looking",
    imageUrl: "/assets/images/scsdImages/WithBG/F.png",
    expMainImgUrl:"assets/images/scsdImages/A-FBig/F.png",
    expansionPoints: [
      {
        text: "F stands for Funny looking.",
        imageUrl: "assets/icons/ExclamationCircleFill.svg",
      },
      
    ],
    open: false,
  },];

  public static coresDataJSON1 = {
    cores: [
      {
        title: "Core 1: Learn About Spots",
        description:
          "Lorem ipsum dolor sit amet, consectetur. Lorem ipsum dolor sit amet, consectetur.",
        imageUrl: "assets/images/Learning.svg",
        chapters: [
          //Core 1 Chapter 1
          {
            chapterNo: "Chapter 1 • 3mins",
            title: "Welcome",
            progress: "done",
            link: "welcome",
            screens: [
              {
                title: "Welcome",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Overview",
                    screens: null,
                  },
                  {
                    type: "overview",
                    title: "<strong>We're going to introduce you</strong>",
                    imageUrl: "/assets/images/welcome-goal.png",
                    points: [
                      "What will you <strong>cover</strong> in Core 1",
                      "Meet Others <strong>Diagnosed with Melanoma</strong>",
                      "Tell us about <strong>Your Experience</strong>",
                    ],
                    screens: null,
                  },
                ],
              },
              {
                title: "Welcome",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "What Will I Cover",
                    screens: null,
                  },
                  {
                    type: "image-text-accordions",
                    screens: null,
                    contents: [
                      {
                        title: "What will I Learn?",
                        imageUrl: "/assets/images/Learning.svg",
                        points: [
                          "Find out abut mySmartSkin",
                          "See facts about melanoma and different types of skin cancer",
                          "Find out about sun-safe behaviors",
                        ],
                      },
                      {
                        title: "What will I do?",
                        imageUrl: "/assets/images/todo.png",
                        points: [
                          "Think about regular skin self-checks and why they are important to me",
                          "Review past experience doing skin self-checks",
                          "Get prepared to conduct a thorough skin self-check",
                          "Learn how to identify potentially suspicious moles",
                          "Gain confidence about doing monthly skin self-checks",
                          "Create a monthly skin self-check action plan",
                        ],
                      },
                      {
                        title: "How will this help?",
                        imageUrl: "/assets/images/help.png",
                        points: [
                          "It introduces and welcomes you to mySmartSkin",
                          "It helps you learn how to add healthy skin habits into your everyday life",
                          "Doing monthly skin self-checks can help you feel in control of your health",
                          "Doing monthly skin self-checks can help you identify suspicious moles that should be checked by your doctor",
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Welcome",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 30,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "What is mySmartSkin",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",
                    text: "<strong>mySmartSkin</strong> is a program specifically for people like you who have been diagnosed with melanoma.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",
                    text: "Many people diagnosed with melanoma find it <strong>hard to check their skin</strong> on a regular basis for any potential growths or changes that may be skin cancer. People also struggle to <strong>protect themselves sufficiently from the sun</strong>",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",
                    text: "Here are some of the ways that <strong>mySmartSkin</strong> will help you:",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: "<strong>Learn why</strong> checking your skin and engaging in sun-safe habits is important and worth doing",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: "<strong>Learn what</strong> you need to know and do to check your skin on a regular basis and become more sun-safe",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: "<strong>Devise and follow plans</strong> for regularly checking your skin and being sun-safe",
                    screens: null,
                  },
                ],
              },
              {
                title: "Great Job!",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter",
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    imageUrl: "assets/images/ChapterComplete.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main",
                    text: "Chapter 1 is Completed!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub1",
                    text: "You’ve earned a badge!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub2",
                    text: "Keep going to learn more!",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    title: "You’ve Completed",
                    description:
                      "Understanding what you will learn and do in Core 1 and how completing these chapters will help you.",
                    isImgLeft: false,
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    title: "In Next Chapter",
                    description: "We will brush up on facts about melanoma.",
                    isImgLeft: true,
                    screens: null,
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 2
          {
            chapterNo: "Chapter 2 • 3mins",
            title: "What is Skin Cancer?",
            progress: "locked",
            screens: [
              {
                title: "Learn About Skin Cancer",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 25,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: null,
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "img-text-mainimg-accordion",
                    multipleOptions: null,
                    text: null,
                    title: "What is Melanoma? ",
                    imageUrl: "/assets/images/todo.png",
                    description: null,
                    isImgLeft: false,
                    height: null,
                    question: null,
                    coreVideoUrl: null,
                    coreVideoText: null,
                    accImg: null,
                    accHeader: null,
                    accDesc: null,
                    accPoints: null,
                    bottomDesc: null,
                    expMainImgText:
                      "Melanoma is a type of skin cancer that begins in cells called melanocytes. Melanoma develops when melanocytes become abnormal, grow without control, and invade other tissues. ",
                    expMainImgUrl: "assets/images/melanocytes.png",
                    screens: null,
                    points: null,
                    contents: null,
                    data: null,
                    activityOptions: null,
                    options: null,
                    expansionPoints: [
                      {
                        text: "The abnormal cells form a growth or tumor on the surface of the skin. ",
                        imageUrl: "assets/icons/scan.png",
                      },
                      {
                        text: "If melanoma is given time to grow, it grows down into the skin and can spread to other areas of the body. ",
                        imageUrl: "assets/icons/hand.png",
                      },
                      {
                        text: "That is why it is important to detect and treat melanoma as early as possible.",
                        imageUrl: "assets/icons/calendar.png",
                      },
                    ],
                  },
                  {
                    type: "img-text-mainimg-accordion",
                    multipleOptions: null,
                    text: null,
                    title: "What are Melanocytes? ",
                    imageUrl: "/assets/images/todo.png",
                    description: null,
                    isImgLeft: false,
                    height: null,
                    question: null,
                    coreVideoUrl: null,
                    coreVideoText: null,
                    accImg: null,
                    accHeader: null,
                    accDesc: null,
                    accPoints: null,
                    bottomDesc: null,
                    expMainImgText: null,
                    expMainImgUrl: null,
                    screens: null,
                    points: null,
                    contents: null,
                    data: [
                      {
                        id: 0,
                        title: null,
                        description: null,
                        question: null,
                        questionImages: null,
                        answer: null,
                        answerType: null,
                        mcqButton: null,
                      },
                    ],
                    activityOptions: [
                      {
                        imageUrl: null,
                        title: null,
                        desc: null,
                        options: [
                          {
                            imageUrl: null,
                            text: null,
                            options: [
                              {
                                mainImgUrl: null,
                                mainTitle: null,
                                mainDesc: null,
                                mainDesc2: null,
                                title: null,
                                imageUrl: null,
                                showBottomCheckBox: false,
                                options: [
                                  {
                                    imageUrl: null,
                                    title: null,
                                    desc: null,
                                    isSelected: false,
                                    isThere: false,
                                    summaryGoal: null,
                                    summaryGoalOption: null,
                                    summaryFutureOptions: null,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    expansionPoints: [
                      {
                        text: "The usual role of melanocytes is to produce a pigment called melanin that gives skin its natural color. ",
                        imageUrl: null,
                      },
                      {
                        text: "When the skin is exposed to the sun (or artificial sources of ultraviolet (UV) radiation such as indoor tanning beds), melanocytes produce more melanin and the skin becomes darker.",
                        imageUrl: null,
                      },
                    ],
                    options: [
                      {
                        text: null,
                        isCorrect: false,
                        hints: [],
                      },
                    ],
                  },
                  {
                    type: "img-text-mainimg-accordion",
                    multipleOptions: null,
                    text: null,
                    title: "Other Skin Cancers",
                    imageUrl: "/assets/images/todo.png",
                    description: null,
                    isImgLeft: false,
                    height: null,
                    question: null,
                    coreVideoUrl: null,
                    coreVideoText: null,
                    accImg: null,
                    accHeader: null,
                    accDesc: null,
                    accPoints: null,
                    bottomDesc: null,
                    expMainImgText: null,
                    expMainImgUrl: null,
                    screens: null,
                    points: null,
                    contents: null,
                    data: null,
                    activityOptions: null,
                    options: null,
                    expansionPoints: [
                      {
                        text: "Melanoma is one type of skin cancer.",
                        imageUrl: "assets/icons/spanner.png",
                      },
                      {
                        text: "People diagnosed with melanoma are also at risk for developing other skin cancers, including basal cell carcinoma and squamous cell carcinoma. ",
                        imageUrl: "assets/icons/hand.png",
                      },
                      {
                        text: "Together, they are referred to as non-melanoma skin cancer or keratinocyte carcinoma (because they develop from cells in the skin called keratinocytes).",
                        imageUrl: "assets/icons/hand.png",
                      },
                    ],
                  },
                  {
                    type: "img-text-mainimg-accordion",
                    multipleOptions: null,
                    text: null,
                    title: "Basal and Squamous Skin Cancers",
                    imageUrl: "/assets/images/todo.png",
                    description: null,
                    isImgLeft: false,
                    height: null,
                    question: null,
                    coreVideoUrl: null,
                    coreVideoText: null,
                    accImg: null,
                    accHeader: null,
                    accDesc: null,
                    accPoints: null,
                    bottomDesc: null,
                    expMainImgText:
                      "About basal and squamous cell skin cancers",
                    expMainImgUrl: null,
                    screens: null,
                    points: null,
                    contents: null,
                    data: [
                      {
                        id: 0,
                        title: null,
                        description: null,
                        question: null,
                        questionImages: null,
                        answer: null,
                        answerType: null,
                        mcqButton: null,
                      },
                    ],
                    activityOptions: [
                      {
                        imageUrl: null,
                        title: null,
                        desc: null,
                        options: [
                          {
                            imageUrl: null,
                            text: null,
                            options: [
                              {
                                mainImgUrl: null,
                                mainTitle: null,
                                mainDesc: null,
                                mainDesc2: null,
                                title: null,
                                imageUrl: null,
                                showBottomCheckBox: false,
                                options: [
                                  {
                                    imageUrl: null,
                                    title: null,
                                    desc: null,
                                    isSelected: false,
                                    isThere: false,
                                    summaryGoal: null,
                                    summaryGoalOption: null,
                                    summaryFutureOptions: null,
                                  },
                                ],
                              },
                            ],
                          },
                        ],
                      },
                    ],
                    expansionPoints: [
                      {
                        text: "Both basal and squamous cell carcinoma are much more commonly diagnosed than melanoma.",
                        imageUrl: "/assets/images/todo.png",
                      },
                      {
                        text: "Both are often found on parts of the body that receive a lot of sun exposure, such as the face, head, and neck.",
                        imageUrl: "/assets/images/todo.png",
                      },
                      {
                        text: "Almost all of the risk factors for melanoma are also risk factors for basal cell and squamous cell skin cancers.",
                        imageUrl: "/assets/images/todo.png",
                      },
                      {
                        text: "These cancers (especially basal cell carcinoma) do not usually spread to other areas of the body. However, if they are not treated early enough, they can become large and cause significant skin damage.",
                        imageUrl: "/assets/images/todo.png",
                      },
                    ],
                    options: [
                      {
                        text: null,
                        isCorrect: false,
                        hints: [],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Your Experience",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Your Experience with Melanoma",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "You may have been diagnosed and treated for melanoma recently or up to several years ago. mySmartSkin will give you the best help and support no matter your experience with melanoma.",
                    screens: null,
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question: "When were you diagnosed with melanoma?",
                    options: [
                      {
                        text: "Less than a year ago",
                        isCorrect: true,
                        hints: [
                          "This is a great time to use mySmartSkin to learn and do all you can to add healthy skin habits into your everyday life.You may find the facts and strategies in mySmartSkin are new to you. mySmartSkin will help you feel confident adding new habits to your everyday routine.",
                        ],
                      },
                      {
                        text: "About a year ago",
                        isCorrect: false,
                        hints: [
                          "Now is an excellent time to use mySmartSkin to make sure you are practicing healthy skin habits in your everyday routine. You may find some of the facts and strategies in mySmartSkin are new to you. mySmartSkin will support and guide you at every step of the way.",
                        ],
                      },
                      {
                        text: "More than a year ago",
                        isCorrect: false,
                        hints: [
                          "Now is the perfect time to use mySmartSkin to review your everyday skin habits. You may be familiar with some of the facts and strategies in mySmartSkin. Most people find it very helpful to review and reflect on their healthy skin habits. mySmartSkin will help you do that",
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Your Experience",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Your Experience with Melanoma",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "You may have been diagnosed and treated for melanoma recently or up to several years ago. mySmartSkin will give you the best help and support no matter your experience with melanoma.",
                    screens: null,
                  },

                  {
                    type: "mcq",
                    screens: null,
                    question:
                      "How much do you know about checking your skin for melanoma and protecting yourself from the sun?",
                    options: [
                      {
                        text: "very little",
                        isCorrect: true,
                        hints: [
                          "Many people start using mySmartSkin with little knowledge about checking their skin for melanoma and protecting themselves from the sun. mySmartSkin will provide everything you need to become confident in regularly performing skin self-checks and sun-safe behaviors.",
                        ],
                      },
                      {
                        text: "some things",
                        isCorrect: false,
                        hints: [
                          "A lot of people use mySmartSkin knowing some things about checking their skin for melanoma and protecting themselves from the sun. mySmartSkin will provide everything you need to become confident in regularly performing skin self-checks and sun-safe behaviors.",
                        ],
                      },
                      {
                        text: "a lot",
                        isCorrect: false,
                        hints: [
                          "It's great you know so much about checking your skin for melanoma and protecting yourself from the sun. Some of the facts and strategies in mySmartSkin may be review for you. Many of our knowledgeable users fmd they learn new things when they review the information in mySmartSkin. mySmartSkin will provide just what you need to be confident in regularly performing skin self-checks and sun-safe behaviors.",
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Melanoma Highlights",
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 80,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "img-text-mainimg",
                    screens: null,
                    imageUrl: "/assets/images/melanoma_highlights.png",
                    mainImgText: "70,000+ people diagnosed each year.",
                    expansionPoints: [
                      {
                        text: "Melanoma is the 6th most common cancer.",
                        imageUrl: "assets/icons/scan.png",
                      },
                      {
                        text: "1 in 48 diagnosed during lifetime.",
                        imageUrl: "assets/icons/hand.png",
                      },
                      {
                        text: "About 1 million people in U.S. live with melanoma.",
                        imageUrl: "assets/icons/calendar.png",
                      },
                      {
                        text: "Melanoma numbers are increasing.",
                        imageUrl: "assets/icons/calendar.png",
                      },
                    ],
                  },
                ],
              },
              {
                title: "Where Does it appear on the body",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 30,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Where Does Melanoma Appear on the Body?",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",
                    text: "Melanoma can begin as a new growth on the skin or in an existing mole.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Moles are normal growths on the skin made up of melanocytes.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Moles come in different shapes, colors, and sizes.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "You will learn more about moles in the next Chapter of mySmartSkin.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",
                    text: "Melanoma can appear anywhere on the body, even on areas that are not exposed to the sun.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: "Among men, melanoma often occurs on the head, neck, or back.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: "Among women, melanoma is more often found on the legs.",
                    screens: null,
                  },
                ],
              },
              {
                title: "Images of Melanoma",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 30,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Images of Melanoma",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                ],
              },

              {
                title: "Risk Factors",
                // chapterNo: "Lesson 2",
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 40,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Sun Exposure",
                    imageUrl:
                      "/assets/images/Chapter2-RiskFactors/Sun Safe Behavior.svg",
                    description:
                      "Up to 90% of melanomas are caused by excess ultraviolet (UV) exposure. The greater a person's lifetime exposure to the sun, the higher their risk for developing melanoma.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Sunburns",
                    imageUrl: "/assets/images/Chapter2-RiskFactors/Sunburn.png",
                    description:
                      "People who have had one or more severe, blistering sunburns are at increased risk for melanoma.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Skin Type",
                    imageUrl:
                      "/assets/images/Chapter2-RiskFactors/Skin type.png",
                    description:
                      "People with fair skin that burns easily in the sun are at increased risk for skin cancer. People with a lot of freckles are also at higher risk for melanoma.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Spots",
                    imageUrl: "/assets/images/Chapter2-RiskFactors/Mole.png",
                    description:
                      "People who have more than 50 moles on their body have a higher risk of developing melanoma. It's important to know, however, taht people with few moles can still get melanoma",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Hair & Eyes Color",
                    imageUrl:
                      "/assets/images/Chapter2-RiskFactors/Hair & eye color.png",
                    description:
                      "People with naturally lighter colored hair (such as blonde or red) or eyes (such as green, blue, or gray) are at increased risk for melanoma.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Family History",
                    imageUrl:
                      "/assets/images/Chapter2-RiskFactors/Family history.png",
                    description:
                      "People with a family history of melanoma are at increased risk for the disease. Your diagnosis of melanoma means that your family members may be at increased risk for melanoma. This may be due to shared risk factors among family members, such as having a similar history of high sun exposure, sunburns, or sun-sensitive skin. Sometimes, a genetic disorder may cause melanoma to run in families.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Indoor Tanning",
                    imageUrl:
                      "/assets/images/Chapter2-RiskFactors/Indoor tanning.png",
                    description:
                      "People who have a history of using indoor tanning beds, booths, or lamps are at increased risk for melanoma.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Older Age",
                    imageUrl: "/assets/images/Chapter2-RiskFactors/Old age.png",
                    description:
                      "Individuals aged 50 years and older are more at risk for being diagnosed with melanoma. However, it is also one of the most common cancers diagnosed among 18-29 year olds {particularly among women).",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Male Gender",
                    imageUrl: "/assets/images/Chapter2-RiskFactors/Male.png",
                    description:
                      "Overall, men are most likely to be diagnosed with melanoma than women. This may be because of differences in their sun exposures and protective behaviors. In recent years, The risk of melanoma has been raising among young adult women. This is partly due to their increasing use of indoor tanning devices.",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Weakened Immune System",
                    imageUrl:
                      "/assets/images/Chapter2-RiskFactors/Weak immune system.png",
                    description:
                      "Individuals who have a weakened (or suppressed) immune system due to certain medical conditions or medications are at increased risk for melanoma.",
                  },
                ],
              },
              {
                title: "Risk Factors",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 90,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "mcq",
                    screens: null,
                    question:
                      "How do you think your history of melanoma affects your risk of getting another melanoma?",
                    options: [
                      {
                        text: "I have a lower risk",
                        isCorrect: false,
                        hints: [
                          "People who have had melanoma have up to 9 times the risk of having another melanoma compared to people who have never had it.",
                          "That is why it is especially important to regularly check your skin and engage in sun-safe behaviors. MySmartSkin will help you engage in these behaviors!",
                        ],
                      },
                      {
                        text: "It does not affect my risk",
                        isCorrect: false,
                        hints: [
                          "People who have had melanoma have up to 9 times the risk of having another melanoma compared to people who have never had it.",
                          "That is why it is especially important to regularly check your skin and engage in sun-safe behaviors. MySmartSkin will help you engage in these behaviors!",
                        ],
                      },
                      {
                        text: "I have a high risk",
                        isCorrect: true,
                        hints: [
                          "People who have had melanoma have up to 9 times the risk of having another melanoma compared to people who have never had it.",
                          "That is why it is especially important to regularly check your skin and engage in sun-safe behaviors. MySmartSkin will help you engage in these behaviors!",
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Great Job!",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter ",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    screens: null,
                    imageUrl: "assets/images/ChapterComplete.png",
                  },
                  {
                    type: "mid-heading-main",
                    screens: null,
                    text: "Chapter 2 is Completed!",
                  },
                  {
                    type: "mid-heading-sub1",
                    screens: null,
                    text: "You’ve earned a badge!",
                  },
                  {
                    type: "mid-heading-sub2",
                    screens: null,
                    text: "Keep going to learn more!",
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "You’ve Completed",
                    description:
                      "Understanding what you will learn and do in Core 1 and how completing these chapters will help you.",
                    isImgLeft: false,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "In Next Chapter",
                    description:
                      "We will brush up on facts about Monthly self-checks.",
                    isImgLeft: true,
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 3
          {
            chapterNo: "Chapter 3 • 3mins",

            title: "Monthly Self-Checks",

            progress: "locked",

            screens: [
              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 10,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: false,
                  },
                ],

                contents: [
                  {
                    type: "side-heading",

                    text: "Monthly Skin Self-Checks",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",

                    text: "In addition to having a periodic full body skin examination from a physician, doctors recommend that people with a history of melanoma also conduct a <strong>monthly check of their entire body</strong> for moles or other marks on the skin that could be skin cancer. This is called a <strong>monthly skin self-check or skin self-examination.</strong>",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",

                    text: "Doing your own <strong>monthly skin self-check</strong> is a great way to detect potential problems that can be addressed by your doctor as soon as possible. <strong>Starting in Core 2</strong>, <strong>mySmartSkin</strong> will help you to follow a monthly skin self-check routine.",

                    screens: null,
                  },
                ],
              },

              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 20,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "side-heading",

                    text: "What are Moles?",

                    screens: null,
                  },

                  {
                    type: "fnf",

                    data: [
                      {
                        id: 1,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Moles are growths on the skin that are usually brown,black,pink or skin-toned",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 2,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "Normal moles are usually multicolored",

                        answer: "",

                        answerType: "Fiction",
                      },

                      {
                        id: 3,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "Normal moles can be raised or flat",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 4,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Normal moles usually have a round or oval shape",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 5,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Moles are normal growths on the skin made up of cells called melanocytes",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 6,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Most people have from 10 to 40 normal moles on their body",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 7,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "Moles can appear anywhere on the skin",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 8,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "Melanoma always starts as a normal mole",

                        answer: "",

                        answerType: "Fiction",
                      },

                      {
                        id: 9,

                        title: "What do you know about Moles?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "As people get older, some moles may gradually go away",

                        answer: "",

                        answerType: "Fiction",
                      },
                    ],

                    screens: null,
                  },
                ],
              },

              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 30,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: false,
                  },
                ],

                contents: [
                  {
                    type: "side-heading",

                    text: "The ABCDEFs of Melanoma",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",

                    text: "The ABCDEFs of Melanoma are a guide that can help you identify suspicious spots.Each letter relates to a warning sign to help tell the difference between normal spots and melanomas.",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",

                    text: "mySmartSkin will show you how to use the ABCDEF Guide.",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",

                    text: "Click the characterstics to learn more:",

                    screens: null,
                  },

                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Asymmetrical",

                    imageUrl: "/assets/images/scsdImages/WithBG/A.png",

                    expMainImgText:
                      "A stands for Asymmetrical. Normal moles usually have a symmetrical shape, which means that one half looks similar to the other half. If one half of a mole has a very different shape from the other half, then the mole is Asymmetrical, which is a sign of potential melanoma.",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/A.png",

                    expansionPoints: [
                      {
                        text: "Asymmetrical means that a mole has one half that looks very different from the other half.",

                        imageUrl: "assets/icons/scan.png",
                      },
                    ],
                  },

                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Border Irregularity",

                    imageUrl: "/assets/images/scsdImages/WithBG/B.png",

                    expMainImgText:
                      "B stands for Border irregularity. Normal moles usually have a regular and clearly defined border. A mole with Border irregularity is a sign of potential melanoma. There are several ways that a mole can have an irregular border, including being: notched or scol/oped, ragged, or poorly defined.",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/B.png",

                    expansionPoints: [
                      {
                        text: "Border irregularity means that a mole has a border that is not clearly defined.",

                        imageUrl: "assets/icons/scan.png",
                      },
                    ],
                  },

                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Color Variation",

                    imageUrl: "/assets/images/scsdImages/WithBG/C.png",

                    expMainImgText:
                      "C stands for Color variation. Normal moles are usually a single color (often skin-toned or a shade of brown, black, or pink). It is also common for the edge of a normal mole to be slightly lighter in color than the rest of the mole. A mole that has significant Color variation is a sign of potential melanoma.",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/C.png",

                    expansionPoints: [
                      {
                        text: "Color variation means that a mole has multiple colors or more than one shade of a single color. ",

                        imageUrl: "assets/icons/scan.png",
                      },
                    ],
                  },

                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Diameter larger that 6mm",

                    imageUrl: "/assets/images/scsdImages/WithBG/D.png",

                    expMainImgText:
                      "D stands for Diameter larger than 6 mm. Normal moles are usually not larger than the diameter (the width) of a pencil eraser, which is 6 mm (aboutÂ¼ inch). A mole that has a Diameter larger than 6 mm is a sign of potential melanoma.",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/D.png",

                    expansionPoints: [
                      {
                        text: "To measure the diameter of a mole, measure its width at its widest point (which could be from top to bottom, from side to side, or at an angle).",

                        imageUrl: "assets/icons/scan.png",
                      },
                    ],
                  },

                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Evolving",

                    imageUrl: "/assets/images/scsdImages/WithBG/E.png",

                    expMainImgText:
                      "E stands for Evolving. Normal moles usually do not change over time. A mole that is Evolving or changing is a sign of potential melanoma",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/E.png",

                    expansionPoints: [
                      {
                        text: "There are several ways that a mole can evolve, including: Changing shape, Changing color, Changing size, Changing surface, such as becoming lumpy, scaly, or crusty Changing symptoms, such as bleeding or becoming itchy or tender",

                        imageUrl: "assets/icons/scan.png",
                      },
                    ],
                  },

                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Funny Looking",

                    imageUrl: "/assets/images/scsdImages/WithBG/F.png",

                    expMainImgText:
                      'F stands for Funny Looking. The moles on your body usually look quite similar to each other. A mole that is Funny looking compared to your other moles is a sign of potential melanoma. This is also sometimes called the "ugly duckling" sign. Be on the lookout for moles that look very different from the other moles on your body.',

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/F.png",

                    expansionPoints: [
                      {
                        text: "Funny looking means that a mole looks different from other moles on your body. ",

                        imageUrl: "assets/icons/scan.png",
                      },
                    ],
                  },
                ],
              },

              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 40,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "fnfmcq",

                    data: [
                      {
                        id: 1,

                        title: "ABCDEFs identifying challenge",

                        description:
                          "Practice identifying Asymmetrical moles! Remember, a mole is Asymmetrical when one half of the mole is a very different shape from the other half. ",

                        question: "",

                        questionImages: "assets/images/scsdImages/mc/A.png",

                        answer: "Asymmetrical",

                        answerType: "",

                        mcqButton: ["Symmetrical", "Asymmetrical"],
                      },

                      {
                        id: 2,

                        title: "ABCDEFs identifying challenge",

                        description:
                          "Practice identifying moles with Border irregularity! Remember, a mole with Border irregularity may have a border that is notched or scalloped, ragged, or poorly defined. ",

                        question: "",

                        questionImages: "assets/images/scsdImages/mc/B.png",

                        answer: "Normal Border",

                        answerType: "",

                        mcqButton: ["Normal Border", "Irregular Border"],
                      },

                      {
                        id: 3,

                        title: "ABCDEFs identifying challenge",

                        description:
                          "Practice identifying moles with Color variation! Remember, a mole has Color variation ifit has multiple colors or more than one shade of a single color.",

                        question: "",

                        questionImages: "assets/images/scsdImages/mc/C.png",

                        answer: "Color Variation",

                        answerType: "",

                        mcqButton: ["No Color Variation", "Color Variation"],
                      },

                      {
                        id: 4,

                        title: "ABCDEFs identifying challenge",

                        description:
                          "Practice identifying moles with a Diameter larger than 6 mm! Remember, to check whether a mole has a Diameter larger than 6 mm, measure its width at its widest point. ",

                        question: "",

                        questionImages: "assets/images/scsdImages/mc/D.png",

                        answer: "Diameter 6 mm or smaller",

                        answerType: "",

                        mcqButton: [
                          "Diameter 6 mm or smaller",
                          "Diameter larger than 6 mm",
                        ],
                      },

                      {
                        id: 5,

                        title: "ABCDEFs identifying challenge",

                        description:
                          "Practice identifying Evolving moles! Remember, a mole is Evolving if it is a new mole or if there are changes to an existing mole (in terms of its shape, color, size, surface, or symptoms). ",

                        question: "",

                        questionImages: "assets/images/scsdImages/mc/E.png",

                        answer: "Evolving",

                        answerType: "",

                        mcqButton: ["Not Evolving", "Evolving"],
                      },

                      {
                        id: 6,

                        title: "ABCDEFs identifying challenge",

                        description:
                          "Practice identifying Funny looking moles! Remember, a mole is Funny looking ifit looks very different from the other moles on your body ",

                        question: "",

                        questionImages: "assets/images/scsdImages/mc/F.png",

                        answer: "Not Funny Looking",

                        answerType: "",

                        mcqButton: ["Not Funny Looking", "Funny Looking"],
                      },
                    ],

                    screens: null,
                  },
                ],
              },

              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 40,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "fnfmcq",

                    data: [
                      {
                        id: 1,

                        title: "Review the ABCDEFs of Melanoma",

                        description:
                          "For each of the ABCDEFs, choose the characteristic that relates to the letter.",

                        question: "Normal spots are usually multicoloured.",

                        questionImages: "assets/images/scsdImages/A.png",

                        answer: "Asymmetrical",

                        answerType: "A is for Asymmetrical",

                        mcqButton: [
                          "Actinic",
                          "Anemic",
                          "Asymmetrical",
                          "Aligned",
                        ],
                      },

                      {
                        id: 2,

                        title: "Review the ABCDEFs of Melanoma",

                        description:
                          "For each of the ABCDEFs, choose the characteristic that relates to the letter.",

                        question:
                          "Normal spots usually have a round or oval shape.",

                        questionImages: "assets/images/scsdImages/B.png",

                        answer: "Border irregularity",

                        answerType: "B is for Border irregularity",

                        mcqButton: [
                          "Blurry",
                          "Brown color",
                          "Bulging",
                          "Border irregularity",
                        ],
                      },
                    ],

                    screens: null,
                  },
                ],
              },

              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 50,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Jump to Activity",

                    isDisabled: false,
                  },
                ],

                contents: [
                  {
                    type: "mid-heading-main-small",

                    text: "Identify Suspicious Spots!",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "Description-Summary",

                    text: "Now it's time to put your knowledge of the ABCDEF guide to melanoma into practice.",
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "Description-Summary",

                    text: "The ABCDEF guide helps you to identify suspicious spots.",
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "Description-Summary",

                    text: "In this activity, you will be shown images of spots. For each image, indicate whether the spot is suspicious or not suspicious.",
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },
                ],
              },

              {
                title: "Monthly Skin Self-Checks",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 60,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "fnfmcq",

                    data: [
                      {
                        id: 1,

                        title:
                          "Asymmetrical, Border Irregularity and Color Variation",

                        description:
                          "Look at the image. Based off the ABCDEF guide do you think the spot's suspicious or not supsicious?",

                        question: "",

                        questionImages: "assets/images/SusSpot.png",

                        answer: "Suspicious",

                        answerType:
                          "This spot is suspicious. It meets the warning signs for Asymmetrical. Border irregularity, and Color variation.",

                        mcqButton: ["Not Suspicious", "Suspicious"],
                      },

                      {
                        id: 2,

                        title: "Diameter Larger than 6mm",

                        description:
                          "Look at the image. Based off the ABCDEF guide do you think the spot's suspicious or not supsicious?",

                        question: "",

                        questionImages: "assets/images/DiaSpot.png",

                        answer: "Suspicious",

                        answerType:
                          "This spot is suspicious. It meets the warning signs as the spot is greater than 6mm.",

                        mcqButton: ["Not Suspicious", "Suspicious"],
                      },
                    ],

                    screens: null,
                  },
                ],
              },

              {
                title: "How to Self-Check!",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 70,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Next",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "side-sub-heading",

                    text: "A skin self-check is when you check all your body parts or other marks on your skin that may be skin cancer.",

                    screens: null,
                  },

                  {
                    type: "gap",

                    height: "16px",
                  },

                  {
                    type: "mcq",

                    screens: null,

                    question:
                      "Click the statement that best describes your experience with skin self-checks to learn more:",

                    options: [
                      {
                        text: "I have never done a skin self-check",

                        isCorrect: true,

                        hints: [
                          "<strong>That's OK</strong>. This is a good time to learn about skin self-checks. <strong>mySmartSkin will help you start a routine of monthly skin self-checks.</strong>",
                        ],
                      },

                      {
                        text: "I have done it but not within the past month",

                        isCorrect: false,

                        hints: [
                          "<strong>You're on the right track.</strong> Many people find it hard to check their body for skin cancer on a regular basis, but you can build on the experience you already have. <strong>mySmartSkin will help you to get into a routine of monthly skin self checks.</strong>",
                        ],
                      },

                      {
                        text: "I have done a skin self-check in the past month",

                        isCorrect: false,

                        hints: [
                          "<strong>That's great!</strong> mySmartSkin will help you <strong>continue and improve your regular skin self-check routine.</strong>",
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                title: "How to Self-Check!",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 80,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Next",

                    isDisabled: false,
                  },
                ],

                contents: [
                  {
                    type: "side-heading",

                    text: "When You Find a Suspicious Mole",

                    screens: null,
                  },
                  {
                    type: "gap",

                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",

                    text: "If you identify a mole or other mark on the skin that you are concerned about, visit the doctor who usually conducts your full body skin examinations. If you already have an upcoming appointment, show your doctor the mole or mark on your skin that you are concerned about. The doctor can also check the rest of your body for any areas of concern. Identifying a potential problem early will make it easier to treat.",

                    screens: null,
                  },
                  {
                    type: "gap",

                    height: "16px",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sarah",

                    imageUrl: "/assets/images/sarah.png",

                    description:
                      "I noticed a new growth on my skin during a skin self-check one month. I called my sister and she suggested that I see my doctor. I'm glad I followed her advice because it turned out to be a melanoma. Fortunately, we caught it at an early stage, so my doctor was able to remove it without any complications. That experience reminded me how important it is for me to keep doing my monthly skin self-checks.",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Bill",

                    imageUrl: "/assets/images/Judith.png",

                    description:
                      "I noticed a new growth on my skin during a skin self-check one month. I called my sister and she suggested that I see my doctor. I'm glad I followed her advice because it turned out to be a melanoma. Fortunately, we caught it at an early stage, so my doctor was able to remove it without any complications. That experience reminded me how important it is for me to keep doing my monthly skin self-checks.",
                  },
                ],
              },

              {
                title: "Great Job!",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 100,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "assets/images/prev.png",

                    text: "Back to Cores",

                    isDisabled: false,
                  },

                  {
                    image: "assets/images/next.png",

                    text: "Next Chapter ",

                    isDisabled: false,
                  },
                ],

                contents: [
                  {
                    type: "mid-img",

                    screens: null,

                    imageUrl: "assets/images/ChapterComplete.png",
                  },

                  {
                    type: "mid-heading-main",

                    screens: null,

                    text: "Chapter 3 is Completed!",
                  },

                  {
                    type: "mid-heading-sub1",

                    screens: null,

                    text: "You've earned a badge!",
                  },

                  {
                    type: "mid-heading-sub2",

                    screens: null,

                    text: "Keep going to learn more!",
                  },

                  {
                    type: "gap",

                    height: "32px",
                  },

                  {
                    type: "core-content-completed-img-text",

                    screens: null,

                    title: "You've Completed",

                    description:
                      "Understanding the purpose of monthly skin self-checks.",

                    isImgLeft: false,
                  },

                  {
                    type: "gap",

                    height: "24px",
                  },

                  {
                    type: "core-content-completed-img-text",

                    screens: null,

                    title: "In Next Chapter",

                    description: "We will see when to have a visit your Doctor",

                    isImgLeft: true,
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 4
          {
            chapterNo: "Chapter 4 • 3mins",
            title: "Visit Your Doctor",
            progress: "locked",
            screens: [
              {
                title: "Purpose",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Introduction",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "When you visit your doctor for your regular full body skin examinations, your doctor will check your skin for any suspicious moles or other growths on your skin that could be melanoma, basal cell carcinoma, or squamous cell carcinoma. You have learned more about what melanoma is, how it starts, and what it looks like.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                ],
              },
              {
                title: "Physician Recommendation",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 90,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Play the video by clicking on the play button on the bottom left of the video.",
                    screens: null,
                  },
                  {
                    type: "video-player",
                    coreVideoUrl: "https://vimeo.com/378460253",
                    coreVideoText: "Physician Recommendation",
                    screens: null,
                  },
                ],
              },
              {
                title: "Great Job!",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter ",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    screens: null,
                    imageUrl: "assets/images/ChapterComplete.png",
                  },
                  {
                    type: "mid-heading-main",
                    screens: null,
                    text: "Chapter 4 is Completed!",
                  },
                  {
                    type: "mid-heading-sub1",
                    screens: null,
                    text: "You’ve earned a badge!",
                  },
                  {
                    type: "mid-heading-sub2",
                    screens: null,
                    text: "Keep going to learn more!",
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "You’ve Completed",
                    description:
                      "Understanding the purpose of regular doctor visits.",
                    isImgLeft: false,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "In Next Chapter",
                    description: "We will see some facts about skin checks",
                    isImgLeft: true,
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 5
          {
            chapterNo: "Chapter 5 • 3mins",
            title: "Did You Know?",
            progress: "locked",
            screens: [
              {
                title: "Facts",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 90,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "content-accordian",
                    description: "",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc:
                      "There is also a rare type of melanoma, called acral lentiginous melanoma, that appears under the fingernails or toenails, on the soles of the feet, or on the palms of the hand",
                    accPoints: [],
                    bottomDesc: "",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "content-accordian",
                    description:
                      "Damage to the skin is caused by the <strong>sun’s ultraviolet (UV) rays</strong>, which you can’t see or feel. UV rays are different from the light from the sun that you see (visible light) or its warmth that you feel (infrared radiation). The sun’s UV rays can still be very strong even when it is cloudy or cool outside. That is why it is important to be sun-safe and <strong>protect your skin year round</strong> and in <strong>any weather conditions.</strong>",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc:
                      "A number of factors can <strong>make the sun’s UV rays even stronger</strong>, including:",
                    accPoints: [
                      "<strong>The time of day:</strong> the sun’s UV rays are strongest from 10 a.m. to 4 p.m.",
                      "<strong>Reflective surfaces</strong> such as water, sand, or snow",
                      "Being at a <strong>higher altitude<strong>",
                      "Being <strong>closer to the equator</strong>",
                    ],
                    bottomDesc:
                      "Understanding UV is important to keep your skin safe. Even though a burn may heal, damage beneath the surface of the skin can <strong>never</strong> be repaired. However, it's never too late to protect your skin from further damage. Engaging in sun-safe behaviors will help you to <strong>reduce your risk of skin cancer</strong> and <strong>prevent skin aging.</strong>",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "content-accordian",
                    description: "",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc: "",
                    accPoints: [
                      "To provide maximum protection from the sun, combine your use of sunscreen with other sun-safe behaviors. ",
                    ],
                    bottomDesc: "",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "content-accordian",
                    description: "",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc: "",
                    accPoints: [
                      "A tanned appearance is actually a sign that your skin has been damaged by the sun. When skin is exposed to ultraviolet (UV) rays the DNA becomes damaged, and the tanned appearance you get is a result of the skin's DNA trying to repair itself.",
                    ],
                    bottomDesc: "",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "content-accordian",
                    description: "",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc: "",
                    accPoints: [
                      "Each year, indoor tanning causes around 400,000 cases of skin cancer in the United States",
                      "Every time you indoor tan you increase your risk of getting melanoma",
                      "Indoor tanning also increases the risk of other skin cancers, including basal cell carcinoma and squamous cell carcinoma;",
                      "Indoor tanning causes premature skin aging, such as wrinkles and age spots; ",
                      "Indoor tanning damages the texture of the skin;",
                      "Indoor tanning increases the risk of potentially blinding eye diseases ",
                      "Around 3,000 Americans each year go to emergency rooms with injuries caused by indoor tanning, including burns to the skin and eye damage",
                      "The UVA rays emitted by indoor tanning beds are significantly stronger than the sun's UVA rays",
                      "Tanning beds are manufactured to reduce the chance of sunburn, which can give people the false impression that they are not harming their skin",
                    ],
                    bottomDesc: "",
                    screens: null,
                  },
                ],
              },
              {
                title: "Great Job!",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter ",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    screens: null,
                    imageUrl: "assets/images/ChapterComplete.png",
                  },
                  {
                    type: "mid-heading-main",
                    screens: null,
                    text: "Chapter 5 is Completed!",
                  },
                  {
                    type: "mid-heading-sub1",
                    screens: null,
                    text: "You’ve earned a badge!",
                  },
                  {
                    type: "mid-heading-sub2",
                    screens: null,
                    text: "Keep going to learn more!",
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "You’ve Completed",
                    description: "Understanding some facts",
                    isImgLeft: false,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "In Next Chapter",
                    description:
                      "We will meet other's/Other's Stories/ Other's Experiences",
                    isImgLeft: true,
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 6
          {
            chapterNo: "Chapter 6 • 3mins",
            title: "Meet other's/Other's Stories/ Other's Experiences",
            progress: "locked",
            screens: [
              {
                title: "Meet others diagnosed with melanoma ",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Meet Others Diagnosed with Melanoma",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "These five people have been diagnosed with melanoma. You will be following their stories and experiences throughout mySmartSkin. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "image-text-bio-accordions",
                    screens: null,
                    contents: [
                      {
                        title: "Judith",
                        imageUrl: "/assets/images/people/Judith.png",
                        description:
                          "I was very surprised when I was diagnosed with melanoma two years ago. I didn't use a lot of sun protection when I was younger, but I thought I was doing a pretty good Job at protecting my skin more recently. I have been retired for 6 years, and I enjoy gardening and looking after my granddaughter. I am also a member of a community walking group",
                        bio: {
                          bioDesc:
                            "Judith is married with two children and one grandchild. She is a retired school teacher, and provides care for her eight year-old granddaughter theree afternoons a week.",
                          age: "71",
                          diagnosis: "Melanoma Stage II",
                          location: "Skin",
                          ageOfDiagnosis: "69",
                          skinType: "Very fair",
                          hair: "Blonde (now gray)",
                          eyes: "Green",
                        },
                      },
                      {
                        title: "Sarah",
                        imageUrl: "/assets/images/people/Sarah.png",
                        description:
                          "I was diagnosed 4 years ago with basal cell skin cancer on my nose. Before my diagnosis I had heard of skin cancer, but I thought it was something that only really happened later in life. My doctor told me that I was more at risk for skin cancer because of my foir skin and history of sunbathing and indoor tanning. After I was diagnosed with basal cell skin cancer, I stopped going to the tanning salon. Two years ago, however, I was diagnosed with stage O melanoma",
                        bio: {
                          bioDesc:
                            "Sarah is an office manager. She lives an active lifestyle, and enjoys running with her dog, Toby.",
                          age: "35",
                          diagnosis: "Melanoma Stage 0 & basal cell carcinoma",
                          location: "Upper back",
                          ageOfDiagnosis: "33",
                          skinType: "Fair",
                          hair: "Medium brown",
                          eyes: "Hazel",
                        },
                      },
                      {
                        title: "Sergio",
                        imageUrl: "/assets/images/people/Sergio.png",
                        description:
                          "Before my diagnosis, I rarely wore sunscreen because I didn't know that I needed to. I thought because I am Latino and don't burn easily in the sun, I didn't have to worry about skin cancer, and I never really paid attention to my moles. My physical therapist noticed something on my shoulder at an appointment for an old sports injury, and suggested I follow up with my doctor. I'm glad she did, because it turned out to be melanoma.",
                        bio: {
                          bioDesc:
                            "Sergio  is an architect. He enjoys playing tennis and coaching soccer for his local YMCA",
                          age: "61",
                          diagnosis: "Melanoma Stage III",
                          location: "Shoulder",
                          ageOfDiagnosis: "60",
                          skinType: "Olive",
                          hair: "Dark brown(now gray)",
                          eyes: "Dark Brown",
                        },
                      },
                      {
                        title: "Robert",
                        imageUrl: "/assets/images/people/Robert.png",
                        description:
                          "I work outside at a construction site, so I'm out in the sun a lot. To be honest, skin cancer was not something I thought about. My ex-wife used to tell me to wear sunscreen, but none of the guys at work really bothered. 1,Je wear hard hats, work gloves, and special glasses for protection, and that always felt like enough",
                        bio: {
                          bioDesc:
                            "Robert is divorced and has two children in high school. He has been a construction worker for many years.",
                          age: "55",
                          diagnosis: "Melanoma Stage II",
                          location: "Chest",
                          ageOfDiagnosis: "53",
                          skinType: "Fair to medium",
                          hair: "Dark brown",
                          eyes: "Blue-gray",
                        },
                      },
                      {
                        title: "Bill",
                        imageUrl: "/assets/images/people/Bill.png",
                        description:
                          "I have very fair skin and have had quite a few sunburns over the years. I always tried to use sun protection, particularly as I got older and started seeing sun spots on my skin. A few years ago, I had a spot on my ear that kept itching. I didn't think much of it until my wife noticed and told me to see my doctor. My doctor told me it was stage III melanoma. The cancer might have been caught sooner if I had been checking my skin regularly",
                        bio: {
                          bioDesc:
                            "Bill is marrried and has three children and seven grandchildren. He is retired air traffic control worker. Bill and his wife enjoy swimming in the ocean most mornings, and he has been an avid golfer for many years.",
                          age: "72",
                          diagnosis: "Melanoma Stage III",
                          location: "Ear",
                          ageOfDiagnosis: "68",
                          skinType: "Very Fair",
                          hair: "Red (now gray)",
                          eyes: "Blue",
                        },
                      },
                    ],
                  },
                ],
              },
              {
                title: "Physician Skin Examinations",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Physician Skin Examinations",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "It is important that people diagnosed with melanoma visit a doctor periodically for a <strong>full body skin examination.</strong> Your doctor will tell you how often you need to have this examination.<strong>Contact your doctor</strong> if you don't have your next follow-up appointment scheduled.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Visiting a doctor for a periodic skin examination will help to <strong>detect potential problems</strong> and get started treating those problems as early as possible. It will also help you <strong>learn about your own skin</strong> and any particular moles or other growths that you should closely monitor. You can also show your doctor any moles or growths of concern that you identify during your own skin self-checks.",
                    screens: null,
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Robert",

                    imageUrl: "/assets/images/people/Robert.png",

                    description:
                      "Although I'm sometimes a little nervous before having my full body skin examination, I'm always glad that I do it. My doctor has helped me realize that having my skin checked on a regular basis ensures that we can tackle any problems in a timely manner.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sarah",

                    imageUrl: "/assets/images/people/Sarah.png",

                    description:
                      "Visiting my doctor for my full body skin examinations gives me peace of mind and helps me feel in control of my health",
                  },
                ],
              },
              {
                title: "Physician Skin Examinations",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 90,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Play the video by clicking on the play button on the bottom left of the video.",
                    screens: null,
                  },
                  {
                    type: "video-player",
                    coreVideoUrl: "https://vimeo.com/378460253",
                    coreVideoText:
                      "Why having a regular physician skin exam is important, a doctor's perspective.",
                    screens: null,
                  },
                ],
              },
              {
                title: "Monthly self-checks",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Why do others believe monthly self checks are important?",
                    screens: null,
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Robert",

                    imageUrl: "/assets/images/people/Robert.png",

                    description:
                      "I knew skin self-checks were important, but it all seemed too hard at first and I sometimes forgot to do my checks. But once I got into a monthly routine of checking my skin, it really helped me get to know my moles and other marks on my skin. It made it so much easier to notice changes.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sergio",

                    imageUrl: "/assets/images/people/Sergio.png",

                    description:
                      "My doctor suggested that I do a skin self-check each month, so that I can help to find problems at an early age. He said that finding mething earlier makes it easier to treat.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Bill",

                    imageUrl: "/assets/images/people/Bill.png",

                    description:
                      "My doctor checks my skin periodically. She told me that an important part of my follow-up care is for me to do my own monthly skin self-checks. That way, we work together as a team and can find any potential problems as soon as possible.",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Judith",

                    imageUrl: "/assets/images/people/Judith.png",

                    description:
                      "My doctor recommended monthly skin self-checks.I found that doing them helped me feel more in control of my health. It gave me reassurance that I wasn't missing any suspicious areas on my skin.",
                  },
                ],
              },
              {
                title: "Monthly self-checks",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Why do others believe monthly self checks are important?",
                    screens: null,
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Robert",

                    imageUrl: "/assets/images/people/Robert.png",

                    description:
                      "I knew skin self-checks were important, but it all seemed too hard at first and I sometimes forgot to do my checks. But once I got into a monthly routine of checking my skin, it really helped me get to know my moles and other marks on my skin. It made it so much easier to notice changes.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sergio",

                    imageUrl: "/assets/images/people/Sergio.png",

                    description:
                      "My doctor suggested that I do a skin self-check each month, so that I can help to find problems at an early age. He said that finding mething earlier makes it easier to treat.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Bill",

                    imageUrl: "/assets/images/people/Bill.png",

                    description:
                      "My doctor checks my skin periodically. She told me that an important part of my follow-up care is for me to do my own monthly skin self-checks. That way, we work together as a team and can find any potential problems as soon as possible.",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Judith",

                    imageUrl: "/assets/images/people/Judith.png",

                    description:
                      "My doctor recommended monthly skin self-checks.I found that doing them helped me feel more in control of my health. It gave me reassurance that I wasn't missing any suspicious areas on my skin.",
                  },
                ],
              },
              {
                title: "Confidence Identifying Moles",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Other’s confidence identifying moles",
                    screens: null,
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sergio",

                    imageUrl: "/assets/images/people/Sergio.png",

                    description:
                      "To be honest, I found the ABCDEF information a little overwhelming at first. I never thought I would be able to keep track of everything to watch for. But, I reviewed the material a few times and went slowly with my first skin self-check. And now I know what an abnormal mole looks like and find it much easier to do my monthly skin self-checks.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sarah",

                    imageUrl: "/assets/images/people/Sarah.png",

                    description:
                      "My first skin self-check took the longest. I have a lot of moles, so I was woffied that checking my skin would be too hard. But now I know what to look for and have done it so many times, I feel much more comfortable checking my skin.",
                  },
                ],
              },
              {
                title: "Suspicious Moles",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "What did others do when they found a suspicious mole?",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "If you identify a mole or other mark on the skin that you are concerned about, visit the doctor who usually conducts your full body skin examinations. If you already have an upcoming appointment, show your doctor the mole or mark on your skin that you are concerned about. The doctor can also check the rest of your body for any areas of concern. Identifying a potential problem early will make it easier to treat.",
                    screens: null,
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sarah",

                    imageUrl: "/assets/images/people/Sarah.png",

                    description:
                      "I noticed a new growth on my skin during a skin self-check one month. I called my sister and she suggested that I see my doctor. I'm glad I followed her advice because it turned out to be a melanoma. Fortunately, we caught it at an early stage, so my doctor was able to remove it without any complications. That experience reminded me how important it is for me to keep doing my monthly skin self-checks.",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Bill",

                    imageUrl: "/assets/images/people/Bill.png",

                    description:
                      "My wife and I noticed a suspicious mole during a skin self-check, and she encouraged me to go and see my doctor. Even though it didn't turn out to be cancer, my doctor said that I did the right thing by coming in to get it checked out. She also said that it was good that I was checking my skin every month and monitoring my moles closely.",
                  },
                ],
              },
              {
                title: "Great Job!",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter ",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    screens: null,
                    imageUrl: "assets/images/ChapterComplete.png",
                  },
                  {
                    type: "mid-heading-main",
                    screens: null,
                    text: "Chapter 6 is Completed!",
                  },
                  {
                    type: "mid-heading-sub1",
                    screens: null,
                    text: "You’ve earned a badge!",
                  },
                  {
                    type: "mid-heading-sub2",
                    screens: null,
                    text: "Keep going to learn more!",
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "You’ve Completed",
                    description: "Meeting others their stories & experiences",
                    isImgLeft: false,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    screens: null,
                    title: "In Next Chapter",
                    description: "We will do some activity",
                    isImgLeft: true,
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 7
          {
            chapterNo: "Activity",
            title: "Skin Self-Check Goal",
            progress: "done",
            screens: [
              {
                title: "Set Self-Check Goals",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: true,
                },
                footerButtons: [],
                contents: [
                  {
                    type: "heading-normal",
                    text: "We recommend being familiar with the Core 1 content before setting a goal.",
                    screens: null,
                  },
                  {
                    type: "overview-goal",
                    title: "I'm familiar with Core 1, let's set a goal:",
                    imageUrl: "/assets/images/welcome-goal.png",
                    points: [
                      "Understand your <strong>motivation</strong>.",
                      "Choose your <strong>goal</strong> and set <strong>strategies</strong>.",
                      "Identify <strong>barriers</strong> and view <strong>solutions</strong> to overcome them.",
                    ],
                    screens: null,
                  },
                  {
                    type: "learn-more-img-text",
                    text: "No, I am not ready to set a goal.",
                    screens: null,
                    imageUrl: "/assets/images/learn-more.png",
                  },
                ],
              },
              {
                title: "Set Self-Check Goals",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 10,
                  isActivity: true,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "img-text-col",
                    imageUrl: "assets/images/StartChecking.png",
                    text: "Before you set a goal, <strong>how important are skin self-checks to you?</strong>",
                    screens: null,
                  },
                  {
                    type: "side-heading-small",
                    text: "", //How important are skin self-checks to you?
                    screens: null,
                  },
                  {
                    type: "five-star-rating",
                    descriptions: [
                      "Not Important",
                      "Somewhat Important",
                      "Moderately Important",
                      "Important",
                      "Very Important",
                    ],
                    screens: null,
                  },
                  {
                    type: "mcq-multiple-check-hidden",
                    screens: null,
                    question: "Why do you want to perform skin-self checks?",
                    desc: "Please select <strong>one or more</strong> reason below.",
                    options: [
                      "Be a role model for others",
                      "Make my family less worried",
                      "Feel in control of my health",
                      "Find spots early that may be or may become cancerous",
                      "Have peace of mind for myself and my family about my health",
                    ],
                  },
                ],
              },
              {
                title: "Set Self-Check Goals",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 40,
                  isActivity: true,
                },
                footerButtons: [],
                contents: [
                  {
                    type: "task-selection",
                    screens: null,
                    question:
                      "<strong>Choose a goal</strong> to help you with your skin self-checks.",
                    options: [
                      {
                        imageUrl: "assets/images/exposure.png",
                        title: "Start Doing a Skin Self-Check ",
                        subtitle: "(if you don’t do one already)",
                        desc: "Select the task you want to work on",
                        barrierIndexes: [0, 1, 2, 3, 4, 5],
                        options: [
                          {
                            imageUrl: "assets/icons/spanner.png",
                            text: "Gather the right tools",
                            options: {
                              mainImgUrl: "assets/images/exposure.png",
                              mainTitle: "Start Doing a Skin Self-Check",
                              mainDesc: "Gather the right tools",
                              mainDesc2:
                                "<strong>Select the tools</strong> that you use or would like to use to help you with your skin self-check.",
                              title: "Tools",
                              imageUrl: "assets/icons/SpannerOutline.png",
                              showBottomCheckBox: true,
                              options: [
                                {
                                  imageUrl: "assets/images/HandMirror.png",
                                  title: "Hand Mirror",
                                  desc: "to view hard to reach places",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption:
                                    "gathering the right tools",
                                  // summaryTitle: "Gather the right tools",
                                  // summaryDesc: "<strong>Hand Mirror</strong>",
                                  // summarySubDesc: "to view hard to reach places"
                                  summaryFutureOptions: [
                                    "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Notebook.png",
                                  title: "Notebook",
                                  desc: "to record suspicious spots",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption:
                                    "gathering the right tools",
                                  summaryFutureOptions: [
                                    "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/CellPhone.png",
                                  title: "Cell Phone",
                                  desc: "to take pictures of suspicious spots",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption:
                                    "gathering the right tools",
                                  summaryFutureOptions: [
                                    "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Ruler.png",
                                  title: "Plastic Ruler",
                                  desc: "to measure your spots",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption:
                                    "gathering the right tools",
                                  summaryFutureOptions: [
                                    "Work on <strong>collecting the remaining tools</strong> and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                          {
                            imageUrl: "assets/icons/scan.png",
                            text: "Find someone to help",
                            options: {
                              mainImgUrl: "assets/images/exposure.png",
                              mainTitle: "Start Doing a Skin Self-Check",
                              mainDesc: "Find someone to help",
                              mainDesc2:
                                "<strong>Select which option</strong> you will use to find someone to help.",
                              showBottomCheckBox: false,
                              options: [
                                {
                                  imageUrl: "assets/images/Family.png",
                                  title: "Ask a <strong>family member</strong>",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption: "finding someone to help",
                                  summaryTitle: "Find Someone to Help",
                                  summaryDesc: "Ask a family member",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Ask a family to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Friends.png",
                                  title: "Ask a friend",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption: "finding someone to help",
                                  summaryTitle: "Find Someone to Help",
                                  summaryDesc: "Ask a <strong>friend</strong>",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Ask a friend to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                          {
                            imageUrl: "assets/icons/hand.png",
                            text: "Set reminders",
                            options: {
                              mainImgUrl: "assets/images/exposure.png",
                              mainTitle: "Start Doing a Skin Self-Check",
                              mainDesc: "Set reminders",
                              mainDesc2:
                                "<strong>Select the option</strong> you will use to remind yourself. ",
                              showBottomCheckBox: false,
                              options: [
                                {
                                  imageUrl: "assets/images/Reminder.png",
                                  title: "Have MSS remind you",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption: "Set reminders",
                                  summaryTitle: "Set reminders",
                                  summaryDesc: "Have MSS remind you",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Use the MSS reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/CellPhone.png",
                                  title: "Set phone reminders",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption: "Set reminders",
                                  summaryTitle: "Set reminders",
                                  summaryDesc: "Set phone reminders",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Use phone reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Calander.png",
                                  title: "Mark a date on your calendar",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Start Doing a skin self-check",
                                  summaryGoalOption: "Set reminders",
                                  summaryTitle: "Set reminders",
                                  summaryDesc: "Mark a date on your calendar ",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Write the date in your calendar</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        imageUrl: "assets/images/todo.png",
                        title: "Check All Parts of My Body",
                        subtitle: "(if you want to do a more thorough check)",
                        desc: "Select the task you want to work on",
                        barrierIndexes: [3, 2, 4, 5],
                        options: [
                          {
                            imageUrl: "assets/icons/ptssc.png",
                            text: "Perform thorough skin self-checks",
                            options: {
                              mainImgUrl: "assets/images/todo.png",
                              mainTitle: "Check All Parts of My Body",

                              mainDesc: "Perform thorough skin self-checks",
                              mainDesc2:
                                "<strong>Select the option</strong> you will use to make sure you are performing thorough skin self-checks.",
                              showBottomCheckBox: false,
                              options: [
                                {
                                  imageUrl: "assets/images/Body Map.png",
                                  title: "<strong>MSS Body Map</strong>",
                                  desc: "to make sure I check all body parts",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Check All Parts of My Body",
                                  summaryGoalOption:
                                    "performing thorough skin self-check",
                                  summaryTitle: "Thorough skin self-checks",
                                  summaryDesc: "<strong>MSS Body Map</strong>",
                                  summarySubDesc:
                                    "to make sure I check all body parts",
                                  summaryFutureOptions: [
                                    "<strong>Use the MSS body map </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Checklist.png",
                                  title:
                                    "<span class='text-underline'> Separate Checklist</span>",
                                  desc: "of body parts",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Check All Parts of My Body",
                                  summaryGoalOption:
                                    "performing thorough skin self-check",
                                  summaryTitle: "Thorough skin self-checks",
                                  summaryDesc:
                                    "<span class='text-underline'> Separate Checklist</span>",
                                  summarySubDesc: "of body parts",
                                  summaryFutureOptions: [
                                    "<strong>Use a separate checklist </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                          {
                            imageUrl: "assets/icons/cfma.png",
                            text: "Check frequently missed areas",
                            options: {
                              mainImgUrl: "assets/images/todo.png",
                              mainTitle: "Check All Parts of My Body",
                              mainDesc: "Check frequently missed areas",
                              mainDesc2:
                                "<strong>Select the option</strong> you will use to make sure you check frequently missed areas.",
                              showBottomCheckBox: false,
                              options: [
                                {
                                  imageUrl: "assets/images/Body Map.png",
                                  title: "MSS Body Map",
                                  desc: "to make sure I check all body parts",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Check All Parts of My Body",
                                  summaryGoalOption:
                                    "checking frequently missed areas",
                                  summaryTitle: "Check Missed Areas",
                                  summaryDesc: "<strong>MSS Body Map</strong>",
                                  summarySubDesc:
                                    "to make sure I check all body parts",
                                  summaryFutureOptions: [
                                    "<strong>Use the MSS body map </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Checklist.png",
                                  title:
                                    "<span class='text-underline'> Separate Checklist</span>",
                                  desc: "of body parts",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Check All Parts of My Body",
                                  summaryGoalOption:
                                    "checking frequently missed areas",
                                  summaryTitle: "Check Missed Areas",
                                  summaryDesc:
                                    "<span class='text-underline'> Separate Checklist</span>",
                                  summarySubDesc: "of body parts",
                                  summaryFutureOptions: [
                                    "<strong>Use a separate checklist </strong>to check all body parts and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                      {
                        imageUrl: "assets/images/help.png",
                        title: "Make Skin Self-Checks a Habit",
                        subtitle: "(if you don’t do one regularly)",
                        desc: "Select the task you want to work on",
                        barrierIndexes: [2, 4, 5],
                        options: [
                          {
                            imageUrl: "assets/icons/ClockCircleFill.png",
                            text: "Set aside time each month",
                            options: {
                              mainImgUrl: "assets/images/help.png",
                              mainTitle: "Make Skin Self-Checks a Habit",
                              mainDesc: "Set aside time each month",
                              mainDesc2:
                                "<strong>Select the option</strong> you will use to help set aside time each month. ",
                              showBottomCheckBox: false,
                              options: [
                                {
                                  imageUrl: "assets/images/Reserve a time.png",
                                  title: "Reserve a day or time",
                                  desc: "such as in the morning on the first Saturday of every month",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make Skin Self-Checks a Habit",
                                  summaryGoalOption:
                                    "setting aside time monthly",
                                  summaryTitle: "Set Aside Time Monthly",
                                  summaryDesc: "Reserve a day or time",
                                  summarySubDesc:
                                    "such as in the morning on the first Saturday of every month",
                                  summaryFutureOptions: [
                                    "<strong>Reserve a certain day and time</strong> for your monthly skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Block time.png",
                                  title: "Block off time",
                                  desc: "In your planner or diary",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make skin self-checks a Habit",
                                  summaryGoalOption:
                                    "setting aside time monthly",
                                  summaryTitle: "Set Aside Time Monthly",
                                  summaryDesc: "Block off time",
                                  summarySubDesc: "In your planner or diary",
                                  summaryFutureOptions: [
                                    "<strong>Block off the time</strong> for your monthly skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                          {
                            imageUrl: "assets/icons/CalendarFilled.png",
                            text: "Set a monthly reminder",
                            options: {
                              mainImgUrl: "assets/images/help.png",
                              mainTitle: "Make Skin Self-Checks a Habit",
                              mainDesc: "Set a monthly reminder",
                              mainDesc2:
                                "<strong>Select the option</strong> you will use to remind yourself to complete your skin self-check each month.",
                              showBottomCheckBox: false,
                              options: [
                                {
                                  imageUrl: "assets/images/Reminder.png",
                                  title: "Have MSS remind you",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make skin self-checks a Habit",
                                  summaryGoalOption: "Set a monthly reminder ",
                                  summaryTitle: "Set a monthly reminder ",
                                  summaryDesc: "Have MSS remind you",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Use the MSS reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/CellPhone.png",
                                  title: "Set phone reminders",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make skin self-checks a Habit",
                                  summaryGoalOption: "Set a monthly reminder",
                                  summaryTitle: "Set a monthly reminder",
                                  summaryDesc: "Set phone reminders",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Use phone reminders</strong> to remind yourself and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Calander.png",
                                  title: "Mark a date on your calendar ",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make skin self-checks a Habit",
                                  summaryGoalOption: "Set a monthly reminder",
                                  summaryTitle: "Set a monthly reminder",
                                  summaryDesc: "Mark a date on your calendar",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Write the date in your calendar</strong> to remind yourself and keep track of your progress in  <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                          {
                            imageUrl: "assets/icons/UserFilled.png",
                            text: "Find someone to hold me accountable ",
                            options: {
                              mainImgUrl: "assets/images/help.png",
                              mainTitle: "Make Skin Self-Checks a Habit",
                              mainDesc: "Find someone to hold me accountable",
                              mainDesc2:
                                "<strong>Select which option</strong> you will use to find someone to hold you accountable for completing a skin self-check each month.",
                              showBottomCheckBox: false,
                              options: [
                                // {
                                //   imageUrl: "assets/images/Accontable.png",
                                //   title:
                                //     "<strong>Select which option</strong> you will use to find someone to hold you accountable for completing a skin self-check each month.",
                                //   desc: "",
                                //   isSelected: false,
                                //   isThere: false,
                                //   summaryGoal: "Make skin self-checks a Habit",
                                //   summaryGoalOption:
                                //     "Find someone to hold me accountable",
                                //   summaryTitle: "Find an Accountable Person",
                                //   summaryDesc: "Please work on to <strong>find an accountable person</strong> to hold you accountable for completing you skin self-check",
                                //   summarySubDesc: "",
                                //   summaryFutureOptions: [
                                //     "<strong>Find an accountable person</strong> to remind you for monthly skin self-check and keep track of your progress in  <span class='text-underline'>MyStuff.</span>",
                                //     "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                //     "Remind me to complete my skin self-check at a later date."

                                //   ]
                                // },
                                {
                                  imageUrl: "assets/images/Family.png",
                                  title: "Ask a <strong>family member</strong>",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make skin self-checks a Habit",
                                  summaryGoalOption: "finding someone to help",
                                  summaryTitle: "Find Someone to Help",
                                  summaryDesc: "Ask a family member",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Ask a family to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                                {
                                  imageUrl: "assets/images/Friends.png",
                                  title: "Ask a friend",
                                  desc: "",
                                  isSelected: false,
                                  isThere: false,
                                  summaryGoal: "Make skin self-checks a Habit",
                                  summaryGoalOption: "finding someone to help",
                                  summaryTitle: "Find Someone to Help",
                                  summaryDesc: "Ask a <strong>friend</strong>",
                                  summarySubDesc: "",
                                  summaryFutureOptions: [
                                    "<strong>Ask a friend to help</strong> you in skin self-check and keep track of your progress in <span class='text-underline'>MyStuff.</span>",
                                    "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.",
                                    "Remind me to complete my skin self-check at a later date.",
                                  ],
                                },
                              ],
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                title: "Set Self-Check Goals",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: true,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  // here
                  {
                    type: "materials-multiple-select",
                    screens: null,
                    mainImgUrl: "assets/images/todo.png",
                    mainTitle: "Start Doing a Skin Self-Check",
                    mainDesc: "Gather the right tools",
                    mainDesc2:
                      "<strong>Select the tools</strong> that you use or would like to use to help you with your skin self-check.",
                    title: "Tools",
                    imageUrl: "assets/icons/SpannerOutline.png",
                    options: [
                      {
                        imageUrl: "assets/images/HandMirror.png",
                        title: "Hand Mirror",
                        desc: "to view hard to reach places",
                        isSelected: false,
                        isThere: false,
                      },
                      {
                        imageUrl: "assets/images/Notebook.png",
                        title: "Notebook", //"<span class='text-underline'>Notebook</span>",
                        desc: "to record suspicious spots",
                        isSelected: false,
                        isThere: false,
                      },
                      {
                        imageUrl: "assets/images/CellPhone.png",
                        title: "Cell Phone",
                        desc: "to take pictures of suspicious spots",
                        isSelected: false,
                        isThere: false,
                      },
                      {
                        imageUrl: "assets/images/Ruler.png",
                        title: "Plastic Ruler",
                        desc: "to measure your spots",
                        isSelected: false,
                        isThere: false,
                      },
                    ],
                  },
                ],
              },

              {
                title: "Identify Your Barriers",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 80,
                  isActivity: true,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "img-text-col-8",
                    imageUrl: "assets/images/Barrier.png",
                    text: "<strong>Choose up to 2 barriers</strong> that would prevent you from <strong>doing a skin self-check</strong>.",
                    screens: null,
                  },
                  {
                    type: "barrier-mcq-multiple-check",
                    screens: null,
                    question: "Why do you want to perform skin-self checks?",
                    desc: "Please select <strong>one or more</strong> reasons below.",
                    // options: [
                    //   "<strong>I don’t feel confident</strong> performing a skin self-check.",
                    //   "I am <strong>anxious to finding</strong> an abnormal spot.",
                    //   "I <strong>don't have time</strong> for skin self-check.",
                    //   "It's hard to check so many spots. I <strong>need help</strong>.",
                    //   "I would <strong>prefer a Doctor</strong> my spot.",
                    //   "<strong>None of these</strong>",
                    // ],
                    options: [
                      {
                        text: "<strong>I don’t feel confident</strong> performing a skin self-check.",
                        solution: {
                          isViewed: false,
                          isChecked: true,
                          text: "Confidence",
                          desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                          pointsDesc:
                            "Which of the following <strong>strategies</strong> will you use?",
                          points: [
                            "Review <span class='text-underline'>mySmartSkin content</span> to increase knowledge and skills",
                            "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                            "<strong>Have someone</strong> to help me during my skin self-check",
                          ],
                          textSummary: "Confident",
                          pointsDescSummary: "To feel confident, I will",
                          pointsSummary: [
                            "Review MSS to increase knowledge and skill set",
                            "Print out pictures of abnormal spots to compare my spots",
                            "Have someone to help me during my skin self-check",
                          ],
                        },
                      },
                      {
                        text: "I am <strong>anxious</strong> to find an abnormal spot.",
                        solution: {
                          isViewed: false,
                          isChecked: true,
                          text: "Anxiety",
                          desc: "It is <strong>completely normal</strong> to be <strong>anxious</strong> about finding abnormal spots.",
                          pointsDesc:
                            "Which of the following <strong>strategies</strong> will you use?",
                          points: [
                            "<strong>Have someone</strong> to help me during my skin self-check",
                            "<strong>Schedule an appointment</strong> with <strong>my doctor</strong> to review any suspicious spots I find during my skin self-check",
                          ],
                          textSummary: "Anxious",
                          pointsDescSummary: "To feel less anxious, I will",
                          pointsSummary: [
                            "Ask for help with my skin self-check.",
                            "Schedule an appointment with my doctor to review the suspicious spots",
                          ],
                        },
                      },
                      {
                        text: "I <strong>don't have time</strong> to do a skin self-check.",
                        solution: {
                          isViewed: false,
                          isChecked: true,
                          text: "Don't Have Time",
                          desc: "The most thorough skin self-checks only take <strong>10-15 minutes to complete</strong> each month.<br><br>Although it may be an inconvenience, doing these skin self-checks is an important part of your survivorship because they <strong>help you to monitor</strong> spots on your body for abnormalities.",
                          pointsDesc: "",
                          points: [],
                          textSummary: "Don't Have Time",
                          pointsDescSummary:
                            "It only takes 10-15 minutes to complete skin self-check ",
                          pointsSummary: [],
                        },
                      },
                      {
                        text: "I <strong>need help</strong> doing a skin self-check because I have too many spots.",
                        solution: {
                          isViewed: false,
                          isChecked: true,
                          text: "Need Help",
                          desc: "Although you want to check all body parts, you only need to note and <strong>monitor abnormal spots</strong> on your body map.",
                          pointsDesc:
                            "Which of the following <strong>strategies</strong> will you use?",
                          points: [
                            "<strong>Complete one body part</strong> at a time to make it manageable",
                            "<strong>Focus on the spots</strong> that I am most concerned about",
                            "<strong>Ask a family</strong> member or friend to help me.",
                          ],
                          textSummary: "Need Help",
                          pointsDescSummary: "To feel less overwhelmed, I Will",
                          pointsSummary: [
                            "Complete one body part at a time to make it manageable",
                            "Focus on the spots that I am most concerned about",
                            "Ask a family member or friend to help me.",
                          ],
                        },
                      },
                      {
                        text: "I would <strong>prefer a doctor</strong> examine my skin.",
                        solution: {
                          isViewed: false,
                          isChecked: true,
                          text: "Prefer a Doctor",
                          desc: "Doing skin self-checks are not instead of having your doctor check your body for abnormal spots. Self-checks allow you to <strong>monitor your body</strong> in between doctor appointments to make sure there is <strong>nothing suspicious</strong>.<br><br>skin self-checks are especially important because about <strong>50% of people find their skin cancer</strong> on their own. If you do find an abnormal or changing spot, you should schedule an appointment with your physician.</br></br>",
                          pointsDesc: "",
                          points: [],
                          textSummary: "Prefer a Doctor",
                          pointsDescSummary:
                            "skin self-checks are especially important because about 50% of people find their skin cancer on their own. If you do find an abnormal or changing spot, you should schedule an appointment with your physician.",
                          pointsSummary: [],
                        },
                      },
                      {
                        text: "<strong>None of these</strong>",
                        solution: {
                          isViewed: false,
                          isChecked: true,
                          text: "None of these",
                          desc: "It is <strong>completely normal</strong> to feel none. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                          pointsDesc:
                            "Following <strong>strategies</strong> will help you to overcome your barrier.",
                          points: [
                            "None <strong>Review MSS</strong> to increase knowledge and skill set",
                            "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                            "<strong>Have someone</strong> to help me during my skin self-check",
                          ],
                          textSummary: "None of these",
                        },
                      },
                      {
                        isChecked: false,
                        text: "Need Help",
                        desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                        pointsDesc:
                          "Following <strong>strategies</strong> will help you to overcome your barrier.",
                        points: [
                          "<strong>Review MSS</strong> to increase knowledge and skill set",
                          "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                          "<strong>Have someone</strong> to help me during my skin self-check",
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Identify Your Barriers",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 90,
                  isActivity: true,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "img-text-col-8",
                    imageUrl: "assets/images/Barrier2.png",
                    text: "Great, you have selected your barriers! Click on each barrier to see strategies to help you overcome them.",
                    screens: null,
                  },
                  {
                    type: "side-heading",
                    text: "Barriers & Solutions",
                    screens: null,
                  },
                  {
                    type: "accordian-barrier-solution",
                    screens: null,
                    // options: [
                    //   {
                    //     isChecked: true,
                    //     text: "Confidence",
                    //     desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                    //     pointsDesc:
                    //       "Following <strong>strategies</strong> will help you to overcome your barrier.",
                    //     points: [
                    //       "<strong>Review MSS</strong> to increase knowledge and skill set",
                    //       "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                    //       "<strong>Have someone</strong> to help me during my skin self-check",
                    //     ],
                    //   },
                    //   {
                    //     isChecked: true,
                    //     text: "Anxiety",
                    //     desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                    //     pointsDesc:
                    //       "Following <strong>strategies</strong> will help you to overcome your barrier.",
                    //     points: [
                    //       "<strong>Review MSS</strong> to increase knowledge and skill set",
                    //       "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                    //       "<strong>Have someone</strong> to help me during my skin self-check",
                    //     ],
                    //   },
                    // {
                    //   isChecked: false,
                    //   text: "Need Help",
                    //   desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
                    //   pointsDesc:
                    //     "Following <strong>strategies</strong> will help you to overcome your barrier.",
                    //   points: [
                    //     "<strong>Review MSS</strong> to increase knowledge and skill set",
                    //     "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
                    //     "<strong>Have someone</strong> to help me during my skin self-check",
                    //   ],
                    // },
                    // ],
                  },
                ],
              },

              {
                title: "Activity Summary",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter",
                  },
                ],
                contents: [
                  {
                    type: "mid-img-small-240",
                    imageUrl: "assets/images/ChapterComplete.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main-small",
                    text: "You’ve Set a Goal!",
                    screens: null,
                  },

                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "core-content-completed-img-text-summary",
                    title: "You’ve Completed",
                    isImgLeft: false,
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "barrier-solution-summary",
                    screens: null,
                    title: "Barriers & Solutions",
                    options: [
                      {
                        text: "Confident",
                        pointsDesc: "To feel confident, I will",
                        points: [
                          "Review MSS to increase knowledge and skill set",
                          "Print out pictures of abnormal spots to compare my spots",
                          "have someone to help me during my skin self-check",
                        ],
                      },
                      {
                        title: "Anxious",
                        pointsDesc: "To feel less anxious, I will",
                        points: [
                          "Ask for help with my skin self-check",
                          "Schedule an appointment with my doctor to review any suspicious spots",
                        ],
                      },
                    ],
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text-setgoal",
                    title: "Keep Going!",
                    description: "Review Core 1 & Continue to complete Core 1",
                  },
                ],
              },
            ],
          },
          //Core 1 Chapter 8
          {
            chapterNo: "Chapter 8 • 3mins",
            title: "Core Summary",
            progress: "locked",
            screens: [
              {
                title: "Summary",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Here is the most important information from this Core.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Melanoma is a common cancer that can begin as a new growth on the skin or in an existing mole.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Because of your personal history of melanoma, you are at increased risk for being diagnosed with another melanoma.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "People diagnosed with melanoma should visit a doctor regularly for a full body skin examination.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "People diagnosed with melanoma should perform monthly skin self-checks to help find and take care of potential problems as soon as possible.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "The ABCDEF guide to melanoma can help you tell the difference between melanomas and normal moles .",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "You can find printable summaries of the ABCDEF Guide to melanoma.",
                  },
                ],
              },

              {
                //latest
                title: "Core 1 Summary",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: true,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Core",
                  },
                ],
                contents: [
                  {
                    type: "mid-img-small-150",
                    imageUrl: "assets/images/celebrate.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main-small",
                    text: "You’ve Completed Core 1!",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },
                  {
                    type: "completed-chapters-summary",
                    title: "You’ve Completed",
                    multipleOptions: [
                      "Chapter 1 : Welcome",
                      "Chapter 2 : What's Skin Cancer?",
                      "Chapter 3 : Skin Self-Checks",
                      "Chapter 4 : Did You Know?",
                      "Chapter 5 : Visit Your Doctor",
                      "Activity : Set Self Check Goals",
                    ],
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    title: "Next Core",
                    description: "We will learn about Sun Safe Behaviors.",
                    isImgLeft: true,
                    screens: null,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: "Core 2: Sun Safe Behaviors",
        description:
          "Lorem ipsum dolor sit amet, consectetur. Lorem ipsum dolor sit amet, consectetur.",
        imageUrl: "assets/images/Sun Safe Behavior.svg",
        chapters: [
          //Chapter 1
          {
            chapterNo: "Chapter 1",
            title: "Introduction/Objectives • 3mins",
            progress: "done",
            link: "welcome",
            screens: [
              {
                title: "Chapter 1",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Overview",
                    screens: null,
                  },
                  {
                    type: "overview",
                    title: "<strong>We're going to introduce you</strong>",
                    imageUrl: "/assets/images/welcome-goal.png",
                    points: [
                      "What will you <strong>cover</strong> in Core 2",
                      "Find out about sun-safe behaviors",
                      "Set goals to protect yourself from the sun ",
                    ],
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 1",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "What Will I Cover",
                    screens: null,
                  },
                  {
                    type: "image-text-accordions",
                    screens: null,
                    contents: [
                      {
                        title: "What will I Learn?",
                        imageUrl: "/assets/images/Learning.svg",
                        points: [
                          "Find out about sun safe behaviors",
                          "Learn how to protect yourself from the sun ",
                          "Gain confidence engaging in sun-safe behaviors",
                        ],
                      },
                      {
                        title: "What will I do?",
                        imageUrl: "/assets/images/todo.png",
                        points: [
                          "Find out about sun safe behaviors",
                          "Review your past experiences of protecting yourself from the sun ",
                          "Set goals plan to protect yourself from the sun ",
                        ],
                      },
                      {
                        title: "How will this help?",
                        imageUrl: "/assets/images/help.png",
                        points: [
                          "Engaging in sun-safe behaviors can help to prevent further skin cancers",
                          "Protecting yourself from the sun can help you feel in control of your health",
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                title: "Chapter 1",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter",
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    imageUrl: "assets/images/ChapterComplete.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main",
                    text: "Chapter 1 is Completed!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub1",
                    text: "You’ve earned a badge!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub2",
                    text: "Keep going to learn more!",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },

                  {
                    type: "core-content-completed-img-text",
                    title: "In Next Chapter",
                    description: "We will learn more about sun safety",
                    isImgLeft: true,
                    screens: null,
                  },
                ],
              },
            ],
          },
          //Chapter 2
          {
            chapterNo: "Chapter 2",
            title: "Learn more about sun safety • 3mins",
            progress: "done",
            link: "welcome",
            screens: [
              //Screen1 How Sunscreen Works
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "How Sunscreen Works",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "There are two types of sunscreen that protect your skin in different ways: <strong>physical sunscreen</strong> and <strong>chemical sunscreen.</strong> ",
                    screens: null,
                  },
                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Physical Sunscreen",

                    imageUrl: "/assets/images/scsdImages/WithBG/F.png",

                    expMainImgText:
                      "Physical sunscreens reflect the sun's ultraviolet A (UVA} and ultraviolet B (UVB} rays ",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/F.png",

                    expansionPoints: [
                      {
                        text: "<strong>Also called</strong>: Inorganic or mineral  ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>How they work</strong> : UVA and UVB rays are reflected (as if tiny mirrors are spread on your skin) ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>Common Ingredients</strong>:  Titanium dioxide &  Zinc oxide",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>Texture</strong> :Sometimes thicker and may leave a white sheen & Sometimes feel greasy ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>Reapplication needed?</strong>:  Yes,  Reapply every 2 hours or more often if sweating or in water ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                    ],
                  },
                  {
                    type: "img-text-mainimg-accordion",

                    screens: null,

                    title: "Chemical Sunscreen",

                    imageUrl: "/assets/images/scsdImages/WithBG/F.png",

                    expMainImgText:
                      "Chemical sunscreens absorb the sun's rays. ",

                    expMainImgUrl: "assets/images/scsdImages/A-FBig/F.png",

                    expansionPoints: [
                      {
                        text: "<strong>Also called</strong>: Organic  ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>How they work</strong> : UVA and UVB rays are absorbed (and then released as a small amount of heat)  ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>Common Ingredients</strong>:   Axobenzone , Oxybenzone & Octisalate ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>Texture</strong> : Usually runny and are less likely to leave a white sheen & Less likely to be greasy ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "<strong>Reapplication needed?</strong>:  Yes,  Reapply every 2 hours or more often if sweating or in water ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                    ],
                  },
                ],
              },
              //Screen 2 Sunscreen Facts & Fiction
              {
                title: "Chapter 2",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 20,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "side-heading",

                    text: "Sunscreen Facts & Fiction ",

                    screens: null,
                  },

                  {
                    type: "fnf",

                    data: [
                      {
                        id: 1,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Don't wait until you are outside to apply sunscreen",

                        answer: "Apply sunscreen as soon as you get outside ",

                        answerType: "Fiction",
                      },

                      {
                        id: 2,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Sunscreen should be applied before you go outside",

                        answer:
                          "Apply sunscreen about 20 minutes before you go outside ",

                        answerType: "Fact",
                      },

                      {
                        id: 3,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "To use enough sunscreen, you can apply it in two layers: use it once on all exposed areas and then apply it again right away",

                        answer: "",

                        answerType: "Fact",
                      },

                      {
                        id: 4,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Use sunscreen on all parts of your skin exposed to the sun ",

                        answer: "Lorem",

                        answerType: "Fact",
                      },

                      {
                        id: 5,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Most people apply less than half the recommended amount of sunscreen",

                        answer: "Lorem",

                        answerType: "Fact",
                      },

                      {
                        id: 6,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Reapply sunscreen every 2 hours or more often if you are sweating a lot or swimming ",

                        answer: "Lorem",

                        answerType: "Fiction",
                      },

                      {
                        id: 7,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "People often forget to apply sunscreen to their nose, ears, and the back of their neck ",

                        answer: "Lorem",

                        answerType: "Fiction",
                      },

                      {
                        id: 8,

                        title: "What do you know about sunscreen?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "One ounce of sunscreen, which is about the size of a shot glass or a large handful, should be enough to cover an average-sized adult e",

                        answer: "Lorem",

                        answerType: "Fiction",
                      },
                    ],

                    screens: null,
                  },
                ],
              },
              //Screen 3 Sun-Safe Behaviors
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 40,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: null,
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Sun-Safe Behaviors",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "Sun-safe behaviors protect you from the sun and can help lower your risk of skin cancer. Place your mouse over the images below to learn how to be sun-safe when you are outside:",
                    screens: null,
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Wear hats",
                    imageUrl: "/assets/images/exposure.png",
                    description: "Lorem",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Stay in the shade",
                    imageUrl: "/assets/images/exposure.png",
                    description: "Lorem",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Timing",
                    imageUrl: "/assets/images/exposure.png",
                    description: "Lorem",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Clothes",
                    imageUrl: "/assets/images/exposure.png",
                    description: "Lorem",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Eye protection",
                    imageUrl: "/assets/images/exposure.png",
                    description: "Lorem",
                  },
                  {
                    type: "text-accordion",
                    screens: null,
                    title: "Sunscreen",
                    imageUrl: "/assets/images/exposure.png",
                    description: "Lorem",
                  },
                ],
              },
              //Screen 4  The Risks of Ultraviolet (UV) Rays from the Sun
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "The Risks of Ultraviolet (UV) Rays from the Sun",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "content-accordian",
                    desc: "Damage to the skin is caused by the sun’s ultraviolet (UV) rays, which you can’t see or feel. UV rays are different from the light from the sun that you see (visible light) or its warmth that you feel (infrared radiation). The sun’s UV rays can still be very strong even when it is cloudy or cool outside. That is why it is important to be sun-safe and protect your skin year round and in any weather conditions.",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc:
                      "A number of factors can make the sun’s UV rays even stronger, including:",
                    accPoints: [
                      "The time of day: the sun’s UV rays are strongest from 10 a.m. to 4 p.m.",
                      "Reflective surfaces such as water, sand, or snow",
                      "Being at a higher altitude",
                      "Being closer to the equator",
                    ],
                    bottomDesc:
                      "Understanding UV is important to keep your skin safe. Even though a burn may heal, damage beneath the surface of the skin can never be repaired. However, it's never too late to protect your skin from further damage. Engaging in sun-safe behaviors will help you to reduce your risk of skin cancer and prevent skin aging.",
                  },
                  {
                    type: "content-img-accordian",
                    setOpen: "false",
                    desc: "The sun emits two types of ultraviolet (UV) rays that can damage the skin:",
                    accDescHead: "UVA Rays",
                    accDescHead2: "UVB Rays",
                    accDescPoints: [
                      "Cause early Aging of the skin, wrinkles, and sun spots",
                      "Damage the deeper layers of your skin",
                      "Cause skin cancer",
                    ],
                    accDescPoints2: [
                      "Cause immediate damage in the form of a Burn",
                      "Damage the top layers of your skin",
                      "Cause skin cancer",
                    ],
                  },
                ],
              },
              //Screen 5 Avoiding Sunburns During Outdoor Activities
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Avoiding Sunburns During Outdoor Activities",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "The most common situations in which people get a sunburn are watching or taking part in recreational activities, including water sports such as swimming and boating. Gardening, home repair activities, and doing paid work outside are also situations when many people get a sunburn. More than a third of Americans get at least one sunburn each year. ",
                    screens: null,
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question: "Have you had a sunburn in the past 12 months?",
                    options: [
                      {
                        text: "No Sunburns ",
                        isCorrect: true,
                        hints: [
                          "It's great that you haven't had a sunburn in the past year. Due to your history of melanoma, it's especially important that you avoid sunburns. And remember, even if you don't get a sunburn, spending time out in the sun without protecting yourself (such as with clothing or sunscreen) can still damage your skin and increase your risk of having another skin cancer. ",
                        ],
                      },
                      {
                        text: "Sunburns",
                        isCorrect: false,
                        hints: [
                          "Due to your history of melanoma, it's especially important that you avoid sunburns. Sunburns increase the risk of having another skin cancer. Remember, even if you don't get a sunburn, spending time out in the sun without protecting yourself (such as with clothing or sunscreen) can still damage your skin and increase your risk of having another skin cancer. ",
                        ],
                      },
                    ],
                  },
                ],
              },
              //Screen 6 The Ultraviolet (UV) Index
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "The Ultraviolet {UV) Index",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "One way to help you <strong>plan sun-safe activities</strong> outside is to check the weather forecast. That will help you to know what the temperature will be and how to plan activities and sun-safe behaviors. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "There is another type of forecast that you may also find helpful, called the <strong>UV index forecast.</strong> The UV index is a daily forecast of the <strong>potential risk of unprotected sun exposure.</strong> The UV index takes into account clouds and other local conditions that affect <strong>the amount of UV radiation</strong> from the sun that reaches the ground. ",
                    screens: null,
                  },
                ],
              },
              //Screen 7 Risk of Harm from Unprotected Sun Exposure
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Risk of Harm from Unprotected Sun Exposure",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "The UV index goes from a low of 1 to a high of 11 or more. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "There is another type of forecast that you may also find helpful, called the <strong>UV index forecast.</strong> The UV index is a daily forecast of the <strong>potential risk of unprotected sun exposure.</strong> The UV index takes into account clouds and other local conditions that affect <strong>the amount of UV radiation</strong> from the sun that reaches the ground. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: "<strong>A UV index of 1 or 2 means Low risk.</strong> Even with a low UV index, sun protection may be needed if you are outside for long periods. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: "<strong>A UV index of 3, 4, or 5 means Moderate risk.</strong> It is important to protect yourself from the sun outdoors when the UV index is 3 or higher. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: "<strong>A UV index of 6 or 7 means High risk.</strong> You should be fully protected when outdoors.  ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: "<strong>A UV index of 8,9, or 10 means Very High risk.</strong> When the UV index is 8 or higher, consider avoiding the sun entirely.  ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: "<strong>A UV index of 11 or more means Extreme risk.</strong>Do your best to avoid the sun. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "There are a number of ways you can find your local UV index,including:  ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: " Local weather stations  ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: "Newspapers",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: " On the website of the Environmental Protection Agency: www.epa.gov/sunsafety/uv-index-l ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },
                  {
                    type: "Description-Summary",
                    text: " A weather app for smartphones ",
                    screens: null,
                  },
                ],
              },
              //Screen 5 Avoiding Sunburns During Outdoor Activities
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Avoiding Sunburns During Outdoor Activities",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Water Sports",

                    imageUrl: "/assets/images/people/Sergio.png",

                    description:
                      "I used to get sunburned often when I went to the beach. Now I try to sit under a large beach umbrella and always wear sunglasses and a hat. I make sure to wear water-resistant SPF 30+ sunscreen and try to reapply every 2 hours. I also try to go to the beach in the late afternoon, when the sun isn’t as strong. It’s also nice at that time because the beach isn’t as busy as it is earlier in the day.",
                    audioUrl: "/assets/audio/sergio_oa.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Goint to the Park",

                    imageUrl: "/assets/images/people/Bill.png",

                    description:
                      "We always go to a park that has plenty of shade, and try to go after 4 p.m. when it isn’t so hot. I always bring extra hats and sunscreen in case someone in the family forgets theirs!",
                    audioUrl: "/assets/audio/bill_oa.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Outdoor Work",

                    imageUrl: "/assets/images/people/Robert.png",

                    description:
                      "I now make it a point to take my work breaks in the shade. I wear protective gear like a long-sleeved shirt, a hat, and UV sunglasses. I also reapply sunscreen throughout the day, and use scheduled work break times as a reminder to reapply. I’ll be honest, it’s not always easy and sometimes I forget to reapply when I’m really busy, but I do the best I can.",
                    audioUrl: "/assets/audio/robert_oa.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Gardening & Yard Work ",

                    imageUrl: "/assets/images/people/Judith.png",

                    description:
                      "I keep a wide-brimmed hat and some sunscreen near my back door so I don't forget to use them when I’m gardening. I also have a couple of ‘gardening tops’ which have long sleeves and a collar.",
                    audioUrl: "/assets/audio/judith_oa.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Winter Sports",

                    imageUrl: "/assets/images/people/Sarah.png",

                    description:
                      "My doctor reminded me that it’s still important to be sun-safe when doing outdoor activities in the winter. Even though you may not feel hot, the sun’s ultraviolet (UV) rays are much stronger at higher altitudes and when reflected by snow. When I go skiing, I wear goggles with UV protection, apply lip balm with SPF 30, and use sunscreen on my face and neck. I take the lip balm and a small sunscreen with me on the slopes so I can reapply them every 2 hours.",
                    audioUrl: "/assets/audio/sarah_oa.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Unplanned Outdoor Activities",

                    imageUrl: "/assets/images/people/Doctor.png",

                    description:
                      "Keep a bottle of sunscreen, sunglasses, a long-sleeved shirt, and a wide-brimmed hat in your bag or car just in case. It's OK to tell someone you need to stay out of the sun and have to find shade or 90 indoors",
                    audioUrl: "/assets/audio/Dr1_oa.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Watching Sports",

                    imageUrl: "/assets/images/people/Doctor.png",

                    description:
                      "Be sure to use multiple sun-safe approaches, especially if you'll be outside for a long time. I recommend you wear a wide-brimmed hat, a long-sleeve collared shirt, sunglasses, and sunscreen. Set a timer on your phone to remind yourself to reapply sunscreen at least every 2 hours. Or you might reapply sunscreen at a certain time in the game, such as between certain quarters or innings.",
                    audioUrl: "/assets/audio/Dr2_oa.mp3",
                  },
                ],
              },
              //Screen 6 Tanning Facts & Fiction
              {
                title: "Chapter 2",

                backgroundIsWhite: false,

                showBackButton: true,

                showExitButton: true,

                progressBar: {
                  show: true,

                  percentage: 20,

                  isActivity: false,
                },

                footerButtons: [
                  {
                    image: "",

                    text: "Continue",

                    isDisabled: true,
                  },
                ],

                contents: [
                  {
                    type: "side-heading",

                    text: "Tanning Facts & Fiction ",

                    screens: null,
                  },

                  {
                    type: "fnf",

                    data: [
                      {
                        id: 1,

                        title: "How much do you know about tanning?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "How much do you know about tanning?",

                        answer:
                          "Indoor tanning is not safer thantanninginthesun.The concentrated ultraviolet (UV) rays produced by tanning beds and booths damage the skin, can cause a sunburn, and increase the risk of skin cancer.  ",

                        answerType: "Fiction",
                      },

                      {
                        id: 2,

                        title: "How much do you know about tanning?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "A 'base tan' protects you from sunburns ",

                        answer:
                          "A 'base tan' is not a safe tan.A tan is a sign of skin damage. ",

                        answerType: "Fiction",
                      },

                      {
                        id: 3,

                        title: "How much do you know about tanning?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question: "Having a tan is healthy",

                        answer:
                          "A tan is a sign your skin is damaged, not healthy. If you want a tanned appearance, consider using sunless tanning products (such as lotions, creams, or a spray tan). ",

                        answerType: "Fiction",
                      },

                      {
                        id: 4,

                        title: "How much do you know about tanning?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Sunless tanning products always make your skin look orange ",

                        answer:
                          "Sunless tanning products (such as lotions, creams, and spray tans) have improved and the color is a lot more natural than it was previously. Resu Its can differ depending on your skin tone, so you may need to try different brands and products to find one that you like. ",

                        answerType: "Fiction",
                      },

                      {
                        id: 5,

                        title: "How much do you know about tanning?",

                        description:
                          "Read each statement and chose if it's fact or fiction",

                        question:
                          "Sunless tanning products protects you from ultraviolet (UV) radiation",

                        answer:
                          "If you are using sunless tanning products (such as lotions, creams, or a spray tan) you still need to use sun protection! Some sunless tanning products have sunscreen in them, but this protection does not last as long as the tanned appearance. ",

                        answerType: "Fiction",
                      },
                    ],

                    screens: null,
                  },
                ],
              },

              //Screen unknown Your SUnsafe behaviors
              // {
              //   "title": "Chapter 2",
              //   "backgroundIsWhite": false,
              //   "showBackButton": true,
              //   "showExitButton": true,
              //   "progressBar": {
              //     "show": true,
              //     "percentage": 40,
              //     "isActivity": false
              //   },
              //   "footerButtons": [
              //     {
              //       "image": null,
              //       "text": "Continue",
              //       "isDisabled": false
              //     }
              //   ],
              //   "contents": [
              //     {
              //       type: "side-heading",
              //       text: "Your Sun-Safe Behaviors",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "Sun-safe behaviors protect you from the sun and can help lower your risk of skin cancer. To help mySmartSkin know your sun-safe behaviors, take the Sun-Safe survey below. Think back to warm, sunny days in the last 12 months. On these days, when you were outside, how often did you ...",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "use sunscreen with SPF 30 or higher? ",
              //       screens: null,
              //     },

              //     {
              //       type: "likertemoji",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "wear a long-sleeved shirt?",
              //       screens: null,
              //     },
              //     {
              //       type: "likertemoji",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "wear a wide-brimmed hat?",
              //       screens: null,
              //     },
              //     {
              //       type: "likertemoji",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "wear sunglasses?",
              //       screens: null,
              //     },
              //     {
              //       type: "likertemoji",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "avoid the sun from 10 a.m. to 4 p.m.?",
              //       screens: null,
              //     },
              //     {
              //       type: "likertemoji",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "stay in the shade or under an umbrella? ",
              //       screens: null,
              //     },
              //     {
              //       type: "likertemoji",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "Your Sun-Safe Score: XX ",
              //       screens: null,
              //     },
              //     {
              //       type: "gap",
              //       height: "16px",
              //       screens: null,
              //     },
              //     {
              //       type: "side-sub-heading",
              //       text: "Your Sun-Safe Score of XX out of XX shows opportunities to improve your sun-safe behaviors. ",
              //       screens: null,
              //     },
              //   ]
              // },

              //Screen Penultimate Sunscreen FAQs
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Sunscreen FAQs",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "8px",
                    screens: null,
                  },

                  {
                    type: "mcq",
                    screens: null,
                    question:
                      "Should I choose a lotion, gel, spray, or stick sunscreen? ",
                    options: [
                      {
                        text: "Choose whichever sunscreen you will use most often!",
                        isCorrect: true,
                        hints: [
                          "Try different types of sunscreen to find the ones that work best for you.",
                          " You may find that you prefer using one type of sunscreen for certain parts of your body (such as a lotion foryourface) and a different type for other areas (such as a spray or gel for your arms and legs or areas of the body that are covered by hair) ",
                        ],
                      },
                    ],
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question:
                      "How do I make sure I am using enough sunscreen? ",
                    options: [
                      {
                        text: "Regardless of the type of sunscreen you use, if you apply enough sunscreen, your skin will have a glossy, almost white sheen immediately after you apply it ",
                        isCorrect: true,
                        hints: [
                          " Use more than you think you need! Most people apply less than half the recommended amount of sunscreen",
                          " Try applying sunscreen twice: use it once on all exposed areas and then apply it again straight away ",
                          "if you are using a spray sunscreen, spray it for at least 6 seconds on each area of the body ",
                          "Be careful if you use a spray sunscreen when outside; the wind can cause the sunscreen to miss your body",
                        ],
                      },
                    ],
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question:
                      "When choosing a sunscreen, should I buy the one with the highest SPF?",
                    options: [
                      {
                        text: "Not necessarily!",
                        isCorrect: true,
                        hints: [
                          "  An SPF 30 sunscreen blocks 97% of the sun's UVB rays, which is almost identical to SPF 50 sunscreen, which blocks 98% of the sun's UVB rays",
                          "  Sunscreens with a very high SPF (for example, above SPF 30) are sometimes more greasy or leave a residue on the skin; this may make you less likely to use them ",
                          " If you find a very high SPF sunscreen that you like, that is great; most people, however, find that an SPF 30 sunscreen works well",
                        ],
                      },
                    ],
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question:
                      "If I use another product that has an SPF rating do I have to use sunscreen?",
                    options: [
                      {
                        text: " If you use such products, you should also use sunscreen",
                        isCorrect: true,
                        hints: [
                          "   Products such as foundation, moisturizer, and after-shave lotion may have an SPF rating ",
                          "  This is because with regular use, you don't apply enough of the product to obtain the SPF rating that it claims to have ",
                          " Also, you may not reapply the product and its SPF properties won't last all day",
                          "It can help to use these products, but be sure to always combine them with an SPF 30+ sunscreen ",
                        ],
                      },
                    ],
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question: "What should I look for on the sunscreen label?",
                    options: [
                      {
                        text: " SPF 30 or higher",
                        isCorrect: true,
                        hints: [
                          "The higher the SPF, the more the sunscreen protects you from burning.",
                          " An SPF of 30 will allow people to stay out in the sun 30 times longer than they normally would be able to, before they burn. ",
                          " Broad spectrum or UVA/UVB protection that the sunscreen protects the skin from the sun's UVA and UVB rays, both of which cause skin aging and skin cancer. ",
                          "Water resistant sunscreens resist being washed away by water or sweat for up to 40 or 80 minutes.  ",
                        ],
                      },
                    ],
                  },
                  {
                    type: "side-heading",
                    text: "What do the experts say about sunscreen?",
                    screens: null,
                  },
                  {
                    type: "video-player",
                    coreVideoUrl:
                      "https://vimeo.com/871697000/119579568e?share=copy",
                    coreVideoText: "Sunscreen FAQs",
                    screens: null,
                  },
                ],
              },

              //Screen Last
              {
                title: "Chapter 2",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter",
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    imageUrl: "assets/images/ChapterComplete.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main",
                    text: "Chapter 2 is Completed!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub1",
                    text: "You’ve earned a badge!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub2",
                    text: "Keep going to learn more!",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },

                  {
                    type: "core-content-completed-img-text",
                    title: "In Next Chapter",
                    description:
                      "We will assess your current sun protection behaviors",
                    isImgLeft: true,
                    screens: null,
                  },
                ],
              },
            ],
          },
          {
            chapterNo: "Chapter 3",
            title: "Assess your current sun protection behaviors • 3mins",
            progress: "done",
            link: "",
            screens: [
              {
                title: "Chapter 3",
                backgroundIsWhite: true,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Risky Behavior: Sunbathing",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Sunbathing involves deliberately exposing your skin to the sun in order to get a tan.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "singlecheckoption",
                    question: "Have you ever sunbathed?",
                    listOptions: ["Yes", "No"],
                  },

                  {
                    type: "incdec",
                    question:
                      "How many times in the past 12 months have you sunbathed?",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "singlecheckoption",
                    question: "Do you plan to sunbathe in the next 12 months?",
                    listOptions: ["Yes", "No", "Not Sure"],
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Your Sunbathing Behavior",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "You indicated that you sunbathed <strong> {x} </strong>times in the past year and <strong>plan to continue sunbathing in the next year.</strong>",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Due to your history of melanoma, <strong>it's especially important that you avoid sunbathing.</strong> Even occasional sunbathing can damage your skin and increase your risk of having another skin cancer. mySmartSkin will help you to come up with some sun-safe strategies to avoid sunbathing.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "content-accordian",
                    description: "",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc:
                      "A tanned appearance is actually a sign that your skin has been damaged by the sun. When skin is exposed to ultraviolet (UV) rays the DNA becomes damaged, and the tanned appearance you get is a result of the skin's DNA trying to repair itself. ",
                    accPoints: [],
                    bottomDesc: "",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "And remember ... if you tan, you risk sunburn and also increase your total sun exposure, both of which increase your risk of developing further skin cancers.",
                    screens: null,
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Bill",

                    imageUrl: "/assets/images/people/Bill.png",
                    audioUrl: "/assets/audio/mss_bill-sunbath.mp3",

                    description:
                      "I used to sunbathe when my wife and I went to the beach with our children. But now, when I go to the beach with my grandkids, I never sunbathe and we try to go early or late in the day. I also bring a large shade tent and we all use it as much as possible. That way we can still enjoy the outdoors on a nice day.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: true,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Sun-Safe Behaviors for You and Your Family",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Play the video by clicking on the play button on the bottom left of the video.",
                    screens: null,
                  },
                  {
                    type: "video-player",
                    coreVideoUrl:
                      "https://vimeo.com/871697036/2c6923d69d?share=copy",
                    coreVideoText: "",
                    screens: null,
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Your Tanning Beliefs",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "You indicated that you weren't sure or definitely plan to sunbathe or indoor tan in the next year.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "form",
                    question: "",
                    desc: "Take a minute and think about the reasons that may make you want to have a tan, sunbathe, or indoor tan. Select any of the reasons below that are true for you. You can also add your own reasons.",
                    listOptions: [
                      "I look more attractive when I have a tan",
                      "a tan makes me feel better about myself",
                      "I look healthier when I have a tan ",
                      "having a tan makes my body look more toned ",
                      "using sunless tanning products is difficult and takes time",
                      "sunless tanning doesn't look good ",
                      " it feels good to sunbathe ",
                      "it feels good to indoor tan",
                      "enter my own strategy",
                    ],
                    limitOptions: 0,
                    isGoal: false,
                    screens: null,
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Address Your Tanning Beliefs",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "The reasons you gave for wanting to have a tan, sunbathe, or indoor tan are shown below. For each reason, consider the other viewpoint that is given. ",
                    screens: null,
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question: "Having a tan makes my body look more toned",
                    options: [
                      {
                        text: "Consider this:",
                        isCorrect: true,
                        hints: [
                          "There are other, <strong>more healthy ways, to make your body appear more toned,</strong> such as eating well and getting regular exercise. It is also possible to use sunless tanning products to get the appearance of a tan without the dangerous ultraviolet (UV) exposure. ",
                        ],
                      },
                    ],
                  },
                  {
                    type: "mcq",
                    screens: null,
                    question: " It feels good to sunbathe ",
                    options: [
                      {
                        text: "Consider this:",
                        isCorrect: true,
                        hints: [
                          "Sunbathing can <strong>damage your skin and increase your risk of having another skin cancer.</strong> If you like to sit or lie in the sun, you may find that it still feels good if you are under shade and protected with clothing and sunscreen.",
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Limiting Your Sun Exposure",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "mySmartSkin has feedback for you based on your behaviors of <strong>sometimes</strong> avoiding the sun from 1 o a.m. to 4 p.m. and <strong>rarely </strong>staying in the shade or under an umbrella on warm, sunny days.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "The sun is very strong between 1 o a.m. and 4 p.m. and <strong>limiting ultraviolet (UV) exposure during this time</strong> is an important sun-safe behavior. When you can't avoid being outdoors, remember to use multiple sun-safe behaviors for best protection. Try to plan your outdoor activities at times when the sun is less strong, such as first thing in the morning or in the late afternoon or evening. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "It's important to try and <strong>stay in the shade or use a large umbrella for shade</strong> when you are in the sun. When you can't find shade or use an umbrella, remember to use multiple sun-safe behaviors for best protection. Try to plan your outdoor activities at times when the sun is less strong, such as first thing in the morning or in the late afternoon or evening.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Judith",

                    imageUrl: "/assets/images/people/Judith.png",
                    audioUrl: "/assets/audio/mss_judith_limitsun.mp3",

                    description:
                      "After I was diagnosed, I was very worried that I wouldn’t be able to spend time outside gardening. But my doctor suggested that I do my gardening when the sun is less intense and take shade breaks, even on cloudy days. If I can, I now try to finish my gardening before 10 a.m. I can do what I love and not feel overly worried about my health.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 66,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Reasons For Doing Sun-Safe Behaviors",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "There are many reasons why it is important to engage in sun-safe behaviors. In Core 2, mySmartSkin will help you to improve your sun-safe behaviors, which will reduce your sun exposure. Click each picture to see why others believe sun-safe behaviors are important. ",
                    screens: null,
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sergio",

                    imageUrl: "/assets/images/people/Sergio.png",

                    description:
                      "My doctor explained to me that it is important for everyone to protect themselves from the sun, regardless of their race, ethnicity, or skin color.",
                    audioUrl: "/assets/audio/sergio_rssb.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Bill",

                    imageUrl: "/assets/images/people/Bill.png",

                    description:
                      "Using sun protection lowers my risk of further melanomas. It also helps me to avoid sunburns, and I’m setting a good example for my children and grandchildren.",
                    audioUrl: "/assets/audio/bill_rssb.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Robert",

                    imageUrl: "/assets/images/people/Robert.png",

                    description:
                      "I guess I always knew sun protection was important, but it felt like too much trouble. Then I realized that getting into a routine of using sunscreen made being sun-safe easier.",
                    audioUrl: "/assets/audio/robert_rssb.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Judith",

                    imageUrl: "/assets/images/people/Judith.png",

                    description:
                      "Making sure I am always protected from the sun helps me feel in control of my health.",
                    audioUrl: "/assets/audio/judith_rssb.mp3",
                  },
                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Sarah",

                    imageUrl: "/assets/images/people/Sarah.png",

                    description:
                      "My doctor explained to me that exposure to ultraviolet (UV) light from the sun is the main cause of skin aging. So, now that I protect my skin from the sun, I will stay looking younger for a longer time",
                    audioUrl: "/assets/audio/sarah_rssb.mp3",
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "side-sub-heading",
                    text: "Play the video by clicking on the play button on the bottom left of the video.",
                    screens: null,
                  },
                  {
                    type: "video-player",
                    coreVideoUrl:
                      "https://vimeo.com/871696926/26c4f69d28?share=copy",
                    coreVideoText: "",
                    screens: null,
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Your Sunscreen Use",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "mySmartSkin has feedback for you based on your behavior of <strong>sometimes</strong> using sunscreen with SPF 30 or higher on warm, sunny days. ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Many people struggle with regular sunscreen use. Don't give up! Using sunscreen is an important sun-safe behavior. mySmartSkin will help you to overcome your own challenges to using sunscreen. For the best protection, use a sunscreen with an SPF of 30 or higher. ",
                    screens: null,
                  },

                  {
                    type: "content-accordian",
                    description: "",
                    accImg: "./assets/images/qustnImg.png",
                    accHeader: "Did You Know?",
                    accDesc:
                      "To provide maximum protection from the sun, combine your use of sunscreen with other sun-safe behaviors. ",
                    accPoints: [],
                    bottomDesc: "",
                    screens: null,
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Robert",

                    imageUrl: "/assets/images/people/Robert.png",
                    audioUrl: "/assets/audio/mss_robert_ssu.mp3",

                    description:
                      "To be honest, most of my life I thought wearing sunscreen was more trouble than it was worth. But now I know it is worth it, especially because I work outside. It’s part of how I stay healthy and safe. I keep sunscreen at work, in the car, and at home. It took me a while to get used to wearing sunscreen, but now it’s part of my routine and doesn’t seem so bad. Wearing a long-sleeved collared shirt and a wide-brimmed hat when I am outside also helps.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Sun-Safe Clothing and Sunglasses",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "mySmartSkin has feedback for you based on your behaviors of <strong>rarely</strong> wearing a long-sleeved shirt, sometimes wearing a wide-brimmed hat, and <strong>sometimes</strong> wearing sunglasses on warm, sunny days.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Wearing a <strong>long-sleeved shirt</strong> is an important sun-safe behavior, and one you have the opportunity to engage in more often. It's an easy way to keep your torso and arms protected from the sun.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Wearing a <strong>wide-brimmed hat</strong> when you are in the sun is a great way to protect your head, face, ears, and neck. This is a sun-safe behavior you can be engaging in more often.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "It's important to <strong>wear sunglasses</strong> that offer ultraviolet (UV) protection. The sun's rays can damage your eyes and cause cataracts and other eye conditions. Wearing sunglasses can also help to prevent wrinkles around the eyes. This is a sun-safe behavior you can be engaging in more often.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "image-desc-bio-accordion",

                    screens: null,

                    title: "Judith",

                    imageUrl: "/assets/images/people/Sergio.png",
                    audioUrl: "/assets/audio/mss_sergio_sscs.mp3",

                    description:
                      "I never used to wear sunglasses. I wear prescription glasses and so sunglasses just seemed too hard to organize. Since my diagnosis, I’ve started wearing sunglasses, protective clothing, and sunscreen every time I exercise outside. I found an affordable pair of prescription sunglasses that have UV protection and wrap around my face while I exercise. I keep them with my sneakers so I don't forget them when I leave the house.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 30,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Sun-Safe Clothing",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "side-sub-heading",
                    text: "Covering your skin with clothing is a great way to protect yourself from the sun. But did you know that not all types of clothing provide the same level of protection against the sun's ultraviolet (UV) rays? ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "<strong>Greater UV protection is provided by</strong>",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: " Fabrics with a darker or more vivid color ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: " Synthetic fabrics (such as polyester, nylon, or lycra) ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Fabrics with a tighter weave (such as denim) ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "<strong>Less UV protection is provided by</strong>",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Fabrics with a lighter or less vivid color",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: "Natural fabrics (such as cotton or linen) ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },

                  {
                    type: "Description-Summary",
                    text: " Fabrics with a looser weave ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: " Wet clothing ",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "It can be hard to wear dark, heavy fabrics (such as denim) when it is very hot outside. Instead, consider wearing clothing that is labeled with an <strong>ultraviolet protection factor (UPF) value.</strong>",
                    screens: null,
                  },
                  {
                    type: "img-text-mainimg-accordion",
                    screens: null,
                    title: "UPF clothing facts and tips",
                    imageUrl: "/assets/images/todo.png",
                    expMainImgText:
                      " The UPF value of clothing is similar to the SPF value of sunscreen: the higher the UPF value, the more protection the clothing provides against UV rays",
                    expMainImgUrl: "",
                    expansionPoints: [
                      {
                        text: "UPF indicates how well the clothing protects against both UVA and UVB rays",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: " The SPF value of sunscreen refers to its protection against UVB rays only ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "Look for clothing with a UPF value of 30 or higher, which allow only 1130th of the UV rays to pass through it  ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: "You can buy UPF clothing on the Internet or at large sporting goods or outdoor stores  ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                      {
                        text: " You can also add UV protection to your clothes using a laundry additive, such as SunGuard ",
                        imageUrl: "assets/icons/ExclamationCircleFill.svg",
                      },
                    ],
                  },
                ],
              },

              {
                title: "Chapter 3",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 100,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter",
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    imageUrl: "assets/images/ChapterComplete.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main",
                    text: "Chapter 3 is Completed!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub1",
                    text: "You’ve earned a badge!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub2",
                    text: "Keep going to learn more!",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "32px",
                  },

                  {
                    type: "core-content-completed-img-text",
                    title: "In Next Chapter",
                    description:
                      "We will learn how we can increase confidence and motivation",
                    isImgLeft: true,
                    screens: null,
                  },
                ],
              },
            ],
          },
          {
            chapterNo: "Activity",
            title: "Goal Setting Activity",
            progress: "locked",
            screens: [
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: true,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Continue",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Your Sun-Safe Goals",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "Setting and working on goals is the best way to improve your sun-safe behaviors. mySmartSkin will now help you identify some <strong>sun-safe goals for you to work on</strong>. mySmartSkin will take you through the goal setting process to set you up for success.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "First, identify and commit to the sun-safe behavior goals that you wish to work on at the current time. Once you have set your initial goals, you can review and change them at any time.",
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "form-init",
                    question:
                      "Based on the information you entered earlier, the behaviors in which you have the most opportunities to make positive changes have been highlighted for you.",
                    description:
                      "<strong>Select up to 3 behaviors</strong> you wish to improve at this time and <strong>then click Submit</strong>.",
                    listOptions: [
                      "Use sunscreen more often",
                      "Wear a long-sleeved shirt more often",
                      "Wear a wide-brimmed hat more often",
                      "Wear sunglasses more often",
                      "Limit my sun exposure",
                      "Avoid sunbathing",
                      "Avoid indoor tanning",
                    ],
                    limitOptions: 3,
                    isGoal: false,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [],
                contents: [
                  {
                    type: "task-selection-option",
                    description:
                      "Below are the sun-safe goals you selected. mySmartSkin will now help you to create an Action Plan for each goal. Once you have created an Action Plan for each goal, the Next button will appear and you can continue.",
                    activityOptions: [
                      {
                        text: "Use sunscreen more often",
                        options: {
                          text: "Use sunscreen more often",
                          screensSkip: 1,
                        },
                      },
                      {
                        text: "Wear sunglasses more often",
                        options: {
                          text: "Wear sunglasses more often",
                          screensSkip: 4,
                        },
                      },
                      {
                        text: "Limit my sun exposure",
                        options: {
                          text: "Limit my sun exposure",
                          screensSkip: 7,
                        },
                      },
                    ],
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Strategies: Use Sunscreen More Often",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form",
                    question: "",
                    desc: "From the list below, <strong>select up to two strategies</strong> that you think will help you to meet your goal of <strong>using sunscreen more often</strong>.",
                    listOptions: [
                      "make it more convenient",
                      "make it more pleasant",
                      "make it more affordable",
                      "find sunscreen that doesn't interfere with my outdoor activities",
                      "make it part of my daily routine",
                      "remember to use it",
                      "learn more about what kind of sunscreen to use",
                      "enter my own strategy",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Action Steps: Use Sunscreen More Often",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form1",
                    question: "",
                    desc: "To help you make sunscreen <strong>more convenient</strong>, select one or two of the following action steps:",
                    listOptions: [
                      "apply sunscreen every day before leaving home",
                      "put sunscreen in an easy to see place (such as the bathroom counter) ",
                      "keep sunscreen at home, in my car, in my bag, and in my suitcase ",
                      "use a spray sunscreen ",
                      "enter my own action step ",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                skipScreens: 6,
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Action Steps: Use Sunscreen More Often",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form2",
                    question: "",
                    desc: "To help you make sunscreen <strong>part of your daily routine</strong>, select one or two of the following action steps:",
                    listOptions: [
                      "place my sunscreen in an easy to see place, such as next to my toothbrush or with my keys near the front door",
                      "keep sunscreen at home, in my car, in my bag, and in my suitcase ",
                      "enter my own action step",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                skipScreensBack: 2,
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Strategies: Wear Sunglasses More Often",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form3",
                    question: "",
                    desc: "From the list below, <strong>select up to two strategies</strong> that you think will help you to meet your goal of <strong>wearing sunglasses more often</strong>.",
                    listOptions: [
                      "find sunglasses that look good ",
                      "find sunglasses that don't interfere with my outdoor activities",
                      "learn more about what kinds of sunglasses to wear",
                      "wear sunglasses even though I wear prescription glasses",
                      "enter my own strategy",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Strategies: Wear Sunglasses More Often",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form4",
                    question: "",
                    desc: "To help you find sunglasses that <strong>don't interfere with your outdoor activities</strong>, select one or two of the following action steps:",
                    listOptions: [
                      "try sports sunglasses or sunglasses for people who have an active lifestyle",
                      "try different stores or shop on the Internet for a pair that I like",
                      "enter my own action step",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                skipScreens: 3,
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Action Steps: Wear Sunglasses More Often",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form5",
                    question: "",
                    desc: "To help you <strong>wear sunglasses even though you wear prescription glasses</strong>, select one or two of the following action steps: ",
                    listOptions: [
                      "try lenses that clip onto my prescription glasses",
                      "speak to my optometrist or shop on the Internet for affordable prescription sunglasses",
                      "enter your own action step",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                skipScreensBack: 2,
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Strategies: Limit My Sun Exposure",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form6",
                    question: "",
                    desc: "From the list below, select <strong>up to two strategies</strong> that you think will help you to meet your goal of <strong>limiting your sun exposure<strong>.",
                    listOptions: [
                      "avoid the sun from 1 o a.m. to 4 p.m. on warm, sunny days",
                      "stay in the shade or under an umbrella on warm, sunny days",
                      "enter my own strategy",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: true,
                  percentage: 50,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: true,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Goal Setting Strategies: Limit My Sun Exposure",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                    screens: null,
                  },
                  {
                    type: "form7",
                    question: "",
                    desc: "To help you avoid the sun from <strong>1 a.m. to 4 p.m. on warm, sunny days</strong>, select one or two of the following action steps:",
                    listOptions: [
                      "plan my schedule so my outdoor activities happen earlier in the morning or in the late afternoon",
                      "if I am going somewhere with limited shade, such as the beach, take a large umbrella and create my own shade!",
                      "enter my own action step",
                    ],
                    limitOptions: 2,
                    isGoal: true,
                    screens: null,
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-sub-heading",
                    text: "Below are the sun-safe goals you selected. mySmartSkin will now help you to <strong>create an Action Plan</strong> for each goal. Once you have created an Action Plan for each goal, the Next button will appear and you can continue.",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "selected-points-summary-contents",
                    text: "",
                    points: [
                      "Use sunscreen more often",
                      "Wear sunglasses more often",
                      "Limit my sun exposure",
                    ],
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text-setgoal-core2",
                    title: "Keep Going!",
                    // description: "Review Core 1 & Continue to complete Core 1",
                  },
                ],
              },
              {
                title: "Chapter 5",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "",
                    text: "Next",
                    isDisabled: false,
                  },
                ],
                contents: [
                  {
                    type: "side-heading",
                    text: "Summary",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "<strong>Here is the most important information from this Core:</strong>",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Sun-safe behaviors protect you from the sun and can help lower your risk of skin cancer",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Due to your history of melanoma, it is very important that you protect yourself from the sun",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Tanning is a sign that your skin is damaged and increases your risk of skin cancer",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "As someone with a history of melanoma, it is particularly important that you avoid sunbathing and indoor tanning",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Setting small and achievable goals is the best way to improve your sun-safe behaviors",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Follow your <strong>Sun-Safe Action Plan</strong>, which you should review and update at least once a month",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "20px",
                  },
                  {
                    type: "side-sub-heading",
                    text: "There are 6 key ways to protect yourself from the sun:",
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Use sunscreen with SPF rating 30 or higher",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Wear a long-sleeved shirt",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Wear a wide-brimmed hat",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Wear sunglasses",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Avoid the sun from 1O a.m. to 4 p.m.",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "16px",
                  },
                  {
                    type: "Description-Summary",
                    text: "Stay in the shade or under an umbrella",
                    screens: null,
                  },
                ],
              },
              {
                title: "Great Job!",
                backgroundIsWhite: false,
                showBackButton: true,
                showExitButton: true,
                progressBar: {
                  show: false,
                  percentage: 0,
                  isActivity: false,
                },
                footerButtons: [
                  {
                    image: "assets/images/prev.png",
                    text: "Back to Cores",
                    isDisabled: false,
                  },
                  {
                    image: "assets/images/next.png",
                    text: "Next Chapter",
                  },
                ],
                contents: [
                  {
                    type: "mid-img",
                    imageUrl: "assets/images/ChapterComplete.png",
                    screens: null,
                  },
                  {
                    type: "mid-heading-main",
                    text: "Chapter 5 is Completed!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub1",
                    text: "You’ve earned a badge!",
                    screens: null,
                  },
                  {
                    type: "mid-heading-sub2",
                    text: "Keep going to learn more!",
                    screens: null,
                  },
                  {
                    type: "gap",
                    height: "24px",
                  },
                  {
                    type: "core-content-completed-img-text",
                    title: "In Next Chapter",
                    description:
                      "We will brush up on facts about sun safe behaviors.",
                    isImgLeft: true,
                    screens: null,
                  },
                ],
              },
            ],
          },
          {
            chapterNo: "Summary",
            title: "Core Summary",
            progress: "locked",
            screens: [],
          },
        ],
      },
    ],
  };
  public static coresDataJSON: { cores: any[] };
  public static getScreens(coreIndex: number, chapterIndex: number) {
    const core = this.coresDataJSON.cores[coreIndex];
    const chapter = core.chapters[chapterIndex];
    const screens = chapter.screens;
    return screens;
  }

  public static getLessonScreens(
    coreIndex: number,
    chapterIndex: number,
    lessonIndex: number
  ) {
    var returnScreens;

    const core = this.coresDataJSON.cores[coreIndex];
    const chapter = core.chapters[chapterIndex];
    const screens = chapter.screens;
    if (screens) {
      returnScreens = screens[0].contents[lessonIndex].screens;
    }

    return returnScreens;
  }

  public static currentCoreScreen: any;
  public static currentCoreScreens: any;

  public static currentCoreIndex: number;
  public static currentChapterIndex: number;
  public static currentLessonIndex: number;

  public static isLesson: boolean;

  // public static tabSelected = "Home";

  //Rewards & Recognitions

  public static isBadgeEarned = false;
  public static isPrizeEarned = true;
}

// type.ts for core Fact and Fiction
export type CoreFnfJsonDataType = {
  id: number;
  title: string;
  description: string;
  question: string;
  answer: string;
  answerType: string;
};

export type CoreMCQJsonDataType = {
  id: number;
  title: string;
  description: string;
  question: string;
  questionImages: string;
  answer: string;
  answerType: string;
  mcqButton: Array<any>;
};



import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useEffect, useState } from "react";

import "./MCQMultipleCheck.css";

import "../../Fonts.css";
import MCQMultipleCheckOption from "../mcq_multiple_check_option/MCQMultipleCheckOption";
import { DataSubmissionService } from "../../services/DataSubmissionService";

interface MCQMultipleCheckProps {
  question: string;
  desc: string;
  options: Array<any>;
  selected: Function;
}

const MCQMultipleCheck: React.FC<MCQMultipleCheckProps> = ({
  question,
  desc,
  options,
  selected,
}) => {
  var [valsChanged, setValsChanged] = useState(false);
  var [optionsArraySelected, setOptionsArraySelected] = useState([
    false,
    false,
  ]);

  useEffect(() => {
    let savedOptions = null;
    savedOptions = DataSubmissionService.c1A1SkinSelfCheckReasons;

    if (savedOptions && (savedOptions.length > 0)) {
      if ((savedOptions[0]).toString() === "true" || (savedOptions[0].toString() === "false")) {
        let realSavedOptions = [];
        for (let i = 0; i < savedOptions.length; i++) {
          if (savedOptions[i]) {
            realSavedOptions.push(options[i]);
          }
        }
        savedOptions = realSavedOptions;
      }
    }


    if (savedOptions.length > 0) {
      let tempOptionsSelected = Array(options.length).fill(false);
      let tempIndexesSelected: Array<number> = [];
      for (let i = 0; i < savedOptions.length; i++) {
        let ind = options.indexOf(savedOptions[i]);
        tempIndexesSelected.push(ind);
        tempOptionsSelected[ind] = true;
      }
      console.log(tempOptionsSelected);
      setOptionsArraySelected(tempOptionsSelected);

      setTimeout(() => {
        selected(tempOptionsSelected);
      }, 300);
    } else {
      setOptionsArraySelected(Array(options.length).fill(false));
    }
  }, [options.length]);

  const optionSelected = (index: number) => {
    let optsSelArr = optionsArraySelected;
    optsSelArr[index] = !optsSelArr[index];
    setOptionsArraySelected(optsSelArr);
    setValsChanged(!valsChanged);

    var selectedOptionsArr: string[] = [];
    optsSelArr.map((opt, i) => {
      if (opt) {
        selectedOptionsArr.push(options[i]);
      }
    });

    selected(selectedOptionsArr);
  };

  return (
    <div className="acceMainContainer ccaqMainContainer mcqmcMainContainer">
      <IonGrid>
        <IonRow>
          <IonCol className="acceTitleCol mcqmcQuestionTextCol" size="12">
            <IonText className="ccaqTitleText">{question}</IonText>
          </IonCol>
          <IonCol className="mcqmcDescCol" size="12">
            <IonText
              className="mcqmcDescText"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      {options.map((option: any, i: any) => {
        return (
          <MCQMultipleCheckOption
            key={i}
            index={i}
            text={option}
            isOpen={optionsArraySelected[i]}
            isSelected={optionSelected}
          />
        );
      })}
    </div>
  );
};

export default MCQMultipleCheck;

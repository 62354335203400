import "./HomeCoreProgressBar.css";

interface HomeCoreProgressBarProps {
  percentage: number;
}

const HomeCoreProgressBar: React.FC<HomeCoreProgressBarProps> = ({
  percentage,
}) => {
  return (
    <div className="hcpbMainContainer">
      <div className="hcpbFillContainer" style={{width: `${percentage}%`}}>

      </div>
    </div>
  );
};

export default HomeCoreProgressBar;

import {
  IonAvatar,
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonFooter,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonModal,
  IonPage,
  IonRow,
  IonSearchbar,
  IonText,
  IonTitle,
  useIonRouter,
} from "@ionic/react";
import "./CoreChapterEnd.css";
import { settingsOutline } from "ionicons/icons";
import "../../Fonts.css";
import ProgressContainer from "../../components/progress/Progress";
import CoreContentCompletedImgText from "../../components/core_content_completed_img_text/CoreContentCompletedImgText";
import { DataService } from "../../services/DataService";
import { useRef } from "react";
import MCQMultipleCheck from "../../components/mcq_multiple_check/MCQMultipleCheck";
import StarRatingSummary from "../../components/star_rating_summary/StarRatingSummary";
import SelectedPointsSummary from "../../components/selected_points_summary/SelectedPointsSummary";
import MaterialsMultipleSelect from "../../components/materials_multiple_select/MaterialsMultipleSelect";
import TaskSelection from "../../components/task_selection/TaskSelection";
import ImgTextColTappable from "../../components/img_text_col_tappable/ImgTextColTappable";
import BarrierMCQMultipleCheck from "../../components/barrier_mcq_multiple_check/BarrierMCQMultipleCheck";
import AccordianBarrierSolution from "../../components/accordian_barrier_solution/AccordianBarrierSolution";
import AccordianToolsSelected from "../../components/accordian_tools_selected/AccordianToolsSelected";
import AccordianBarrierSolutionSummary from "../../components/accordian_barrier_solution_summary/AccordianBarrierSolutionSummary";
import AccordianSummaryColorText from "../../components/accordian_summary_color_text/AccordianSummaryColorText";
import CompletedChaptersSummary from "../../components/completed_chapters_summary/CompletedChaptersSummary";
import AccordianGoalSummary from "../../components/accordian_goal_summary/AccordianGoalSummary";
import HomeIntro from "../../components/home_intro/HomeIntro";
import SkinCheckProgress from "../../components/skin_check_progress/SkinCheckProgress";
import HomeSSCGoal from "../../components/home_ssc_goal/HomeSSCGoal";
import GetPrize from "../../components/get_prize/GetPrize";
import HomeCoreProgressBar from "../../components/home_core_progress_bar/HomeCoreProgressBar";
import CoreFnFBars from "../../components/core_fnf_bars/CoreFnFBars";

const CoreChapterEnd: React.FC = () => {
  var router = useIonRouter();
  const modal = useRef<HTMLIonModalElement>(null);

  const prizes = [
    {
      text: "Core 1",
      imgUrl: "assets/images/Learn.svg",
      showGetPrize: true,
      progress: 0,
      progressTotal: 13
    },
    {
      text: "Core 2",
      imgUrl: "assets/images/Sunsafe.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: 13
    },
    {
      text: "Skin Self-Check",
      imgUrl: "assets/images/sscm.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: 13
    },
    {
      text: "Progress Check for Sun Goal",
      imgUrl: "assets/images/SunSafe2.svg",
      showGetPrize: false,
      progress: null,
      progressTotal: 13
    },
  ];

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#ffffff" }}>
        <div style={{padding: "16px"}}>
        {/* <CoreFnFBars question="Normal spots are usually multicolored."
       description="The correct answer is: Normal spots are not usually multicolored"
       isQuestion ={false} isRight ={false}></CoreFnFBars> */}
        </div>

        {/* <GetPrize prizes={prizes}/> */}

        {/* <HomeCoreProgressBar percentage={10}/> */}

        {/* <SkinCheckProgress />
         */}
        {/* <HomeSSCGoal imageUrl="assets/images/flag1.png" text="Goals from <strong>Skin Self-Check Core</strong>" count={2} /> */}
        {/* <HomeIntro imageUrl="assets/images/Welcome.png" title="Welcome to MySmartSkin" desc="MySmartSkin helps skin cancer patients to track and learn more about their suspicious spots. You will find well-designed content and a simple, self-guided flow for their monthly Skin-Self Check.​" question="Do you want to take a tour?" buttonText="Yes, Show Me Around" buttonAction={() => {

        }} /> */}
        {/* <AccordianGoalSummary text="Goal Title" desc="Goal Desc" subDesc="Goal Sub Desc" imgUrl="assets/images/todo.png" /> */}
        {/* <CompletedChaptersSummary
          title="You’ve Completed"
          options={[
            "Chapter 1 : Welcome",
            "Chapter 2 : What's Skin Cancer?",
            "Chapter 3 : Skin Self-Checks",
            "Chapter 4 : Did You Know?",
            "Chapter 5 : Visit Your Doctor",
            "Chapter 6 : Set Self Check Goals",
          ]}
        /> */}
        {/* <AccordianSummaryColorText title="In the Future" options={["Work on <strong>collecting the remaining tools</strong> and keep track of your progress in MyStuff. ", "<span class='text-underline'>Set another task</span> under the Start Doing a skin self-check.", "<span class='text-underline'>Remind me</span> to complete my skin self-check at a later date."]} />
        <AccordianBarrierSolutionSummary
          title="Barriers & Solutions"
          options={[
            {
              title: "Confident",
              pointsDesc: "To feel confident, I will",
              points: [
                "Review MSS to increase knowledge and skill set",
                "Print out pictures of abnormal spots to compare my spots",
                "have someone to help me during my skin self-check",
              ],
            },
            {
              title: "Anxious",
              pointsDesc: "To feel less anxious, I will",
              points: [
                "Ask for help with my skin self-check",
                "Schedule an appointment with my doctor to review any suspicious spots",
              ],
            },
          ]}
        /> */}
        {/* ___________________________________ */}
        {/* <AccordianToolsSelected
          title="Selected Tools"
          options={[
            {
              title: "What you have",
              options: [
                {
                  title: "Hand Mirror",
                  imageUrl: "assets/images/HandMirror.png",
                },
                {
                  title: "Notebook",
                  imageUrl: "assets/images/Notebook.png",
                },
              ],
            },
            {
              title: "Tools you'll need to get",
              options: [
                {
                  title: "Cell Phone",
                  imageUrl: "assets/images/CellPhone.png",
                },
                {
                  title: "Ruler",
                  imageUrl: "assets/images/Ruler.png",
                },
              ],
            },
          ]}
        /> */}
      </IonContent>
    </IonPage>
  );
};

export default CoreChapterEnd;

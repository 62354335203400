import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";

import "./CoreChapterActivityOptionHint.css";

import "../../Fonts.css";

interface CoreChapterActivityOptionHintProps {
  text: string;
  isWhiteBG: boolean;
  textClicked: Function;
}

const CoreChapterActivityOptionHint: React.FC<
  CoreChapterActivityOptionHintProps
> = ({ text, isWhiteBG, textClicked }) => {
  const [isOpen, setIsOpen] = useState(false);
  var router = useIonRouter();
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const textCoreClicked = () => {
      textClicked(text);
  };

  return (
    <div className={isWhiteBG ? "acceMainContainer ccaohMainContainer" : "acceMainContainer ccaohMainContainerGray"}>
      <IonGrid>
        <IonRow>
          <IonCol className="acceTitleCol ccaoh-acceTitleCol" size="12">
            <IonText className="acceTitleText" onClick={() => {
                textCoreClicked();
              }}  dangerouslySetInnerHTML={{ __html: text }}></IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default CoreChapterActivityOptionHint;

import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";

import "./StarRatingSummary.css";

import "../../Fonts.css";
import { useEffect, useState } from "react";

interface StarRatingSummaryProps {
  stars: number;
  text: string;
  ratingDesc: string;
}

const StarRatingSummary: React.FC<StarRatingSummaryProps> = ({
  stars,
  text,
  ratingDesc,
}) => {
  const [starsArr, setStarsArr] = useState([false, false, false, false, false]);
  const [finalText, setFinalText]= useState(text);

  useEffect(() => {
    setStarsArr(Array(stars).fill(false));
    setFinalText(text.replace("{x}", ratingDesc));
  }, [finalText, ratingDesc, stars]);

  return (
    <div className="srsTextMainDiv">
      <IonGrid>
        <IonRow>
          <IonCol className="srsTextCol" size="12">
            <IonText
              className="srsText"
              dangerouslySetInnerHTML={{ __html: finalText }}
            ></IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="srsStarsDivMain">
        <div className="srsStarsDiv">
          {starsArr.map((star, i) => {
            return (
              <IonImg className="srsStarImg" src="assets/icons/Star.png" />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default StarRatingSummary;

import {
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonText,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./CoreFnFBars.css";

import "../../Fonts.css";
import { CoreFnfJsonDataType } from "../../services/DataService";

interface CoreFnFBarsProps {
  question: string;
  isQuestion: boolean;
  isRight?: boolean;
  continueHandler?: () => void;
  currentQuestion?: CoreFnfJsonDataType;
  setIsQuestion?: React.Dispatch<React.SetStateAction<boolean>>;
  hideContinue: boolean;
  finalAnswerSelected: Function;
  answerSelected: Function;
}
const CoreFnFBars: React.FC<CoreFnFBarsProps> = ({
  question,
  continueHandler,
  currentQuestion,
  isQuestion,
  setIsQuestion,
  hideContinue,
  finalAnswerSelected,
  answerSelected
}) => {
  //const [isQuestion, setIsQuestion] = useState(true);
  const [isRight, setIsRight] = useState(false);
  const getBackgroundDiv = (isright: boolean, isques: boolean) => {
    if (isQuestion) {
      return "corefnfbarQuesContainer";
    } else {
      if (isright) {
        return "corefnfbarQuesRightContainer";
      } else {
        return "corefnfbarQuesWrongContainer";
      }
    }
  };

  const factHandler = () => {
    answerSelected(currentQuestion, "fact");

    if (setIsQuestion) {
      setIsQuestion(!isQuestion);
    } 
    if (currentQuestion?.answerType.toLocaleLowerCase() === "fact") {
      setIsRight(true);
    } else {
      setIsRight(false);
    }
    console.log(isRight,"fact");

    if (hideContinue) {
      finalAnswerSelected();
    }
  };

  const fictionHandler = () => {
    answerSelected(currentQuestion, "fiction");

    if (setIsQuestion) {
      setIsQuestion(!isQuestion);
    }
    if (currentQuestion?.answerType.toLocaleLowerCase() === "fiction") {
      setIsRight(true);
    } else {
      setIsRight(false);
    }
    console.log(isRight,"fiction");

    if (hideContinue) {
      finalAnswerSelected();
    }
  };

  const getDescriptionStyle = (isright: boolean) => {
    if (isright) {
      return "corefnfTitleText";
    } else {
      return "corefnfWrongTitleDescText";
    }
  };

  // const [selectedStates, setSelectedStates] = useState([false]);

  // useEffect(() => {
  //   let tempArr = [];
  //   tempArr = new Array(listOptions.length).fill(false);
  //   setSelectedStates(tempArr);
  // }, [])

  // const setOptionselected = (index: number) => {
  //   let tempArr = [];
  //   tempArr = new Array(listOptions.length).fill(false);
  //   tempArr[index] = true;
  //   setSelectedStates(tempArr);
  // };

  return (
    <div className={getBackgroundDiv(isRight, isQuestion)}>
      <IonGrid>
        {/* {!isRight && !isQuestion && (
          <IonRow className="corefnfRowfirst">
            <IonText className="corefnfWrongTitleText">
              Thanks for trying!
            </IonText>
          </IonRow>
        )} */}
        {isRight && !isQuestion && (
          <IonRow className="corefnfRowfirst">
            <IonText className="corefnfRightTitleText">Thats Right! <span className="coreFnfRightText">{currentQuestion?.answer}</span></IonText>
          </IonRow>
        )}

        {!isQuestion && !isRight && (currentQuestion?.answer !== null) && (currentQuestion?.answer !== undefined) && (currentQuestion?.answer.length > 0)  && (
          <IonRow className="corefnfRowRight">
            <IonText className="corefnfWrongTitleText">
              The correct answer is: <span className="coreFnfWrongText">{currentQuestion?.answer}</span>
            </IonText>
          </IonRow>
        )}

        {/* {!isQuestion && (
          <IonRow className="corefnfRow">
            <IonText className={getDescriptionStyle(isRight)}>
              {currentQuestion?.answer}
            </IonText>
          </IonRow>
        )} */}
        {isQuestion && (
          <IonRow className="corefnfRow">
            <IonText className="corefnfTitleText">{question}</IonText>
          </IonRow>
        )}
        {isQuestion && (
          <IonRow>
            <div className="corefnfdiv" onClick={factHandler}>
              <IonText
                className="corefnfbuttonTitleText"
              >
                Fact
              </IonText>
            </div>
          </IonRow>
        )}
        {isQuestion && (
          <IonRow>
            <div className="corefnfseconddiv" onClick={fictionHandler}>
              <IonText
                className="corefnfbuttonTitleText"
              >
                Fiction
              </IonText>
            </div>
          </IonRow>
        )}

        {!hideContinue && !isQuestion && (
          <IonRow>
            <div className="corefnfseconddiv" onClick={continueHandler}>
              <IonText
                className="corefnfbuttonTitleText"
              >
                Continue
              </IonText>
            </div>
          </IonRow>
        )}
      </IonGrid>
    </div>
  );
};

export default CoreFnFBars;

import {
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";

import "./TaskSelection.css";

import "../../Fonts.css";
import TaskSelectionOption from "../task_selection_option/TaskSelectionOption";
import { DataSubmissionService } from "../../services/DataSubmissionService";

interface TaskSelectionProps {
  question: string;
  options: Array<any>;
  click: Function;
  mainOptionClick: Function;
}

const TaskSelection: React.FC<TaskSelectionProps> = ({
  question,
  options,
  click,
  mainOptionClick
}) => {
  var [optionsArraySelected, setOptionsArraySelected] = useState([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    let savedOptionIndex = DataSubmissionService.chooseAGoalOptionMainQuestionIndex;
    optionSelected(savedOptionIndex);
  }, []);

  const optionSelected = (index: number) => {
    let optsSelArr = [];
    optionsArraySelected.map((opt, index) => {
      optsSelArr.push(false);
    });
    optsSelArr[index] = true;
    mainOptionClick(index);
    setOptionsArraySelected(optsSelArr);
  };

  return (
    <div className="tsMainMainContainer">
      <div className="">
        <IonText
          className="mmsMainDesc2Text"
          dangerouslySetInnerHTML={{ __html: question }}
        ></IonText>
      </div>
      <div className="tsMainContainer">
        {options.map((option: any, i: any) => {
          return (
            <TaskSelectionOption
              index={i}
              isOpen={optionsArraySelected[i]}
              isSelected={optionSelected}
              imageUrl={option.imageUrl}
              title={option.title}
              subtitle={option.subtitle}
              desc={option.desc}
              options={option.options}
              click={click}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TaskSelection;

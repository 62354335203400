import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/react";
import { useRef, useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./AccordianCoreContent.css";

import "../../Fonts.css";
import AccordianCoreContentExp from "../accordian_core_content_exp/AccordianCoreContentExp";
import AccordianCoreContentBioExp from "../accordian_core_content_bio_exp/AccordianCoreContentBioExp";

interface AccordianCoreContentProps {
  title: string;
  description: string;
  imageUrl: string;
  expansionPoints: Array<string>;
  bioExpansionPoints: any;
  audioUrl: string;
  open: boolean;
  isOpened: Function;
}

const AccordianCoreContent: React.FC<AccordianCoreContentProps> = ({
  title,
  description,
  imageUrl,
  expansionPoints,
  bioExpansionPoints,
  audioUrl,
  open,
  isOpened
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [showDesc, setShowDesc] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      isOpened()
    }
  };

  const toggleDesc = () => {
    setShowDesc(!showDesc);
  };

  const togglePlay = () => {
    if (isPlaying) {
      if (audioRef !== null && audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    } else {
      if (audioRef !== null && audioRef.current) {
        audioRef.current.play();
      }
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="accMainContainer accccMainContainer">
      <div>
        {/* <button onClick={togglePlay}>{isPlaying ? "Pause" : "Play"}</button> */}
        <audio ref={audioRef}>
          <source src={audioUrl} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <IonGrid
        className="accTitleGrid accccTitleGrid"
        onClick={toggleAccordion}
      >
        <IonRow>
          <IonCol size="auto">
            {/* <div className="accLessonImg"></div> */}
            <IonImg src={imageUrl} style={{ width: "70px" }} />
          </IonCol>
          <IonCol className="accTitleCol accccTitleCol" size="8">
            <IonText className="accTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen && (showDesc || (expansionPoints !== null && expansionPoints !== undefined && expansionPoints.length > 0)) && description.length === 0 && (
        <div>
          {expansionPoints.map((point, i) => {
            return <AccordianCoreContentExp text={point} />;
          })}
          {/* <AccordianCoreContentExp />
          <AccordianCoreContentExp />
          <AccordianCoreContentExp /> */}
        </div>
      )}
      {isOpen && description.length > 0 && (
        <div>
          <IonGrid class="accAudioDescBtnsGrid">
            <div className="accAudioDescBtnsDiv">
              <IonRow>
                <IonCol className="accShowTextCol">
                  <IonText className="accShowText" onClick={toggleDesc}>
                    {showDesc ? "Hide Text" : "Show Text"}
                  </IonText>
                </IonCol>
                <IonCol>
                  <div className="accVolumeBtnDiv">
                    <IonImg
                      className="accVolumeImg"
                      src="assets/icons/volume.png"
                    />
                    <div className="accVolumeBtnTextDiv" onClick={togglePlay}>
                      <IonText className="accVolumeBtnText">
                        Listen Audio
                      </IonText>
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </div>
          </IonGrid>
          {showDesc && (
            <div className="accDescTextContainer">
              <IonText class="accDescText">{`"${description}"`}</IonText>
            </div>
          )}
          <AccordianCoreContentBioExp
            description={bioExpansionPoints.description}
            bioDesc={bioExpansionPoints.bioDesc}
            age={bioExpansionPoints.age}
            diagnosis={bioExpansionPoints.diagnosis}
            location={bioExpansionPoints.location}
            ageOfDiagnosis={bioExpansionPoints.ageOfDiagnosis}
            skinType={bioExpansionPoints.skinType}
            hair={bioExpansionPoints.hair}
            eyes={bioExpansionPoints.eyes}
          />
        </div>
      )}
    </div>
  );
};

export default AccordianCoreContent;

import {
  IonCol,
  IonGrid,
  IonRow,
  IonToggle,
} from "@ionic/react";
import "./ToggleButton.css";
import "../../Fonts.css";

interface ToggleButtonProps {
  Title: string;
  isOn: boolean;
  toggleEvent: Function;
}

const ToggleButton: React.FC<ToggleButtonProps> = ({
  Title,
  isOn,
  toggleEvent
}) => {
  return (
    <>
      <div className="subAccMainContainer skinCheckContainer">
        <IonGrid>
          <IonRow className="ion-align-items-center">
            <IonCol className="text-default link-label">{Title}</IonCol>
            <IonCol size="2">
              <IonToggle checked={isOn} onIonChange={(event: any) => {
                toggleEvent(event);
              }}></IonToggle>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default ToggleButton;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonPopover,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import "./SkinCheckEnd.css";

import { useRef, useState } from "react";
import ProgressFraction from "../../components/progress_fraction/ProgressFraction";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import { UserService } from "../../services/UserService";

const SkinCheckEnd: React.FC = () => {
  var router = useIonRouter();
  const popover = useRef<HTMLIonPopoverElement>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setPopoverOpen(true);
  };

  const navigateBack = () => {
    UserService.controlBottomBarFromBack=2;
    router.goBack();
  };

  const calculatePartsPercentage = () => {
    console.log("skinselfcheckTotalRegionsSpotsCount end",SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount)
    var per = updateSelSpots()/
     SkinSelfCheckDataService.skinselfcheckTotalRegionsCount;
    per = per*100;
    return Math.round(per);
  }

  const updateSelSpots = () => {
    var bodymap = SkinSelfCheckDataService.bodyMap;
    SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount = 0;
    for (var i = 0; i < bodymap.regions.length; i++) {
      var parts = bodymap.regions[i].parts;
      for (var j = 0; j < parts.length; j++) {
        var localpart = parts[j];
        for(var k=0;k<localpart.parts.length;k++){
          var localinnerpart =localpart.parts[k];
          if ("spots" in localinnerpart||localpart.isChecked==true) {
            SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount =
              SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount + 1;
              break;
          }
        }
        
      }
    }
    console.log("skinselfcheckTotalRegionsSpotsCount end",SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount)
    return SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount;
  }

  const navigateToBodymap = () => {
    SkinSelfCheckDataService.selectedChars = [];
    SkinSelfCheckDataService.selectedDia = -1;
    SkinSelfCheckDataService.selectedImgUrl = "";
    UserService.controlBottomBarFromBack=2;
    router.push("home/skinchecktab", "forward", "push");
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <IonPopover
          ref={popover}
          isOpen={popoverOpen}
          onDidDismiss={() => setPopoverOpen(false)}
        >
          <IonContent class="ion-padding">Hello World!</IonContent>
        </IonPopover>

        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonText className="titleText">
              {SkinSelfCheckDataService.selectedRegionSubPart.title.length > 0 ? SkinSelfCheckDataService.selectedRegionSubPart.title : ""}
            </IonText>
          </IonRow>
        </IonGrid>
        <div
          className="mainContent skin-selfcheck-Content"
          style={{ marginTop: "54px", paddingTop: "80px" }}
        >
          {/* Back Button */}
          <div className="cciBackButtonContainer" onClick={navigateToBodymap}>
            <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
          </div>
          {/* _______________________________________________________________________ */}
          {/* Progress bar */}
          <ProgressFraction
            percent={calculatePartsPercentage()}
            fraction={updateSelSpots()+"/"+
          SkinSelfCheckDataService.skinselfcheckTotalRegionsCount}
            description="Body Parts"
          />

          <div className="sceImgDiv">
            <IonImg src="/assets/images/Welldonenew.png" className="sceImg" />
          </div>

          <div className="sceMainTextDiv">
            <IonText className="sceMainText">Well done!</IonText>
          </div>

          <div className="sceMainText2Div">
            <IonText className="sceMainText2">
              You've logged a new spot.
            </IonText>
          </div>

          <div className="sceMainText3Div">
            <IonText className="sceMainText3">
              Total unique spots logged
            </IonText>
            <div className="sceMainText3NoDiv">
              <IonText className="sceMainText3No">1</IonText>
            </div>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled scsdNextBtn"
                onClick={navigateToBodymap}
              >
                <IonImg
                  className="sceContinueImg"
                  src="/assets/icons/CheckCircleOutline.png"
                />
                <IonText>Continue</IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckEnd;

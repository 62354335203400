import axios from "axios";
import { Component } from "react";
import {
  getApi,
  patchAppTime,
  patchSSCTime,
  patchSectionsTime,
  postAppTime,
  postSSCTime,
  postSectionsTime,
} from "../services/dataApi";
import { DataService } from "./DataService";
import { UserService } from "./UserService";

export abstract class AppInfoTimeService {
  protected abstract getBaseUrl(): string;

  //App Session Time
  public static appSessionTimeId: any;

  public static appSessionTimeNid: number = -1;

  public static isAppTimerStarted = false;

  public static appStartTime: string = "";

  public static appEndTime: string = "";

  public static sendAppTimeToBE = async () => {
    if (!AppInfoTimeService.isAppTimerStarted) {
      AppInfoTimeService.isAppTimerStarted = true;

      AppInfoTimeService.appStartTime = new Date().toString();

      AppInfoTimeService.appSessionTimeId = setInterval(async () => {
        if (UserService.csrfToken.length > 0) {
          AppInfoTimeService.appEndTime = new Date().toString();

          console.log(
            "App Start Time >>>>>>> " + AppInfoTimeService.appStartTime
          );
          console.log("App End Time >>>>>>> " + AppInfoTimeService.appEndTime);

          const formattedStartTime = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false, // Use 24-hour format
          }).format(new Date(AppInfoTimeService.appStartTime));

          const formattedEndTime = new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: false, // Use 24-hour format
          }).format(new Date(AppInfoTimeService.appEndTime));

          //Send to BE here

          if (AppInfoTimeService.appSessionTimeNid === -1) {
            //Post
            try {
              // openLoader();
              const responseData = await postAppTime(
                {
                  type: [
                    {
                      target_id: "time_in_app",
                    },
                  ],
                  title: [
                    {
                      value: "time_in",
                    },
                  ],
                  field_start: [
                    {
                      value: formattedStartTime,
                    },
                  ],
                  field_end: [
                    {
                      value: formattedEndTime,
                    },
                  ],
                },
                (isSuccess: boolean, respData: any) => {
                  if (isSuccess) {
                    //Success
                    // closeLoader();
                    const nid = respData.nid;
                    console.log(nid);
                  } else if (!isSuccess) {
                    //Error
                    // closeLoader();
                    console.log(respData);
                  }
                }
              );
              console.log(responseData);
            } catch (error) {
              // closeLoader();
              console.error("Error fetching data:", error);
            }
          } else {
            //Patch to NID
            console.log("Patch to NID VVVV " + this.appSessionTimeNid);
            try {
              // openLoader();
              const responseData = await patchAppTime(
                {
                  type: [
                    {
                      target_id: "time_in_app",
                    },
                  ],
                  title: [
                    {
                      value: "time_in",
                    },
                  ],
                  field_start: [
                    {
                      value: formattedStartTime,
                    },
                  ],
                  field_end: [
                    {
                      value: formattedEndTime,
                    },
                  ],
                },
                AppInfoTimeService.appSessionTimeNid.toString()
              );
            } catch (error) {
              // closeLoader();
              console.error("Error fetching data:", error);
            }
          }
        }
      }, 30000);
    }
  };

  /////////////////////////////////////////////////////////////////////////////////////////

  //SSE Time
  public static appSSETimeId: any;

  public static skinSelfCheckStartTime: string = "";

  public static skinSelfCheckEndTime: string = "";

  public static appSkinSelfCheckNid: number = -1;

  public static sendSSETimeToBE = async () => {
    AppInfoTimeService.appSSETimeId = setInterval(async () => {
      if (AppInfoTimeService.appSkinSelfCheckNid === -1) {
        AppInfoTimeService.skinSelfCheckStartTime = new Date().toString();

        // AppInfoTimeService.appSSETimeId = setInterval(() => {
        AppInfoTimeService.skinSelfCheckEndTime = new Date().toString();

        const formattedStartTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date(AppInfoTimeService.skinSelfCheckStartTime));

        const formattedEndTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date(AppInfoTimeService.skinSelfCheckEndTime));

        console.log(
          "SSE Start Time >>>>>>> " + AppInfoTimeService.skinSelfCheckStartTime
        );
        console.log(
          "SSE End Time >>>>>>> " + AppInfoTimeService.skinSelfCheckEndTime
        );

        //Send to BE here
        //Post
        try {
          // openLoader();
          const responseData = await postSSCTime(
            {
              type: [
                {
                  target_id: "time_in_ssc",
                },
              ],
              title: [
                {
                  value: "time_in_ssc",
                },
              ],
              field_start: [
                {
                  value: formattedStartTime,
                },
              ],
              field_end: [
                {
                  value: formattedEndTime,
                },
              ],
            },
            (isSuccess: boolean, respData: any) => {
              if (isSuccess) {
                //Success
                // closeLoader();
                const nid = respData.nid;
                console.log(nid);
              } else if (!isSuccess) {
                //Error
                // closeLoader();
                console.log(respData);
              }
            }
          );
          console.log(responseData);
        } catch (error) {
          // closeLoader();
          console.error("Error fetching data:", error);
        }
      } else {
        // clearInterval(this.appSSETimeId);

        // AppInfoTimeService.skinSelfCheckStartTime = new Date().toString();

        // AppInfoTimeService.appSSETimeId = setInterval(() => {
        AppInfoTimeService.skinSelfCheckEndTime = new Date().toString();

        const formattedStartTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date(AppInfoTimeService.skinSelfCheckStartTime));

        const formattedEndTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date());

        //Patch to NID
        console.log("Patch to NID VVVV " + this.appSkinSelfCheckNid);
        try {
          // openLoader();
          const responseData = await patchSSCTime(
            {
              type: [
                {
                  target_id: "time_in_ssc",
                },
              ],
              title: [
                {
                  value: "time_in_ssc",
                },
              ],
              field_start: [
                {
                  value: formattedStartTime,
                },
              ],
              field_end: [
                {
                  value: formattedEndTime,
                },
              ],
            },
            AppInfoTimeService.appSkinSelfCheckNid.toString()
          );
        } catch (error) {
          // closeLoader();
          console.error("Error fetching data:", error);
        }
      }
    }, 30000);
  };

  public static resetSSETimer = async () => {
    clearInterval(this.appSSETimeId);
    AppInfoTimeService.appSkinSelfCheckNid = -1;
  };

  // public static sendSSETimeToBE = async () => {
  //   AppInfoTimeService.skinSelfCheckStartTime = new Date().toString();

  //   // AppInfoTimeService.appSSETimeId = setInterval(() => {
  //   AppInfoTimeService.skinSelfCheckEndTime = new Date().toString();

  //   const formattedStartTime = new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false, // Use 24-hour format
  //   }).format(new Date(AppInfoTimeService.skinSelfCheckStartTime));

  //   const formattedEndTime = new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false, // Use 24-hour format
  //   }).format(new Date(AppInfoTimeService.skinSelfCheckEndTime));

  //   console.log(
  //     "SSE Start Time >>>>>>> " + AppInfoTimeService.skinSelfCheckStartTime
  //   );
  //   console.log(
  //     "SSE End Time >>>>>>> " + AppInfoTimeService.skinSelfCheckEndTime
  //   );

  //   //Send to BE here
  //   //Post
  //   try {
  //     // openLoader();
  //     const responseData = await postSSCTime(
  //       {
  //         type: [
  //           {
  //             target_id: "time_in_ssc",
  //           },
  //         ],
  //         title: [
  //           {
  //             value: "time_in_ssc",
  //           },
  //         ],
  //         field_start: [
  //           {
  //             value: formattedStartTime,
  //           },
  //         ],
  //         field_end: [
  //           {
  //             value: formattedEndTime,
  //           },
  //         ],
  //       },
  //       (isSuccess: boolean, respData: any) => {
  //         if (isSuccess) {
  //           //Success
  //           // closeLoader();
  //           const nid = respData.nid;
  //           console.log(nid);
  //         } else if (!isSuccess) {
  //           //Error
  //           // closeLoader();
  //           console.log(respData);
  //         }
  //       }
  //     );
  //     console.log(responseData);
  //   } catch (error) {
  //     // closeLoader();
  //     console.error("Error fetching data:", error);
  //   }
  //   // }, 5000);
  // };

  // public static resetSSETimer = async () => {
  //   // clearInterval(this.appSSETimeId);

  //   // AppInfoTimeService.skinSelfCheckStartTime = new Date().toString();

  //   // AppInfoTimeService.appSSETimeId = setInterval(() => {
  //   AppInfoTimeService.skinSelfCheckEndTime = new Date().toString();

  //   const formattedStartTime = new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false, // Use 24-hour format
  //   }).format(new Date(AppInfoTimeService.skinSelfCheckStartTime));

  //   const formattedEndTime = new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false, // Use 24-hour format
  //   }).format(new Date());

  //   //Patch to NID
  //   console.log("Patch to NID VVVV " + this.appSkinSelfCheckNid);
  //   try {
  //     // openLoader();
  //     const responseData = await patchSSCTime(
  //       {
  //         type: [
  //           {
  //             target_id: "time_in_ssc",
  //           },
  //         ],
  //         title: [
  //           {
  //             value: "time_in_ssc",
  //           },
  //         ],
  //         field_start: [
  //           {
  //             value: formattedStartTime,
  //           },
  //         ],
  //         field_end: [
  //           {
  //             value: formattedEndTime,
  //           },
  //         ],
  //       },
  //       AppInfoTimeService.appSkinSelfCheckNid.toString()
  //     );
  //   } catch (error) {
  //     // closeLoader();
  //     console.error("Error fetching data:", error);
  //   }
  // };

  /////////////////////////////////////////////////////////////////////////////////////////

  //Sections Time
  public static appSectionsTimeId: any;

  public static appSectionsStartTime: string = "";

  public static appSectionsEndTime: string = "";

  public static appSectionsTimeNid: number = -1;

  public static appSectionsCurrentSectionNo = -1;

  public static sendAppSectionsTimeToBE = async (sectionNo: number) => {
    AppInfoTimeService.appSectionsTimeId = setInterval(async () => {
      if (AppInfoTimeService.appSectionsTimeNid === -1) {
        AppInfoTimeService.appSectionsStartTime = new Date().toString();
        AppInfoTimeService.appSectionsEndTime = new Date().toString();

        AppInfoTimeService.appSectionsCurrentSectionNo = sectionNo;

        const formattedStartTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date(AppInfoTimeService.appSectionsStartTime));

        const formattedEndTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date(AppInfoTimeService.appSectionsEndTime));

        console.log(`Section No >>>>>>> ${sectionNo}`);
        console.log(
          "Sections Start Time >>>>>>> " +
            AppInfoTimeService.appSectionsStartTime
        );
        console.log(
          "Sections End Time >>>>>>> " + AppInfoTimeService.appSectionsEndTime
        );

        //Send to BE here
        try {
          // openLoader();
          const responseData = await postSectionsTime(
            {
              type: [
                {
                  target_id: "time_in_sections",
                },
              ],
              title: [
                {
                  value: "time_in_sections",
                },
              ],
              field_sections: [
                {
                  value: sectionNo,
                },
              ],
              field_start: [
                {
                  value: formattedStartTime,
                },
              ],
              field_end: [
                {
                  value: formattedEndTime,
                },
              ],
            },
            (isSuccess: boolean, respData: any) => {
              if (isSuccess) {
                //Success
                // closeLoader();
                const nid = respData.nid;
                console.log(nid);
              } else if (!isSuccess) {
                //Error
                // closeLoader();
                console.log(respData);
              }
            }
          );
          console.log(responseData);
        } catch (error) {
          // closeLoader();
          console.error("Error fetching data:", error);
        }
      } else {
        //Patch

        const formattedStartTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date(AppInfoTimeService.appSectionsStartTime));

        const formattedEndTime = new Intl.DateTimeFormat("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false, // Use 24-hour format
        }).format(new Date());

        console.log(`Section No >>>>>>> ${sectionNo}`);
        console.log(
          "Sections Patch Start Time >>>>>>> " + formattedStartTime.toString()
        );
        console.log(
          "Sections Patch End Time >>>>>>> " + formattedEndTime.toString()
        );

        const responseData = await patchSectionsTime(
          {
            type: [
              {
                target_id: "time_in_sections",
              },
            ],
            title: [
              {
                value: "time_in_sections",
              },
            ],
            field_sections: [
              {
                value: sectionNo,
              },
            ],
            field_start: [
              {
                value: formattedStartTime,
              },
            ],
            field_end: [
              {
                value: formattedEndTime,
              },
            ],
          },
          AppInfoTimeService.appSectionsTimeNid.toString()
        );
      }
    }, 30000);
  };

  public static resetAppSectionsTimer = async () => {
    clearInterval(this.appSectionsTimeId);
    AppInfoTimeService.appSectionsTimeNid = -1;

    // let prevSecNo = AppInfoTimeService.appSectionsCurrentSectionNo;
    // let currSecNo = sectionNo;

    // const formattedStartTime = new Intl.DateTimeFormat("en-US", {
    //   hour: "numeric",
    //   minute: "numeric",
    //   second: "numeric",
    //   hour12: false, // Use 24-hour format
    // }).format(new Date(AppInfoTimeService.appSectionsStartTime));

    // const formattedEndTime = new Intl.DateTimeFormat("en-US", {
    //   hour: "numeric",
    //   minute: "numeric",
    //   second: "numeric",
    //   hour12: false, // Use 24-hour format
    // }).format(new Date());

    // //Patch to NID
    // console.log("Patch to NID VVVV " + this.appSectionsTimeNid);
    // try {
    //   // openLoader();
    //   const responseData = await patchSectionsTime(
    //     {
    //       type: [
    //         {
    //           target_id: "time_in_sections",
    //         },
    //       ],
    //       title: [
    //         {
    //           value: "time_in_sections",
    //         },
    //       ],
    //       field_sections: [
    //         {
    //           value: prevSecNo,
    //         },
    //       ],
    //       field_start: [
    //         {
    //           value: formattedStartTime,
    //         },
    //       ],
    //       field_end: [
    //         {
    //           value: formattedEndTime,
    //         },
    //       ],
    //     },
    //     AppInfoTimeService.appSectionsTimeNid.toString()
    //   );

    //   if (prevSecNo !== currSecNo) {
    //     AppInfoTimeService.appSectionsCurrentSectionNo = currSecNo;

    //     AppInfoTimeService.appSectionsStartTime = new Date().toString();
    //     AppInfoTimeService.appSectionsEndTime = new Date().toString();

    //     const formattedStartTime = new Intl.DateTimeFormat("en-US", {
    //       hour: "numeric",
    //       minute: "numeric",
    //       second: "numeric",
    //       hour12: false, // Use 24-hour format
    //     }).format(new Date(AppInfoTimeService.appSectionsStartTime));

    //     const formattedEndTime = new Intl.DateTimeFormat("en-US", {
    //       hour: "numeric",
    //       minute: "numeric",
    //       second: "numeric",
    //       hour12: false, // Use 24-hour format
    //     }).format(new Date(AppInfoTimeService.appSectionsEndTime));

    //     //Send to BE here
    //     try {
    //       // openLoader();
    //       const responseData = await postSectionsTime(
    //         {
    //           type: [
    //             {
    //               target_id: "time_in_sections",
    //             },
    //           ],
    //           title: [
    //             {
    //               value: "time_in_sections",
    //             },
    //           ],
    //           field_sections: [
    //             {
    //               value: currSecNo,
    //             },
    //           ],
    //           field_start: [
    //             {
    //               value: formattedStartTime,
    //             },
    //           ],
    //           field_end: [
    //             {
    //               value: formattedEndTime,
    //             },
    //           ],
    //         },
    //         (isSuccess: boolean, respData: any) => {
    //           if (isSuccess) {
    //             //Success
    //             // closeLoader();
    //             const nid = respData.nid;
    //             console.log(nid);
    //           } else if (!isSuccess) {
    //             //Error
    //             // closeLoader();
    //             console.log(respData);
    //           }
    //         }
    //       );
    //       console.log(responseData);
    //     } catch (error) {
    //       // closeLoader();
    //       console.error("Error fetching data:", error);
    //     }
    //   }
    // } catch (error) {
    //   // closeLoader();
    //   console.error("Error fetching data:", error);
    // }
  };

  // public static sendAppSectionsTimeToBE = async(sectionNo: number) => {
  //   AppInfoTimeService.appSectionsStartTime = new Date().toString();

  //   // AppInfoTimeService.appSectionsTimeId = setInterval(() => {
  //     AppInfoTimeService.appSectionsEndTime = new Date().toString();

  //     AppInfoTimeService.appSectionsCurrentSectionNo = sectionNo;

  //     const formattedStartTime = new Intl.DateTimeFormat("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: false, // Use 24-hour format
  //     }).format(new Date(AppInfoTimeService.appSectionsStartTime));

  //     const formattedEndTime = new Intl.DateTimeFormat("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: false, // Use 24-hour format
  //     }).format(new Date(AppInfoTimeService.appSectionsEndTime));

  //     console.log(`Section No >>>>>>> ${sectionNo}`);
  //     console.log(
  //       "Sections Start Time >>>>>>> " + AppInfoTimeService.appSectionsStartTime
  //     );
  //     console.log(
  //       "Sections End Time >>>>>>> " + AppInfoTimeService.appSectionsEndTime
  //     );

  //     //Send to BE here
  //     try {
  //       // openLoader();
  //       const responseData = await postSectionsTime(
  //         {
  //           type: [
  //             {
  //               target_id: "time_in_sections",
  //             },
  //           ],
  //           title: [
  //             {
  //               value: "time_in_sections",
  //             },
  //           ],
  //           field_sections: [
  //             {
  //               value: sectionNo,
  //             },
  //           ],
  //           field_start: [
  //             {
  //               value: formattedStartTime,
  //             },
  //           ],
  //           field_end: [
  //             {
  //               value: formattedEndTime,
  //             },
  //           ],
  //         },
  //         (isSuccess: boolean, respData: any) => {
  //           if (isSuccess) {
  //             //Success
  //             // closeLoader();
  //             const nid = respData.nid;
  //             console.log(nid);
  //           } else if (!isSuccess) {
  //             //Error
  //             // closeLoader();
  //             console.log(respData);
  //           }
  //         }
  //       );
  //       console.log(responseData);
  //     } catch (error) {
  //       // closeLoader();
  //       console.error("Error fetching data:", error);
  //     }

  //   // }, 5000);
  // };

  // public static resetAppSectionsTimer = async(sectionNo: number) => {
  //   // clearInterval(this.appSectionsTimeId);

  //   let prevSecNo = AppInfoTimeService.appSectionsCurrentSectionNo;
  //   let currSecNo = sectionNo;

  //   const formattedStartTime = new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false, // Use 24-hour format
  //   }).format(new Date(AppInfoTimeService.appSectionsStartTime));

  //   const formattedEndTime = new Intl.DateTimeFormat("en-US", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //     hour12: false, // Use 24-hour format
  //   }).format(new Date());

  //       //Patch to NID
  //       console.log("Patch to NID VVVV " + this.appSectionsTimeNid);
  //       try {
  //         // openLoader();
  //         const responseData = await patchSectionsTime(
  //           {
  //             type: [
  //               {
  //                 target_id: "time_in_sections",
  //               },
  //             ],
  //             title: [
  //               {
  //                 value: "time_in_sections",
  //               },
  //             ],
  //             field_sections: [
  //               {
  //                 value: prevSecNo,
  //               },
  //             ],
  //             field_start: [
  //               {
  //                 value: formattedStartTime,
  //               },
  //             ],
  //             field_end: [
  //               {
  //                 value: formattedEndTime,
  //               },
  //             ],
  //           },
  //           AppInfoTimeService.appSectionsTimeNid.toString()
  //         );

  //         if (prevSecNo !== currSecNo) {
  //           AppInfoTimeService.appSectionsCurrentSectionNo = currSecNo;

  //           AppInfoTimeService.appSectionsStartTime = new Date().toString();
  //           AppInfoTimeService.appSectionsEndTime = new Date().toString();

  //           const formattedStartTime = new Intl.DateTimeFormat("en-US", {
  //             hour: "numeric",
  //             minute: "numeric",
  //             second: "numeric",
  //             hour12: false, // Use 24-hour format
  //           }).format(new Date(AppInfoTimeService.appSectionsStartTime));

  //           const formattedEndTime = new Intl.DateTimeFormat("en-US", {
  //             hour: "numeric",
  //             minute: "numeric",
  //             second: "numeric",
  //             hour12: false, // Use 24-hour format
  //           }).format(new Date(AppInfoTimeService.appSectionsEndTime));

  //           //Send to BE here
  //           try {
  //             // openLoader();
  //             const responseData = await postSectionsTime(
  //               {
  //                 type: [
  //                   {
  //                     target_id: "time_in_sections",
  //                   },
  //                 ],
  //                 title: [
  //                   {
  //                     value: "time_in_sections",
  //                   },
  //                 ],
  //                 field_sections: [
  //                   {
  //                     value: currSecNo,
  //                   },
  //                 ],
  //                 field_start: [
  //                   {
  //                     value: formattedStartTime,
  //                   },
  //                 ],
  //                 field_end: [
  //                   {
  //                     value: formattedEndTime,
  //                   },
  //                 ],
  //               },
  //               (isSuccess: boolean, respData: any) => {
  //                 if (isSuccess) {
  //                   //Success
  //                   // closeLoader();
  //                   const nid = respData.nid;
  //                   console.log(nid);
  //                 } else if (!isSuccess) {
  //                   //Error
  //                   // closeLoader();
  //                   console.log(respData);
  //                 }
  //               }
  //             );
  //             console.log(responseData);
  //           } catch (error) {
  //             // closeLoader();
  //             console.error("Error fetching data:", error);
  //           }
  //         }
  //       } catch (error) {
  //         // closeLoader();
  //         console.error("Error fetching data:", error);
  //       }
  // };
}

import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import { useState } from "react";

import "./TaskSelectionOptionHint.css";

import "../../Fonts.css";

interface TaskSelectionOptionHintProps {
  text: string;
  imageUrl: string;
  isFullWidth: boolean;
  defaultSpacingTop: boolean;
}

const TaskSelectionOptionHint: React.FC<TaskSelectionOptionHintProps> = ({
  text,
  imageUrl,
  isFullWidth,
  defaultSpacingTop,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  if (!defaultSpacingTop) {
    return (
      <div
        className={
          isFullWidth
            ? "acceMainContainer ccaohMainContainerGray tsohMainContainerFullWidth"
            : "acceMainContainer ccaohMainContainerGray"
        }
      >
        <IonGrid>
          <IonRow>
            {imageUrl !== null &&
              imageUrl !== undefined &&
              imageUrl.length > 0 && (
                <IonCol size="auto">
                  <IonImg src={imageUrl} />
                </IonCol>
              )}
            <IonCol
              className="acceTitleCol tsohCol2"
              style={{
                paddingLeft:
                  imageUrl !== null &&
                  imageUrl !== undefined &&
                  imageUrl.length > 0
                    ? "16px"
                    : "0px",
              }}
            >
              <IonText className="tsohText">{text}</IonText>
            </IonCol>
            <IonCol
              className="acceTitleCol ccaoh-acceTitleCol tsohCol3"
              size="auto"
            >
              <IonImg
                className="tsohChevronImg"
                src="assets/images/RightOutline-right.svg"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  } else {
    return (
      <div
        className={
          isFullWidth
            ? "acceMainContainer ccaohMainContainerGray tsohMainContainerFullWidth"
            : "acceMainContainer ccaohMainContainerGray"
        }
        style={{ marginTop: "16px" }}
      >
        <IonGrid>
          <IonRow>
            {imageUrl !== null &&
              imageUrl !== undefined &&
              imageUrl.length > 0 && (
                <IonCol className="tsohIconCol" size="auto">
                  <IonImg src={imageUrl} />
                </IonCol>
              )}
            <IonCol
              className="acceTitleCol tsohCol2"
              style={{
                paddingLeft:
                  imageUrl !== null &&
                  imageUrl !== undefined &&
                  imageUrl.length > 0
                    ? "16px"
                    : "0px",
              }}
            >
              <IonText className="tsohText">{text}</IonText>
            </IonCol>
            <IonCol
              className="acceTitleCol ccaoh-acceTitleCol tsohCol3"
              size="auto"
            >
              <IonImg
                className="tsohChevronImg"
                src="assets/images/RightOutline-right.svg"
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    );
  }
};

export default TaskSelectionOptionHint;

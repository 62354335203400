import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  useIonRouter,
} from "@ionic/react";
import { useState } from "react";

import "./CoreContentCompletedImgTextSetGoal.css";

import "../../Fonts.css";

interface CoreContentCompletedImgTextGoalProps {
  title: string;
  desc: string;
  textLinkClick: Function;
}

const CoreContentCompletedImgTextSetGoal: React.FC<
  CoreContentCompletedImgTextGoalProps
> = ({ title, desc, textLinkClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  var router = useIonRouter();
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const navigatetogoal = () => {
    textLinkClick();
  };

  return (
    <div className="cccitMainContainerLeft animate__animated animate__fadeIn 1000ms animate__delay-1s ">
      <IonGrid>
        <IonRow>
          <IonCol size="auto" className="acceImgCol">
            <IonImg src="assets/images/RectangleLeft.png" />
          </IonCol>
          <IonCol className="acceTitleCol pl-32">
            <IonGrid>
              <IonRow>
                <IonCol className="cccitTitleCol">
                  <IonText className="cccitTitle">{title}</IonText>
                </IonCol>
                <IonCol size="12" className="cccitTitleCol">
                  <IonText className="cccitTitleDesc">{desc}</IonText>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="cccitTitleLinkCol" onClick={navigatetogoal}>
                
                  <IonText>
                  <span className="text-underline cccitTitleLink">
                  Set another goal
                  </span>{" "}
                  </IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonImg
        className="cccitAbsImgLeft"
        src="assets/images/CompletedLeft.svg"
      ></IonImg>
    </div>
  );
};

export default CoreContentCompletedImgTextSetGoal;

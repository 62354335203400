import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useEffect, useState } from "react";

import "./MaterialsMultipleSelectOption.css";

import "../../Fonts.css";

interface MaterialsMultipleSelectOptionProps {
  index: number;
  text: string;
  desc: string;
  isOpen: boolean;
  isBottomOpen: boolean;
  imageUrl: string;
  isSelected: Function;
  isBottomSelected: Function;
  clicked: Function;
  showBottomCheckBox: boolean;
  showSelectionControl: boolean;
}

const MaterialsMultipleSelectOption: React.FC<
  MaterialsMultipleSelectOptionProps
> = ({
  index,
  text,
  desc,
  isOpen,
  isBottomOpen,
  imageUrl,
  isSelected,
  isBottomSelected,
  clicked,
  showBottomCheckBox,
  showSelectionControl,
}) => {
  useEffect(() => {
    if (!showSelectionControl) {
      isSelected(0);
    }
  }, []);

  const selected = () => {
    isSelected(index);
    // clicked(index);
  };

  const bottomSelected = () => {
    isBottomSelected(index);
  };

  // const optionClicked = () => {
  //   clicked({index: index});
  // };

  // const textCLicked = () => {
  //   if (text === "<span class='text-underline'> Separate Checklist</span>") {
  //     console.log("pdf link");
  //     window.open("https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf");
  //   }
  // };

  const convertFileToDataUrl = (file: Blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  };

  // const textClicked = async () => {
  //   try {
  //     const response = await fetch("assets/pdfs/Body.pdf");
  //     const fileBlob = await response.blob();
  //     const dataUrl = await convertFileToDataUrl(fileBlob) as string;
  //     window.open(dataUrl, "_blank");
  //   } catch (error) {
  //     console.error("Error opening PDF:", error);
  //   }
  // };

  const textClicked = () => {
    if (text === "<span class='text-underline'> Separate Checklist</span>") {
      const filePath = "assets/pdfs/Body.pdf"; // Path to your PDF file in the assets folder
      window.open(filePath, "_blank");
    }
  };

  return (
    <div
      className={
        isOpen
          ? "acceMainContainer mmsoMainContainerSelected"
          : "acceMainContainer mmsoMainContainer"
      }
    >
      <IonGrid>
        <IonRow>
          <IonCol size="auto" className="mmsoImgCol">
            {isOpen && showBottomCheckBox && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/CheckSelected.png"
                onClick={selected}
              />
            )}
            {!isOpen && showBottomCheckBox && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/Check.png"
                onClick={selected}
              />
            )}

            {/* Radio Btn */}

            {showSelectionControl && isOpen && !showBottomCheckBox && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/RadioSelected.png"
                onClick={selected}
              />
            )}
            {showSelectionControl && !isOpen && !showBottomCheckBox && (
              <IonImg
                className="mcqmcoCheckImg"
                src="assets/icons/Radio.png"
                onClick={selected}
              />
            )}
          </IonCol>
          <IonCol
            className={showSelectionControl ? "mmsoImg1Col" : ""}
            size="auto"
          >
            <IonImg className="mmsoImg" src={imageUrl} />
          </IonCol>
          <IonCol className="acceTitleCol mmsoTextDescCol">
            <IonText
              className="mmsoText"
              dangerouslySetInnerHTML={{ __html: text }}
              onClick={textClicked}
            ></IonText>
            <IonText
              className="mmsoDesc"
              dangerouslySetInnerHTML={{ __html: desc }}
            ></IonText>
          </IonCol>
        </IonRow>
        {showSelectionControl && isOpen && showBottomCheckBox && (
          <IonRow>
            <IonCol size="12">
              <div className="mmsoBottomDiv">
                <IonImg
                  className="mcqmcoCheckImg"
                  src={
                    isBottomOpen
                      ? "assets/icons/CheckSelected.png"
                      : "assets/icons/Check.png"
                  }
                  onClick={bottomSelected}
                />
                <IonText className="mssoBottomText">
                  I already have this tool
                </IonText>
              </div>
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </div>
  );
};

export default MaterialsMultipleSelectOption;

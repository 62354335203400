import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { Route } from "react-router-dom";
import Home from "./pages/Home";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import Contact from "./pages/contact/Contact";
import CoreChapterActivity from "./pages/core_chapter_activity/CoreChapterActivity";
import CoreChapterContentOne from "./pages/core_chapter_content_one/CoreChapterContentOne";
import CoreChapterContents from "./pages/core_chapter_contents/CoreChapterContents";
import CoreChapterEnd from "./pages/core_chapter_end/CoreChapterEnd";
import CoreChapterIntro from "./pages/core_chapter_intro/CoreChapterIntro";
import Login from "./pages/login/Login";
import ProfileEdit from "./pages/profile_edit/ProfileEdit";
import SkinCheckSettings from "./pages/skin_check_settings/SkinCheckSettings";
import "./theme/variables.css";

import SkinCheckCameraPreview from "./pages/skin_check_camera_preview/SkinCheckCameraPreview";
import SkinCheckDiameter from "./pages/skin_check_diameter/SkinCheckDiameter";
import SkinCheckEnd from "./pages/skin_check_end/SkinCheckEnd";
import SkinCheckSpotDetails from "./pages/skin_check_spot_details/SkinCheckSpotDetails";
import SkinCheckSpotReview from "./pages/skin_check_spot_review/SkinCheckSpotReview";
import SkinSelfCheckPartSelected from "./pages/skin_self_check_part_selected/SkinSelfCheckPartSelected";
import SkinCheckSpotArchive from "./pages/skincheck_spot_archive/skincheck_spot_archive";
import SkinCheckSpotRevisit from "./pages/skincheck_spot_revisit/skincheck_spot_revisit";

import Hamburger from "./components/hamburger/hamburger";
import ComponentTesting from "./pages/component_testing/ComponentTesting";
import FormPage from "./pages/component_testing/FormPage";
import CC0 from "./pages/control_content/cc0/CC0";
import CC1 from "./pages/control_content/cc1/CC1";
import CC2 from "./pages/control_content/cc2/CC2";
import CC3 from "./pages/control_content/cc3/CC3";
import CC4 from "./pages/control_content/cc4/CC4";
import CC5 from "./pages/control_content/cc5/CC5";
import CC6 from "./pages/control_content/cc6/CC6";
import CC7 from "./pages/control_content/cc7/CC7";
import CC8 from "./pages/control_content/cc8/CC8";
import Core2Chapter1Comp from "./pages/core2_chapter1_comp/Core2Chapter1Comp";
import InitialLogin from "./pages/initial_login/InitialLogin";
import PrizeSelectedWelldone from "./pages/prize_selected_welldone/PrizeSelectedWelldone";
import RedeemPrizes from "./pages/redeem_prizes/RedeemPrizes";
import Register from "./pages/registration/Register";
import RewardsBadgesPrizes from "./pages/rewards_badges_prizes/RewardsBadgesPrizes";
import SkinCheckFaceZones from "./pages/skin_check_face_zones/SkinCheckFaceZones";
import SkinCheckImageMultiSpots from "./pages/skin_check_image_multispots/SkinCheckImageMultiSpots";
import SkinCheckTour from "./pages/skin_check_tour/SkinCheckTour";
import SkinCheckZones from "./pages/skin_check_zones/SkinCheckZones";
import SkinSelfCheckPartSel from "./pages/skin_self_check_part_sel/SkinSelfCheckPartSel";
import SkinSelfCheckSpotHistory from "./pages/skin_check_spots_history/skincheck_spot_history";
import SkinCheckCamera from "./pages/skincheck_camera/SkinCheckCamera";
import Sprite from "./pages/sprite/Sprite";
import ForgotPassword from "./pages/forgot_password/ForgotPassword";

setupIonicReact();

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonRouterOutlet>
        <Route exact path="/" component={Login} />
        <Route path="/login" component={Login} />
        <Route path="/forgotpassword" component={ForgotPassword} />
        <Route path="/home" component={Home} />
        <Route path="/corechapterintro" component={CoreChapterIntro} />
        <Route
          path="/corechaptercontentone"
          component={CoreChapterContentOne}
        />
        <Route path="/corechapteractivity" component={CoreChapterActivity} />
        <Route path="/corechapterend" component={CoreChapterEnd} />
        <Route path="/corechaptercontents" component={CoreChapterContents} />
        <Route path="/profileedit" component={ProfileEdit} />
        <Route path="/skinchecksettings" component={SkinCheckSettings} />

        <Route path="/skincheckspotdetails" component={SkinCheckSpotDetails} />
        <Route path="/skincheckspotreview" component={SkinCheckSpotReview} />
        <Route path="/skincheckdiameter" component={SkinCheckDiameter} />
        <Route path="/contact" component={Contact} />
        <Route
          path="/skinselfcheckpartselected"
          component={SkinSelfCheckPartSelected}
        />
        <Route path="/skincheckend" component={SkinCheckEnd} />
        <Route path="/skincheckcamera" component={SkinCheckCamera} />
        <Route
          path="/skincheckcamerapreview"
          component={SkinCheckCameraPreview}
        />

        <Route path="/skincheckspotrevisit" component={SkinCheckSpotRevisit} />

        <Route path="/skincheckspotarchive" component={SkinCheckSpotArchive} />
        <Route
          path="/skincheckimagemultispots"
          component={SkinCheckImageMultiSpots}
        />

        <Route path="/sprite" component={Sprite} />
        <Route path="/hamburger" component={Hamburger} />
        <Route path="/rewardsbadgesprizes" component={RewardsBadgesPrizes} />
        <Route path="/redeemprizes" component={RedeemPrizes} />
        <Route
          path="/prizeselectedwelldone"
          component={PrizeSelectedWelldone}
        />
        <Route path="/initiallogin" component={InitialLogin} />
        <Route path="/register" component={Register} />
        <Route path="/skinselfcheckpartsel" component={SkinSelfCheckPartSel} />
        <Route path="/skinselfcheckspothistory" component={SkinSelfCheckSpotHistory} />
        <Route path="/skincheckzones" component={SkinCheckZones} />
        <Route path="/skincheckfacezones" component={SkinCheckFaceZones} />

        <Route path="/skinchecktour" component={SkinCheckTour} />
        {/* Remove Later(For Components Testing Only) */}
        <Route path="/componenttest" component={ComponentTesting} />
        <Route path="/core2chapter1comp" component={Core2Chapter1Comp} />
        <Route path="/formpage" component={FormPage} />

        <Route path="/cc0" component={CC0} />
        <Route path="/cc1" component={CC1} />
        <Route path="/cc2" component={CC2} />
        <Route path="/cc3" component={CC3} />
        <Route path="/cc4" component={CC4} />
        <Route path="/cc5" component={CC5} />
        <Route path="/cc6" component={CC6} />
        <Route path="/cc7" component={CC7} />
        <Route path="/cc8" component={CC8} />
      </IonRouterOutlet>
    </IonReactRouter>
  </IonApp>
);

export default App;

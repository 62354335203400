import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonModal,
  IonRow,
  IonText,
} from "@ionic/react";
import { useState } from "react";
import "./ImageDescriptionPopOver.css";

interface ImageDescriptionPopOverProps {
  //   imageUrl: string;
  //   description: string;
  imageArray: Array<any>;
}

const ImageDescriptionPopOver: React.FC<ImageDescriptionPopOverProps> = ({
  imageArray,
}) => {
  const [showDescription, setShowDescription] = useState(
    Array(imageArray.length).fill(false)
  );


  const toggleDescription = (index: any) => {
    let newShowDescriptions: boolean[] = [];
    Array(newShowDescriptions.length).fill(false)
    newShowDescriptions[index] = !newShowDescriptions[index];
    setShowDescription(newShowDescriptions);
  };

  return (
    <IonGrid>
      <IonRow className="scsdRow ">
        {imageArray.map((image, index) => (
          <IonCol size="12/2" className={index % 2 === 0 ? "imageDescriptionColEven" : "imageDescriptionColOdd"}>
            <div className="imageDescriptionDiv">
              <div
                className="imageDescriptionSubDiv"
                style={{ backgroundColor: "rgb(153, 193, 248)" }}
              >
                <IonImg
                  src={image.imageUrl}
                  onClick={() => toggleDescription(index)}
                />
              </div>
              {showDescription[index] && (
                <div>
                  <IonText className="image-title-div">
                    {image.title}
                  </IonText>
                    <IonText className="image-points">{image.text}</IonText>
                </div>
              )}
            </div>
          </IonCol>
        ))}
      </IonRow>
    </IonGrid>
  );
};

export default ImageDescriptionPopOver;

import { useIonViewWillLeave } from "@ionic/react";
import { useEffect, useState } from "react";

import "./BarrierMCQMultipleCheck.css";

import "../../Fonts.css";
import BarrierMCQMultipleCheckOption from "../barrier_mcq_multiple_check_option/BarrierMCQMultipleCheckOption";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { DataService } from "../../services/DataService";

let noSelectedGlobal = 0;

interface BarrierMCQMultipleCheckProps {
  question: string;
  desc: string;
  options: Array<any>;
  selected: Function;
  noChangesCallback: Function;
}

const BarrierMCQMultipleCheck: React.FC<BarrierMCQMultipleCheckProps> = ({
  question,
  desc,
  options,
  selected,
  noChangesCallback,
}) => {
  var [valsChanged, setValsChanged] = useState(false);
  var [optionsArraySelected, setOptionsArraySelected] = useState([
    false,
    false,
  ]);

  useEffect(() => {
    let savedOptions = null;
    savedOptions = DataSubmissionService.c1A1SkinSelfCheckBarriers;
    // optionsDisableArr = Array(options.length).fill(false);

    // BE goal Core1 Activity 1 sync.
    let selGoal = DataService.selectedC1A1Goal;
    if (selGoal !== null && selGoal !== undefined) {
      let selGoalMainDesc = selGoal.goal.mainDesc;
      console.log(selGoalMainDesc);
      savedOptions = selGoal.barriers;
    }

    if (savedOptions && savedOptions.length > 0) {
      if (
        savedOptions[0].toString() === "true" ||
        savedOptions[0].toString() === "false"
      ) {
        let realSavedOptions = [];
        for (let i = 0; i < savedOptions.length; i++) {
          if (savedOptions[i]) {
            realSavedOptions.push(options[i]);
          }
        }
        savedOptions = realSavedOptions;
      }
    }

    if (savedOptions.length > 0) {
      let tempOptionsSelected = Array(options.length).fill(false);
      let tempIndexesSelected: Array<number> = [];

      for (let i = 0; i < savedOptions.length; i++) {
        for (let ind = 0; ind < options.length; ind++) {
          let option = options[ind];
          let option1Text = (option.text as String).replaceAll("<strong>", "");
          let option2Text = (option1Text as String).replaceAll("</strong>", "");

          let savedOption = savedOptions[i];
          let savedOption1Text = (savedOption.text as String).replaceAll(
            "<strong>",
            ""
          );
          let savedOption2Text = (savedOption1Text as String).replaceAll(
            "</strong>",
            ""
          );
          if (
            savedOptions[i] !== undefined &&
            savedOption2Text === option2Text
          ) {
            tempIndexesSelected.push(ind);
            tempOptionsSelected[ind] = true;
          }
        }
      }
      console.log(tempOptionsSelected);
      setOptionsArraySelected(tempOptionsSelected);

      if (!DataSubmissionService.c1A1CameBackFromBarrierSolutions) {
        setTimeout(() => {
          selected(tempOptionsSelected);
        }, 300);
      } else {
        noChangesCallback();
      }
    } else {
      setOptionsArraySelected(Array(options.length).fill(false));
    }
  }, [options.length]);

  const optionSelected = (index: number) => {
    let optsSelArr = optionsArraySelected;

    if (index === optsSelArr.length - 1) {
      let tempOptsSelArr = Array(options.length).fill(false);
      optionsArraySelected = tempOptsSelArr;
      optsSelArr = optionsArraySelected;
    } else {
      if (optionsArraySelected[optionsArraySelected.length - 1]) {
        let tempOptsSelArr = Array(options.length).fill(false);
        tempOptsSelArr[index] = false;
        optionsArraySelected = tempOptsSelArr;
        optsSelArr = optionsArraySelected;
      }
    }

    let noSelected = 0;
    noSelectedGlobal = noSelected;
    optsSelArr.map((opt, i) => {
      if (opt) {
        noSelected += 1;
      }
    });
    noSelectedGlobal = noSelected;

    //Allow to deselect or allow to select only max 2 barriers
    if (noSelected < 2 || optsSelArr[index]) {
      optsSelArr[index] = !optsSelArr[index];
      setOptionsArraySelected(optsSelArr);
      setValsChanged(!valsChanged);

      var selectedOptionsArr: string[] = [];
      optsSelArr.map((opt, i) => {
        if (opt) {
          selectedOptionsArr.push(options[i]);
        }
      });

      selected(selectedOptionsArr);
    }
  };

  const checkDisable = (index: number) => {
    const booleanArray = optionsArraySelected;
    const trueValues = booleanArray.filter((value) => value === true);
    noSelectedGlobal = trueValues.length;

    //None of these should be enabled always
    if (index === optionsArraySelected.length - 1) {
      return false;
    }

    //If None of these selected
    if (index < optionsArraySelected.length - 1) {
      if (optionsArraySelected[optionsArraySelected.length - 1]) {
        return false;
      }
    }

    let disabled = true;
    if (optionsArraySelected[index]) {
      //If checked
      disabled = false;
    } else {
      //If not checked
      if (noSelectedGlobal >= 2) {
        disabled = true;
      } else {
        disabled = false;
      }
    }
    return disabled;
  };

  return (
    <div className="acceMainContainer ccaqMainContainer mcqmcMainContainer bmcqmcMainContainer animate__animated animate__fadeInRight">
      {options.map((option: any, i: any) => {
        return (
          <BarrierMCQMultipleCheckOption
            key={i}
            index={i}
            text={option.text}
            isOpen={optionsArraySelected[i]}
            isSelected={optionSelected}
            isDisabled={checkDisable(i)}
          />
        );
      })}
    </div>
  );
};

export default BarrierMCQMultipleCheck;

export abstract class DataSubmissionService {
  //Core 1 Activity Submission
  public static c1A1SkinSelfCheckImportanceRating: number;
  public static c1A1SkinSelfCheckImportanceRatingDesc: string;
  public static c1A1SkinSelfCheckReasons: Array<string> = [];
  public static chooseAGoalOption: any;
  public static chooseAGoalOptionMainQuestionIndex = -1; //Not required for submission to BE
  public static c1A1SkinSelfCheckBarriers: Array<string> = [];
  public static c1A1SkinSelfCheckBarriersSolutions: Array<string> = [];
  public static c1A1SkinSelfCheckMaterials: Array<string> = [];
  public static c1A1SkinSelfCheckHaveMaterials: Array<boolean> = [];
  public static c1A1SkinSelfCheckHaveMaterialsFinalDataSummary: Array<any> = [
    {
      title: "What you have",
      options: [
        {
          title: "Hand Mirror",
          imageUrl: "assets/images/HandMirror.svg",
        },
        {
          title: "Notebook",
          imageUrl: "assets/images/Notebook.svg",
        },
      ],
    },
    {
      title: "Tools you'll need to get",
      options: [
        {
          title: "Cell Phone",
          imageUrl: "assets/images/CellPhone.svg",
        },
        {
          title: "Ruler",
          imageUrl: "assets/images/Ruler.svg",
        },
      ],
    },
  ];
  public static mainGoalIndex = -1;
  public static c1A1BarrierIndexes: Array<number> = [];

  public static isGoalsGetAPICalled = false;

  public static c1A1SkinSelfCheckReasonsAllOptions = [
    "Be a role model for others",
    "Make my family less worried",
    "Feel in control of my health",
    "Find spots early that may be or may become cancerous",
    "Have peace of mind for myself and my family about my health",
  ];

  public static noneOfTheseSelected = false;

  public static c1A1SkinSelfCheckBarriersSolutionsAllOptions = [
    {
      text: "I <strong>don’t feel confident</strong> performing a skin self-check.",
      solution: {
        isViewed: false,
        isChecked: true,
        text: "Confidence",
        desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your Skin Self-Check.",
        pointsDesc:
          "Which of the following <strong>strategies</strong> will you use?",
        points: [
          "Review <span class='text-underline'>mySmartSkin content</span> to increase knowledge and skills",
          "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
          "<strong>Have someone</strong> to help me during my Skin Self-Check",
        ],
      },
    },
    {
      text: "I am <strong>anxious</strong> to find an abnormal spot.",
      solution: {
        isViewed: false,
        isChecked: true,
        text: "Anxiety",
        desc: "It is <strong>completely normal</strong> to be <strong>anxious</strong> about finding abnormal spots.",
        pointsDesc:
          "Which of the following <strong>strategies</strong> will you use?",
        points: [
          "<strong>Have someone</strong> to help me during my Skin Self-Check",
          "<strong>Schedule an appointment</strong> with <strong>my doctor</strong> to review any suspicious spots I find during my Skin Self-Check",
        ],
      },
    },
    {
      text: "I <strong>don't have time</strong> to do a skin self-check.",
      solution: {
        isChecked: true,
        isViewed: false,
        text: "Don't Have Time",
        desc: "The most thorough skin self-checks only take <strong>10-15 minutes to complete</strong> each month.<br><br>Although it may be an inconvenience, doing these skin self-checks is an important part of your survivorship because they <strong>help you to monitor</strong> spots on your body for abnormalities.</br></br>",
        pointsDesc: "",
        points: [],
      },
    },
    {
      text: "I <strong>need help</strong> doing a skin self-check because I have too many spots.",
      solution: {
        isViewed: false,
        isChecked: true,
        text: "Need Help",
        desc: "Although you want to check all body parts, you only need to note and <strong>monitor abnormal spots</strong> on your body map.",
        pointsDesc:
          "Which of the following <strong>strategies</strong> will you use?",
        points: [
          "<strong>Complete one body part</strong> at a time to make it manageable",
          "<strong>Focus on the spots</strong> that I am most concerned about",
          "<strong>Ask a family</strong> member or friend to help me.",
        ],
      },
    },
    {
      text: "I would <strong>prefer a doctor</strong> examine my skin.",
      solution: {
        isChecked: true,
        isViewed: false,
        text: "Prefer a Doctor",
        desc: "Doing skin self-checks are not instead of having your doctor check your body for abnormal spots. Self-checks allow you to <strong>monitor your body</strong> in between doctor appointments to make sure there is <strong>nothing suspicious</strong>.<br><br>skin self-checks are especially important because about <strong>50% of people find their skin cancer</strong> on their own. If you do find an abnormal or changing spot, you should schedule an appointment with your physician.</br></br>",
        pointsDesc: "",
        points: [],
      },
    },
    {
      text: "<strong>None of these</strong>",
      solution: {
        isChecked: true,
        isViewed: false,
        text: "Confidence",
        desc: "It is <strong>completely normal</strong> to feel none. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
        pointsDesc:
          "Following <strong>strategies</strong> will help you to overcome your barrier.",
        points: [
          "None <strong>Review MSS</strong> to increase knowledge and skill set",
          "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
          "<strong>Have someone</strong> to help me during my skin self-check",
        ],
      },
    },
    {
      isChecked: false,
      text: "Need Help",
      desc: "It is <strong>completely normal</strong> to feel this way. You will become more and <strong>more confident</strong> as you progress through your skin self-check.",
      pointsDesc:
        "Following <strong>strategies</strong> will help you to overcome your barrier.",
      points: [
        "<strong>Review MSS</strong> to increase knowledge and skill set",
        "<strong>Print out pictures</strong> of abnormal spots to compare my spots",
        "<strong>Have someone</strong> to help me during my skin self-check",
      ],
    },
  ];

  public static c1A1CameBackFromBarrierSolutions = false;
  // public static capturedImgUrl = "";

  public static getC1A1BEJson = () => {
    let retVal = {
      importanceRating: this.c1A1SkinSelfCheckImportanceRating,
      importanceRatingDesc: this.c1A1SkinSelfCheckImportanceRatingDesc,
      reasons: this.c1A1SkinSelfCheckReasons,
      goal: this.chooseAGoalOption,
      barriers: this.c1A1SkinSelfCheckBarriers,
      barrierSolutions: this.c1A1SkinSelfCheckBarriersSolutions,
    };

    console.log(retVal);

    return this.updateC1A1BEJSON(retVal);
  };

  public static updateC1A1BEJSON = (c1A1BEJson: any) => {
    let retVal = this.c1A1BEJSON;

    if (c1A1BEJson.goal.mainTitle === "Start Doing a Skin Self-Check") {
      if (c1A1BEJson.goal.mainDesc === "Gather the right tools") {
        retVal.goals[0].subgoals.gatherTheRightTools = c1A1BEJson;
      } else if (c1A1BEJson.goal.mainDesc === "Find someone to help") {
        retVal.goals[0].subgoals.findSomeoneToHelp = c1A1BEJson;
      } else if (c1A1BEJson.goal.mainDesc === "Set reminders") {
        retVal.goals[0].subgoals.setReminders = c1A1BEJson;
      }
    } else if (c1A1BEJson.goal.mainTitle === "Check All Parts of My Body") {
      if (c1A1BEJson.goal.mainDesc === "Perform thorough skin self-checks") {
        retVal.goals[1].subgoals.performThoroughSSCs = c1A1BEJson;
      } else if (c1A1BEJson.goal.mainDesc === "Check frequently missed areas") {
        retVal.goals[1].subgoals.checkFrequentlyMissedAreas = c1A1BEJson;
      }
    } else if (c1A1BEJson.goal.mainTitle === "Make Skin Self-Checks a Habit") {
      if (c1A1BEJson.goal.mainDesc === "Set aside time each month") {
        retVal.goals[2].subgoals.setAsideTimeEachMonth = c1A1BEJson;
      } else if (c1A1BEJson.goal.mainDesc === "Set a monthly reminder") {
        retVal.goals[2].subgoals.setAMonthlyReminder = c1A1BEJson;
      } else if (
        c1A1BEJson.goal.mainDesc === "Find someone to hold me accountable"
      ) {
        retVal.goals[2].subgoals.findSOmeoneToHoldMeAcc = c1A1BEJson;
      }
    }

    this.c1A1BEJSON = retVal;
    return retVal;
  };

  /////////////////////////////////////////////////////////////CORE 2 Activity////////////////////////////////////////////////////////

  public static c2A1SelfCheckGoalsGoalsInitVal: c2A1GoalType[] = [];
  public static c2A1SelfCheckGoalsInitialFormInitVal: c2A1InitialFormType = {
    question: "",
    answers: [],
  };

  public static c2A1SkinSelfCheckGoals = {
    goals: this.c2A1SelfCheckGoalsGoalsInitVal,
    // initialForm: this.c2A1SelfCheckGoalsInitialFormInitVal,
  };

  public static c2A1LatestGoalTitle = "";

  public static resetC2A1Data = () => {
    DataSubmissionService.c2A1SkinSelfCheckGoals = {
      goals: this.c2A1SelfCheckGoalsGoalsInitVal,
      // initialForm: this.c2A1SelfCheckGoalsInitialFormInitVal,
    };
  };

  /////////////////////////////////////////////////////////////CORE 1 Activity Static BE JSON for Testing////////////////////////////////////////////////////////
  public static c1A1BEJSON = {
    goals: [
      {
        goal: "Start Doing a Skin Self-Check",
        subgoals: {
          gatherTheRightTools: null,
          findSomeoneToHelp: null,
          setReminders: null,
        },
      },
      {
        goal: "Check All Parts of My Body",
        subgoals: {
          performThoroughSSCs: null,
          checkFrequentlyMissedAreas: null,
        },
      },
      {
        goal: "Make Skin Self-Checks a Habit",
        subgoals: {
          setAsideTimeEachMonth: null,
          setAMonthlyReminder: null,
          findSOmeoneToHoldMeAcc: null,
        },
      },
    ],
  };
}

export type c2A1GoalType = {
  title: string;
  mcqs: [
    {
      question: string;
      answers: string[];
      strategies: string[];
    }
  ];
};

export type c2A1InitialFormType = {
  question: string;
  answers: string[];
};

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "./Login.css";
import "../../theme/GlobalStyles.css";
import InputTextField from "../../components/input_text_field/InputTextField";
import { loginApi } from "../../services/dataApi";
import { UserService } from "../../services/UserService";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";
import { ProgressDataService } from "../../services/ProgressDataService";
import { AppInfoTimeService } from "../../services/AppInfoTimeService";

let username = "";
let password = "";

const Login: React.FC = () => {
  const navigaton = useIonRouter();

  // const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [enableNextBtn, setEnableNextBtn] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  // const [errorText, setErrorText] = useState("");

  useIonViewWillEnter(() => {
    AppInfoTimeService.sendAppTimeToBE();
  });

  useEffect(() => {
    if (!UserService.loginAPICalled) {
      UserService.loginAPICalled = true;
      let username = localStorage.getItem("username");
      let password = localStorage.getItem("password");

      if (username !== null && password !== null) {
        login(username, password);
      }
    }
  }, []);

  const nextBtnClickHandler = () => {
    if (!enableNextBtn) {
      return;
    }

    // getCurrentLocation();
    login(username, password);
  };

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };
  // async function login(username: string, password: string) {
  //   // navigaton.push("/home", "root", "replace");

  //   try {
  //     const responseData = await loginApi(username, password);
  //     console.log(responseData);
  //     return;
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  const autoLoginHandler = () => {
    login("kartik", "kartik");
  };

  const skipLoginHandler = () => {
    ProgressDataService.isGetCalled = false;
    UserService.controlBottomBarFromBack=0;
    navigaton.push("/home", "root", "replace");
  };

  async function login(username: string, password: string) {
    try {
      openLoader();
      const responseData = await loginApi(
        username,
        password,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            //Save user data
            UserService.userName = respData.current_user.name;
            UserService.userId = respData.current_user.uid;
            UserService.csrfToken = respData.csrf_token;
            UserService.logoutToken = respData.logout_token;
            UserService.userRole = respData.current_user.roles;
            localStorage.setItem("username", username);
            localStorage.setItem("password", password);
            ProgressDataService.isGetCalled = false;
            for (var i = 0; i < UserService.userRole.length; i++) {
              if (UserService.userRole[i] === "controlcontent") {
                UserService.isControlContentRole = true;
                break;
              }
            }
            UserService.controlBottomBarFromBack=0;
            //UserService.isControlContentRole = true;
            navigaton.push("/home", "root", "replace");
          } else if (!isSuccess) {
            //Error
            closeLoader();
            setLoginError(respData.message);
            console.log(respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      closeLoader();
      console.error("Error fetching data:", error);
    }
  }

  const usernameChanged = (event: any) => {
    console.log("userame: " + event.detail.value);
    username = event.detail.value;

    checkEnableNextBtn();
  };

  const passwordChanged = (event: any) => {
    console.log("password: " + event.detail.value);
    password = event.detail.value;

    checkEnableNextBtn();
  };

  const checkEnableNextBtn = () => {
    if (username.length > 0 && password.length > 0) {
      setEnableNextBtn(true);
    } else {
      setEnableNextBtn(false);
    }
  };
  const goToRegister = () => {
    navigaton.push("/register", "forward", "push");
  };
  const testcomponent = () => {
    navigaton.push("/componenttest", "forward", "push");
  };

  return (
    <IonPage>
      <IonContent fullscreen className="ion-padding ion-text-center">
        {/* <div
          className="lBack"
          onClick={() => {
            navigaton.goBack();
          }}
        >
          <IonImg src="/assets/icons/LeftOutlineBlack.png"></IonImg>
        </div> */}
        <LoadingScreen text="Logging in..." isOpen={showLoader} />
        <IonGrid className="titleSubtitleGrid">
          <IonRow>
            <IonCol className="loginLogoImgCol" size="12">
              <IonImg
                className="loginLogoImg"
                src="assets/images/Welcome.svg"
              ></IonImg>
            </IonCol>
            <IonCol className="loginTitleTextCol" size="12">
              <IonText className="loginTitleText">
                Welcome to mySmartSkin
              </IonText>
            </IonCol>
            {/* <IonCol className="loginSubtitleTextCol" size="12">
              <IonText className="loginSubtitleText">
                Glad to see you, Again!
              </IonText>
            </IonCol> */}
          </IonRow>
        </IonGrid>

        <IonGrid className="textInputGrid">
          <IonRow>
            <IonCol size="12">
              <InputTextField
                placeholder="User Name"
                icon="assets/icons/MailOutline.svg"
                isPassword={false}
                textChanged={usernameChanged}
                val={username}
              />
            </IonCol>
            <IonCol className="loginTextFieldCol" size="12">
              <InputTextField
                placeholder="Password"
                icon="assets/icons/LockOutline.svg"
                isPassword={true}
                textChanged={passwordChanged}
                val={password}
              />
            </IonCol>
            <IonCol
              className="loginForgotPasswordTextCol"
              size="12"
              onClick={() => {
                navigaton.push("/forgotpassword");
              }}
            >
              <IonText className="loginForgotPasswordText">
                Forgot password?
              </IonText>
            </IonCol>
            {/* <IonCol
              className="loginForgotPasswordTextCol"
              onClick={() => skipLoginHandler()}
              size="12"
            >
              <IonText className="loginForgotPasswordText">Skip Login</IonText>
            </IonCol> */}
            <IonCol size="12" className="loginLoginBtnCol ilBottomSpace">
              {enableNextBtn && (
                <IonButton
                  className="loginBtnEnabled loginLoginBtn"
                  onClick={() => nextBtnClickHandler()}
                >
                  Login
                </IonButton>
              )}
              {!enableNextBtn && (
                <IonButton
                  className="loginBtn"
                  onClick={() => nextBtnClickHandler()}
                >
                  Login
                </IonButton>
              )}
            </IonCol>
            <IonCol size="12">
              <IonText className="error-message">{loginError}</IonText>
            </IonCol>
            {/* <IonCol size="12">
              <IonText className="ildescText">Don’t have an account?</IonText>
              <IonText className="ilregText" onClick={goToRegister}>
                Register Now
              </IonText>
            </IonCol> */}

            {/* <IonCol
              className="testingBtn"
              onClick={() => testcomponent()}
              size="6"
            >
              <IonText className="testingtext"> Component Testing</IonText>
            </IonCol> */}
            {/* <IonCol
              className="testingBtn"
              onClick={() => autoLoginHandler()}
              size="6"
            >
              <IonText className="testingtext">Auto Login</IonText>
            </IonCol> */}
            {/* <IonCol
              className="testingBtn"
              onClick={() => skipLoginHandler()}
              size="12"
            >
              <IonText className="testingtext">Skip Login</IonText>
            </IonCol> */}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;

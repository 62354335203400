import {
  IonAlert,
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import "./Profile.css";
import TaskSelectionOptionHint from "../../../components/task_selection_option_hint/TaskSelectionOptionHint";
import { UserService } from "../../../services/UserService";
import { Location } from "history";
import { useState } from "react";

const ProfileTab: React.FC = () => {
  const [showAlert, setShowAlert] = useState(false);

  var router = useIonRouter();

  const navigateToSkinSettings = () => {
    router.push("/skinchecksettings", "forward", "push");
  };
  const navigateToContact = () => {
    router.push("/contact", "forward", "push");
  };

  const handleLogout = () => {
    UserService.userName = "";
    UserService.userId = "";
    UserService.userRole=[];
    UserService.csrfToken = "";
    UserService.logoutToken = "";
    localStorage.clear();
    router.push("/login", "forward", "push");
    setShowAlert(false);
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        {/* <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.svg"></IonImg>
        </div> */}
        <IonGrid>
          <IonRow class="profileTitleRow">
            {/* <IonIcon
              icon={chevronBack}
              style={{ color: "transparent" }}
            ></IonIcon> */}

            <IonCol size="3">
              <IonText style={{ color: "transparent" }}>Log out</IonText>
            </IonCol>
            <IonCol size="6">
              <IonText className="titleText profile-title-col">Profile</IonText>
            </IonCol>
            <IonCol size="3">
              <IonText
                className="logout-title"
                onClick={() => setShowAlert(true)}
              >
                Log out
              </IonText>
            </IonCol>
            <IonAlert
              isOpen={showAlert}
              onDidDismiss={() => setShowAlert(false)}
              header={"Logout"}
              message={"Are you sure you want to logout?"}
              buttons={[
                {
                  text: "Cancel",
                  role: "cancel",
                },
                {
                  text: "Logout",
                  handler: handleLogout,
                },
              ]}
            />
            {/* <IonText
              className="cciExitBtn"
              onClick={() => {
              }}
            >
              Exit
            </IonText> */}
          </IonRow>
        </IonGrid>
        <div className="mainContent profileMainContent">
          <IonGrid>
          {!UserService.isControlContentRole && <IonRow
              onClick={() => {
                navigateToSkinSettings();
              }}
            >
              <TaskSelectionOptionHint
                imageUrl="assets/icons/SetOutline.svg"
                text="Skin Check Settings"
                isFullWidth={true}
                defaultSpacingTop={false}
              />
            </IonRow>}
            <IonRow
              onClick={() => {
                navigateToContact();
              }}
            >
              <TaskSelectionOptionHint
                imageUrl="assets/icons/PhoneFill.svg"
                text="Contact Us"
                isFullWidth={true}
                defaultSpacingTop={true}
              />
            </IonRow>
            </IonGrid>
            </div>
           
          
         
       
      </IonContent>
      <div className="buttonFooter">
        {/* <IonGrid>
          <IonRow>
            <IonCol
              size="12"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnEnabled"
                style={{
                  width: "100%",
                }}
                onClick={() => {
                  router.push("/corechaptercontentone", "forward", "push");
                }}
              >
                Continue
              </IonButton>{" "}
            </IonCol>
          </IonRow>
        </IonGrid> */}
      </div>
    </IonPage>
  );
};

export default ProfileTab;




import {
  IonPage,
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonText,
  IonCol,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import { useEffect, useState } from "react";
import "./SkinCheckFaceZones.css";

const tempSvgId = "";

const SkinCheckFaceZones: React.FC = () => {
  var router = useIonRouter();

  const onclickHandler = (region: string) => {
    console.log(region);
    SkinSelfCheckDataService.selectedFaceZone = region;
    // SkinSelfCheckDataService.selectedRegionSubPart.title =
    //   SkinSelfCheckDataService.selectedRegionSubPart.title + " - " + region;
    router.push("/skincheckspotdetails", "forward", "push");
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#F5F6FA", "--padding-top": "0px" }}
      >
        <div className="scz-main-div" style={{ paddingTop: "16px" }}>
          <div className="sez-title-div">
            <IonText className="sez-title sez-new-title">
              Great, let’s identify the location of spot.
            </IonText>
          </div>
          <div className="sez-grid-div">
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="sez-select-col">
                  <IonText className="sez-select-text">
                    Tap on the region to select it.
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow className="sez-svg-row">
                <div className="zones-svg">
                  <IonButton
                    className="zoneBtnEnabled scz-continue-btn"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      onclickHandler("Eyes");
                    }}
                  >
                    Eyes
                  </IonButton>

                  <IonButton
                    className="zoneBtnEnabled scz-continue-btn"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      onclickHandler("Ears");
                    }}
                  >
                    Ears
                  </IonButton>

                  <IonButton
                    className="zoneBtnEnabled scz-continue-btn"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      onclickHandler("Nose");
                    }}
                  >
                    Nose
                  </IonButton>
                  <IonButton
                    className="zoneBtnEnabled scz-continue-btnlast"
                    style={{
                      width: "100%",
                    }}
                    onClick={() => {
                      onclickHandler("Cheeks");
                    }}
                  >
                    Cheeks
                  </IonButton>
                </div>
              </IonRow>
            </IonGrid>
          </div>
          {/* <IonButton
            className="sscpsButton12 scz-back-btn"
            style={{
              width: "100%",
            }}
            onClick={() => {
              router.goBack();
            }}
          >
            Go Back
          </IonButton> */}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SkinCheckFaceZones;

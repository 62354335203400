import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
  useIonRouter,
} from "@ionic/react";
import "./HomeCores.css";
import { DataService } from "../../services/DataService";
import { AppInfoTimeService } from "../../services/AppInfoTimeService";

interface HomeCoresProps {
  coreimageUrl: string;
  corestatusimageurl: string;
  coreno: string;
  corelessonname: string;
  corelessondesc: string;
  isCoreLocked: boolean;
}

const HomeCores: React.FC<HomeCoresProps> = ({
  coreimageUrl,
  corestatusimageurl,
  coreno,
  corelessonname,
  corelessondesc,
  isCoreLocked,
}) => {
  var router = useIonRouter();

  return (
    <IonGrid className="hisGridFill">
      <IonRow>
        <IonCol size="auto">
          <IonImg src={coreimageUrl}></IonImg>
        </IonCol>
        <IonCol className="hisColSep">
          <IonRow>
            {isCoreLocked && (
              <IonCol size="auto">
                <IonImg src={corestatusimageurl}></IonImg>
              </IonCol>
            )}
            <IonCol>
              <IonText
                className={!isCoreLocked ? "hisTextCoreNo1" : "hisTextCoreNo"}
              >
                {coreno}
              </IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonText className="hisTextCoreDesc">{corelessonname}</IonText>
          </IonRow>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol size="12">
          <IonText className="hisTextLorem">{corelessondesc}</IonText>
        </IonCol>
      </IonRow>
      <div
        className="hisDivBtn2"
        onClick={() => {
          if (coreno === "Section 1") {
            //Start Sections Timer
            AppInfoTimeService.sendAppSectionsTimeToBE(1);

            DataService.navigateToCore1 = true;
            DataService.navigateToCore2 = false;
            DataService.refreshCoresFromBE = true;
            DataService.currentCoreIndex = 0;
            DataService.currentChapterIndex = 0;
            router.push("/home/corestab", "root", "replace");
          } else if (coreno === "Section 2") {
            //Start Sections Timer
            AppInfoTimeService.sendAppSectionsTimeToBE(2);

            DataService.navigateToCore1 = false;
            DataService.navigateToCore2 = true;
            DataService.refreshCoresFromBE = true;
            DataService.currentCoreIndex = 1;
            DataService.currentChapterIndex = 0;
            router.push("/home/corestab", "root", "replace");
          }
        }}
      >
        <IonText className="hisTextLeanrnow">Learn Now</IonText>
      </div>
    </IonGrid>
  );
};

export default HomeCores;

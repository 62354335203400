import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useEffect, useState } from "react";

import "./CoreSingleCheckOption.css";

import "../../Fonts.css";
import { C2DataSubmissionService } from "../../services/C2DataSubmissionService";

interface CoreSingleCheckOptionProps {
  title: string;
  listOptions: Array<any>;
  selected: Function
}
const CoreSingleCheckOption: React.FC<CoreSingleCheckOptionProps> = ({
  title,
  listOptions,
  selected,
}) => {

  const [selectedStates, setSelectedStates] = useState([false]);

  useEffect(() => {
    let tempArr = [];
    tempArr = new Array(listOptions.length).fill(false);
    setSelectedStates(tempArr);

    let answer = "";
    if (title === "Have you ever sunbathed?") {
      answer = C2DataSubmissionService.c2Ch3S1SinMCQ1;
      if (answer === "Yes") {
        setOptionselected(0);
      } else if (answer === "No") {
        setOptionselected(1);
      }
    } else if (title === "Do you plan to sunbathe in the next 12 months?") {
      answer = C2DataSubmissionService.c2Ch3S1SinMCQ2;
      if (answer === "Yes") {
        setOptionselected(0);
      } else if (answer === "No") {
        setOptionselected(1);
      } else if (answer === "Not Sure") {
        setOptionselected(2);
      }
    }
  }, [])
  
  const setOptionselected = (index: number) => {
    let tempArr = [];
    tempArr = new Array(listOptions.length).fill(false);
    tempArr[index] = true;
    setSelectedStates(tempArr);
    selected(title, tempArr);    
  };

  return (
    <div className="coresinglecheckContainer">
      <IonGrid >
        <IonRow className="coresingleRow">
          <IonText
            className="coresinglecheckTitleText">
            {title}
          </IonText>
        </IonRow>

        <IonRow>
          {listOptions.map((option: string, i: number) => {
            return <IonRow>
              <IonCol className="coresingleCol" size="auto">

                {(selectedStates[i]) && (
                  <IonImg className="coresinglecheckCheckImg"
                    src="assets/icons/RadioSelected.png"
                    onClick={() => { setOptionselected(i) }} />
                )}
                {!(selectedStates[i]) && (
                  <IonImg className="coresinglecheckCheckImg"
                    src="assets/icons/coresinglecheck_uncheck.png"
                    onClick={() => { setOptionselected(i) }}/>
                )}
              </IonCol>
              <IonCol className="coresingleCol" >
                <IonText className="coresinglecheckOptionsText">{option}</IonText>

              </IonCol>
            </IonRow>
          })}
        </IonRow>
      </IonGrid>

    </div>
  );
};

export default CoreSingleCheckOption;

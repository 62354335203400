export abstract class DateUtil {
  // January 22, 2023
  public static convertDateToReadableFormat1 = (date: Date) => {
    const dat = new Date(date);
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const day = dat.getDate();
    const monthIndex = dat.getMonth();
    const monthName = monthNames[monthIndex];
    const year = dat.getFullYear();

    return `${monthName} ${day}, ${year}`;
  };
}

import { IonGrid, IonRow, IonCol, IonText } from "@ionic/react";
import "./corefnfProgressBar.css";
import HomeCoreProgressBar from "../home_core_progress_bar/HomeCoreProgressBar";

interface CoreFnfProgressBar {
  coreFnfTitle: string;
  coreFnfDescription: string;
  questionNo: string;
}

const CoreFnfProgressBar: React.FC<CoreFnfProgressBar> = ({
  coreFnfTitle,
  coreFnfDescription,
  questionNo,
}) => {
    
  const progressBarPercentageHandler = () => {
    const questionNum = questionNo.split(" ")[0].split("/");
    const numerator = Number(questionNum[0]);
    const denominator = Number(questionNum[1]);
    const progressBarPercentage = (numerator / denominator) * 100;
    return progressBarPercentage;
  };

  return (
    <>
      <div className="coreFnfMainContainer">
        <IonGrid>
          <IonRow className="coreFnfRow">
            <IonCol>
              <IonText className="coreFnfTitle">{coreFnfTitle}</IonText>
            </IonCol>
          </IonRow>
          <IonRow className="coreFnfRow">
            <IonCol>
              <IonText className="coreFnfDesc">{coreFnfDescription}</IonText>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol className="coreFnfProgressCol">
              <IonText className="coreFnfQuesNo">{questionNo}</IonText>
              <HomeCoreProgressBar
                percentage={progressBarPercentageHandler()}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};
export default CoreFnfProgressBar;

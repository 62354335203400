import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";
import { useEffect, useState } from "react";

import "./MaterialsMultipleSelect.css";

import "../../Fonts.css";
import MaterialsMultipleSelectOption from "../materials_multiple_select_option/MaterialsMultipleSelectOption";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { DataService } from "../../services/DataService";

interface MaterialsMultipleSelectProps {
  mainImgUrl: string;
  mainTitle: string;
  mainDesc: string;
  mainDesc2: string;
  title: string;
  imageUrl: string;
  options: Array<any>;
  click: Function;
  showBottomCheckBox: boolean;
}

const MaterialsMultipleSelect: React.FC<MaterialsMultipleSelectProps> = ({
  mainImgUrl,
  mainTitle,
  mainDesc,
  mainDesc2,
  title,
  imageUrl,
  options,
  click,
  showBottomCheckBox,
}) => {
  var [valsChanged, setValsChanged] = useState(false);
  var [optionsArraySelected, setOptionsArraySelected] = useState([
    false,
    false,
  ]);
  var [optionsBottomArraySelected, setOptionsBottomArraySelected] = useState([
    false,
    false,
  ]);

  useEffect(() => {
    let savedOptions = DataSubmissionService.chooseAGoalOption.options;

    // BE goal Core1 Activity 1 sync.
    let selGoal = DataService.selectedC1A1Goal;
    if (selGoal !== null && selGoal !== undefined) {
      let selGoalMainDesc = selGoal.goal.mainDesc;
      console.log(selGoalMainDesc);

      let selGoalOptions = selGoal.goal.options;
      savedOptions = selGoalOptions;
    }

    if (savedOptions.length > 0) {
      let tempOptionsSelected = Array(options.length).fill(false);
      let tempOptionsBottomSelected = Array(options.length).fill(false);

      for (let i = 0; i < savedOptions.length; i++) {
        tempOptionsSelected[i] = savedOptions[i].isSelected;
        tempOptionsBottomSelected[i] = savedOptions[i].isThere;
      }
      console.log(tempOptionsSelected);
      setOptionsArraySelected(tempOptionsSelected);
      setOptionsBottomArraySelected(tempOptionsBottomSelected);

      if (tempOptionsBottomSelected.length > 0) {
        click(tempOptionsBottomSelected, false);
      }

      if (tempOptionsSelected.length > 0) {
        click(tempOptionsSelected, true);
      }
    } else {
      setOptionsArraySelected(Array(options.length).fill(false));
      setOptionsBottomArraySelected(Array(options.length).fill(false));
    }
  }, [options.length]);

  const optionSelected = (index: number) => {
    let optsSelArr = optionsArraySelected;
    optsSelArr[index] = !optsSelArr[index];

    if (!showBottomCheckBox) {
      optsSelArr = Array(options.length).fill(false);
      optsSelArr[index] = true;
    }

    if (!optsSelArr[index]) {
      optionBottomSelectedIndexUnselect(index);
    }
    setOptionsArraySelected(optsSelArr);
    setValsChanged(!valsChanged);

    click(optsSelArr, true);
  };

  const optionBottomSelected = (index: number) => {
    let optsSelArr = optionsBottomArraySelected;
    optsSelArr[index] = !optsSelArr[index];
    setOptionsBottomArraySelected(optsSelArr);
    setValsChanged(!valsChanged);

    click(optsSelArr, false);
  };

  const optionBottomSelectedIndexUnselect = (index: number) => {
    let optsSelArr = optionsBottomArraySelected;
    optsSelArr[index] = false;
    setOptionsBottomArraySelected(optsSelArr);
    setValsChanged(!valsChanged);

    click(optsSelArr, false);
  };

  return (
    <div className="mmsMainMainContainer">
      <IonGrid>
        <IonRow>
          <IonCol className="mmsImgCol" size="auto">
            <IonImg className="" src={mainImgUrl} />
          </IonCol>
          <IonCol className="">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonText className="mmsMainTitleText">{mainTitle}</IonText>
                </IonCol>
                <IonCol size="12">
                  <IonText className="mmsMainDescText">{mainDesc}</IonText>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      <div className="mmsMainDesc2Div">
        <IonText
          className="mmsMainDesc2Text"
          dangerouslySetInnerHTML={{ __html: mainDesc2 }}
        ></IonText>
      </div>
      <div className="mmsMainContainer  animate__animated animate__fadeInRight">
        <IonGrid>
          <IonRow>
            {imageUrl !== null &&
              imageUrl !== undefined &&
              imageUrl.length > 0 && (
                <IonCol className="mmsImgCol" size="auto">
                  <IonImg className="" src={imageUrl} />
                </IonCol>
              )}
            <IonCol className="">
              <IonText className="mmsTitleText">{title}</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        {options.map((option: any, i: any) => {
          return (
            <MaterialsMultipleSelectOption
              key={i}
              index={i}
              text={option.title}
              desc={option.desc}
              isOpen={optionsArraySelected[i]}
              isBottomOpen={optionsBottomArraySelected[i]}
              imageUrl={option.imageUrl}
              isSelected={optionSelected}
              isBottomSelected={optionBottomSelected}
              clicked={click}
              showBottomCheckBox={showBottomCheckBox}
              showSelectionControl={options.length > 1}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MaterialsMultipleSelect;

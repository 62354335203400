import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import {useEffect, useState } from "react";

import "./CoreChapterActivityQuestion.css";

import "../../Fonts.css";
import CoreChapterActivityOption from "../core_chapter_activity_option/CoreChapterActivityOption";

interface CoreChapterActivityQuestionProps {
  question: string;
  options: Array<any>;
  isSelected: Function;
}

const CoreChapterActivityQuestion: React.FC<
  CoreChapterActivityQuestionProps
> = ({ question, options, isSelected }) => {
  var [optionsArraySelected, setOptionsArraySelected] = useState([
    false,
    false,
    false,
    false,
  ]);

  useEffect(() => {
    setOptionsArraySelected([
      false,
      false,
      false,
      false,
    ])
  }, [options])

  const optionSelected = (index: number) => {
    let optsSelArr = [];
    optionsArraySelected.map((opt, index) => {
      optsSelArr.push(false);
    });
    optsSelArr[index] = true;
    setOptionsArraySelected(optsSelArr);
    isSelected(optsSelArr);
  };

  return (
    <div className="acceMainContainer ccaqMainContainer">
      <IonGrid>
        <IonRow>
          <IonCol size="auto" className="acceImgCol">
            <IonImg src="assets/icons/Question.png" />
          </IonCol>
          <IonCol className="acceTitleCol">
            <IonText className="ccaqTitleText">{question}</IonText>
          </IonCol>
        </IonRow>
      </IonGrid>
      {options.map((option: any, i: any) => {
        return (
          <CoreChapterActivityOption
            index={i}
            text={option.text}
            hints={option.hints}
            isCorrect={option.isCorrect}
            isOpen={optionsArraySelected[i]}
            isSelected={optionSelected}
          />
        );
      })}
    </div>
  );
};

export default CoreChapterActivityQuestion;

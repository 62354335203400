import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";
import { chevronBack, settingsOutline } from "ionicons/icons";

import "./SkinCheckSettings.css";
import ToggleButton from "../../components/toggle_button/ToggleButton";
import ButtonGroup from "../../components/button_group/ButtonGroup";
import { useEffect, useState } from "react";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import { getProfileData, postProfileData, postProfileData2 } from "../../services/dataApi";
import LoadingScreen from "../../components/loading_screen/LoadingScreen";
import { UserService } from "../../services/UserService";

const SkinCheckSettings: React.FC = () => {
  var router = useIonRouter();

  const [colorsSelected, setColorsSelected] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [colorsList, setcolorsList] = useState([
    "#D8E7FD",
    "#1F1410",
    "#2E1B14",
    "#3F271B",
    "#7A4B22",
    "#8D5524",
    "#C68642",
    "#F0B48F",
    "#F5D4B3",
    "#FAF1C8",
  ]);

  const [selectedColor, setSelectedColor] = useState("");

  const [showLoader, setShowLoader] = useState(false);

  const openLoader = () => {
    setShowLoader(true);
  };

  const closeLoader = () => {
    setShowLoader(false);
  };

  useEffect(() => {
    const color = (SkinSelfCheckDataService.skinColor.length > 0 ? SkinSelfCheckDataService.skinColor : "#D8E7FD");
    let tempArr = colorsSelected;
    if (color!== null && color.length > 0) {
      colorsList.forEach((element,i) => {
        if (color === element) {
          tempArr[i] = true;
        } else {
          tempArr[i] = false;
        }
      });

      setColorsSelected(tempArr);
    }
  });

  async function postProfile() {
    try {
      openLoader();
      const responseData = await postProfileData2(0,
        (isSuccess: boolean, respData: any) => {
          if (isSuccess) {
            //Success
            closeLoader();
            router.goBack();
            //Save user data
          } else if (!isSuccess) {
            //Error
            closeLoader();
            console.log(respData);
          }
        }
      );
      console.log(responseData);
    } catch (error) {
      // closeLoader();
      console.error("Error fetching data:", error);
    }
  }

  const notificationToggle = (event: any) => {
    const isOn = event.detail.checked;
    UserService.turnOnNotifications = isOn;
  };

  const hintsToggle = (event: any) => {
    const isOn = event.detail.checked;
    UserService.showHints = isOn;
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <LoadingScreen text="Loading Skin Check Settings" isOpen={showLoader} />
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            SkinSelfCheckDataService.showSkinSelfCheckVideo = true;
            UserService.controlBottomBarFromBack=4;
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonText className="titleText">Skin Check Settings</IonText>
          </IonRow>
        </IonGrid>
        <div className="mainContent skincheck-mainContent profileMainContent">
          <ToggleButton
            Title={"Notifications"}
            isOn={UserService.turnOnNotifications}
            toggleEvent={notificationToggle}
          ></ToggleButton>
          {/* <ToggleButton
            Title={"Show Hints"}
            isOn={UserService.showHints}
            toggleEvent={hintsToggle}
          ></ToggleButton> */}
          {/* <div className="subAccMainContainer button-grp">
            <IonGrid>
              <IonRow className="">
                <IonCol className="text-default link-label">
                  Accessibility
                </IonCol>
              </IonRow>
              <IonRow className="">
                <IonCol className="text-default sub-heading">Font Size</IonCol>
              </IonRow>
              <ButtonGroup></ButtonGroup>
            </IonGrid>
          </div> */}
          <div className="subAccMainContainer">
            <IonGrid>
              {/* Skin Palette  */}
              <div className="cceColorGrid">
                <IonRow className="cceRowText">
                  <IonCol>
                    <IonText>Skin Color Palette </IonText>
                  </IonCol>
                </IonRow>

                <IonRow className="cceRowPalette">
                  {colorsSelected.map((color: boolean, i: number) => {
                    return (
                      <IonCol className="cceColPalette" size="12/5">
                        <div
                          className={
                            color
                              ? "cceColorCircleSelected stsColor" + (i + 1)
                              : "cceColorCircle stsColor" + (i + 1)
                          }
                          onClick={() => {
                            let tempArr = [
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                              false,
                            ];
                            tempArr[i] = true;
                            const setColor = colorsList[i];
                            setSelectedColor(setColor);
                            setColorsSelected(tempArr);
                          }}
                        ></div>
                        )
                      </IonCol>
                    );
                  })}
                </IonRow>
              </div>
            </IonGrid>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnDefault"
                onClick={() => {
                  router.goBack();
                }}
              >
                Cancel
              </IonButton>
              <IonButton
                className="loginBtnEnabled"
                onClick={() => {
                  SkinSelfCheckDataService.skinColor = selectedColor.length > 0 ? selectedColor : (SkinSelfCheckDataService.skinColor.length > 0 ? SkinSelfCheckDataService.skinColor : "#D8E7FD");
                  postProfile();
                }}
              >
                Save
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckSettings;

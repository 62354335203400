import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useState } from "react";

import "./AccordianCoreContentBioExp.css";

import "../../Fonts.css";

interface AccordianCoreContentBioExpProps {
  description: string;
  bioDesc: string;
  age: string;
  diagnosis: string;
  location: string;
  ageOfDiagnosis: string;
  skinType: string;
  hair: string;
  eyes: string;
}

const AccordianCoreContentBioExp: React.FC<AccordianCoreContentBioExpProps> = ({
  description,
  bioDesc,
  age,
  diagnosis,
  location,
  ageOfDiagnosis,
  skinType,
  hair,
  eyes,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="acceMainContainer accbeMainContainer">
      <IonGrid>
        <IonRow>
          <IonCol size="12">
            <IonText className="acceBioText">Bio:</IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonText className="acceBioText" style={{fontWeight: "normal"}}>{bioDesc}</IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <IonText className="acceBioTextVal">{description}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className="acceBioDemographicsRow">
          <IonCol size="12">
            <IonText className="acceBioText">Demographics:</IonText>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size="12">
            <ul>
              <li>
                <IonText className="acceBioTextVal">Age: </IonText>
                <IonText className="acceBioText">{age}</IonText>
              </li>
              <li>
                <IonText className="acceBioTextVal">Diagnosis: </IonText>
                <IonText className="acceBioText">{diagnosis}</IonText>
              </li>
              <li>
                <IonText className="acceBioTextVal">
                  Location of melanoma: </IonText>
                <IonText className="acceBioText">{location}</IonText>
              </li>
              <li>
                <IonText className="acceBioTextVal">Age at diagnosis: </IonText>
                <IonText className="acceBioText">{ageOfDiagnosis}</IonText>
              </li>
              <li>
                <IonText className="acceBioTextVal">Skin type: </IonText>
                <IonText className="acceBioText">{skinType}</IonText>
              </li>
              <li>
                <IonText className="acceBioTextVal">Hair: </IonText>
                <IonText className="acceBioText">{hair}</IonText>
              </li>
              <li>
                <IonText className="acceBioTextVal">Eyes: </IonText>
                <IonText className="acceBioText">{eyes}</IonText>
              </li>
            </ul>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AccordianCoreContentBioExp;

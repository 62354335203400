import { useState } from "react";
import CoreFnFBars from "../core_fnf_bars/CoreFnFBars";
import CoreFnfProgressBar from "../core_fnf_progress-bar/corefnfProgressBar";
import "./corefnf2.css";
import { CoreFnfJsonDataType } from "../../services/DataService";

interface CoreFnf2 {}

const jsonData = [
  {
    id: 1,
    title: "What do you know about spots?",
    description: "Read each statement and choose if it's fact, or fiction",
    question: "Normal spots are usually multicoloured.",
    answer: "Normal spots are not usually multicoloured",
    answerType: "Fiction",
  },
  {
    id: 2,
    title: "What do you know about spots?",
    description: "Read each statement and choose if it's fact, or fiction",
    question: "Normal spots usually have a round or oval shape.",
    answer: "Normal spots usually have a round or oval shape.",
    answerType: "Fact",
  },

  {
    id: 3,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question: "Apply sunscreen as soon as you get outside.",
    answer: "Don't wait until you are outside to apply sunscreen.",
    answerType: "Fiction",
  },
  {
    id: 4,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question: "Apply sunscreen about 20 minutes before you go outside.",
    answer: "Sunscreen should be applied before you go outside.",
    answerType: "Fact",
  },
  {
    id: 5,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question:
      "Most people apply less than half the recommended amount of sunscreen.",
    answer: "keep this fact in mind when you are applying sunscreen.",
    answerType: "Fact",
  },
  {
    id: 6,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question: "Sunscreen is all you need to use to stay sun-safe.",
    answer:
      "Sunscreen is just one of several behaviors that can help you stay sun-safe.",
    answerType: "Fiction",
  },
  {
    id: 7,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question:
      "To use enough sunscreen, you can apply it in two layers:use it once on all exposed areas and then apply it again right away .",
    answer:
      "This also helps ensure that you don't miss any areas of your body.",
    answerType: "Fact",
  },
  {
    id: 8,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question:
      "Once you have put sunscreen on in the morning, you are protected for the day.",
    answer: "Sunscreen must be reapplied to be effective.",
    answerType: "Fiction",
  },
  {
    id: 9,
    title: "What do you know about sunscreen?",
    description: "Read each statement and choose if it's fact, or fiction",
    question:
      "Reapply sunscreen every two hours or more often if you are sweating a lot or swimming.",
    answer:
      "Be sure to reapply sunscreen early & often, especially if you have been sweating or swimming.",
    answerType: "Fact",
  },
];

interface CoreFnF2Props {
  data: Array<any>;
  isSelected: Function;
}


const CoreFnf2: React.FC<CoreFnF2Props> = ({ data, isSelected }) => {
  const [index, setIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<CoreFnfJsonDataType>(
    data[index]
  );
  const [isQuestion, setIsQuestion] = useState<boolean>(true);
  const totalNoOfQuestions = data.length;

  const onClickContinue = () => {
    if (index === totalNoOfQuestions - 1) {
      // setCurrentQuestion(data[totalNoOfQuestions - 1]);
      // setHideContinue(true);
    } else {
      var tempIndex = index + 1;

      setIndex(tempIndex);
      setCurrentQuestion(data[tempIndex]);
      setIsQuestion(true);
    }
  };

  const finalAnswerSelected = () => {
    isSelected(data);
  }

  const answerSelected = (question: any, answer: string) => {
    console.log(question);
    console.log(answerSelected);

    question["enteredAnswer"] = answer;
    console.log(question);
  }

  return (
    <>
      <CoreFnfProgressBar
        coreFnfTitle={currentQuestion.title}
        coreFnfDescription={currentQuestion.description}
        questionNo={`${currentQuestion.id}/${totalNoOfQuestions}`}
      />
      <CoreFnFBars
        question={currentQuestion.question}
        currentQuestion={currentQuestion}
        continueHandler={onClickContinue}
        isQuestion={isQuestion}
        setIsQuestion={setIsQuestion}
        hideContinue={(currentQuestion.id === totalNoOfQuestions)}
        finalAnswerSelected = {finalAnswerSelected}
        answerSelected={answerSelected}
      ></CoreFnFBars>
    </>
  );
};

export default CoreFnf2;

import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
} from "@ionic/react";

import "./SkinCheckImageMultiSpots.css";
import { useEffect, useRef, useState } from "react";
import ProgressFraction from "../../components/progress_fraction/ProgressFraction";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";

let imageTaggingAllowed = true;
let removedDefSpot = false;

const SkinCheckImageMultiSpots: React.FC = () => {
  const router = useIonRouter();

  const calculatePartsPercentage = () => {
    var per = SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount / SkinSelfCheckDataService.skinselfcheckTotalRegionsCount;
    per = per * 100;
    return Math.round(per);
  }

  useEffect(() => {
    let divwid = SkinSelfCheckDataService.addimagedivWidth;
    //let divwid =354;
    console.log("Div Width: " + divwid);
    if (divwid > 0) {
      setDivWidth(divwid);
    } else {
      if (divRef.current != null) {
        setDivWidth(divRef.current.clientWidth);
      } else {
        setDivWidth(divwid);
      }

      //setDivWidth(354);
    }
    setTags(SkinSelfCheckDataService.addimageselSpots);

    if (!removedDefSpot) {
      removedDefSpot = true;
      tags.pop();
    }
  });

  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const [pointX, setPointX] = useState(0);
  const [pointY, setPointY] = useState(0);
  const [tags, setTags] = useState([
    {
      x: 0,
      y: 0,
    },
  ]);
  const divRef = useRef(document.createElement("div"));
  const [divWidth, setDivWidth] = useState(0);

  const getSpotPositions = async (event: React.MouseEvent) => {
    const boundingRect = event.currentTarget.getBoundingClientRect();
    //var bounds = event.target.getBoundingClientRect();
    var spotx = event.clientX - boundingRect.left;
    var spoty = event.clientY - boundingRect.top;
    setPointX(spotx);
    setPointY(spoty);

    // const { width, height } = event.target.getBoundingClientRect();
    // const { offsetX, offsetY } = event.nativeEvent;

    // console.log("width is: " + width);
    // console.log("height is: " + height);

    // setImgWidth(width);
    // setImgHeight(height);

    // var newX = Math.round((offsetX / width) * 100);
    // var newY = Math.round((offsetY / height) * 100);
    // // This output's the X coord of the click
    // console.log(
    //   "X coordinate: " +
    //   newX +
    //   ". eventX is " +
    //   x +
    //   ". " +
    //   newX +
    //   "% of " +
    //   width +
    //   " is " +
    //   (newX / 100) * width
    // );

    // // This output's the Y coord of the click
    // console.log("Y coordinate: " + newY);

    var newTag = {
      x: spotx,
      y: spoty,
    };
    let tempTags = tags;
    if (imageTaggingAllowed) {
      tempTags.push(newTag);
    } else {
      return;
    }

    setTags(tempTags);

    console.log("IMAGE CLICKED: " + tags);
  };
  const SaveNavigationToReview = (tags: any) => {
    //   if(tags.length > 0)
    // {
    //   SkinSelfCheckDataService.selectedTags = tags;
    // }
    SkinSelfCheckDataService.addimageselSpots = tags;
    SkinSelfCheckDataService.addimageDivRef = divRef;
    SkinSelfCheckDataService.addimageWidth = imgWidth;
    SkinSelfCheckDataService.addimageHeight = imgHeight;
    SkinSelfCheckDataService.addimagedivWidth = divWidth;
    SkinSelfCheckDataService.isInitialLoggingImg = false;

    router.push("/skincheckspotreview", "forward", "push");
  };

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#187685" }}>
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        <ProgressFraction
          percent={calculatePartsPercentage()}
          fraction={SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount + "/" + SkinSelfCheckDataService.skinselfcheckTotalRegionsCount}
          description="Body Parts"
        />
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">
              {SkinSelfCheckDataService.selectedRegionSubPart.title.length > 0 ? SkinSelfCheckDataService.selectedRegionSubPart.title : ""}
              </IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                router.push("home/skinchecktab", "forward", "push");
              }}
            >
              <IonText className="scsdRightButton">Body Map</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="mainContent sscpsMainContent sccpMar">
          <IonText className="sccpsDescText">
            Do you have multiple spots in this photo?
          </IonText>
          <div className="sccpsSpotImg">
            <div
              className="sccpsMulPositionsDiv"
              ref={divRef}
              onClick={getSpotPositions}
            >
              <IonImg
                className="sccpMulCapturedImg"
                style={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                src={SkinSelfCheckDataService.selectedImgUrl}
              />
              {tags.length > 0 &&
                tags.map((tag) => {
                  return (
                    <div
                      style={{
                        position: "absolute",
                        left: `${tag.x}px`,
                        top: `${tag.y}px`,
                        //top: tag.y,
                        // left: `${(tag.x / 100) * imgWidth +
                        //   (divWidth - imgWidth) / 2 -
                        //   10
                        //   }px`,
                        // top: `${(tag.y / 100) * imgHeight - 20}px`,
                        height: "20px",
                        width: "20px",
                      }}
                    >
                      <IonImg src="/assets/icons/PointerArrow.png"></IonImg>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="scimsDiv1">
            <IonRow>
              <IonCol size="2">
                <IonImg
                  src={"/assets/icons/click.png"}
                  className="scimsImg1"
                ></IonImg>
              </IonCol>
              <IonCol size="10">
                <IonText className="scimsText2">
                  If yes, click on the spot you want to log right now and
                  continue.
                </IonText>
              </IonCol>
            </IonRow>
          </div>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              <IonButton
                className="loginBtnDefault sccpCancelBtn"
                onClick={() => {
                  SkinSelfCheckDataService.addimageselSpots = tags;
                  SkinSelfCheckDataService.addimageDivRef = divRef;
                  SkinSelfCheckDataService.addimageWidth = imgWidth;
                  SkinSelfCheckDataService.addimageHeight = imgHeight;
                  console.log("div width in onclick", divWidth);
                  SkinSelfCheckDataService.addimagedivWidth = divWidth;
                  router.push("/skincheckspotreview", "forward", "push");
                }}
              >
                Only 1 Spot
              </IonButton>
              <IonButton
                className="loginBtnEnabled scsdNextBtn sccpNextBtn"
                onClick={() => {
                  {
                    SaveNavigationToReview(tags);
                  }
                }}
              >
                <IonImg
                  className="sceContinueImg"
                  src="/assets/icons/CheckCircleOutline.png"
                />
                <IonText className="sccpText">Continue</IonText>
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckImageMultiSpots;

import { IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import "./LikertScale.css";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { C2DataSubmissionService } from "../../services/C2DataSubmissionService";

interface LikertScaleProps {
  question: string;
  likertClickIndex: Function;
}

let dataLoaded = false;

const LikertScale: React.FC<LikertScaleProps> = ({
  likertClickIndex,
  question,
}) => {
  const likertImageURls = [
    "assets/icons/Radio1.svg",
    "assets/icons/Radio1.svg",
    "assets/icons/Radio1.svg",
    "assets/icons/Radio1.svg",
    "assets/icons/Radio1.svg",
  ];
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  useEffect(() => {
    // if (!dataLoaded) {
    //   dataLoaded = true;

      let answer = -1;
      if (question === "Use sunscreen with SPF 30 or higher?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn1");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn1 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn1;
      } else if (question === "Wear a long-sleeved shirt?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn2");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn2 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn2;
      } else if (question === "Wear a wide-brimmed hat?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn3");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn3 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn3;
      } else if (question === "Wear sunglasses?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn4");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn4 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn4;
      } else if (question === "Avoid the sun from 10 a.m. to 4 p.m.?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn5");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn5 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn5;
      } else if (question === "Stay in the shade or under an umbrella?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn6");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn6 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn6;
      } else if (question === "Have a sunburn?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn7");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn7 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn7;
      } else if (question === "Intentionally or incidentally tan?") {
        const ans = localStorage.getItem("c2Ch1S3LikertQstn8");
        if ((ans !== undefined) && ans?.length !== undefined) {
          C2DataSubmissionService.c2Ch1S3LikertQstn8 = Number(ans);
        }
        answer = C2DataSubmissionService.c2Ch1S3LikertQstn8;
      }
  
      if (answer >= 0) {
        handleImageClick(answer);
      }
    // }
  }, []);

  const handleImageClick = (index: any) => {
    setSelectedImageIndex(index); //(index === selectedImageIndex ? null : index);
    likertClickIndex(question, index);
  };

  return (
    <>
      <div>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              <div className="">
                <IonText className="ccaDescText">{question}</IonText>
              </div>
              <div className="likert-component">
                {/* <div className="likert-bar"></div> */}
                {likertImageURls.map((imageUrl, index) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="likert-icon-3"
                        // {
                        //   index === 3
                        //     ? "likert-icon-3"
                        //     : index === 4
                        //     ? "likert-icon-4"
                        //     : ""
                        // }
                        key={index}
                        onClick={() => handleImageClick(index)}
                      >
                        <IonImg
                          src={
                            selectedImageIndex === index
                              ? "assets/icons/RadioSel.svg"
                              : imageUrl
                          }
                        />
                        {/* {selectedImageIndex === index && (
                          <IonImg
                            className={
                              index === 3
                                ? "likert-pointer-3"
                                : index === 4
                                ? "likert-pointer-4"
                                : "likert-pointer"
                            }
                            src={"assets/icons/likert_pointer.png"}
                          />
                        )} */}
                      </div>
                      <IonText className="likert-desc-text">
                        {index === 0
                          ? "Never"
                          : index === 1
                          ? "Rarely"
                          : index === 2
                          ? "Sometimes"
                          : index === 3
                          ? "Often"
                          : "Always"}
                      </IonText>
                    </div>
                  </>
                ))}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default LikertScale;

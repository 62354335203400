import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";

import "./TaskSelectionOption.css";

import "../../Fonts.css";
import TaskSelectionOptionHint from "../task_selection_option_hint/TaskSelectionOptionHint";
import { DataSubmissionService } from "../../services/DataSubmissionService";
import { useEffect } from "react";
import { DataService } from "../../services/DataService";

interface TaskSelectionOptionProps {
  index: number;
  imageUrl: string;
  title: string;
  subtitle: string;
  desc: string;
  options: any;
  isOpen: boolean;
  isSelected: Function;
  click: Function;
}

const TaskSelectionOption: React.FC<TaskSelectionOptionProps> = ({
  index,
  title,
  subtitle,
  imageUrl,
  desc,
  options,
  isOpen,
  isSelected,
  click,
}) => {
  const selected = () => {
    DataSubmissionService.chooseAGoalOptionMainQuestionIndex = index;
    isSelected(index);
  };

  const optionSelected = (options: any) => {
    click(options);
  };

  useEffect(() => {
    // BE goal Core1 Activity 1 sync.
    let selGoal = DataService.selectedC1A1Goal;
    if (selGoal !== null && selGoal !== undefined) {
      let selGoalMainDesc = selGoal.goal.mainDesc;

      console.log(selGoalMainDesc);

      let selIndex = -1;
      for (let index = 0; index < options.length; index++) {
        const option = options[index];
        if (option.text === selGoalMainDesc) {
          selIndex = index;
        }
      }

      if (selIndex >= 0) {
        DataSubmissionService.chooseAGoalOptionMainQuestionIndex = index;
        DataSubmissionService.mainGoalIndex = index;
        let selOptions = options[selIndex].options;
        optionSelected(selOptions);
      }
    }
  });

  return (
    <div className="acceMainContainer tsoMainContainer animate__animated animate__fadeInRight ">
      <IonGrid>
        <IonRow>
          {imageUrl !== null &&
            imageUrl !== undefined &&
            imageUrl.length > 0 && (
              <IonCol size="auto" className="tsoImgCol">
                {isOpen && (
                  <IonImg
                    src="assets/icons/RadioSelected.png"
                    onClick={selected}
                  />
                )}
                {!isOpen && (
                  <IonImg src="assets/icons/Radio.png" onClick={selected} />
                )}
              </IonCol>
            )}
          <IonCol
            className="acceTitleCol accqTitleCol"
            style={{
              paddingLeft:
                imageUrl !== null &&
                imageUrl !== undefined &&
                imageUrl.length > 0
                  ? "16px"
                  : "0px",
            }}
          >
            <IonGrid>
              <IonRow>
                {imageUrl !== null &&
                  imageUrl !== undefined &&
                  imageUrl.length > 0 && (
                    <IonCol size="auto">
                      <IonImg className="tsoMainImg" src={imageUrl} />
                    </IonCol>
                  )}
                {/* <IonCol className="tsoTitleCol">
                  <IonText className="tsoTitleText">{title}</IonText>
                </IonCol> */}
                <IonCol
                  className={
                    imageUrl !== null &&
                    imageUrl !== undefined &&
                    imageUrl.length > 0
                      ? "tsoTitleCol"
                      : ""
                  }
                >
                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonText className="tsoTitleText">{title}</IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonText className="tsoSubTitleText">
                          {subtitle}
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonCol>
              </IonRow>
              {isOpen && (
                <IonRow>
                  <IonCol className="tsoDescCol" size="12">
                    <IonText className="tsoDescText">{desc}</IonText>
                  </IonCol>
                </IonRow>
              )}
              {isOpen && (
                <IonRow>
                  {options.map((option: any, i: number) => {
                    return (
                      <IonCol
                        size="12"
                        onClick={() => {
                          optionSelected(option.options);
                        }}
                      >
                        <TaskSelectionOptionHint
                          text={option.text}
                          imageUrl={option.imageUrl}
                          isFullWidth={false}
                          defaultSpacingTop={true}
                        />
                      </IonCol>
                    );
                  })}
                </IonRow>
              )}
            </IonGrid>
          </IonCol>
        </IonRow>
      </IonGrid>
      {/* {isOpen && (
        <div>
          {options.map((option: any, i: any) => {
            return (
              <CoreChapterActivityOptionHint
                text={option.text}
                isWhiteBG={true}
              />
            );
          })}
        </div>
      )} */}
    </div>
  );
};

export default TaskSelectionOption;

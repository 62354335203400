import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useState } from "react";
import "./AccordianGoalSummary.css";
import "../../Fonts.css";
import { chevronDown, chevronUp } from "ionicons/icons";

interface AccordianGoalSummaryProps {
  text: string;
  desc: string;
  subDesc: string;
  imgUrl: string;
}

const AccordianGoalSummary: React.FC<AccordianGoalSummaryProps> = ({
  text,
  desc,
  subDesc,
  imgUrl,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="absMainContainer animate__animated animate__fadeInRight">
      <IonGrid
        onClick={() => {
          toggleAccordion();
        }}
      >
        <IonRow>
          {!isOpen && (
            <IonCol size="12">
              <div className="agsMainDiv">
                <IonText className="agaMainDivText">{text}</IonText>
                <IonIcon
                    className="accChevronIcon"
                    icon={isOpen ? chevronUp : chevronDown}
                    onClick={toggleAccordion}
                  ></IonIcon>
              </div>
            </IonCol>
          )}
          {isOpen && (
            <>
              {" "}
              <IonCol size="12">
                <div className="agsMainDiv">
                  <IonText className="agaMainDivText">{text}</IonText>
                  <IonIcon
                    className="accChevronIcon"
                    icon={isOpen ? chevronUp : chevronDown}
                    onClick={toggleAccordion}
                  ></IonIcon>
                </div>
              </IonCol>
              <IonCol size="12">
                <div className="agssOpenedDiv">
                  <IonGrid>
                    <IonRow>
                      <IonCol className="agssOpenedDivImgCol" size="auto">
                        <IonImg className="agssOpenedDivImg" src={imgUrl} />
                      </IonCol>
                      <IonCol className="agssTextCol">
                        <IonText
                          className="agssOpenedDivTitle"
                          dangerouslySetInnerHTML={{ __html: desc }}
                        ></IonText>
                        <IonText
                          className="agssOpenedDivDesc"
                          dangerouslySetInnerHTML={{ __html: subDesc }}
                        ></IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonCol>
            </>
          )}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AccordianGoalSummary;

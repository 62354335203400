import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from "@ionic/react";
import { useState } from "react";

import "./AccordianToolsSelectedExp.css";

import "../../Fonts.css";

interface AccordianToolsSelectedExpProps {
  index: number;
  msg: string;
  text:string
  option: any;
}

const AccordianToolsSelectedExp: React.FC<AccordianToolsSelectedExpProps> = ({
  index,
  msg,
  text,
  option,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={
        index % 2 === 0
          ? "acceMainContainer ascteMainContainerColor1"
          : "acceMainContainer ascteMainContainerColor2"
      }
    >
      <IonGrid className={option.options.length === 0  ? "atseMainGrid" : ""}>
        <IonRow>
          <IonCol size="12">
            <IonText className="atseMainTitleText">{((msg.length > 0) && (option.options.length === 0)) ? msg : option.title}</IonText>
          </IonCol>
        </IonRow>
        <IonRow className="atseOptionsRow">
          {option.options.map((opt: any, i: number) => {
            return (
              
              <IonCol className="atseOptionCol" size="auto">
                <IonImg className="atseImg" src={opt.imageUrl} />
                <div className="atseOptTextDiv">
                  <IonText className="atseOptText" dangerouslySetInnerHTML={{ __html:  opt.title }}></IonText>
                </div>
              </IonCol>
            );
          })}
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default AccordianToolsSelectedExp;

import {
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useState } from "react";

import "./AccordianSummaryColorText.css";

import "../../Fonts.css";
import AccordianSummaryColorTextExp from "../accordian_summary_color_text_exp/AccordianSummaryColorTextExp";

interface AccordianSummaryColorTextProps {
  title: string;
  options: Array<any>;
}

const AccordianSummaryColorText: React.FC<AccordianSummaryColorTextProps> = ({
  title,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accMainContainer accccMainContainer abbsMainContainer">
      <IonGrid className="accTitleGrid accccTitleGrid">
        <IonRow>
          <IonCol className="accccTitleCol" size="8">
            <IonText className="abssTitleText">{title}</IonText>
          </IonCol>
          {/* <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
                onClick={toggleAccordion}
              ></IonIcon>
            </div>
          </IonCol> */}
        </IonRow>
      </IonGrid>
      {isOpen &&
        options.map((option: any, i: number) => {
          return <AccordianSummaryColorTextExp index={i} text={option} />;
        })}
    </div>
  );
};

export default AccordianSummaryColorText;

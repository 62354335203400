import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./SkinCheckDiameter.css";
import { useRef, useState } from "react";
import ProgressFraction from "../../components/progress_fraction/ProgressFraction";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";

const SkinCheckDiameter: React.FC = () => {
  var router = useIonRouter();
  const [dia, setDia] = useState(0);

  useIonViewWillEnter(() => {
    if (SkinSelfCheckDataService.isInitialLoggingDia) {
      setDia(0);
    }
  });

  const increment = () => {
    setDia(dia + 1);
  };

  const decrement = () => {
    setDia(dia - 1);
  };

  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
  };

  const save = () => {
    SkinSelfCheckDataService.selectedDia = dia;
  };
  const calculatePartsPercentage = () => {
    var per = SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount/ SkinSelfCheckDataService.skinselfcheckTotalRegionsCount;
    per = per*100;
    return Math.round(per);
  }

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
      >
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            SkinSelfCheckDataService.isInitialLoggingChars = false;
            router.push("/SkinCheckSpotDetails", "back", "push");
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>
        {/* _______________________________________________________________________ */}
        {/* Progress bar */}
        <ProgressFraction
          percent={calculatePartsPercentage()}
          fraction={SkinSelfCheckDataService.skinselfcheckTotalRegionsSpotsCount
            +"/"+SkinSelfCheckDataService.skinselfcheckTotalRegionsCount}
          description="Body Parts"
        />
        <IonGrid>
          <IonRow class="profileTitleRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText">{SkinSelfCheckDataService.selectedRegionSubPart.title}</IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                router.push("home/skinchecktab", "forward", "push");
              }}
            >
              <IonText className="scsdRightButton">Body Map</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className="mainContent skincheck-mainContent profileMainContent scsrMar">
          {/* <IonImg
            className="scsdFloatingdiv"
            src="/assets/icons/FixedScroll.png"
            onClick={scrollToBottom}
          ></IonImg> */}
          <div className="scdDiv1">
            <IonGrid>
              <IonRow>
                <IonCol size="12">
                  <IonText className="scdsub-heading">Diameter</IonText>
                </IonCol>
                {/* <IonCol className="scdCol">
                  <IonText className="scdsub-heading1">
                    Try to use your Metric or MSS ruler to help measure your
                    spot.
                  </IonText>
                </IonCol> */}
              </IonRow>
              <IonRow className="scdRow2">
                <IonCol size="12">
                  <IonText className="scdsub-heading2">Enter Diameter</IonText>
                </IonCol>

                <IonCol size="12" className="scdCol2">
                  <div className="scdDiaTextFieldDiv">
                    <IonGrid>
                      <IonRow>
                        <IonCol></IonCol>
                        <IonCol className="scdCol3" size="auto">
                          {dia === 0  && (
                            <IonImg src="/assets/icons/MinusCircleDisabled.png"></IonImg>
                          )}
                          {dia > 0 && (
                            <IonImg
                              src="/assets/icons/MinusCircleEnabled.png"
                              onClick={decrement}
                            ></IonImg>
                          )}
                          {/* { <IonText className="scdText">{("0" + dia.toString()).slice(-2)}</IonText> } */}
                          <IonInput
                            className="scdInput"
                            value={("0" + dia.toString()).slice(-2)}
                          >
                            {" "}
                          </IonInput>
                          {/* onIonChange={(e: any) => setDiaInp(e.target.value)} */}
                          <IonText className="scdText scdInput">MM</IonText>
                          <IonImg
                            src="/assets/icons/AddCircleOutline.png"
                            onClick={increment}
                          ></IonImg>
                        </IonCol>
                        <IonCol></IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          <IonGrid className="scdGrid3">
            <IonRow>
              <IonCol className="scsdAccurateTextCol" size="12">
                <IonText className="scdText2">
                  How to Measure Accurately!
                </IonText>
              </IonCol>
              <IonCol className="scdCol5" size="12">
                <IonImg
                  className="scdImg2"
                  src="/assets/images/MeasuringRuler.png"
                ></IonImg>
              </IonCol>
              <IonCol size="12" className="scdCol4">
                <IonText className="scdText1"> Hold the ruler up to your spot flush against your skin. </IonText>
              </IonCol>
              <IonCol size="12" className="scdCol4">
                <IonText className="scdText1">  If your spot is larger than 6mm or 1/4 inch, about the size of a pencil eraser, please have it checked out by your healthcare provider. </IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              {dia === 0  && (
              <IonButton
                className="loginBtnEnabled scsdNextBtn" 
                disabled={true}
                onClick={() => {
                  // save();
                  // SkinSelfCheckDataService.isInitialLoggingDia = false;
                  // SkinSelfCheckDataService.isInitialLoggingChars = false;

                  // router.push("/SkinCheckSpotReview", "forward", "push");
                }}
              >
                <IonImg
                  className="sceContinueImg"
                  src="/assets/icons/CheckCircleOutline.png"
                />
                <IonText>Continue</IonText>
              </IonButton>)}
              {dia > 0  && (
              <IonButton
                className="loginBtnEnabled scsdNextBtn" 
                onClick={() => {
                  save();
                  SkinSelfCheckDataService.isInitialLoggingDia = false;
                  SkinSelfCheckDataService.isInitialLoggingChars = false;

                  router.push("/SkinCheckSpotReview", "forward", "push");
                }}
              >
                <IonImg
                  className="sceContinueImg"
                  src="/assets/icons/CheckCircleOutline.png"
                />
                <IonText>Continue</IonText>
              </IonButton>)}
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckDiameter;

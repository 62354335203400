import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
} from "@ionic/react";
import { useState } from "react";
import { chevronDown, chevronUp, settings } from "ionicons/icons";

import "./AccordianToolsSelected.css";

import "../../Fonts.css";
import AccordianToolsSelectedExp from "../accordian_tools_selected_exp/AccordianToolsSelectedExp";

interface AccordianToolsSelectedProps {
  title: string;
  msg: string;
  options: Array<any>;
}

const AccordianToolsSelected: React.FC<AccordianToolsSelectedProps> = ({
  title,
  msg,
  options,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accMainContainer accccMainContainer animate__animated animate__fadeInRight">
      <IonGrid className="accTitleGrid accccTitleGrid">
        <IonRow>
          <IonCol className="accccTitleCol" size="8">
            <IonText className="atsTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
                onClick={toggleAccordion}
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen &&
        options.map((option: any, i: number) => {
          return (
            <AccordianToolsSelectedExp index={i} msg={msg} text={option.title} option={option} />
          );
        })}
    </div>
  );
};

export default AccordianToolsSelected;

import {
  IonContent,
  IonImg,
  IonPage,
  useIonRouter,
} from "@ionic/react";
import "./FormPage.css";
import CoreForm from "../../components/core_form/CoreForm";

const FormPage: React.FC = () => {
  var router = useIonRouter();

  const questionDesc =
    "Please select <strong>one or more</strong> reasons below.";

  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#f5f6fa" }}>
        <div className="fpMainContainer">
          <div
            className="lBack"
            onClick={() => {
              router.goBack();
            }}
          >
            <IonImg src="/assets/icons/LeftOutlineBlack.png"></IonImg>
          </div>

          <CoreForm
            question="Sun Safe Behaviours: Reasons they are important to you."
            desc={"Please select <strong>one or more</strong> reasons below."}
            limitOptions={3}
            listOptions={[
              "Role model for others",
              "Family (make them less worried)",
              "In control of my Health",
              "Find cancerous spots early",
              "Peace of mind for myself and my family about my health",
              "Enter my own strategy",
            ]}
            selected={(selectedOptions: Array<string>, selectedStrategies: Array<string>) => {
              console.log(selectedOptions);
              console.log(selectedStrategies);
            }}
          ></CoreForm>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default FormPage;

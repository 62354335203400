import { IonText, IonGrid, IonRow, IonCol } from "@ionic/react";

import "./SummaryDescription.css";

import "../../Fonts.css";

interface SummaryDescriptionProps {
  text: string;
}

const SummaryDescription: React.FC<SummaryDescriptionProps> = ({ text }) => {
  return (
    <>
      <div className="">
        <IonGrid>
          <IonRow>
            <IonCol className="sdDiv" size="12">
              <IonText
                className="sdFont"
                dangerouslySetInnerHTML={{ __html: text }}
              ></IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
};

export default SummaryDescription;

import {
  IonIcon,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
  IonButton,
} from "@ionic/react";
import { useRef, useState } from "react";
import { chevronDown, chevronUp } from "ionicons/icons";

import "./AccordianBioDesc.css";

import "../../Fonts.css";

interface AccordianBioDescProps {
  title: string;
  description: string;
  imageUrl: string;
  audioUrl: string;
  open: boolean;
  isOpened: Function;
}

const AccordianBioDesc: React.FC<AccordianBioDescProps> = ({
  title,
  description,
  imageUrl,
  audioUrl,
  open, 
  isOpened
}) => {
  const [isOpen, setIsOpen] = useState(open);

  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);

    if (!isOpen) {
      isOpened();
    }
  };

  const togglePlay = () => {
    if (isPlaying) {
      if (audioRef !== null && audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
    } else {
      if (audioRef !== null && audioRef.current) {
        audioRef.current.play();
      }
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div className="accMainContainer accccMainContainer">
      <div>
        <audio ref={audioRef}>
          <source src={audioUrl} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
      <IonGrid
        className="accTitleGrid accccTitleGrid"
        onClick={toggleAccordion}
      >
        <IonRow>
          <IonCol size="auto">
            <IonImg src={imageUrl} style={{ width: "70px" }} />
          </IonCol>
          <IonCol className="accTitleCol accccTitleCol" size="8">
            <IonText className="accTitleText">{title}</IonText>
          </IonCol>
          <IonCol class="accChevronCol accccChevronCol">
            <div className="accChevronColDiv">
              <IonIcon
                className="accChevronIcon"
                icon={isOpen ? chevronUp : chevronDown}
              ></IonIcon>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
      {isOpen && description.length > 0 && (
        <div>
          <IonGrid class="abdAudioDescBtnGrid">
            <IonRow>
              <IonCol size="12">
                <div className="accVolumeBtnDiv">
                  <IonImg
                    className="accVolumeImg"
                    src="assets/icons/volume.png"
                  />
                  <div className="accVolumeBtnTextDiv" onClick={togglePlay}>
                    <IonText className="accVolumeBtnText">Listen Audio</IonText>
                  </div>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
          <div className="accDescTextContainer">
            <IonText class="accDescText">{`"${description}"`}</IonText>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordianBioDesc;

export abstract class UserService {
  public static userName: string;
  public static userId: string;
  public static csrfToken: string = "";
  public static logoutToken: string;

  //Profile
  public static turnOnNotifications: boolean = true;
  public static showHints: boolean = true;

  public static isProfileGetApiCalled = false;

  public static loginAPICalled = false;

  public static userRole:Array<any>=[];

  public static isControlContentRole:boolean=false;

  public static controlBottomBarFromBack:number=0;
}

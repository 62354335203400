import { IonCol, IonGrid, IonImg, IonRow, IonText } from "@ionic/react";
import "./GetBadge.css";

interface GetBadgeProps {
  badges: Array<any>;
  badgeSelected: Function;
}

const GetBadge: React.FC<GetBadgeProps> = ({ badges, badgeSelected }) => {
  let isFinalDisabled = true;
  return (
    <div className="">
          <IonGrid>
            <IonRow>
              {badges.map((option: any, i: number) => {
                return (
                  <IonCol size="4" onClick={() => {
                    badgeSelected(option)
                  }}>
                    <IonImg src={option.imagePath} className={option.isDisabled ? "rbpImageBadge rbpImageBadgeLast" :"rbpImageBadge" }>
                      {" "}
                    </IonImg>
                    <IonText className="rbpImageText">{option.badgeName}</IonText>
                    {option.earnedBadges !== null && <div className="rbpDivProgressParent">
                    <div className="rbpDivProgress">
                     <IonText className="rbpDivProgressText">{`${option.earnedBadges} / ${option.totalBadges}`}</IonText>
                    </div>
                    </div>}
                  </IonCol>
                );
              })}
            </IonRow>
                {/* <IonRow>
                <IonCol size="4" className="rbpLastBadge">
                  </IonCol>
                  <IonCol size="4" className="rbpLastBadge">
                    <IonImg src="/assets/images/badges/FinalMss.png" className={isFinalDisabled ? "rbpImageBadge rbpImageBadgeLast" : "rbpImageBadge"}></IonImg>
                    <IonText className="rbpImageText">Completing MSS</IonText>
                  </IonCol>
                  <IonCol size="4" className="rbpLastBadge">
                  </IonCol>
                </IonRow> */}
          </IonGrid>
          </div>
  );
};

export default GetBadge;

import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonImg,
    IonPage,
    IonRow,
    IonText,
    useIonRouter,
  } from "@ionic/react";
  import "./InitialLogin.css";
  import "../../theme/GlobalStyles.css";

  const InitialLogin: React.FC = () => {
    const navigaton = useIonRouter();

    const goToLogin = () => {
      navigaton.push("/login", "forward", "push");
    }
    const goToRegister = () => {
      navigaton.push("/register", "forward", "push");
    }

    return (
      <IonPage>
        <IonContent fullscreen className="ion-padding ion-text-center">
          <IonGrid className="ilGrid">
            <IonRow class="ilRow">
              <IonCol size="auto" className="ilColimg">
                <IonImg src="/assets/images/InitialLoginImg.png"></IonImg>
              </IonCol>
              <IonCol size="12" className="ilTextCol">
                <IonText className="ilColText">Let’s you in</IonText>
                </IonCol>
              <IonCol size="12" className="loginLoginBtnCol ilBottomSpace">
                  <IonButton
                    className="loginBtnEnabled loginLoginBtn" onClick={goToLogin}>
                    Sign in with password
                  </IonButton>
              </IonCol>
              <IonCol size="12">
                <IonText className="ildescText">Don’t have an account?</IonText>
                <IonText className="ilregText" onClick={goToRegister}>Register Now</IonText>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  
  export default InitialLogin;
  
import {
  IonText,
} from "@ionic/react";
import { useState } from "react";

import "./AccordianSummaryColorTextExp.css";

import "../../Fonts.css";

interface AccordianSummaryColorTextExpProps {
  index: number;
  text: string;
}

const AccordianSummaryColorTextExp: React.FC<
  AccordianSummaryColorTextExpProps
> = ({ index, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className= {
        index % 2 === 0
          ? "acceMainContainer ascteMainContainerColor1  animate__animated animate__fadeInRight"
          : "acceMainContainer ascteMainContainerColor2  animate__animated animate__fadeInRight"
      }
    >
      <IonText
        className="ascteText"
        dangerouslySetInnerHTML={{ __html: text }}
      ></IonText>
    </div>
  );
};

export default AccordianSummaryColorTextExp;

import {
    IonText,
    IonGrid,
    IonRow,
    IonCol,
    IonImg,
  } from "@ionic/react";
  import { useEffect, useState } from "react";
  import "./CoreAccordianImageDesc.css";
  import "../../Fonts.css";
  import { DataSubmissionService } from "../../services/DataSubmissionService";
  
  interface CoreAccordianImageDescProps {
    setOpen: boolean;
     desc: string;
     accDescHead:string;
     accDescPoints: Array<string>;
     accDescHead2:string;
     accDescPoints2: Array<string>;
  }
  
  const CoreAccordianImageDesc: React.FC<CoreAccordianImageDescProps> = ({
     setOpen,
     desc,
     accDescHead,
     accDescPoints,
     accDescHead2,
     accDescPoints2
  }) => {
    const [isOpen, setIsOpen] = useState(setOpen ? true : false);
  
    const toggleAccordion = () => {
      setIsOpen(!isOpen);
    };
  
    useEffect(() => {
    //   console.log(text);
      console.log(DataSubmissionService.noneOfTheseSelected);
      console.log("kk")
    }, [])
  
    return (
      <div className="absMainContainer caidBottomMarAccordian animate__animated animate__fadeInRight">
        <IonGrid onClick={() => {
                  toggleAccordion();
                }}>
          <IonRow>
            <IonCol size="auto" className="caidColImgheading">
                <IonImg src="/assets/icons/sunsafeicon.png"></IonImg>
            </IonCol>
            <IonCol className="caidColHeading">
                <IonText className="caidTextHeading">UVA and UVB rays</IonText>
            </IonCol>
            <IonCol
              className="acceTitleCol ccaoh-acceTitleCol tsohCol3"
              size="auto" 
            >
              <IonImg
                className="absChevronImg"
                src={isOpen ? "assets/icons/UpOutline.png" : "assets/icons/DownOutline.png"}
              />
            </IonCol>
          </IonRow>
          <div style={{marginBottom:"16px"}}></div>
          {isOpen && (
            <IonRow className="caidGridMar">
             <IonCol className="caidColMar">
                <IonImg  src="/assets/images/uva-b.svg"></IonImg>
             </IonCol>
             <IonCol size="12">
                <IonText className="caidDescText">{desc}</IonText>
             </IonCol>
             <IonCol size="12">
             <div>
                <h1>
                                    <IonText className="caidHeadText">
                                        {accDescHead}
                                    </IonText>
                                    </h1>
                                    <ul className="caidmarUl">
                                        {accDescPoints.map((option: any, i: number) => {
                                            return (
                                                <li> <IonText className="caidDescText">{option}</IonText></li>

                                            );
                                        })}
                                    </ul>

                                </div>
                                </IonCol>
                                <IonCol>
             <div>
                                    <IonText className="caidHeadText">
                                        {accDescHead2}
                                    </IonText>
                                    <ul className="caidmarUl">
                                        {accDescPoints2.map((option: any, i: number) => {
                                            return (
                                                <li> <IonText className="caidDescText">{option}</IonText></li>

                                            );
                                        })}
                                    </ul>

                                </div>
                                </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </div>
    );
  };
  
  export default CoreAccordianImageDesc;
  
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonImg,
  IonInput,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewWillEnter,
} from "@ionic/react";
import "./Core2Chapter1Comp.css";
import { useRef, useState } from "react";
import ProgressContainer from "../../components/progress/Progress";
import ProgressFraction from "../../components/progress_fraction/ProgressFraction";
import { debug } from "console";
import { DataService } from "../../services/DataService";
import CoreAccordionDescriptionIntro from "../../components/core_accordion_description_intro/CoreAccordionDescriptionIntro";
import CoreAccordianImageDesc from "../../components/core_accordion_image_desc/CoreAccordianImageDesc";
import { AppInfoTimeService } from "../../services/AppInfoTimeService";

const Core2Chapter1Comp: React.FC = () => {
  var router = useIonRouter();
  const contentRef = useRef<HTMLIonContentElement | null>(null);
  const scrollIconRef = useRef<HTMLIonImgElement | null>(null);
  let scrollThroughBtn = false;
  const scrollToBottom = () => {
    contentRef.current && contentRef.current.scrollToBottom();
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrolling = () => {
    console.log("SCROLLLLLING");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "hidden";
    }
  };

  const scrollingEnd = () => {
    console.log("SCROLLLLLING ENDDDD");
    if (scrollIconRef.current) {
      scrollIconRef.current.style.visibility = "visible";
    }
  };

  return (
    <IonPage>
      <IonContent
        fullscreen
        style={{ "--ion-background-color": "#187685" }}
        ref={contentRef}
        scrollEvents={true}
        onIonScrollStart={scrolling}
        onIonScrollEnd={scrollingEnd}
      >
        <div
          className="cciBackButtonContainer"
          onClick={() => {
            router.goBack();
          }}
        >
          <IonImg src="/assets/images/LeftOutline-LeftOutline.png"></IonImg>
        </div>

        <IonGrid>
          <IonRow>
            <IonCol size="3"></IonCol>
            <IonCol size="7" className="cccColSet">
              <IonText>Core 2 Chapter 1</IonText>
            </IonCol>
            <IonCol size="2"></IonCol>
          </IonRow>
          <IonRow class="profileTitleRow cccMarRow">
            <IonCol size="3"></IonCol>
            <IonCol size="6" className="scsdTitle">
              <IonText className="titleText ccctext">
                Sun-Safe Behaviors
              </IonText>
            </IonCol>
            <IonCol
              className="scsdColRight"
              size="3"
              onClick={() => {
                //Start SSE Timer
                AppInfoTimeService.sendSSETimeToBE();

                router.push("home/skinchecktab", "forward", "push");
              }}
            >
              <IonText className="scsdRightButton">Body Map</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div style={{ marginBottom: "30px" }}></div>
        <div className="rbpmainContent rpMain cccMar">
          {/* <div className="htScrollIconContainer">
              <IonImg
                src="assets/icons/FixedScroll.png"
                className="htScrollIcon"
                onClick={scrollToBottom}
                ref={scrollIconRef}
                alt="none"
              ></IonImg>
            </div> */}
          <IonGrid>
            <IonRow>
              <IonCol size="12">
                <div style={{ marginBottom: "36px" }}></div>
                <IonText className="cccHeadText">
                  The Risks of Ultraviolet (UV) Rays from the Sun
                </IonText>
              </IonCol>
            </IonRow>
            <div style={{ marginBottom: "16px" }}></div>
            <CoreAccordionDescriptionIntro
              desc="Damage to the skin is caused by the sun’s ultraviolet (UV) rays, which you can’t see or feel. UV rays are different from the light from the sun that you see (visible light) or its warmth that you feel (infrared radiation). The sun’s UV rays can still be very strong even when it is cloudy or cool outside. That is why it is important to be sun-safe and protect your skin year round and in any weather conditions."
              accImg="./assets/images/qustnImg.png"
              accHeader="Did You Know?"
              accDesc="A number of factors can make the sun’s UV rays even stronger, including:"
              accPoints={[
                "The time of day: the sun’s UV rays are strongest from 10 a.m. to 4 p.m.",
                "Reflective surfaces such as water, sand, or snow",
                "Being at a higher altitude",
                "Being closer to the equator",
              ]}
              bottomDesc="Understanding UV is important to keep your skin safe. Even though a burn may heal, damage beneath the surface of the skin can never be repaired. However, it's never too late to protect your skin from further damage. Engaging in sun-safe behaviors will help you to reduce your risk of skin cancer and prevent skin aging."
            />
            <CoreAccordianImageDesc
              setOpen={false}
              desc="The sun emits two types of ultraviolet (UV) rays that can damage the skin:"
              accDescHead="UVA Rays"
              accDescHead2="UVB Rays"
              accDescPoints={[
                "Cause early Aging of the skin, wrinkles, and sun spots",
                "Damage the deeper layers of your skin",
                "Cause skin cancer",
              ]}
              accDescPoints2={[
                "Cause immediate damage in the form of a Burn",
                "Damage the top layers of your skin",
                "Cause skin cancer",
              ]}
            />
          </IonGrid>
        </div>
      </IonContent>
      <div className="buttonFooter">
        <IonGrid>
          <IonRow className="ion-justify-content-center">
            <IonCol
              className="custom-flex"
              style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                paddingTop: "8px",
                paddingBottom: "8px",
              }}
            >
              (
              <IonButton
                className="loginBtnEnabled scsdNextBtn"
                onClick={() => {}}
              >
                <IonText>Next</IonText>
              </IonButton>
              )
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default Core2Chapter1Comp;

import { IonGrid, IonRow, IonCol, IonImg, IonInput } from "@ionic/react";

import "./InputTextField.css";

interface InputTextFieldProps {
  placeholder: string;
  icon: string;
  isPassword: boolean;
  val: string;
  textChanged: Function;
  // onClear: Function;
}

const InputTextField: React.FC<InputTextFieldProps> = ({
  placeholder,
  icon,
  isPassword,
  val,
  textChanged,
}) => {
  // const [textVal, setTextVal] = useState("");

  // const clear = () => {
  //   setTextVal("")
  // }

  const onTextChanged = (event: Event) => {
    console.log("text changedddd: " + event);
    textChanged(event);
  };

  return (
    <div className="itfMainContainer">
      <IonGrid className="itfMainGrid">
        <IonRow>
          <IonCol size="1">
            <div className="itfUsernameIconUnderlineContainer">
              <IonImg src={icon} />
            </div>
          </IonCol>
          <IonCol size="11">
            <div className="itfTextFieldContainer">
              <IonInput
                placeholder={placeholder}
                className="itfTextField"
                type={isPassword ? "password" : "text"}
                onIonChange={onTextChanged}
                value={val}
              ></IonInput>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default InputTextField;

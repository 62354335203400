import { IonText, IonGrid, IonRow, IonCol, IonImg } from "@ionic/react";

import "./SelectedPointsSummary.css";

import "../../Fonts.css";

interface SelectedPointsSummaryProps {
  points: Array<string>;
  text: string;
  isActivitySummaryComp: Boolean;
}

const SelectedPointsSummary: React.FC<SelectedPointsSummaryProps> = ({
  points,
  text,
  isActivitySummaryComp,
}) => {
  return (
    <>
      {!isActivitySummaryComp && (
        <div className="spsMainDiv">
          <IonGrid>
            <IonRow>
              {text !== null && text !== undefined && text.length > 0 && (
                <IonCol className="spsTextCol" size="12">
                  <IonText className="spsText">{text}</IonText>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
          <IonGrid>
            {points.map((point, i) => {
              return (
                <IonRow className="spsMainRow">
                  <IonCol size="auto">
                    <IonImg
                      className="spsPointsImg"
                      src="assets/icons/CheckMark.png"
                    />
                  </IonCol>
                  <IonCol className="spsPointTextCol">
                    <IonText className="spsPointText">{point}</IonText>
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
        </div>
      )}
      {isActivitySummaryComp && (
        <IonGrid>
          <IonRow>
            <IonCol className="spsTextColRem" size="12">
              <IonText className="spsPointText">{text}</IonText>
            </IonCol>
          </IonRow>
          <IonGrid>
            {points.map((point, i) => {
              return (
                <IonRow className="spsMainRow">
                  <IonCol className="spsPointTextCol">
                    <IonText className="spsText spsMargin">{point}</IonText>
                  </IonCol>
                </IonRow>
              );
            })}
          </IonGrid>
        </IonGrid>
      )}
    </>
  );
};

export default SelectedPointsSummary;

import {
  IonPage,
  IonContent,
  IonButton,
  IonGrid,
  IonRow,
  IonText,
  IonCol,
  useIonRouter,
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonImg,
} from "@ionic/react";
import { SkinSelfCheckDataService } from "../../services/SkinSelfCheckDataService";
import { useEffect, useState } from "react";
import "./SkinCheckTour.css";



const SkinCheckTour: React.FC = () => {
  var router = useIonRouter();
  const [nextcount, setNextCount] = useState(1);

  const onClickNext = () => {
    if (nextcount == 5) {
      setNextCount(1)
      router.push("/home/hometab", "back", "push");
    } else {
      setNextCount(nextcount + 1)
    }

  }


  return (
    <IonPage>
      <IonContent fullscreen style={{ "--ion-background-color": "#003241" }}>
        <IonGrid>
          <IonRow className="sczColRow">
            <IonCol size="auto" className="sezColImg">
            {(nextcount == 1) &&
              <IonImg className="sezImgWid"
                src="/assets/images/Tour/1.svg"/>}
                {(nextcount == 2) &&
              <IonImg className="sezImgWid"
                src="/assets/images/Tour/2.svg"/>}
                {(nextcount == 3) &&
              <IonImg className="sezImgWid"
                src="/assets/images/Tour/3.svg"/>}
                {(nextcount == 4) &&
              <IonImg className="sezImgWid"
                src="/assets/images/Tour/4.svg"/>}
                {(nextcount == 5) &&
              <IonImg className="sezImgWid"
                src="/assets/images/Tour/5.svg"/>}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
      <div className="scz-bottom-div">
        <IonGrid>
          <IonRow className="sczRowmarg">
            <IonCol size="5"
              className="scz-tour-skip-col scz-tour-skip-button" onClick={() => {
                router.push("/home/hometab", "back", "push");
              }}
            >
              <IonText className="sczTextSkip">Skip</IonText>
            </IonCol>
            <IonCol size="1"></IonCol>
            <IonCol size="5"
              className="scz-tour-skip-col scz-tour-next-button"   onClick={() => {
                onClickNext()
              }}
            >
              <IonText className="sczTextNext">Next</IonText>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </IonPage>
  );
};

export default SkinCheckTour;
